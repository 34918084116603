import React, { useContext } from 'react';
import qs from 'qs';

import RedirectRoute from 'components/navigation/RedirectRoute';
import {
  LoginPageLegacy,
  ForgotPasswordPage,
  ResetPasswordPage,
  Portal,
  CompleteSignup,
  Support,
  AcceptInviteV2,
  OAuthRedirect,
  PortalManagement,
  ChannelAdmin,
  LoginPage,
  ChannelProfile,
} from 'pages';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';

import { RequireUserOnboarded } from './RequireUserOnboarded';
import { routes } from 'lane-shared/config';
import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { useOnboardingV2Enabled } from 'lane-shared/hooks';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';

import LoadingStates from 'components/general/LoadingStates';

import { OnboardingPage } from 'pages/OnboardingPage';
import { IdentifyUser } from 'pages/user/IdentifyUser';

import RestrictedPage from './pages/portal/post/restricted';
import ClaimMagicLink from './pages/user/ClaimMagicLink';
import MagicLinkPage from './pages/user/MagicLinkPage';
import SignUpPageLegacy from 'pages/user/SignupPage';
import { SignUpPage } from 'pages/SignUpPage';
import SignupValidateEmail from './pages/user/SignupV2/SignupValidateEmail';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0Context } from 'lane-shared/contexts/Auth0Context';

const KNOWN_PUBLIC_PATHS = [
  routes.content,
  routes.channelPublicProfile,
] as const;

export const AppRoutes = () => {
  const {
    user,
    route,
    isLoggingIn,
    isInitialized,
    isLoggedIn: isLoggedInViaActivate,
  } = useContext(UserDataContext);
  const { hasWhiteLabelLoaded, isTransitioning } = useContext(AppContext);
  const isOnboardingV2 = useOnboardingV2Enabled();
  // TODO: temporary flag to work on user identify page. replace it with useAuth0Enabled
  // once we have a working user identify page
  const isAuth0Enabled = useAuth0Enabled();
  const { isAuth0LogoutInProgress } = useAuth0Context();
  const {
    isAuthenticated: isAuthenticatedViaAuth0,
    isLoading: isAuth0Loading,
  } = useAuth0();

  const isLoadingState =
    !isInitialized ||
    isLoggingIn ||
    isTransitioning ||
    !hasWhiteLabelLoaded ||
    isAuth0Loading ||
    isAuth0LogoutInProgress;

  if (isLoadingState) {
    return <LoadingStates />;
  }

  // eslint-disable-next-line no-restricted-globals
  const query = qs.parse(location.search.substring(1));
  const contentPath = query.contentPath ? query.contentPath.toString() : null;

  const isUserAuthenticated = isAuthenticatedViaAuth0 || isLoggedInViaActivate;

  const isOnPublicPath = KNOWN_PUBLIC_PATHS.map(path =>
    // eslint-disable-next-line no-restricted-globals
    matchPath(location.pathname, { path })
  ).some(r => r?.isExact);

  return (
    <Switch>
      <Route
        exact
        path={routes.channelPublicProfile}
        component={ChannelProfile}
      />

      <Route exact path={routes.passwordReset} component={ResetPasswordPage} />

      <Route
        exact
        path={routes.forgotPassword}
        component={ForgotPasswordPage}
      />

      <Route
        exact
        path={routes.invite}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
        render={props => <AcceptInviteV2 user={user} {...props} />}
      />

      <Route
        exact
        path={routes.magicLink}
        render={props => <ClaimMagicLink {...props} />}
      />
      <Route exact path={routes.support} component={Support} />
      <Route path={routes.restricted} component={RestrictedPage} />

      <Route path={routes.identifier} component={IdentifyUser} />

      {
        // when user is already authenticated via Activate and we enable Auth0 feature flag later
        // we still want to respect Activate's authentication
        !isOnPublicPath && isAuth0Enabled && !isUserAuthenticated && (
          <Redirect to={routes.identifier} />
        )
      }

      {isAuth0Enabled && <Route path={routes.signup} component={SignUpPage} />}

      {isAuth0Enabled && (
        <Route path={routes.onboarding} component={OnboardingPage} />
      )}

      {isAuth0Enabled ? (
        <Route exact path="/">
          <RequireUserOnboarded>
            <Redirect to={contentPath || routes.home} />
          </RequireUserOnboarded>
        </Route>
      ) : (
        <RedirectRoute
          exact
          redirectWhen
          redirectTo={user ? routes.home : routes.login}
          path="/"
        />
      )}

      {isOnboardingV2 && !user && (
        <Route path={routes.login}>
          <LoginPage />
        </Route>
      )}

      {isOnboardingV2 && Boolean(user) && (
        <RedirectRoute
          exact
          redirectWhen={Boolean(user)}
          redirectTo={route || routes.home}
          path={routes.login}
          component={LoginPage}
        />
      )}

      {!isOnboardingV2 && (
        <RedirectRoute
          exact
          redirectWhen={Boolean(user)}
          redirectTo={route || routes.home}
          path={routes.login}
          component={LoginPageLegacy}
        />
      )}

      {!isOnboardingV2 && (
        <RedirectRoute
          exact
          redirectWhen={Boolean(user)}
          redirectTo={route || routes.home}
          path={routes.manualLogin}
          component={(props: any) => (
            <LoginPageLegacy {...props} isManualLogin />
          )}
        />
      )}
      <RedirectRoute
        exact
        redirectWhen={Boolean(user)}
        redirectTo={route || routes.home}
        path={routes.magicLinkRequest}
        component={MagicLinkPage}
      />
      <Route
        exact
        path={routes.signUpValidateEmail}
        component={SignupValidateEmail}
      />
      <RedirectRoute
        redirectWhen={Boolean(user)}
        redirectTo={route || routes.home}
        path={routes.signUp}
        component={SignUpPageLegacy}
      />

      <RedirectRoute
        redirectWhen={Boolean(user)}
        redirectTo={route || routes.home}
        path={routes.completeSignup}
        component={(props: any) => <CompleteSignup {...props} />}
      />

      <Route path={routes.channelAdmin} component={ChannelAdmin} />

      <Route path={routes.portalManagement} component={PortalManagement} />

      {/* Portal can be one of:
       * anonymous, not requiring login
       * private, requiring login
       */}
      <Route path="/l" component={Portal} />

      <Route path="/redirect/:provider" component={OAuthRedirect} />

      <Route
        path="/sso/saml2*"
        component={(match: any) => {
          window.location.href = `${window.location.origin}${match.location.pathname}`;

          return null;
        }}
      />

      {/* 404 Page */}
      <Route component={() => <h1>This resource was not found</h1>} />
    </Switch>
  );
};
