import React, { useMemo, useState, useContext, useEffect } from 'react';

import cx from 'classnames';
import {
  Button,
  ControlMenu,
  ErrorMessage,
  PageHeader,
  PageNavigationAlert,
} from 'components';
import { trimEnd, lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { createChannel } from 'lane-shared/graphql/mutation';
import { pause, getDisplayName } from 'lane-shared/helpers';
import { constructChannel } from 'lane-shared/helpers/channel';
import {
  CHILDREN_NAMES,
  getChildrenName,
  getSingularChildrenName,
} from 'lane-shared/helpers/constants/channel';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  validateChannelBase,
  validateChannelInfo,
} from 'lane-shared/validation/channel';

import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';

import styles from './styles.scss';

type Props = {
  parent: ChannelType;
};

export default function NewChildChannel({ parent }: Props) {
  const history = useHistory();
  const { user } = useContext(UserDataContext);
  const [, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [isChannelCreated, setIsChannelCreated] = useState(false);
  const [channel, setChannel] = useState(() =>
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    constructChannel({ userId: user._id, parent })
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (parent) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      setChannel(constructChannel({ userId: user._id, parent }));
    }
  }, [parent]);

  useEffect(() => {
    if (isChannelCreated) {
      history.push(`/l/channel/${channel.slug}/admin`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChannelCreated]);

  const childName = CHILDREN_NAMES[parent?.type];

  const isPristine = useMemo(() => {
    return (
      channel.name?.length === 0 &&
      channel.slug?.length === 0 &&
      channel.stats.subscribers === 0 &&
      !channel.description &&
      channel.stats.sf === 0 &&
      !channel.website &&
      channel.profile?.name.length === 0 &&
      !channel.profile?.email &&
      channel.tags.length === 0
    );
  }, [channel]);

  async function validate() {
    try {
      await validateChannelBase.validate(channel, { abortEarly: false });
      await validateChannelInfo.validate(channel, { abortEarly: false });
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  function onChannelUpdated(props: any) {
    setChannel({
      ...channel,
      ...props,
    });

    if (validation) {
      validate();
    }
  }

  const handleOnCancel = async () => {
    const url = routes.channelAdminProperties.replace(':id', parent?.slug);

    history.push(url);
  };

  async function onCreateChannel() {
    // cleaning out channel parent info
    delete channel?.parent?.name;

    if (!(await validate())) {
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await pause();

      // remove some _ids since this is a create.
      // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
      delete channel._id;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      delete channel.address._id;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      delete channel.profile._id;

      await getClient().mutate({
        mutation: createChannel,
        variables: { channel },
      });

      setIsChannelCreated(true);
    } catch (err) {
      setError(err);
      window.Alert.alert({
        title: `Error adding new ${childName}.`,
        message: `This ${childName} was not created, please see the error below and try again.`,
        error: err,
      });
    }

    setLoading(false);
  }

  const isMFCreatePropertyFlagEnabled = useFlag(
    FeatureFlag.MultifamilyCreateProperty,
    false
  );

  const childrenSingularName = getSingularChildrenName(parent?.type);
  const childrenName = getChildrenName(parent?.type);

  const heading = isMFCreatePropertyFlagEnabled
    ? t('web.pages.portal.admin.channel.children.new.heading', {
        type: childrenSingularName,
        parentChannelName: getDisplayName(parent),
      })
    : `
      Add a new ${
        childName === CHILDREN_NAMES[ChannelTypeEnum.Property]
          ? 'property'
          : trimEnd(lowerFirst(childName), 's')
      } under ${getDisplayName(parent)}
    `;

  const validationSummaryMessage = t(
    'web.admin.channel.generic.validationSummary'
  );

  return (
    <div className={cx(styles.NewChannel, styles.fullWidth)}>
      {isMFCreatePropertyFlagEnabled ? (
        <>
          <PageNavigationAlert
            when={!isPristine && !isChannelCreated}
            testId="pageNavigationAlert"
          />
          <PageHeader
            header={heading as string}
            headerLevel="h3"
            breadcrumbs={[
              {
                label: childrenName,
                url: routes.channelAdminProperties.replace(':id', parent?.slug),
              },
              {
                label: t('web.pages.portal.admin.channel.children.new.add', {
                  type: childrenSingularName,
                }),
              },
            ]}
            actionButtons={[
              {
                label: t(
                  'web.pages.portal.admin.channel.children.new.cancelButton'
                ),
                onClick: handleOnCancel,
                type: 'secondary',
              },
              {
                label: t(
                  'web.pages.portal.admin.channel.children.new.createButton'
                ),
                onClick: onCreateChannel,
                type: 'primary',
                testId: 'createPropertyButton',
              },
            ]}
          />
        </>
      ) : (
        <ControlMenu className={styles.controlMenu}>
          <Button
            onClick={onCreateChannel}
            variant="contained"
            testId="createPropertyButton"
          >
            {t('web.pages.portal.admin.channel.children.new.createButton')}
          </Button>
        </ControlMenu>
      )}

      <ErrorMessage error={error} />
      {isMFCreatePropertyFlagEnabled && (
        <ErrorMessage
          className={styles.errorMessage}
          error={validation && validationSummaryMessage}
          fullWidth
        />
      )}
      <section>
        <ChannelInfoEdit
          heading={heading}
          validation={validation}
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          shouldShowStats
          channelForDataIdentifiers={channel}
          forCreate
        />
        {isMFCreatePropertyFlagEnabled && (
          <ControlMenu className={styles.ControlMenuBottom}>
            <Button
              onClick={onCreateChannel}
              interfaceStyle="light"
              variant="contained"
              size="medium"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"defau... Remove this comment to see the full error message
              color="primary"
            >
              {t('web.pages.portal.admin.channel.children.new.createButton')}
            </Button>
            <Button
              onClick={handleOnCancel}
              interfaceStyle="light"
              variant="outlined"
              size="medium"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"def... Remove this comment to see the full error message
              color="secondary"
            >
              {t('web.pages.portal.admin.channel.children.new.cancelButton')}
            </Button>
          </ControlMenu>
        )}
      </section>
    </div>
  );
}
