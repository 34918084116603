import React from 'react';

import { UCIs } from 'components/interactions/UCIs';
import AdminPage from 'components/layout/AdminPage';

import styles from './styles.scss';
import useUserLocale from 'hooks/useUserLocale';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';

export function ChannelPaymentAccountTransactions({
  channel,
  paymentAccountId,
}: any) {
  const locale = useUserLocale();

  return (
    <AdminPage className={styles.ChannelPaymentAccounts}>
      <h1>Transactions</h1>
      <UCIs
        channelId={channel?._id}
        paymentAccountId={paymentAccountId}
        additionalHeaders={[
          {
            key: 'features?.Payment?.amount',
            header: 'Payment Total',
            render: (_amount: any, interaction: any) => {
              const formatter = currencyFormatter({
                locale,
                currency: interaction.features?.Payment?.currency,
              });

              return formatter(
                interaction.features?.Payment?.amount / CURRENCY_CONVERSION
              );
            },
          },
          {
            key: 'features?.Payment',
            header: 'Payment Card',
            render: (payment: any) =>
              `${payment?.cardBrand} - ${payment?.cardLast4}`,
          },
        ]}
      />
    </AdminPage>
  );
}
