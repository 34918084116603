import React, { useEffect, useState } from 'react';

// TODO: update the use-debounce library, newest version has breaking API changes
import { useDebouncedCallback } from 'use-debounce';

import { ContentType } from 'lane-shared/types/content/Content';

export type WizardStepProps<T> = {
  index: number;
  totalSteps: number;
  content?: ContentType;
  wizardState: IWizardState<T>;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToStep: (stepIndex: number) => void;
  goToStepByName: (step: WizardStepComponent<T>) => void;
  onDataUpdated: (data: Partial<T>) => void;
  resetWizard: () => void;
};

// TODO: WizardSceneComponent should be memorized to prevent rerender when wizard state data changes
export type WizardStepComponent<T> = React.FC<WizardStepProps<T>>;

export type WizardProps<T> = {
  scenes: Array<WizardStepComponent<T>>;
  initialWizardState: Omit<IWizardStateInitializer<T>, 'numSteps'>;
  onDataUpdated: (data: T) => void;
  onCompleteStep?(wizardState: IWizardState<T>): Promise<void>;
  onComplete?(wizardState: IWizardState<T>): Promise<void>;
  // keep in data will be preserved inside of wizardState even if it's changed
  keepInData?: Partial<T>;
};

export type IWizardStateInitializer<T> = Omit<
  IWizardState<T>,
  'previousStepIndex' | 'isComplete'
>;

export interface IWizardState<T> {
  readonly numSteps: number;
  readonly data: T;
  readonly currentStepIndex: number;
  readonly previousStepIndex: number;
  readonly isComplete: boolean;
}

export class WizardState<T extends {}> implements IWizardState<T> {
  constructor(
    public numSteps: number,
    public data: T,
    public currentStepIndex: number = 0,
    public previousStepIndex: number = currentStepIndex,
    public isComplete: boolean = false
  ) {}

  goToNextStep(): boolean {
    if (this.goToStep(this.currentStepIndex + 1)) {
      return true;
    }

    if (this.currentStepIndex === this.numSteps - 1) {
      this.isComplete = true;

      return true;
    }

    return false;
  }

  goToPrevStep(): boolean {
    return this.goToStep(this.currentStepIndex - 1);
  }

  goToStep(stepIndex: number): boolean {
    if (stepIndex >= 0 && stepIndex < this.numSteps) {
      this.previousStepIndex = this.currentStepIndex;
      this.currentStepIndex = stepIndex;

      return true;
    }

    return false;
  }

  setData(newData: Partial<T>) {
    const updatedData = {
      ...this.data,
      ...newData,
    };

    if (JSON.stringify(this.data) !== JSON.stringify(updatedData)) {
      this.data = { ...updatedData, updatedAt: new Date() };

      return true;
    }

    return false;
  }

  copy(): WizardState<T> {
    return new WizardState<T>(
      this.numSteps,
      this.data,
      this.currentStepIndex,
      this.previousStepIndex,
      this.isComplete
    );
  }

  static fromState<U extends {}>(
    existingState: IWizardStateInitializer<U>
  ): WizardState<U> {
    return new WizardState<U>(
      existingState.numSteps,
      existingState.data,
      existingState.currentStepIndex
    );
  }
}

export function useWizard<T extends {}>({
  scenes,
  initialWizardState,
  onDataUpdated,
  onCompleteStep,
  onComplete,
  keepInData,
}: WizardProps<T>) {
  const [wizardState, setWizardState] = useState<IWizardState<T>>(
    WizardState.fromState({
      ...initialWizardState,
      numSteps: scenes.length,
    })
  );

  useEffect(() => {
    if (scenes.length !== wizardState.numSteps) {
      setWizardState({
        ...wizardState,
        numSteps: scenes.length,
      });
    }
  }, [scenes.length]);

  useEffect(() => {
    if (onCompleteStep) {
      onCompleteStep(wizardState).catch(e => console.log(e));
    }
  }, [wizardState.currentStepIndex]);

  useEffect(() => {
    if (wizardState.isComplete && onComplete) {
      onComplete(wizardState).catch(e => console.log(e));
    }
  }, [wizardState.isComplete]);

  useEffect(() => {
    onDataUpdated(wizardState.data);
    // WizardState.setData already uses JSON to compare old and new data
  }, [wizardState.data]);

  useEffect(() => {
    if (keepInData) {
      setWizardState(previousState => {
        const wizardStateInstance = WizardState.fromState(previousState);

        // Only update when necessary to reduce unnecessary re-renders (can be important for performance on mobile)
        if (wizardStateInstance.setData(keepInData)) {
          return wizardStateInstance.copy();
        }

        return previousState;
      });
    }
  }, [keepInData]);

  function goToStep(stepIndex: number) {
    setWizardState(previousState => {
      const wizardStateInstance = WizardState.fromState(previousState);

      // Only update when necessary to reduce unnecessary re-renders (can be important for performance on mobile)
      if (wizardStateInstance.goToStep(stepIndex))
        return wizardStateInstance.copy();

      return previousState;
    });
  }

  return {
    wizardState,
    goToNextStep: useDebouncedCallback(
      () => {
        setWizardState(previousState => {
          const wizardStateInstance = WizardState.fromState(previousState);

          // Only update when necessary to reduce unnecessary re-renders (can be important for performance on mobile)
          if (wizardStateInstance.goToNextStep())
            return wizardStateInstance.copy();

          return previousState;
        });
      },
      500,
      { leading: true, trailing: false }
    ).callback,
    goToPrevStep: useDebouncedCallback(
      () => {
        setWizardState(previousState => {
          const wizardStateInstance = WizardState.fromState(previousState);

          // Only update when necessary to reduce unnecessary re-renders (can be important for performance on mobile)
          if (wizardStateInstance.goToPrevStep())
            return wizardStateInstance.copy();

          return previousState;
        });
      },
      500,
      { leading: true, trailing: false }
    ).callback,
    goToStep,
    goToStepByName: (step: WizardStepComponent<T>) => {
      const stepIndex = scenes.findIndex(s => s === step);

      goToStep(stepIndex);
    },
    setData: (data: Partial<T>) => {
      setWizardState(previousState => {
        const wizardStateInstance = WizardState.fromState(previousState);

        // Only update when necessary to reduce unnecessary re-renders (can be important for performance on mobile)
        if (wizardStateInstance.setData(data))
          return wizardStateInstance.copy();

        return previousState;
      });
    },
    resetWizard: () => {
      setWizardState(
        WizardState.fromState({
          ...initialWizardState,
          numSteps: scenes.length,
        })
      );
    },
  };
}
