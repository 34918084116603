import { useMemo } from 'react';
import { Item } from 'components/form/Dropdown/Dropdown';
import {
    GetTenantSuitesQuery,
  } from 'graphql-query-contracts';

interface Suite {
  id: string;
  name: string;
  floors: { id: string; name: string }[];
}

const processSuiteList = (suitesData: GetTenantSuitesQuery | undefined) => {
 const suites = (suitesData?.tenant?.suites || []).filter((suite): suite is Suite => suite !== null);

  const floorNamesSet = new Set<string>();
  const suiteNames: string[] = [];
  const suitesAsLabelValue: Item<string>[] = [];
  const selectedSuitesIds :string[] = [];

  suites.forEach((suite) => {
    suiteNames.push(suite.name);
    selectedSuitesIds.push(suite?.id);
    suitesAsLabelValue.push({ label: suite.name, value: suite.id });
    suite.floors.forEach((floor) => {
      floorNamesSet.add(floor.name);
    });
  });

  const floorNames = Array.from(floorNamesSet).join(', ') || '-';

  return {
    floorNames,
    suiteNames: suiteNames.join(', ') || '-',
    suitesAsLabelValue,
    selectedSuitesIds,
  };
};

export const useProcessSuiteList = (suites: GetTenantSuitesQuery | undefined) => {
  return useMemo(() => processSuiteList(suites), [suites]);
};
