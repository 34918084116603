import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';

import { currencyFormatter } from '../../../helpers/formatters';
import { EssensysProductFeatureProperties } from '../../../types/features/EssensysProductFeatureProperties';
import { PaymentFeatureProperties } from '../../../types/features/PaymentFeatureProperties';

const essensysProductsQuery = gql`
  query essensys($productIds: [ID!]!) {
    essensys {
      products(productIds: $productIds) {
        productId
        productName
      }
    }
  }
`;

type EssensysProductQuery = {
  essensys: {
    products: { [key: string]: any }[];
  };
};

export default function usePointOfSaleLineItem({
  userLocale,
  essensysProductFeature,
  paymentFeature,
}: {
  userLocale: string;
  essensysProductFeature: EssensysProductFeatureProperties;
  paymentFeature?: PaymentFeatureProperties;
}): { loading: boolean; lineItem?: { name: string; price: string } } {
  const { t } = useTranslation();
  const essensysProductsResponse = useQuery<EssensysProductQuery>(
    essensysProductsQuery,
    {
      skip: !essensysProductFeature?.productId,
      variables: {
        productIds: [essensysProductFeature?.productId],
      },
    }
  );

  if (!essensysProductFeature?.productId) {
    return {
      loading: false,
    };
  }

  const price = paymentFeature?.amounts.find(
    pf => pf.type.toLowerCase() === 'cash'
  );

  if (!price || !price?.currency) {
    return {
      loading: essensysProductsResponse?.loading,
    };
  }

  const formatCurrency = currencyFormatter({
    locale: userLocale,
    currency: price?.currency,
  });

  const essensysProduct = essensysProductsResponse.data?.essensys.products?.[0];

  if (!essensysProduct) {
    return { loading: essensysProductsResponse?.loading };
  }

  return {
    loading: essensysProductsResponse?.loading,
    lineItem: {
      name: essensysProduct.productName,
      price: price.amount
        ? formatCurrency(price.amount / CURRENCY_CONVERSION)
        : t('N/A'),
    },
  };
}
