import React from 'react';

import { Control, Controller } from 'react-hook-form';

import { TextArea } from 'design-system-web';

type Props = {
  control: Control<any>;
  name: string;
  showLengthIndicator?: boolean | undefined;
  minRows: number;
  containerClassName?: string | undefined;
  maxLength?: number | undefined;
  placeholder?: string;
  id?: string;
};

export function HookFormTextarea({
  control,
  name,
  showLengthIndicator,
  minRows,
  containerClassName,
  maxLength,
  placeholder,
  id,
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextArea
          onChange={onChange}
          value={value}
          showLengthIndicator={showLengthIndicator}
          minRows={minRows}
          containerClassName={containerClassName}
          maxLength={maxLength}
          placeholder={placeholder}
          id={id}
        />
      )}
    />
  );
}
