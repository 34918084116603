import React, { useState, useEffect, useContext } from 'react';

import {
  ModalBackground,
  ResizableWindow,
  ControlMenu,
  TabStrip,
  Button,
  SendInviteModal,
  UserSearchOnChannel,
  ChannelSelectorButton,
  IconButton,
} from 'components';
import { useChannelForAdminQuery } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext, AnalyticsContext } from 'lane-shared/contexts';
import { addUserGroupRole } from 'lane-shared/graphql/mutation';
import { hasPermission, pause } from 'lane-shared/helpers';
import { emitMemberAddedToGroupRole } from 'lane-shared/helpers/analytics/emitGroupRole';
import {
  PERMISSION_ADMIN,
  PERMISSION_USERS_INVITE,
} from 'lane-shared/helpers/constants/permissions';
import { useFlag, useGroupRoleQuery } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import { ChannelSelectorModesEnum } from 'components/lane/ChannelSelector';
import AdminPage from 'components/layout/AdminPage';

import TeamInvites from './TeamInvites';
import TeamMembers from './TeamMembers';

import styles from './styles.scss';
import { routes } from 'lane-shared/config';
import { history } from 'helpers';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

const tabs = [
  {
    label: 'web.pages.portal.admin.channel.teamManagement.team.view.tabMembers',
    value: 'Members',
  },
  {
    label: 'web.pages.portal.admin.channel.teamManagement.team.view.tabInvites',
    value: 'Invites',
  },
];

type Props = {
  channel: ReturnType<typeof useChannelForAdminQuery>['channel'];
};

export default function TeamView({ channel }: Props) {
  const { t } = useTranslation();
  const { groupRoleId } = useParams<{ groupRoleId: string }>();
  const { user } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [loading, setLoading] = useState(false);
  const [isSendInviteOpen, setIsSendInviteOpen] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [selectedChannelId, setSelectedChannel] = useState(channel?._id);
  const [selectedUser, setSelectedUser] = useState<UserType>();
  const { groupRole } = useGroupRoleQuery({ groupRoleId });

  useEffect(() => {
    setSelectedChannel(channel?._id);
  }, [channel?._id]);

  const inviteAddUserSeparationFlag = useFlag(
    FeatureFlag.InviteAddUserSeparation,
    false
  );
  const canAddUserPermissions = inviteAddUserSeparationFlag
    ? [PERMISSION_ADMIN]
    : [PERMISSION_ADMIN, PERMISSION_USERS_INVITE];

  const canAddUser =
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    user.isSuperUser ||
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    hasPermission(user.roles, canAddUserPermissions, channel?._id);

  async function onAddUser(user: any) {
    setLoading(true);

    try {
      await pause();
      await getClient().mutate({
        refetchQueries: ['queryChannelUsersByGroupRole'],
        mutation: addUserGroupRole,
        variables: {
          userGroupRole: {
            user: {
              _id: user._id,
            },
            groupRole: {
              _id: groupRole?._id,
            },
          },
        },
      });

      window.Toast.show(
        <p>
          {t('web.pages.portal.admin.channel.team.toastSuccess', {
            userProfileName: user.profile.name,
          })}
        </p>
      );
      emitMemberAddedToGroupRole({
        userId: user._id,
        groupRoleId: groupRole?._id,
        groupRoleName: groupRole?.name,
        analytics,
      });
      // do a thing
    } catch (err) {
      window.Alert.alert({
        title: t('web.pages.portal.admin.channel.team.toastErrorTitle', {
          userProfileName: user.profile.name,
        }),
        message: t('web.pages.portal.admin.channel.team.toastErrorMessage', {
          userProfileName: user.profile.name,
          channelName: channel?.name,
        }),
        error: err,
      });
    }

    setIsAddUserOpen(false);
    setLoading(false);
  }

  function onCloseAddUser() {
    setIsAddUserOpen(false);
    setSelectedUser(undefined);
    setSelectedChannel(undefined);
  }

  const handleBulkInvite = () => {
    const url = routes.channelAdminTeamsBulkInvite
      .replace(':id', channel?.slug || '')
      .replace(':groupRoleId', groupRoleId || '');

    history.push(url);
  };

  return (
    <AdminPage className={styles.TeamView}>
      <ControlMenu>
        <h1>{groupRole?.name}</h1>

        <Link
          to={`/l/channel/${channel?.slug}/admin/teams/${groupRole?._id}/edit`}
        >
          <IconButton testId="permissionsSettings" icon="cog" inverted />
        </Link>

        {canAddUser && (
          <Button
            loading={loading}
            onClick={() => {
              setIsAddUserOpen(true);
              setSelectedChannel(channel?._id);
            }}
            testId="buttonAddUser"
          >
            {t('web.pages.portal.admin.channel.team.addUserButton')}
          </Button>
        )}

        <Button
          loading={loading}
          onClick={handleBulkInvite}
          testId="buttonBulkInviteUser"
        >
          {t('web.pages.portal.admin.channel.team.inviteUsers')}
        </Button>
      </ControlMenu>

      <TabStrip
        tabs={tabs}
        selected={selectedTab}
        onSelectTab={tab => setSelectedTab(tab)}
      />

      {!isAddUserOpen && selectedTab.value === tabs[0].value && (
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        <TeamMembers channelId={channel?._id} groupRole={groupRole} />
      )}

      {selectedTab.value === tabs[1].value && (
        <TeamInvites
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "_id"... Remove this comment to see the full error message
          channel={channel}
          groupRole={groupRole}
          isSendInviteOpen={isSendInviteOpen}
        />
      )}

      <SendInviteModal
        className={styles.sendInvite}
        isOpen={isSendInviteOpen}
        groupRoleId={groupRole?._id}
        groupRoleName={groupRole?.name}
        channel={channel}
        onClose={() => setIsSendInviteOpen(false)}
      />

      <ModalBackground
        className={styles.modal}
        onClose={onCloseAddUser}
        isOpen={isAddUserOpen}
        hasOpaqueBackground
      >
        <ResizableWindow
          name="teamMembersAdd"
          showHeader
          className={styles.window}
          onClose={onCloseAddUser}
          defaultPosition={ResizableWindow.centerPosition()}
        >
          <div className={styles.wrapper}>
            <ChannelSelectorButton
              channelId={selectedChannelId}
              modes={[
                ChannelSelectorModesEnum.Geo,
                ChannelSelectorModesEnum.Subscriptions,
              ]}
              className={styles.channel}
              onChannelSelected={(channel: any) =>
                setSelectedChannel(channel?._id)
              }
            />

            {selectedChannelId && (
              <UserSearchOnChannel
                className={styles.userSearch}
                selectedUserId={selectedUser?._id}
                channelId={selectedChannelId}
                onUserSelected={(user: any) =>
                  setSelectedUser(user ?? undefined)
                }
                includeWorkplaceMember
              />
            )}

            <menu>
              <Button
                disabled={!selectedUser}
                onClick={() => onAddUser(selectedUser)}
                testId="windowAddUser"
              >
                {t('web.pages.portal.admin.channel.team.addUserButton')}
              </Button>
            </menu>
          </div>
        </ResizableWindow>
      </ModalBackground>
    </AdminPage>
  );
}
