import React, { PureComponent } from 'react';

import { Icon } from 'design-system-web';
import { withTranslation } from 'react-i18next';

import { OAUTH_PROVIDERS } from 'lane-shared/helpers/constants/user';

class OAuthRedirect extends PureComponent {
  componentDidMount() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'match' does not exist on type 'Readonly<... Remove this comment to see the full error message
      match: {
        params: { provider },
      },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 't' does not exist on type 'Readonly<{}> ... Remove this comment to see the full error message
      t,
    } = this.props;
    // We can use query-string lib here, URLSearchParams (this does not support
    // IE11 or Edge), or something else. Ill use this brute method for now
    const q = window.location.search
      ? window.location.search.substring(1)
      : window.location.hash.substring(1);
    const queryMap = {};

    q.split('&').forEach(qp => {
      const sub = qp.split('=');
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
       
      queryMap[sub[0]] = sub[1];
    });

    if ((queryMap as any).error) {
      const errorMessage = (queryMap as any).error_description
        ? (queryMap as any).error_description
        : t('Login failed. Please try again.');

      this.sendMessage({
        error: (queryMap as any).error,
        errorMessage,
        from: provider,
      });

      // Close tab if user cancelled login
      if ((queryMap as any).error === 'user_cancelled_login') {
        window.close();
      }
    } else {
      switch (provider) {
        case OAUTH_PROVIDERS.LINKEDIN:
          if ((queryMap as any).code)
            this.sendMessage({ code: (queryMap as any).code, provider });

          break;
        case OAUTH_PROVIDERS.GOOGLE:
          if ((queryMap as any).access_token)
            this.sendMessage({ provider, ...queryMap });

          break;
        default:
        // window.close();
      }
    }
  }

  sendMessage = (msg: any) => {
    if (window.opener) window.opener.postMessage(msg, window.location.origin);
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          name="check"
          style={{
            width: '150px',
            height: '150px',
            color: '#0ad26e',
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(OAuthRedirect);
