export const RECURRING_CHARGE_STATUS_MAP: {
  [key: string]: [string, string];
} = {
  EXPIRED: ['abp.subscription.status.expired', 'grey'],
  ACTIVE: ['abp.subscription.status.active', 'green'],
};

export function getRecurringChargeStatus(active: any): [string, string] {
  if (active) {
    return RECURRING_CHARGE_STATUS_MAP.ACTIVE;
  }

  return RECURRING_CHARGE_STATUS_MAP.EXPIRED;
}
