import React, { useEffect, useState } from 'react';

import { Icon, DatePickerButton } from 'design-system-web';
import { getWeekOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  IconButton,
  Input,
  Label,
  Radio,
  TextArea,
} from 'lane-web/src/components';
import { H4, H5 } from 'lane-web/src/components/typography';
import AssignedMemberDropdown from 'lane-web/src/pages/portal/admin/channel/service-requests/details/components/AssignedMemberDropdown';

import {
  recurrenceOptions,
  recurrenceTypes,
} from '../../constants/recurentTypes';
import {
  Equipment,
  ClientPmSchedule,
  CreatePmScheduleDto,
} from 'graphql-query-contracts';

import { StaffTeamsMultiSelectDropdown } from 'pages/portal/admin/channel/service-requests/details/components/StaffTeamsMultiSelectDropdown';

import {
  AttachmentData,
  PMAttachments,
  SelectFileReturnType,
} from '../PMAttachments';
import { scheduleSchema } from './scheduleFormSchema';

import styles from './index.scss';

interface IScheduleFormProps {
  channel: any;
  scheduleData?: ClientPmSchedule;
  title: string;
  channelEquipments: Equipment[];
  attachmentFiles: AttachmentData[];
  onAddAttachments: SelectFileReturnType;
  onRemoveAttachment: (index: string) => void;
  onSaveSchedule: (schedule: CreatePmScheduleDto) => void;
  onCancel: () => void;
  saveButtonText: string;
}

enum ScheduleEndDateType {
  Never = 'never',
  Specific = 'specific',
}

export type ScheduleFormValidationErrors = {
  [x in keyof CreatePmScheduleDto]: string[];
};

export function ScheduleForm({
  title,
  channel,
  scheduleData,
  channelEquipments,
  attachmentFiles,
  onAddAttachments,
  onRemoveAttachment,
  onSaveSchedule,
  onCancel,
  saveButtonText,
}: IScheduleFormProps) {
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<ClientPmSchedule | undefined>({
    ...scheduleData!,
  });

  const [
    validationErrors,
    setValidationErrors,
  ] = useState<ScheduleFormValidationErrors>();
  const [assignedMember, setAssignedMember] = useState<{
    label: string;
    profile: string;
    value: string;
  }>();

  const [assignedTeams, setAssignedTeams] = useState<
    {
      label: string;
      profile: any;
      value: string;
    }[]
  >([]);

  const [isEquipmentLinked, setIsEquipmentLinked] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState<
    { category?: string; equipment?: Equipment }[]
  >([]);

  const [endDateType, setEndDateType] = useState(
    scheduleData?.untilDate
      ? ScheduleEndDateType.Specific
      : ScheduleEndDateType.Never
  );

  const [equipments, setEquipments] = useState<Map<string, Equipment[]>>(
    new Map<string, Equipment[]>()
  );

  const [assignableMembers, _, assignableTeams] = useAssignableMembers(
    channel?._id
  );

  useEffect(() => {
    // set selected assigned
    if (assignableMembers?.length > 0 && scheduleData?.assignee) {
      const assignedUser = assignableMembers.find(
        (u: { value: string }) =>
          convertToUUID(u.value) === scheduleData.assignee?.id
      );

      setAssignedMember(assignedUser);
    }

    if (assignableTeams?.length > 0 && scheduleData?.assigneeGroups) {
      const assignedGroups = assignableTeams.filter(t =>
        scheduleData.assigneeGroups?.find(
          id => convertToUUID(id) === convertToUUID(t.value)
        )
      );

      setAssignedTeams(assignedGroups);
    }
  }, [assignableMembers.length, assignableTeams.length]);

  useEffect(() => {
    const eqMap = new Map<string, Equipment[]>();

    channelEquipments.forEach(eq => {
      if (eqMap.has(eq.category)) {
        const arr = eqMap.get(eq.category);

        arr!.push(eq);
      } else {
        eqMap.set(eq.category, [eq]);
      }
    });

    setEquipments(eqMap);

    // set selected equipments
    if (scheduleData?.equipmentIds && scheduleData.equipmentIds.length > 0) {
      const pmEqs = scheduleData.equipmentIds
        .map(eqId => {
          const eq = channelEquipments.find(eq => eq.id === eqId);

          return eq ? { category: eq.category, equipment: eq } : undefined;
        })
        .filter(eq => eq !== undefined) as {
        category: string;
        equipment: Equipment;
      }[];

      setIsEquipmentLinked(true);
      setSelectedEquipments(pmEqs);
    }
  }, [channelEquipments.length]);

  const handleEquipmentCategoryChange = (category: any, index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = category.value;
    newSelectedEquipments[index]!.equipment = undefined;
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleEquipmentChange = (equipment: any, index: number) => {
    const selectedEq = selectedEquipments[index];
    const category = selectedEq?.category;

    if (!category) return;

    const equipmentOptions = equipments.get(category);
    const equipmentData = equipmentOptions!.find(
      equipmentOption => equipmentOption.id === equipment.value
    );

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = equipmentData?.category;
    newSelectedEquipments[index]!.equipment = equipmentData;
    setSelectedEquipments(newSelectedEquipments);

    if (newSelectedEquipments.length > 0) {
      onScheduleFieldChange('location', undefined);
    }
  };

  const handleAddEquipment = () => {
    if (selectedEquipments[selectedEquipments.length - 1]?.equipment === null)
      return;

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.push({ category: undefined, equipment: undefined });
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleRemoveEquipment = (index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.splice(index, 1);
    setSelectedEquipments(newSelectedEquipments);
  };

  const onScheduleFieldChange = (
    prop: keyof ClientPmSchedule,
    value: unknown
  ) => {
    setSchedule({
      ...(schedule ?? ({} as ClientPmSchedule)),
      [prop]: value,
    });
  };

  const onSave = async () => {
    setValidationErrors(undefined);

    if (!schedule) return;

    let weekN = schedule.nextDueDate
      ? getWeekOfMonth(schedule.nextDueDate)
      : schedule.weekNo;

    if (weekN && weekN > 4) {
      weekN = 4;
    }

    const dataToValidate = {
      ...schedule,
      endDateType,
      extRefId: channel._id,
      assignee: assignedMember?.value ?? '',
      weekday: schedule.nextDueDate
        ? schedule.nextDueDate.getDay()
        : schedule.weekday,
      weekNo: weekN,
      monthNo: schedule.nextDueDate
        ? schedule.nextDueDate!.getMonth() + 1
        : schedule.monthNo,
      equipmentIds: selectedEquipments
        .filter(eq => eq.equipment)
        .map(eq => eq.equipment!.id),
      assigneeGroups: assignedTeams.map(team => convertToUUID(team.value)),
    };

    let dataToSave: CreatePmScheduleDto;

    try {
      dataToSave = scheduleSchema.parse(dataToValidate) as CreatePmScheduleDto;
    } catch (err) {
      window.Toast.show(
        t('web.admin.workOrder.preventiveMaintenance.schedule.Form.toast.error')
      );

      if (
        err instanceof z.ZodError ||
        (err as any).constructor.name === z.ZodError.name
      ) {
        const vErrors = (err as z.ZodError).formErrors.fieldErrors;

        setValidationErrors(vErrors as ScheduleFormValidationErrors);
      }

      return;
    }

    switch (schedule?.repeats) {
      case recurrenceTypes.BIWEEKLY:
      case recurrenceTypes.BIMONTHLY:
        dataToSave.interval = 2;
        break;
      case recurrenceTypes.QUARTERLY:
        dataToSave.interval = 3;
        break;
    }

    await onSaveSchedule(dataToSave);
  };

  const translationKey = (suffix: string) =>
    `web.admin.workOrder.preventiveMaintenance.schedule.Form.${suffix}`;

  const translate = (key: string) => t(translationKey(key));

  const toggleEquipmentLinked = () => {
    // clean out selected equipments when equipments de-linked
    if (!isEquipmentLinked) {
      setSelectedEquipments([]);
    }

    setIsEquipmentLinked(!isEquipmentLinked);
  };

  return (
    <Flex direction="column" gap={5} className={styles.Container}>
      <H4>{title}</H4>
      <Flex direction="column">
        <Label mt={0}>
          {translate('scheduleTitle')}
          <span className={styles.required}>*</span>
        </Label>
        <Input
          testId="schedule-title"
          value={schedule?.title}
          onChange={(value: string) => onScheduleFieldChange('title', value)}
          maxLength={50}
          showLengthIndicator
          error={validationErrors?.title}
          fieldName="schedule-title"
        />
      </Flex>

      <Flex gap={3} className={styles.AssigneeDropdowns}>
        <StaffTeamsMultiSelectDropdown
          items={assignableTeams}
          isSearchable
          value={assignedTeams as any}
          onChange={(newTeams: any[]) => {
            setAssignedTeams(newTeams ?? []);
          }}
          placeholder={translate('select.teams')}
          label={translate('assign.teams')}
          className={styles.SelectDropdown}
        />

        <AssignedMemberDropdown
          fieldName="assign-to-person"
          users={assignableMembers}
          setAssignedMember={setAssignedMember}
          assignedMember={assignedMember}
          placeholder={translate('select.person')}
          label={translate('assign.person')}
          testId="assignedMemberDropdown"
        />
      </Flex>

      {channel?.settings?.hasWorkOrderEquipmentEnabled && (
        <>
          {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ style: { ma... Remove this comment to see the full error message */}
          <Checkbox
            text={translate('linkEquipment')}
            testId="link-equipment"
            selected={isEquipmentLinked}
            onChange={toggleEquipmentLinked}
            name="link-equipment"
          />
          {isEquipmentLinked && (
            <Flex direction="column" gap={3}>
              {selectedEquipments.length > 0 && (
                <Flex className={styles.EquipmentLabels}>
                  <Label mt={0}>
                    {translate('equipmentCategory')}
                    <span className={styles.required}>*</span>
                  </Label>
                  <Label mt={0}>
                    {translate('equipment')}
                    <span className={styles.required}>*</span>
                  </Label>
                </Flex>
              )}
              {selectedEquipments.map((selectedEquipment, index) => (
                <Flex key={index} gap={3} className={styles.EquipmentLabels}>
                  <div className={styles.EquipmentDropdown}>
                    <Dropdown
                      id="select-equipment-category"
                      testId="select-equipment-category"
                      value={selectedEquipment.category}
                      isFullWidth
                      items={
                        Array.from(equipments.keys()).map(category => ({
                          label: category,
                          value: category,
                        })) || []
                      }
                      onChange={value =>
                        handleEquipmentCategoryChange(value, index)
                      }
                      invalid={
                        isEquipmentLinked &&
                        validationErrors?.equipmentIds &&
                        !selectedEquipment.category
                      }
                      errors={
                        isEquipmentLinked &&
                        validationErrors?.equipmentIds &&
                        !selectedEquipment.category
                          ? []
                          : null
                      }
                    />
                  </div>
                  <div className={styles.EquipmentDropdown}>
                    <Dropdown
                      id="select-equipment"
                      testId="select-equipment"
                      value={selectedEquipment.equipment?.id}
                      isFullWidth
                      items={
                        selectedEquipment.category &&
                        equipments.has(selectedEquipment.category)
                          ? equipments
                              .get(selectedEquipment.category)!
                              .map(equipment => ({
                                label: equipment.name,
                                value: equipment.id,
                              }))
                          : []
                      }
                      onChange={value => handleEquipmentChange(value, index)}
                      errors={
                        isEquipmentLinked &&
                        validationErrors?.equipmentIds &&
                        !selectedEquipment.equipment
                          ? validationErrors?.equipmentIds
                          : null
                      }
                      invalid={
                        isEquipmentLinked &&
                        validationErrors?.equipmentIds &&
                        !selectedEquipment.equipment
                      }
                    />
                  </div>
                  <IconButton
                    icon="times"
                    testId="remove-option"
                    size="small"
                    onClick={() => handleRemoveEquipment(index)}
                    className={styles.optionDeleteButton}
                  />
                </Flex>
              ))}
              <div>
                <Button
                  variant="text-icon"
                  startIcon={<Icon name="plus" />}
                  onClick={handleAddEquipment}
                  fullWidth={false}
                  size="small"
                  testId="add-option"
                  className={styles.addEquipmentButton}
                >
                  {translate('addEquipment')}
                </Button>
              </div>
            </Flex>
          )}
        </>
      )}
      {!isEquipmentLinked && (
        <Flex direction="column">
          <Label mt={0}>
            {translate('location')}
            {!isEquipmentLinked && <span className={styles.required}>*</span>}
          </Label>

          <Input
            testId="location"
            value={schedule?.location}
            onChange={(value: string) =>
              onScheduleFieldChange('location', value)
            }
            maxLength={3_000}
            showLengthIndicator={false}
            disabled={isEquipmentLinked && selectedEquipments.length > 0}
            fieldName="location"
          />
        </Flex>
      )}
      <Flex direction="column">
        <Label mt={0}>{translate('notes')}</Label>
        <TextArea
          value={schedule?.notes ?? ''}
          onChange={(value: string) => onScheduleFieldChange('notes', value)}
          maxLength={3_000}
          showLengthIndicator={false}
          minRows={4}
        />
      </Flex>
      <Flex direction="column">
        <Label mt={0}>
          {translate('estimatedTimeToComplete')}
          <span className={styles.required}>*</span>
        </Label>
        <Input
          testId="time-to-complete"
          value={schedule?.timeToComplete}
          helperText={translate('hours')}
          onChange={(value: string) =>
            onScheduleFieldChange('timeToComplete', parseFloat(value))
          }
          fieldName="time-to-complete"
          error={validationErrors?.timeToComplete}
          type="number"
        />
      </Flex>
      <Flex direction="column">
        <Label mt={0}>{translate('steps')}</Label>
        <TextArea
          value={schedule?.steps ?? ''}
          onChange={(value: string) => onScheduleFieldChange('steps', value)}
          maxLength={3_000}
          showLengthIndicator={false}
          minRows={3}
        />
      </Flex>
      <Flex direction="column">
        <Label mt={0}>{translate('meterReading')}</Label>
        <TextArea
          value={schedule?.meterReading ?? ''}
          onChange={(value: string) =>
            onScheduleFieldChange('meterReading', value)
          }
          maxLength={3_000}
          showLengthIndicator={false}
          minRows={3}
        />
      </Flex>

      <H5>{translate('recurrence')}</H5>
      <Flex direction="column">
        <Label mt={0}>
          {translate('repeats')}
          <span className={styles.required}>*</span>
        </Label>
        <Dropdown
          id="schedule-repeats"
          testId="schedule-repeats"
          value={schedule?.repeats}
          items={recurrenceOptions}
          onChange={({ value }) => onScheduleFieldChange('repeats', value)}
          name="schedule-repeats"
        />
      </Flex>

      <Flex direction="column">
        <Label mt={0}>
          {translate('firstDueDate')}
          <span className={styles.required}>*</span>
        </Label>
        <DatePickerButton
          hideLabel
          className={styles.DatePicker}
          value={schedule?.nextDueDate}
          onChange={(d: Date) => {
            onScheduleFieldChange('nextDueDate', d);
          }}
          minDate={new Date()}
          dateError={validationErrors?.nextDueDate}
          hideOnSelect
        />
      </Flex>

      <Flex direction="column" gap={2}>
        <H5>{translate('ends')}</H5>
        <Radio
          name="recurrence-ends"
          key={ScheduleEndDateType.Never}
          selected={endDateType}
          onChange={() => {
            setEndDateType(ScheduleEndDateType.Never);
            onScheduleFieldChange('untilDate', undefined);
          }}
          value={ScheduleEndDateType.Never}
          text={translationKey('never')}
        />
        <Flex gap={2} align="center">
          <Radio
            name="recurrence-ends"
            key={ScheduleEndDateType.Specific}
            selected={endDateType}
            onChange={() => setEndDateType(ScheduleEndDateType.Specific)}
            value={ScheduleEndDateType.Specific}
            text={translationKey('onSpecificDate')}
          />
          <DatePickerButton
            dateInputLabel={translationKey('endDate')}
            value={schedule?.untilDate}
            onChange={(d: Date) => {
              onScheduleFieldChange('untilDate', d);
            }}
            minDate={schedule?.nextDueDate!}
            disabled={endDateType !== ScheduleEndDateType.Specific}
            dateError={
              endDateType === ScheduleEndDateType.Specific &&
              validationErrors?.untilDate
                ? validationErrors.untilDate
                : undefined
            }
            hideOnSelect
          />
        </Flex>
      </Flex>

      <Flex gap={2} style={{ width: '60%' }}>
        <Flex direction="column">
          <Label mt={0}>
            {translate('createTaskInAdvance')}
            <span className={styles.required}>*</span>
          </Label>
          <Input
            testId="days-ahead"
            value={schedule?.daysAhead}
            helperText={translate('days')}
            onChange={(value: string) =>
              onScheduleFieldChange(
                'daysAhead',
                value === '' ? '' : Number(value)
              )
            }
            type="number"
            maxExpand={false}
            error={validationErrors?.daysAhead}
            fieldName="days-ahead"
          />
        </Flex>
      </Flex>

      <H5>{translate('attachments')}</H5>
      <Flex direction="column" gap={2}>
        <PMAttachments
          attachmentFiles={attachmentFiles}
          onAddAttachments={onAddAttachments}
          onRemoveAttachment={onRemoveAttachment}
        />
      </Flex>
      <Flex
        className={styles.submitButtons}
        gap={2}
        align="center"
        width="full"
      >
        <Button
          variant="activate-contained"
          onClick={onSave}
          fullWidth={false}
          size="medium"
          testId="save-button"
        >
          {saveButtonText}
        </Button>
        <Button
          variant="outlined"
          onClick={onCancel}
          fullWidth={false}
          size="medium"
        >
          {translate('cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}
