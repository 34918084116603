import React, { useState, useContext } from 'react';

import {
  ErrorMessage,
  ControlMenu,
  Button,
  Loading,
  ModalBackground,
} from 'components';
import { MemoryHistory } from 'history';
import { ValidationError } from 'yup';
import * as yup from 'yup';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { createChannel } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { constructChannel } from 'lane-shared/helpers/channel';
import { AddressType } from 'lane-shared/types/AddressType';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ProfileType } from 'lane-shared/types/ProfileType';
import {
  validateChannelBase,
  validateChannelInfo,
} from 'lane-shared/validation/channel';

import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';
import { AdminPage } from 'components/layout';

import styles from './styles.scss';

const validationSchema = yup
  .object()
  .concat(validateChannelBase)
  .concat(validateChannelInfo);

export default function NewChannel({ history }: { history: MemoryHistory }) {
  const { user } = useContext(UserDataContext);

  const item = 'organization';

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState<ValidationError | null>(null);
  const [channel, setChannel] = useState(() =>
    constructChannel({ userId: user?._id, type: undefined })
  );

  async function validate() {
    try {
      await validationSchema.validate(channel, { abortEarly: false });
      setValidation(null);

      return true;
    } catch (error: any) {
      setValidation(error);

      return false;
    }
  }

  function onChannelUpdated(updatedChannel: Partial<ChannelType>) {
    setChannel({
      ...channel,
      ...updatedChannel,
    });

    if (validation) {
      validate();
    }
  }

  function onCreateChannel() {
    async function validateAndCreate() {
      if (!(await validate())) return;

      setLoading(true);
      setError(null);

      try {
        await pause();

        // remove some _ids since this is a create, avoid delete
        const { _id: _channelId, ...newChannel } = { ...channel };
        const { _id: _addressId, ...newAddress } = channel.address ?? {};
        const { _id: _profileId, ...newProfile } = channel.profile ?? {};

        newChannel.address = { ...newAddress } as AddressType;
        newChannel.profile = { ...newProfile } as ProfileType;

        await getClient().mutate({
          mutation: createChannel,
          variables: { channel: newChannel },
        });

        history.push(`/l/channel/${newChannel.slug}/admin`);
      } catch (err: any) {
        setError(err);
        window.Alert.alert({
          title: `Error creating ${item}.`,
          message: `This ${item} was not created, please see the error below and try again.`,
          error: err,
        });
      }

      setLoading(false);
    }

    validateAndCreate();
  }

  return (
    <AdminPage className={styles.NewChannel}>
      <ControlMenu className={styles.controlMenu}>
        <Button
          variant="contained"
          onClick={onCreateChannel}
          disabled={loading}
          testId="channelCreationButton"
        >
          Create
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      <section>
        <ChannelInfoEdit
          heading="Create new organization"
          validation={validation}
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          shouldShowType
          channelForDataIdentifiers={channel}
          forCreate
        />
      </section>

      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'onClose' is missing in type '{ children:... Remove this comment to see the full error message */}
      <ModalBackground isOpen={loading}>
        <div className={styles.loadingModal}>
          <h3>Creating {item}</h3>
          <p>This may take a few minutes...</p>
          <Loading />
        </div>
      </ModalBackground>
    </AdminPage>
  );
}
