import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { graphql } from '@apollo/client/react/hoc';

import { getClient } from 'lane-shared/apollo';
import routes from 'lane-shared/config/routes';
import { EmailAlreadyInUseError, LoginInvalidError } from 'activate-errors';
import { getUserLogins, addOAuthLogin } from 'lane-shared/graphql/user';
import { getValidationMessages, isThisError } from 'lane-shared/helpers';
import { DEFAULT_EMAIL } from 'lane-shared/helpers/constants';
import { isTokenExpired } from 'lane-shared/helpers/integrations/ProxyClick';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import { validateProxyClickLogin } from 'lane-shared/validation';

 
import proxyClickLogo from 'static/img/proxyclick-logo.png';

import { history } from '../../../helpers';
import Input from '../../form/Input';
import Button from '../../general/Button';
import Image from '../../general/Image';
import Loading from '../../general/Loading';

import styles from './Login.scss';

function ProxyClickLogin({ userLogins, content }: any) {
  const contentId = content._id;
  const [login, setLogin] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (content) {
      setLoading(content.loading);
    }
  }, [content]);

  if (!userLogins || userLogins.loading) {
    return <Loading />;
  }

  const proxyClickLogin = userLogins.me.user.logins.find(
    (l: any) =>
      l.type === UserLoginTypeEnum.OAuth &&
      l.provider === IntegrationProviderEnum.ProxyClick
  );

  if (proxyClickLogin && !isTokenExpired(proxyClickLogin)) {
    history.replace(routes.content.replace(':id', contentId));

    return null;
  }

  async function validate() {
    setValidation(null);

    try {
      await validateProxyClickLogin.validate(
        { email: login.email, password: login.password },
        {
          abortEarly: false,
        }
      );
    } catch (err) {
      setValidation(err);
    }
  }

  async function onSubmitPressed() {
    setSubmitting(true);
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: addOAuthLogin,
        variables: {
          channelIntegrationId: content.integration._id,
          userKey: login.email.toLowerCase(),
          password: login.password,
          provider: IntegrationProviderEnum.ProxyClick,
          loginType: UserLoginTypeEnum.Email,
        },
      });

      history.replace(routes.content.replace(':id', contentId));
    } catch (err) {
      if (isThisError(err, EmailAlreadyInUseError)) {
        window.Alert.alert({
          title: t(
            'The email "{{email}}" is already in use by another account.',
            login
          ),
        });
      } else if (isThisError(err, LoginInvalidError)) {
        window.Alert.alert({
          title: t('You’ve entered an invalid email or password.'),
        });
      } else {
        window.Alert.alert({
          title: t("We weren't able to sign you in."),
          error: err,
        });
      }
    }

    setLoading(false);
    setSubmitting(false);
  }

  const isButtonDisabled =
    validation || login.email.length === 0 || login.password.length === 0;

  return (
    <div className={styles.proxyClickLogin}>
      {loading && <Loading />}
      <Image
        style={{
          height: 'auto',
          width: '280px',
          alignSelf: 'center',
          marginBottom: 0,
        }}
        alt="proxyclick-logo"
        src={proxyClickLogo}
      />
      <div className={styles.header}>
        <p>{t('Please enter your Proxyclick credentials to continue.')}</p>
        <p>
          {t('Don’t have a Proxyclick account? Contact your office manager.')}
        </p>
      </div>
      <div className={styles.container}>
        <div className={styles.form}>
          <Input
            label="Email"
            type="text"
            className={styles.input}
            placeholder={DEFAULT_EMAIL}
            value={login.email}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | string[] | null' is not assignable t... Remove this comment to see the full error message
            error={
              login.email.length > 0 &&
              getValidationMessages(validation, 'email') && [
                'Please enter a valid email.',
              ]
            }
            onChange={value => {
              setLogin({ ...login, email: value });
              setValidation(null);
            }}
            onBlur={validate}
          />
          <Input
            label={t('Password')}
            type="password"
            value={login.password}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'false | string[] | null' is not assignable t... Remove this comment to see the full error message
            error={
              login.password.length > 0 &&
              getValidationMessages(validation, 'password') && [
                'Please enter a valid password.',
              ]
            }
            onChange={value => {
              setLogin({ ...login, password: value });
              setValidation(null);
            }}
            onBlur={validate}
          />
        </div>
        <Button
          variant="contained"
          disabled={loading || isButtonDisabled || !!submitting}
          loading={loading || submitting}
          onClick={onSubmitPressed}
        >
          {t('Sign In')}
        </Button>
        <p className={styles.footer}>
          {t('This service is powered by')} Proxyclick.
        </p>
      </div>
    </div>
  );
}

export default graphql(getUserLogins, {
  name: 'userLogins',
  options: { fetchPolicy: 'cache-and-network' },
})(ProxyClickLogin);
