import React, { useState } from 'react';

import { Button, Icon } from 'design-system-web';
import cx from 'classnames';
import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import useCompanyLocationsSearch from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import {
  ChannelExperienceTypeEnum,
  ChannelType,
} from 'lane-shared/types/ChannelType';

import { H5 } from 'components/typography';

import { ChannelListItem } from '../../../components/ChannelListItem';
import { Header } from '../components/Header';
import { CompanySelection } from './CompanySelection';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  buildingLabel: 'shared.onboarding.invite.buildingLocation.label',
  createAccountButton: 'shared.onboarding.invite.createAccount.button',
};

type Props = {
  building: ChannelType;
  company?: ChannelType;
  userName: string;
  onboardingState: 'loading' | 'idle' | 'error';
  errorMessage?: string;
  onClear: () => void;
  onComplete: (
    building: ChannelType,
    userName: string,
    company?: ChannelType
  ) => void;
};

export const OnboardingWithInvite = ({
  building,
  company,
  userName,
  onboardingState,
  errorMessage,
  onComplete,
  onClear,
}: Props) => {
  const [selectedCompany, setSelectedCompany] = useState<
    ChannelType | undefined
  >(company);
  const [companyLocationSearch, setCompanyLocationSearch] = useState('');

  const isCompanyLocked = !!company;
  const isMFExperience =
    building.experienceType === ChannelExperienceTypeEnum.multifamily;

  const { t } = useTranslation();

  const companyLocationsResult = useCompanyLocationsSearch({
    skip: false,
    parentCompany: null,
    search: companyLocationSearch,
    oAuth: undefined,
    parentBuilding: building,
  });

  const handleCompanySelectionChange = (data: {
    company: ChannelType | undefined;
  }) => {
    if (!isCompanyLocked) {
      setSelectedCompany(data.company);

      if (data.company === undefined) {
        onClear();
      }
    }
  };

  const handleCompanyLocationSearchTextChange = (
    companyLocationSearch: string
  ) => {
    setCompanyLocationSearch(companyLocationSearch);
  };

  const handleCreateAccount = (data: { company: ChannelType | undefined }) => {
    if (!data.company) {
      onComplete(building, userName);

      return;
    }

    onComplete(building, userName, data.company);
  };

  return (
    <div className={styles.container}>
      <Header userName={userName} />
      {errorMessage && <ErrorMessage error={errorMessage} />}
      <H5>{t(TRANSLATION_KEYS.buildingLabel)}</H5>
      <div className={styles.buildingContainer}>
        <ChannelListItem
          className={cx([styles.selectedElement])}
          showName={false}
          channel={building}
          onClick={() => {}}
        />
        <Icon
          testId="onboarding-building-lock-icon"
          name="lock"
          className={styles.lockIcon}
          set={ICON_SET_FONTAWESOME}
          type={FONT_AWESOME_REGULAR}
        />
      </div>
      <div className={styles.companyContainer}>
        {!isMFExperience && (
          <CompanySelection
            selectedCompany={selectedCompany}
            handleCompanySelectionChange={handleCompanySelectionChange}
            handleCompanyLocationSearchTextChange={
              handleCompanyLocationSearchTextChange
            }
            isCompanyLocked={isCompanyLocked}
            companyLocationSearch={companyLocationSearch}
            companyLocationsResult={companyLocationsResult}
          />
        )}
        <Button
          loading={onboardingState === 'loading'}
          className={styles.button}
          onClick={() => handleCreateAccount({ company: selectedCompany })}
          size="large"
          type="submit"
        >
          {t(TRANSLATION_KEYS.createAccountButton)}
        </Button>
      </div>
    </div>
  );
};
