import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useParams } from 'react-router';

import { Input } from 'components/form';
import { Pagination } from 'components/general';
import { Flex } from 'components/layout';
import Box from 'components/layout/Box';
import { Text } from 'components/typography';

import PublicationStatus from './PublicationStatus';

import styles from './GeneratedChildrenList.scss';

type Props = {
  searchInput: string;
  onSearch: (q: string) => void;
  selectedContentId: string;
  onSelectContent: (id: string) => void;
  generatorContentTemplate: any;
  generatedContentPage: number;
  setGeneratedContentPage: (page: number) => void;
};

/**
 * Displays the list of generated content in the Batch Content management page.
 */
export default function GeneratedChildrenList({
  selectedContentId,
  onSelectContent,
  generatorContentTemplate,
  generatedContentPage,
  onSearch,
  searchInput,
  setGeneratedContentPage,
}: Props) {
  const { contentId } = useParams<{
    contentId: string;
  }>();

  const generatedChildrenExist =
    generatorContentTemplate?.generatedChildren?.pageInfo?.total > 0;

  return (
    <Flex direction="column">
      <Flex direction="column" className={styles.generatedContent} mb={4}>
        <Box mb={4}>
          <Input
            onChange={onSearch}
            value={searchInput}
            placeholder="Search batch content"
            icon="search"
          />
        </Box>
        <button
          className={cx(styles.contentButton, styles.templateButton, {
            [styles.selected]: selectedContentId === contentId,
          })}
          onClick={() => onSelectContent(contentId)}
        >
          Template
          <Icon
            name="arrow-circle-right"
            set="FontAwesome"
            type="far"
            className={cx(styles.templateArrowIcon, {
              [styles.selected]:
                selectedContentId === generatorContentTemplate?._id,
            })}
          />
        </button>
        {generatorContentTemplate &&
          generatorContentTemplate?.generatedChildren?.items?.map(
            (childContent: any) => {
              const isSelected = childContent._id === selectedContentId;

              return (
                <button
                  key={childContent?._id}
                  className={cx(styles.contentButton, {
                    [styles.selected]: isSelected,
                  })}
                  onClick={() => onSelectContent(childContent._id)}
                >
                  <span className={styles.contentButtonText}>
                    {childContent.name}
                  </span>
                  <PublicationStatus
                    contentPublishedAt={childContent.publishedAt}
                    draftUpdatedAt={childContent._updated}
                  />
                </button>
              );
            }
          )}
        {generatorContentTemplate && !generatedChildrenExist && (
          <Text className={styles.generatedChildrenEmpty} mt={6}>
            {searchInput ? 'No content found' : 'No generated content'}
          </Text>
        )}
      </Flex>
      <Pagination
        total={generatorContentTemplate?.generatedChildren?.pageInfo?.total}
        page={generatedContentPage}
        perPage={25}
        onPage={setGeneratedContentPage}
        pagesToShow={1}
      />
    </Flex>
  );
}
