import { ChipStyle } from 'components/ads/chipSelect/types';
import { ImportJobDefinitions } from 'lane-shared/domains/importer/jobDefinition';

export const JOB_STATUS = {
  CREATED: {
    key: 'CREATED',
    friendlyName: 'web.admin.importer.jobStatus.created',
  },
  STARTED: {
    key: 'STARTED',
    friendlyName: 'web.admin.importer.jobStatus.inProgress',
  },
  SUCCEEDED: {
    key: 'SUCCEEDED',
    friendlyName: 'web.admin.importer.jobStatus.completed',
  },
  FAILED: {
    key: 'FAILED',
    friendlyName: 'web.admin.importer.jobStatus.failed',
  },
};
export type IntegrationProcess = {
  id: string;
  processGroupId: string;
  vtsIntegrationId: string;
  metadata: any;
  createdAt: string;
  startedAt: string;
  succeededAt: string;
  failedAt: string;
  totalCreatedRecords: number;
  totalUpdatedRecords: number;
  totalFailedRecords: number;
};

export type ProcessGroups = {
  [key: string]: IntegrationProcess[];
};

export type ProcessAggregation = {
  processGroupId: string;
  totalCreatedRecords: number;
  totalUpdatedRecords: number;
  totalFailedRecords: number;
  status: string;
  module: string;
  count: number;
  importedDate: string;
};

export const getProcessGroups = (process: IntegrationProcess[]) => {
  const processGroups = groupProcessesByGroupId(process);

  return Object.keys(processGroups)
    .map(processGroupId => {
      const group = processGroups[processGroupId];

      return getProcessAggregation(group);
    })
    .sort(
      (a, b) =>
        new Date(b.importedDate).getTime() - new Date(a.importedDate).getTime()
    );
};

export const getProcessAggregation = (process: IntegrationProcess[]) => {
  const aggregation: ProcessAggregation = {
    processGroupId: process[0]?.processGroupId,
    totalCreatedRecords: process.reduce(
      (sum, item) => sum + item.totalCreatedRecords,
      0
    ),
    totalUpdatedRecords: process.reduce(
      (sum, item) => sum + item.totalUpdatedRecords,
      0
    ),
    totalFailedRecords: process.reduce(
      (sum, item) => sum + item.totalFailedRecords,
      0
    ),
    status: getStatusFromProcesses(process), // Default status
    module: ImportJobDefinitions[0].friendlyName,
    count: process.length,
    importedDate: process[0]?.createdAt,
  };

  return aggregation;
};

export const getStatusFromProcesses = (processes: IntegrationProcess[]) => {
  let status = JOB_STATUS.CREATED.friendlyName; // Default status

  // Determine the status of each process group
  for (const process of processes) {
    if (process.totalFailedRecords > 0) {
      status = JOB_STATUS.FAILED.friendlyName;
      break; // If any process has failed, the whole group is considered failed
    } else if (process.succeededAt) {
      status = JOB_STATUS.SUCCEEDED.friendlyName; // Assume complete, but keep checking in case of later failures
    } else if (process.startedAt) {
      status = JOB_STATUS.STARTED.friendlyName; // Assume in progress if not succeeded and started
    } // No else needed for 'Created' as it's the default
  }

  return status;
};

export const getChipStyleFromStatus = (status: string) => {
  switch (status) {
    case JOB_STATUS.CREATED.friendlyName:
      return ChipStyle.Blue;
    case JOB_STATUS.STARTED.friendlyName:
      return ChipStyle.Blue;
    case JOB_STATUS.SUCCEEDED.friendlyName:
      return ChipStyle.Green;
    case JOB_STATUS.FAILED.friendlyName:
      return ChipStyle.Red;
    default:
      return ChipStyle.Blue;
  }
};

export const groupProcessesByGroupId = (processes: IntegrationProcess[]) => {
  return processes.reduce((processGroups: ProcessGroups, item) => {
    // Check if the group already exists
    if (item?.metadata?.integrationMetadata?.entityType !== 'USER') {
      if (!processGroups[item.processGroupId]) {
        // If it doesn't exist, create a new group
        processGroups[item.processGroupId] = [];
      }

      // Add the current item to the group
      processGroups[item.processGroupId].push(item);
    }

    return processGroups;
  }, {});
};
