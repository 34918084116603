import React, { useRef, useState } from 'react';

import cx from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mapb... Remove this comment to see the full error message
import { Control } from 'mapbox-gl';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@map... Remove this comment to see the full error message
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import DrawControl from 'components/mapBox/DrawControl';
import MapboxMap from 'components/mapBox/MapboxMap';
import MapboxRotatableRectangle from 'components/mapBox/MapboxRotatableRectangle';

import mapBoxDrawStyles from './mapBoxDrawStyles';
import { TxRectMode } from 'helpers/MapboxRotate';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './FloorMapMapTool.scss';

const controls = {};

const animationOptions = {
  animate: false,
};

const drawControlModes = { ...MapboxDraw.modes, tx_poly: TxRectMode };

const containerStyle = {
  minWidth: 200,
  minHeight: 200,
  flex: 1,
  display: 'flex',
};

const mapZoom: [number] = [18];

type DrawHandlerEvent = {
  features?: {
    geometry?: {
      coordinates?: GeoCoordinateType[][];
    };
  }[];
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  floor: FloorMapType;
  onClose: () => void;
  onUpdateFloor: (update: Partial<FloorMapType>) => void;
};

export default function FloorMapMapTool({
  className,
  style,
  floor,
  onUpdateFloor,
  onClose,
}: Props) {
  const { channel } = useChannelAdminContext();

  const mapRef = useRef(null);
  const drawControlRef = useRef(null);
  const [drawControl, setDrawControl] = useState<Control>(null);
  const [coordinates, setCoordinates] = useState<
    | [
        GeoCoordinateType,
        GeoCoordinateType,
        GeoCoordinateType,
        GeoCoordinateType
      ]
    | undefined
  >(floor.placement?.coordinates);

  function drawControlReady(ref: any) {
    // because the children of the map require the draw control to also be
    // loaded, we need to trigger a state change to update this.
    if (ref) {
      drawControlRef.current = ref.draw;

      if (ref.draw !== drawControl) {
        setDrawControl(ref.draw);
      }
    }
  }

  function mapReady(map: any) {
    // store the map control for later
    mapRef.current = map;
  }

  function doUpdate() {
    if (coordinates && coordinates.length > 3) {
      const geoCoordinates: [
        GeoCoordinateType,
        GeoCoordinateType,
        GeoCoordinateType,
        GeoCoordinateType
      ] = [coordinates[0], coordinates[1], coordinates[2], coordinates[3]];

      onUpdateFloor({
        _id: floor._id,
        _updated: new Date(),
        placement: {
          ...floor.placement,
          coordinates: geoCoordinates,
        },
      });
    }

    onClose();
  }

  const drawUpdateHandler = ({ features }: DrawHandlerEvent) => {
    const coordinates = features?.[0]?.geometry?.coordinates?.[0] ?? [];

    if (coordinates.length >= 4) {
      setCoordinates([
        coordinates[0],
        coordinates[1],
        coordinates[2],
        coordinates[3],
      ]);
    }
  };

  return (
    <ModalBackground onClose={onClose} isOpen className={styles.background}>
      <ResizableWindow
        name="floorMapMapTool"
        className={styles.window}
        autoHeight={false}
        onClose={onClose}
        defaultPosition={ResizableWindow.fullScreen()}
        showHeader
      >
        <div className={cx(styles.FloorMapMapTool, className)} style={style}>
          <div className={styles.wrapper}>
            <MapboxMap
              animationOptions={animationOptions}
              center={channel?.address?.geo}
              zoom={mapZoom}
              containerStyle={containerStyle}
              onStyleLoad={mapReady}
              minZoom={16}
            >
              <DrawControl
                ref={drawControlReady}
                displayControlsDefault={false}
                controls={controls}
                userProperties
                modes={drawControlModes}
                styles={mapBoxDrawStyles}
                onDrawUpdate={drawUpdateHandler}
              />

              <MapboxRotatableRectangle
                opacity={0.8}
                drawControl={drawControl}
                rectangleId={floor._id}
                imageOverlay={floor.floorPlan}
                coordinates={coordinates}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<[GeoCoordinateType, ... Remove this comment to see the full error message
                onCoordinatesUpdated={setCoordinates}
              />
            </MapboxMap>
          </div>
          <ControlMenu className={styles.menu}>
            <hr />
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>

            <Button variant="contained" onClick={doUpdate}>
              Done
            </Button>
          </ControlMenu>
        </div>
      </ResizableWindow>
    </ModalBackground>
  );
}
