import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { WorkOrderModuleCategoriesSettings } from 'lane-shared/domains/workOrder';
import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import { useChannelServiceRequestFeaturesContext } from 'lane-shared/hooks';

import { ServiceCategory } from './serviceCategory';

import styles from './serviceCategoriesBuilder.scss';

type Props = {
  categories: WorkOrderModuleCategoriesSettings[];
  onSettingsUpdated: (data: WorkOrderModuleCategoriesSettings[]) => void;
  channelId: string;
};

export const ServiceCategoriesBuilder = ({
  categories,
  onSettingsUpdated,
  channelId,
}: Props) => {
  const { t } = useTranslation();
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const [categoriesState, setCategoriesState] = useState(categories);
  const originalCategory = [...categories];
  const [assignableUsers, _, assignableTeams] = useAssignableMembers(
    channelId,
    undefined
  );

  useEffect(() => {
    return () => {
      setCategoriesState(originalCategory);
    };
  }, []);

  const replaceCategory = (
    category: WorkOrderModuleCategoriesSettings,
    index: number
  ) => {
    const updatedCategories = [...categoriesState];

    updatedCategories[index] = category;

    return updatedCategories;
  };

  const removeCategory = (
    category: WorkOrderModuleCategoriesSettings,
    index: number
  ) => {
    return [...categories.slice(0, index), ...categories.slice(index + 1)];
  };

  const saveCategories = (categories: WorkOrderModuleCategoriesSettings[]) => {
    setCategoriesState(categories);
    onSettingsUpdated(categories);
  };

  return (
    <div className={styles.serviceCategoriesBuilder}>
      {categoriesState.map((category, index) => (
        <ServiceCategory
          category={category}
          key={index}
          onSave={category => {
            categories = replaceCategory(category, index);
            saveCategories(categories);
          }}
          onDelete={category => {
            categories = removeCategory(category, index);
            saveCategories(categories);
          }}
          assignableUsers={assignableUsers}
          assignableTeams={assignableTeams}
        />
      ))}

      {serviceRequestFeatures.createServiceRequest && (
        <Button
          variant="outlined"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"def... Remove this comment to see the full error message
          color="secondary"
          startIcon={<Icon name="plus" />}
          size="small"
          onClick={() =>
            saveCategories([
              ...categoriesState,
              {
                firendlyName: `${t`web.admin.serviceRequest.category`} - ${
                  categoriesState.length
                }`,
                issues: ['Sample Issue'],
              },
            ])
          }
        >
          {t`web.admin.serviceRequest.builder.addCategory`}
        </Button>
      )}
    </div>
  );
};
