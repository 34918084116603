import React, { useState } from 'react';

import {
  ControlMenu,
  Button,
  IconButton,
  CopyButton,
  ErrorMessage,
  ResizableWindow,
  ModalBackground,
  MetatagValueEdit,
  Alert,
  Toggle,
} from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { updateMetatagValues } from 'lane-shared/graphql/metatag';
import { pause } from 'lane-shared/helpers';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { validateUpdateMetatag } from 'lane-shared/validation';

import { PropertiesDataTable } from 'components/builder';
import { ValidatedInput, ValidatedTextArea } from 'components/form';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import { H4, H5, P } from 'components/typography';

import MetatagEditComplexProperties from '../MetatagEditComplexProperties';
import MetatagUsage from '../MetatagUsage';
import history from 'helpers/history';

import styles from '../new/styles.scss';

const tabs: TabItem[] = [
  {
    value: 'values',
    label: 'web.admin.content.metatag.tabItem.database',
    icon: 'database',
  },
  {
    value: 'edit',
    label: 'web.admin.content.metatag.tabItem.edit',
    icon: 'edit',
  },
  {
    value: 'usage',
    label: 'web.admin.content.metatag.tabItem.usage',
    icon: 'info',
  },
];

const TAB_VALUES = tabs[0].value;
const TAB_EDIT = tabs[1].value;
const TAB_USAGE = tabs[2].value;

export default function MetatagEditComplex({
  channel,
  hasAttemptedSubmit,
  hasSchemaChanges,
  metatag,
  fields,
  error,
  updating,
  editingField,
  setEditingField,
  addField,
  saveField,
  removeField,
  updateName,
  updateMetatag,
  updateSchemaChanged,
  deleteMetatag,
  resetMetatag,
  saveMetatag,
}: any) {
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const [loading, setLoading] = useState(false);
  const [valueError, setValueError] = useState(null);
  const [isAddingValue, setIsAddingValue] = useState(false);
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [editingValue, setEditingValue] = useState<any>(null);

  const [query, goToUrl] = useQueryString({ tab: TAB_VALUES });

  const hasValues = metatag?.values?.length > 0;
  const { t } = useTranslation();

  async function warnSchemaChanges() {
    if (hasSchemaChanges) {
      try {
        await window.Alert.alert({
          title: 'Save your data changes first.',
          message:
            'There are unsaved changes to the data schema, this must be saved before adding / editing items.',
        });
      } catch (err) {
        // user cancelled
      }
    }

    return hasSchemaChanges;
  }

  async function editItemHandler(metatagValue: any) {
    if (await warnSchemaChanges()) {
      return;
    }

    setIsEditingValue(true);
    setEditingValue(metatagValue);
  }

  async function addItemHandler() {
    if (await warnSchemaChanges()) {
      return;
    }

    setIsAddingValue(true);
  }

  async function onSave() {
    try {
      await saveMetatag();
      window.Toast.show(`${metatag.name} updated.`);
    } catch (err) {
      // err handled in hook
    }
  }

  async function onDelete() {
    try {
      await window.Alert.confirm({
        title: `Delete "${metatag?.name}"?`,
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      const result = await deleteMetatag();

      if (result) {
        window.Toast.show(`${metatag.name} deleted.`);
      }

      const filtersIndexRoute = areContentTagsEnabled
        ? routes.channelAdminMetatags
        : routes.legacyChannelAdminMetatags;

      history.push(filtersIndexRoute.replace(':id', channel.slug));
    } catch (err) {
      // err handled in hook
    }
  }

  async function deleteMetatagValue(value: any) {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.content.metatag.tabItem.edit.deleteMetatagWindowTitle'
        ),
        message: t(
          `web.admin.content.metatag.tabItem.edit.deleteMetatagWindowMessage`
        ),
        confirmText: t(
          'web.admin.content.metatag.tabItem.edit.deleteMetatagWindowConfirm'
        ),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    setLoading(true);

    try {
      await pause();

      await getClient().mutate({
        mutation: updateMetatagValues,
        variables: {
          metatag: {
            _id: metatag._id,
            values: [
              {
                _id: value._id,
                _pull: true,
              },
            ],
          },
        },
      });

      await resetMetatag();
      window.Toast.show(
        t('web.admin.content.metatag.tabItem.edit.valueDeleted')
      );
    } catch (err) {
      setValueError(err);
    }

    setLoading(false);
  }

  return (
    <div className={styles.metatagEdit}>
      <ControlMenu>
        <TabStrip
          tabs={tabs}
          selected={tabs.find(tab => query.tab === tab.value)}
          onSelectTab={tab => goToUrl({ tab: tab.value })}
        />

        <hr />

        {query.tab === TAB_VALUES && (
          <Button loading={updating || loading} onClick={addItemHandler}>
            {t('web.admin.content.metatag.tabItem.values.addItem')}
          </Button>
        )}

        {query.tab === TAB_EDIT && (
          <>
            <Button loading={updating || loading} onClick={resetMetatag}>
              {t('web.admin.content.metatag.tabItem.edit.reset')}
            </Button>
            <Button loading={updating || loading} onClick={onDelete}>
              {t('web.admin.content.metatag.tabItem.edit.delete')}
            </Button>
            <Button
              loading={updating || loading}
              onClick={onSave}
              variant="contained"
            >
              {t('web.admin.content.metatag.tabItem.edit.save')}
            </Button>
          </>
        )}

        {query.tab === TAB_USAGE && (
          <Button loading={updating || loading} onClick={onDelete}>
            {t('web.admin.content.metatag.tabItem.usage.delete')}
          </Button>
        )}
      </ControlMenu>
      <ErrorMessage error={error || valueError} />

      {query.tab === TAB_EDIT && (
        <>
          {hasValues && (
            <Alert color="warning">
              {t('web.admin.content.metatag.tabItem.edit.alertWarning')}
            </Alert>
          )}
          <section className={styles.setup}>
            <H5 mb={2}>{t('web.admin.content.metatag.tabItem.edit.name')}</H5>
            <ValidatedInput
              onChange={updateName}
              placeholder={t(
                'web.admin.content.metatag.tabItem.edit.namePlaceholder'
              )}
              value={metatag.name}
              validation={(validateUpdateMetatag as any).name}
              isPristine={!hasAttemptedSubmit}
            />

            <H5 mb={2}>
              {t('web.admin.content.metatag.tabItem.edit.description')}
            </H5>
            <ValidatedTextArea
              minRows={4}
              placeholder={t(
                'web.admin.content.metatag.tabItem.edit.descriptionPlaceholder'
              )}
              value={metatag.description}
              isPristine={!hasAttemptedSubmit}
              validation={(validateUpdateMetatag as any).description}
              onChange={description => updateMetatag({ description })}
            />

            <Toggle
              text={t('web.admin.content.metatag.tabItem.edit.showDescription')}
              onChange={showDescription => updateMetatag({ showDescription })}
              value={metatag.showDescription}
            />
          </section>

          <MetatagEditComplexProperties
            className={styles.editProperties}
            channel={channel}
            metatag={metatag}
            fields={fields}
            updating={updating}
            addField={addField}
            saveField={saveField}
            removeField={removeField}
            editingField={editingField}
            setEditingField={setEditingField}
            updateMetatag={updateMetatag}
            updateSchemaChanged={updateSchemaChanged}
          />
        </>
      )}

      {query.tab === TAB_VALUES && (
        <section className={styles.complexMetatag}>
          <H4>{metatag.name}</H4>
          <P mt={2} mb={4}>
            {metatag.description}
          </P>
          <PropertiesDataTable
            fields={fields}
            items={metatag.values?.map(
              (metatagValue: any) => metatagValue.value
            )}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '({ value }: any) => JSX.Element' is not assi... Remove this comment to see the full error message
            OptionsControl={({ value }: any) => {
              const metatagValue = metatag.values.find(
                (metatagValue: any) => metatagValue.value._id === value._id
              );

              return (
                <>
                  <IconButton
                    icon="edit"
                    iconSet="FontAwesome"
                    className={styles.iconButton}
                    onClick={() => editItemHandler(metatagValue)}
                  />
                  <CopyButton value={convertToUUID(metatagValue?._id)} />
                  <IconButton
                    icon="times"
                    iconSet="FontAwesome"
                    inverted
                    className={styles.deleteButton}
                    onClick={() => deleteMetatagValue(metatagValue)}
                  />
                </>
              );
            }}
          />
        </section>
      )}

      {query.tab === TAB_USAGE && <MetatagUsage metatagId={metatag?._id} />}

      {isAddingValue && (
        <ModalBackground
          onClose={() => setIsAddingValue(false)}
          className={styles.background}
          isOpen
        >
          <ResizableWindow
            className={styles.window}
            onClose={() => setIsAddingValue(false)}
            name="addMetatagValue"
            defaultPosition={ResizableWindow.centerPosition()}
            showHeader
          >
            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'metatagValue' is missing in type '{ clas... Remove this comment to see the full error message */}
            <MetatagValueEdit
              className={styles.newValue}
              channel={channel}
              metatag={metatag}
              onValueCreated={() => setIsAddingValue(false)}
              onCancel={() => setIsAddingValue(false)}
            />
          </ResizableWindow>
        </ModalBackground>
      )}

      {isEditingValue && (
        <ModalBackground
          onClose={() => setIsEditingValue(false)}
          className={styles.background}
          isOpen
        >
          <ResizableWindow
            className={styles.window}
            onClose={() => setIsEditingValue(false)}
            name="editMetatagValue"
            defaultPosition={ResizableWindow.centerPosition()}
            showHeader
          >
            <MetatagValueEdit
              className={styles.newValue}
              channel={channel}
              metatag={metatag}
              metatagValue={editingValue}
              onValueSaved={() => setIsEditingValue(false)}
              onCancel={() => setIsEditingValue(false)}
            />
          </ResizableWindow>
        </ModalBackground>
      )}
    </div>
  );
}
