import React from 'react';
import { Tag, ChipStyle } from 'components/ads';
import type { ContentTag } from 'lane-shared/types/content/ContentTag';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';
import { useMultiLanguage } from 'lane-shared/hooks';

import styles from './style.scss';

export const TagBar = ({
  tagsOnSection,
  searchOptions,
  updateSearchOptions,
  applySearchOptions,
}: {
  tagsOnSection: ContentTag[];
  searchOptions: SearchOptions | null;
  updateSearchOptions: (searchOptions: Partial<SearchOptions>) => void;
  applySearchOptions: () => void;
}) => {
  const isTagSelected = (tag: string) =>
    searchOptions?.contentTags?.find(currentTag => currentTag === tag);

  const onTagClick = (tag: string) => {
    const tagSelected = isTagSelected(tag);

    if (tagSelected) {
      const filteredContentTags = searchOptions?.contentTags!.filter(
        currentTag => currentTag !== tag
      );

      updateSearchOptions({ contentTags: filteredContentTags });
    } else {
      updateSearchOptions({
        contentTags: [...(searchOptions?.contentTags || []), tag],
      });
    }

    applySearchOptions();
  };

  const getPrimaryLanguageTagName = (tagId: string): string => {
    return tagsOnSection.find(tag => tag.id === tagId)!.name;
  };

  const { translate } = useMultiLanguage();

  const translatedTags = translate({ model: tagsOnSection, columns: ['name'] });

  return (
    <div className={styles.tagContainer}>
      {translatedTags?.map((tag: ContentTag) => (
        <button
          key={tag.id}
          className={styles.tagButton}
          onClick={() => onTagClick(getPrimaryLanguageTagName(tag.id))}
        >
          <Tag
            type={
              isTagSelected(getPrimaryLanguageTagName(tag.id))
                ? ChipStyle.Grey
                : ChipStyle.White
            }
            key={tag.id}
            value={tag.name}
            doTranslate={false}
          />
        </button>
      ))}
    </div>
  );
};
