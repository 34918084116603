import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { v4 as uuid } from 'uuid';

import { LaneType } from 'common-types';
import { getLibraryOptions } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { FloorMapsSettingsType } from 'lane-shared/helpers/integrations/FloorMaps/definition';
import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';
import { DocumentType } from 'lane-shared/types/DocumentType';

import ValidatedInput from 'components/form/ValidatedInput';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import MediaPickerButton from 'components/lane/MediaPickerButton';
import SectionSelectorButton from 'components/lane/SectionSelectorButton';
import Alert, { AlertType } from 'components/lds/Alert';
import { H5 } from 'components/typography';

import FloorMapDeskPlannerTool from './FloorMapDeskPlannerTool';
import FloorMapMapTool from './FloorMapMapTool';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './FloorMapFloor.scss';

type Props = {
  className?: string;
  errors: string[] | null;
  channelIntegration: { _id: string; settings: FloorMapsSettingsType };
  floor: FloorMapType;
  isExpanded: boolean;
  onExpandToggle: () => void;
  onUpdateFloor: (update: Partial<FloorMapType>) => void;
  onRemoveFloor: (floor: FloorMapType) => void;
  dragHandleProps: DraggableProvidedDragHandleProps;
};

export default function FloorMapFloor({
  className,
  channelIntegration,
  floor,
  isExpanded,
  onExpandToggle,
  onUpdateFloor,
  onRemoveFloor,
  dragHandleProps,
  errors,
}: Props) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isDeskPlannerOpen, setIsDeskPlannerOpen] = useState(false);
  const [toShowNewSection, setToShowNewSection] = useState(false);

  // is everything selected for the floor plan to be placed.
  const isFloorPlanPlaceable = !!floor?.floorPlan;

  // is everything setup for the desks to be arranged.
  const areDesksPlannable =
    floor?.sectionIds && floor?.floorPlan && floor?.placement;

  function assignSection(sectionIndex: number, section: DocumentType | null) {
    const sectionIds: LaneType.UUID[] = [...floor.sectionIds];

    if (section === null) {
      sectionIds.splice(sectionIndex, 1);
    } else {
      sectionIds[sectionIndex] = section._id;
    }

    onUpdateFloor({
      sectionIds,
      _id: floor._id,
    });
  }

  function onAddDraftSection() {
    setToShowNewSection(true);
  }

  useEffect(() => {
    if (floor.sectionIds.length > 0 || toShowNewSection) {
      return;
    }

    setToShowNewSection(true);
  }, [floor.sectionIds.length]);

  return (
    <div className={cx(styles.FloorMapFloor, className)}>
      <H5
        className={styles.header}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; className: any; onCli... Remove this comment to see the full error message
        onClick={onExpandToggle}
        role="button"
        tabIndex={0}
        onKeyPress={(e: any) => e.key === Key.Enter && onExpandToggle()}
      >
        <span>{floor.name || t('New Floor')}</span>
        <Icon
          name="trash"
          type="far"
          className={styles.removeIcon}
          set={ICON_SET_FONTAWESOME}
          onClick={() => onRemoveFloor(floor)}
        />
        <div {...dragHandleProps} className={styles.dragIcon}>
          <Icon name="bars" type="far" set={ICON_SET_FONTAWESOME} />
        </div>
        <Icon
          className={styles.chveronIcon}
          name={isExpanded ? 'chevron-up' : 'chevron-down'}
        />
      </H5>
      {isExpanded && (
        <>
          {errors &&
            errors.map(error => (
              <Alert key={error} type={AlertType.error} fullWidth>
                {t(error)}
              </Alert>
            ))}
          <ValidatedInput
            label={t('Floor name')}
            onChange={name => onUpdateFloor({ name, _id: floor._id })}
            value={floor.name}
            className={styles.topInput}
            placeholder="i.e. 10th Floor"
          />
          <div className={styles.row}>
            <div className={styles.col}>
              <H5 mb={2}>{t('Section')}</H5>
              {floor?.sectionIds?.map((id, index) => (
                <SectionSelectorButton
                  key={id}
                  className={styles.sectionSelector}
                  sectionId={id}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  channelId={channel?._id}
                  channelSlug={channel?.slug}
                  onSectionSelected={(section: any) =>
                    assignSection(index, section)
                  }
                />
              ))}
              {toShowNewSection ? (
                <SectionSelectorButton
                  className={styles.sectionSelector}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  channelId={channel?._id}
                  channelSlug={channel?.slug}
                  onSectionSelected={(section: any) => {
                    if (section) {
                      assignSection(floor.sectionIds.length, section);
                    }

                    setToShowNewSection(false);
                  }}
                />
              ) : null}
            </div>
            <div className={styles.col}>
              <H5 mb={2}>{t('Floor Plan Image')}</H5>
              <MediaPickerButton
                libraries={getLibraryOptions({ channel })}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; } | undefined' is not assigna... Remove this comment to see the full error message
                media={floor.floorPlan ? { _id: floor.floorPlan } : undefined}
                storageKey={channel?._id}
                newImageMaxWidth={6000}
                newImageMaxHeight={6000}
                onMediaSelected={media =>
                  onUpdateFloor({
                    _id: floor._id,
                    _updated: new Date(),
                    floorPlan: (media && media._id) || undefined,
                    placement: {
                      _id: uuid(),
                      type: 'Polygon',
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '[]' is not assignable to type '[GeoCoordinat... Remove this comment to see the full error message
                      coordinates: [],
                    },
                  })
                }
                className={styles.imageButton}
              />
            </div>
          </div>

          <button
            className={styles.alternativeAltButton}
            onClick={onAddDraftSection}
          >
            {t('+ Add a new section')}
          </button>

          <ControlMenu className={styles.menu}>
            <hr />

            <Button
              variant="outlined-light"
              startIcon={<Icon name="map" />}
              disabled={!isFloorPlanPlaceable}
              onClick={() => setIsMapOpen(true)}
            >
              {t('Place floor plan')}
            </Button>
            <Button
              variant="outlined-light"
              startIcon={<Icon name="arrows-alt" />}
              disabled={!areDesksPlannable}
              onClick={() => setIsDeskPlannerOpen(true)}
            >
              {t('Desk planner')}
            </Button>
          </ControlMenu>

          {isMapOpen && (
            <FloorMapMapTool
              floor={floor}
              onClose={() => setIsMapOpen(false)}
              onUpdateFloor={onUpdateFloor}
            />
          )}

          {isDeskPlannerOpen && (
            <FloorMapDeskPlannerTool
              channelIntegration={channelIntegration}
              floor={floor}
              onClose={() => setIsDeskPlannerOpen(false)}
              onUpdateFloor={onUpdateFloor}
            />
          )}
        </>
      )}
    </div>
  );
}
