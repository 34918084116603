export const meterReadingUnitOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.cubicFeet',
    value: 'cf',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.cubicMeters',
    value: 'cbm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.gallons',
    value: 'gal',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.kilograms',
    value: 'kg',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.level',
    value: '%',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.levelInches',
    value: 'Lvl(in.)',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.litres',
    value: 'L',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.metricTonne',
    value: 'Mg',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.pounds',
    value: 'lbs',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.capacity.shortTons',
    value: 'Ton',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.concentration.acidityAlkalinity',
    value: 'pH',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.concentration.cycleOfConcentration',
    value: 'Cycles',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.concentration.partsPerMililiter',
    value: 'PpMl',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.concentration.partsPerMillion',
    value: 'Ppm',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.concentration.specificGravity',
    value: 'Specific gravity',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.distance.miles',
    value: 'Miles',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.distance.kilometers',
    value: 'km',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.amperes',
    value: 'Amp',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.frequency',
    value: 'Hz',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.kiloVoltAmperes',
    value: 'kVA',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.kiloWattHours',
    value: 'kWh',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.kiloWatts',
    value: 'kW',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.megaWattHours',
    value: 'MWh',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.megohms',
    value: 'meg',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.microsiemens',
    value: 'µS',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.micromhos',
    value: 'µmho',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.siemens',
    value: 'S',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.electrical.volts',
    value: 'V',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.energy.brithishThermalUnit',
    value: 'BTU',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.energy.hundredCubicFeet',
    value: 'HCF',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.energy.kiloBritishThermalUnit',
    value: 'KBTU',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.energy.millionPounds',
    value: 'MLbs',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.flow.cubicfeetPerMinute',
    value: 'cfm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.flow.cubicMeter',
    value: 'm³',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.flow.gallonsPerMinute',
    value: 'gpm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.flow.litresPerSecond',
    value: 'L/s',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.logic.yesNo',
    value: 'Yes[1]/No[0]',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.pressure.inchesOfMercury',
    value: 'inHg',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.pressure.inchesOfWater',
    value: 'inH2O',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.pressure.kilopascals',
    value: 'kPa',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.pressure.millibars',
    value: 'mbar',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.pressure.poundforcePerSquareInchAbsolute',
    value: 'psia',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.pressure.poundforcePerSquareInchGauge',
    value: 'psig',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.pressure.poundPerSquareInch',
    value: 'psi',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.sound.decibel',
    value: 'dB',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.speed.feetPerMinute',
    value: 'fpm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.speed.revolutionsPerMinute',
    value: 'rpm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.status.3PoleSwitch',
    value: 'On[1]/Off[0]/Auto[2]',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.steam.kilogramsPerSecond',
    value: 'kg/s',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.steam.poundsPerHour',
    value: 'lb/h',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.switchPosition.handOffAuto',
    value: 'Hand[1]/Off[0]/Auto[2]',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.temperature.degreesCelsius',
    value: 'C',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.temperature.degreesFahrenheit',
    value: 'F',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.time.days',
    value: 'd',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.time.hours',
    value: 'h',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.time.minutes',
    value: 'm',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.time.seconds',
    value: 's',
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.unitCount.total',
    value: 'total',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.vibrationAnalysis.veloctiy.milimetersPerSecond',
    value: 'mm/s',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.vibrationAnalysis.veloctiy.inchesPerSecond',
    value: 'in/s',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.vibrationAnalysis.veloctiy.metersPerSecond',
    value: 'm/s',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.waterConductivity.microSiemensPerCentimeter',
    value: 'µS/cm',
  },
  {
    label:
      'web.admin.serviceRequest.equipment.unit.waterConductivity.totalDissolvedSolids',
    value: 'ppm',
  },
];

export function getMeterReadingUnitLabel(value: string) {
  const option = meterReadingUnitOptions.find(option => option.value === value);

  return option?.label || value;
}

export const meterReadingUnitDropDownsOptions: {
  label: string;
  key: string;
  items: { label: string; value: string }[];
}[] = [
  {
    label: 'web.admin.serviceRequest.equipment.unit.logic.yesNo',
    key: 'Yes[1]/No[0]',
    items: [
      {
        label: 'web.admin.serviceRequest.equipment.unit.logic.yesNo.yes',
        value: '1',
      },
      {
        label: 'web.admin.serviceRequest.equipment.unit.logic.yesNo.no',
        value: '0',
      },
    ],
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.status.3PoleSwitch',
    key: 'On[1]/Off[0]/Auto[2]',
    items: [
      {
        label: 'web.admin.serviceRequest.equipment.unit.status.3PoleSwitch.on',
        value: '1',
      },
      {
        label: 'web.admin.serviceRequest.equipment.unit.status.3PoleSwitch.off',
        value: '0',
      },
      {
        label:
          'web.admin.serviceRequest.equipment.unit.status.3PoleSwitch.auto',
        value: '2',
      },
    ],
  },
  {
    label: 'web.admin.serviceRequest.equipment.unit.switchPosition.handOffAuto',
    key: 'Hand[1]/Off[0]/Auto[2]',
    items: [
      {
        label:
          'web.admin.serviceRequest.equipment.unit.switchPosition.handOffAuto.hand',
        value: '1',
      },
      {
        label:
          'web.admin.serviceRequest.equipment.unit.switchPosition.handOffAuto.off',
        value: '0',
      },
      {
        label:
          'web.admin.serviceRequest.equipment.unit.switchPosition.handOffAuto.auto',
        value: '2',
      },
    ],
  },
];

export function getMeterReadingUnitDropDown(key: string) {
  const option = meterReadingUnitDropDownsOptions.find(
    option => option.key === key
  );

  return option;
}
