import { useMemo } from 'react';

import { getVisibleContentData } from 'lane-shared/helpers/content';
import { ContentType } from 'lane-shared/types/content/Content';

import useUnsatisfiedDependenciesToFilter from './useUnsatisfiedDependenciesToFilter';

export default function useVisibleClearedFromDependenciesData({
  data,
  content,
}: {
  data: Record<string, any>;
  content: ContentType;
}) {
  const keysToFilter = useUnsatisfiedDependenciesToFilter(
    data,
    content?.data,
    content?.propertiesOptions?.dependencies
  );

  return useMemo(() => {
    const visibleContentData = getVisibleContentData(content);

    return Object.keys(data)?.reduce((result, key) => {
      if (
        visibleContentData?.data.includes(key) &&
        !keysToFilter.includes(key)
      ) {
        result[key] = data[key];
      }

      return result;
    }, {} as any);
  }, [keysToFilter, data, content]);
}
