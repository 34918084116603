import React from 'react';

import { Checkbox } from 'components';
import { useTranslation } from 'react-i18next';
import { H3, P } from 'components/typography';

import { RISE_MIGRATION_FEATURES } from '../../constants';
import {
  RiseMigrationFeaturesListType,
  RiseMigrationFeaturesType,
  RiseMigrationOptionsType,
} from '../../types';

import { AllowedFeatures as RiseMigrationAllowedFeatures } from 'graphql-query-contracts/src/__generated__/graphql';
import { LaneType } from 'common-types';

import styles from '../../styles.scss';
import { Alert } from 'components/lds';
import { MigrateCatUsers } from './MigrateCATUsers';
import { MigrateUsersAndRedirect } from './MigrateUsersAndRedirect';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

export function RiseMigrationFeatures(
  props: RiseMigrationOptionsType & { parentChannelId?: LaneType.UUID }
) {
  const { features, setFeatures, allowedFeatures } = props;
  const { t } = useTranslation();
  const addRandomSuffixEnabled = useFlag(
    FeatureFlag.RiseMigrationAddRandomSuffix,
    false
  );
  const migrateTenantsEnabled = useFlag(
    FeatureFlag.RiseMigrationExportTenants,
    false
  );

  function setSelected(key: string) {
    const value = Boolean(features[key as keyof RiseMigrationFeaturesType]);

    setFeatures({ ...features, [key]: !value });
  }

  const isFirstMigration = Object.values(allowedFeatures || {}).some(
    val => val === false
  );

  const alertText = isFirstMigration ? 'firstMigration' : 'deltaMigration';

  return (
    <section
      className={styles.Section}
      data-test="rise-migration-features-page"
    >
      <H3>{t('web.admin.channel.autoSetupWizard.selectFeatures')}</H3>
      <P mb={2}>
        {t('web.admin.channel.autoSetupWizard.selectFeaturesDescription')}
      </P>
      <Alert type="info" fullWidth>
        {t(`web.admin.channel.autoSetupWizard.${alertText}`)}
      </Alert>
      {RISE_MIGRATION_FEATURES({
        addRandomSuffixEnabled,
        migrateTenantsEnabled,
      }).map((feature: RiseMigrationFeaturesListType) => {
        const { key, name, description } = feature;
        const value = features[key as keyof RiseMigrationFeaturesType];

        const commonProps = {
          feature,
          isFirstMigration,
        };

        if (key === 'migrateCatUsers') {
          return (
            <MigrateCatUsers {...{ ...props, ...commonProps, setFeatures }} />
          );
        }

        if (key === 'migrateUsers') {
          return (
            <MigrateUsersAndRedirect
              {...{ ...props, ...commonProps, setSelected }}
            />
          );
        }

        const allowedFeatureValue = allowedFeatures
          ? !allowedFeatures[key as keyof RiseMigrationAllowedFeatures]
          : false;
        const isDisabled =
          key === 'addRandomSuffixToSlug' ? false : allowedFeatureValue;

        return (
          <Checkbox
            key={key}
            text={name}
            value={key}
            selected={Boolean(value)}
            onChange={() => setSelected(key)}
            description={description}
            disabled={isDisabled}
          />
        );
      })}
    </section>
  );
}
