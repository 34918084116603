import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

function updateQueryParams(
  searchParams: URLSearchParams,
  paramKey: string,
  paramValue: string
) {
  searchParams.set(paramKey, paramValue);

  window.history.replaceState(null, '', `?${searchParams.toString()}`);
}

export function useLegacyPagination(
  defaultPageInfo = { page: '1', perPage: '25' },
  updateUrl = true
) {
  const { search } = useLocation();
  const searchParamsRef = useRef(new URLSearchParams(search));
  const searchParams = searchParamsRef.current;

  const [page, setPage] = useState<string | number>(() => {
    const pageParam = searchParams.get('page');

    return pageParam ?? defaultPageInfo.page;
  });

  const [perPage, setPerPage] = useState<string | number>(() => {
    const perPageParam = searchParams.get('perPage');

    return perPageParam ?? defaultPageInfo.perPage;
  });

  const [sort, setSort] = useState(() => {
    return searchParams.get('sort');
  });

  const [sortOrder, setSortOrder] = useState(() => {
    return searchParams.get('order');
  });

  return {
    page: Number(page),
    perPage: Number(perPage),
    sort,
    sortOrder,
    updatePageNumber(page: string | number) {
      setPage(page);

      if (updateUrl) {
        let pageNum = Number(page);

        if (!Number.isNaN(pageNum) && Number.isFinite(pageNum)) {
          pageNum++;
          updateQueryParams(searchParams, 'page', pageNum.toString());
        }
      }
    },
    updatePageSize(pageSize: string | number) {
      setPerPage(pageSize);

      if (updateUrl) {
        updateQueryParams(searchParams, 'perPage', pageSize.toString());
      }
    },
    updateSortAndOrder(sort: string, order?: string) {
      setSort(sort);
      setSortOrder(order ?? '');

      if (updateUrl) {
        updateQueryParams(searchParams, 'sort', sort);
        updateQueryParams(searchParams, 'order', order ?? '');
      }
    },
  };
}
