import React, { useEffect, useState } from 'react';

import { Loading } from 'components';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getClient } from 'lane-shared/apollo';
import { getTags } from 'lane-shared/graphql/content';
import { deleteTag } from 'lane-shared/graphql/mutation';
import { Table } from 'design-system-web';
import type { Tag } from './types';
import { routes } from 'lane-shared/config';
import { useMultiLanguage } from 'lane-shared/hooks';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './Tags.scss';

export const Tags = () => {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [tags, setTags] = useState<Tag[] | undefined>();
  const { translate } = useMultiLanguage();
  const history = useHistory();

  const fetchTags = async () => {
    if (!channel?._id) {
      return;
    }

    const variables = {
      channelId: channel._id,
    };

    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getTags,
      variables,
    });

    if (data?.tags) {
      const translatedTags = translate({ model: data.tags, columns: ['name'] });

      setTags(translatedTags);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = async (id: string) => {
    try {
      await window.Alert.confirm({
        title: t('web.pages.portal.admin.channel.tags.deleteConfirmationTitle'),
        message: `${t(
          'web.pages.portal.admin.channel.tags.deleteConfirmationMessage'
        )}`,
      });
    } catch (err) {
      // user cancelled
      return;
    }

    await getClient().mutate({
      mutation: deleteTag,
      variables: { id },
    });

    fetchTags();
  };

  if (!channel || !tags) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const columns = [
    {
      header: t`web.pages.portal.admin.channel.tags.table.name`,
      key: 'name',
      renderCell: (_: any, tag: Tag) => {
        return (
          <Link
            to={routes.channelAdminLibraryTagShow
              .replace(':id', channel?.slug)
              .replace(':tagId', tag.id)}
          >
            {tag.name}
          </Link>
        );
      },
    },
    {
      header: t`web.admin.content.sections.table.contentPlacements`,
      key: 'contents',
      renderCell: (contents: string) => contents?.length,
    },
    {
      header: t`web.admin.content.sections.table.sectionPlacements`,
      key: 'sections',
      renderCell: (sections: string) => sections?.length,
    },
  ];

  const computeTooltipText = (tag: Tag) => {
    let reason;

    if (tag?.contents?.length) {
      reason = t('web.admin.library.tags.contentInUse');
    }

    if (tag.isDefault) {
      reason = t('web.admin.library.tags.contentIsDefault');
    }

    return reason;
  };

  const actions = [
    {
      label: t('Delete'),
      onClick: (tag: Tag) => onDelete(tag.id),
      isDisabled: (tag: Tag) => tag?.contents?.length > 0 || tag.isDefault,
      tooltipText: (tag: Tag) => {
        const disabledReason = computeTooltipText(tag);

        return disabledReason;
      },
    },
    {
      label: t('Edit'),
      onClick: (tag: Tag) =>
        history.push(
          routes.channelAdminLibraryTagEdit
            .replace(':id', channel?.slug)
            .replace(':tagId', tag.id)
        ),
    },
  ];

  return (
    <Table
        columns={columns}
        data={tags}
        pagination="client"
        defaultPageSize={10}
        hasKeywordFilter
        rowActions={actions}
        onPrimaryAction={() =>
          history.push(
            routes.channelAdminLibraryTagNew.replace(':id', channel.slug)
          )
        }
        primaryActionLabel={t(
          'web.pages.portal.admin.library.tags.newButtonText'
        )}
    />
  );
};
