import { useEffect, useState, useRef } from 'react';

import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { LoginNotExistsError } from 'activate-errors';
import { isThisError } from 'lane-shared/helpers';
import { oAuthConfigScheme } from 'lane-shared/helpers/oAuth';
import { UserLoginProviderEnum } from 'lane-shared/types/UserLogin';

import OAuthService from '../../utils/oauth/OAuth.service';
import { decompressData } from './helpers';
import { signInOAuth } from './signInOAuth';

type QueryStringParams = {
  [k: string]: string | (string | null)[] | null;
};

function isUserLoginProviderEnum(t: any): t is UserLoginProviderEnum {
  return Object.values(UserLoginProviderEnum).includes(t);
}

const redirectResponseValidator = yup
  .object()
  .shape({
    code: yup.string().trim().required(),
    secret: yup.string().trim().optional(),
    id_token: yup.string().trim().optional(),
    user: yup.string().trim().optional(),
  })
  .required()
  .noUnknown();

function _getRedirectResponseFromParams(params: QueryStringParams) {
  return redirectResponseValidator.validateSync(params);
}

function formatUserFullName(user: string) {
  const { name } = JSON.parse(user);
  const formattedName = `${name.firstName} ${name.lastName}`;

  return formattedName;
}

export default function useOAuth2Params() {
  const oAuthServiceLock = useRef<boolean>(false);
  const { addOAuthSignUpDetails } = useSignUpContext();
  const [error, setError] = useState<undefined | string>(undefined);

  const history = useHistory();

  const hashString = queryString.parse(window.location.hash);
  const searchString = queryString.parse(window.location.search);

  const paramRef = useRef({
    ...hashString,
    ...searchString,
  });

  useEffect(() => {
    async function handleOAuth2Params(state: string) {
      const decompressedState = await decompressData(state);

      const config = oAuthConfigScheme.required().validateSync({
        clientSecret: paramRef.current.secret,
        ...decompressedState,
      });

      if (!decompressedState.codeVerifier) {
        throw new Error('No code verifier.');
      }

      const provider = config.provider;

      if (!provider || typeof provider !== 'string') {
        throw new Error('Missing OAuth2 provider.');
      }

      const redirectResponse = _getRedirectResponseFromParams(paramRef.current);

      const oAuthService = new OAuthService({
        config,
        codeVerifier: decompressedState.codeVerifier,
      });

      const response = await oAuthService.authorizeFromRedirectResponse(
        redirectResponse
      );

      try {
        await signInOAuth(response.idToken, provider, config.inviteId);
      } catch (err) {
        if (isThisError(err, LoginNotExistsError)) {
          if (!isUserLoginProviderEnum(provider)) {
            throw new Error(`OAuth2 provider "${provider}" is not valid.`);
          }

          // In the case of Apple SSO only, a user object is returned the first time
          // the user signs up.
          // user: {name: { firstName: string, lastName: string}, email: string}
          const { user } = redirectResponse;
          const options: {
            inviteId?: string | null;
            fullName?: string;
          } = {
            inviteId: config.inviteId,
          };

          if (user) {
            const userFullName = formatUserFullName(user);

            options.fullName = userFullName;
          }

          addOAuthSignUpDetails(response.idToken, provider, options);
          history.push(routes.signUpCompany);

          return;
        }

        history.push(routes.signUp);
      }
    }

    if (oAuthServiceLock.current) {
      return;
    }

    const state = paramRef.current.state;

    if (!state || typeof state !== 'string') {
      return;
    }

    oAuthServiceLock.current = true;

    handleOAuth2Params(state).catch(err => {
      setError(err.message);
    });
  }, [paramRef.current.state, history, addOAuthSignUpDetails]);

  return { ...paramRef.current, error };
}
