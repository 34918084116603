import { useEffect } from 'react';

import { FontStyleType, FontType } from 'lane-shared/config/fonts';

export function useFonts({
  bodyFont,
  headerFont,
}: {
  bodyFont: FontType;
  headerFont: FontType;
}) {
  async function loadFontFace(font: FontStyleType) {
    if (font.url) {
      try {
        const fontFace = new FontFace(font.family, `url(${font.url})`, {
          weight: font.weight,
          style: 'normal',
        });

        const loadedFont = await fontFace.load();

        document.fonts.add(loadedFont);
      } catch (e) {
        console.error(e);
      }
    }
  }

  useEffect(() => {
    const fontfacesToLoad: FontStyleType[] = [];

    fontfacesToLoad.push(...Object.values(bodyFont.fontStyles));

    if (headerFont.fontFamily !== bodyFont.fontFamily) {
      fontfacesToLoad.push(...Object.values(headerFont.fontStyles));
    }

    fontfacesToLoad.forEach(font => {
      loadFontFace(font);
    });
  }, [bodyFont.fontFamily, headerFont.fontFamily]);
}
