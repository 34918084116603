import React from 'react';

import cx from 'classnames';

import { checkSecurityRulesAgainstObject } from 'lane-shared/helpers';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { Property, PropertyValue } from 'components/builder';

import styles from './ObjectPropertiesRenderer.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  properties: [string, PropertyType][];
  disabled: boolean;
  creatorId: string;
  ownerId: string;
  sourceId: string;
  user: any;
  data: any;
  channel: any;
  onPropertyValueChanged: (key: string, value: any) => void;
};

export default function ObjectPropertiesRenderer({
  className,
  style,
  disabled,
  properties,
  creatorId,
  ownerId,
  sourceId,
  user,
  data,
  channel,
  onPropertyValueChanged,
}: Props) {
  if (!properties || !properties.length) {
    return null;
  }

  return (
    <div
      className={cx(styles.ObjectPropertiesRenderer, className)}
      style={style}
    >
      {properties.map(([key, property]) => {
        const isEditable = checkSecurityRulesAgainstObject({
          permissions: [],
          user,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'PropertySecurityRule[] | undefined' is not a... Remove this comment to see the full error message
          rules: property.secure?.update,
          creatorId,
          ownerId,
          sourceId,
        });

        return (
          <div key={key} className={styles.property}>
            <label>{property.friendlyName || property.name || key}</label>
            {isEditable ? (
              <Property
                disabled={disabled}
                object={data}
                channel={channel}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ disabled: boolean; object: any; channel: a... Remove this comment to see the full error message
                user={user._id}
                property={property}
                value={data?.[key]}
                onChange={value => onPropertyValueChanged(key, value)}
                onPropertyChange={({ key, value }) =>
                  onPropertyValueChanged(key, value)
                }
              />
            ) : (
              <PropertyValue field={property} value={data?.[key]} />
            )}
          </div>
        );
      })}
    </div>
  );
}
