import React, { useEffect, useState } from 'react';

import {
  Loading,
  ControlMenu,
  Button,
  ErrorMessage,
  WhitelabelInfoEdit,
} from 'components';

import { getAdminClient } from 'lane-shared/apollo';
import routes from 'lane-shared/config/routes';
import { castForUpdate, pause } from 'lane-shared/helpers';
import {
  useUpdatedData,
  useValidation,
  useDefaultWhitelabel,
} from 'lane-shared/hooks';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { validateWhitelabel } from 'lane-shared/validation';

import createWhitelabelMutation from '../createWhitelabelMutation';
import history from 'helpers/history';

import styles from './styles.scss';

export function formatDefaultWhitelabel(whitelabel: WhiteLabelType) {
  return {
    address: {
      _order: 0,
      name: '',
      street1: '',
      street2: '',
      street3: null,
      city: '',
      state: '',
      code: '',
      country: '',
      geo: [0, 0],
    },
    profile: {
      name: whitelabel?.profile?.name,
      description: '',
      email: '',
      phone: '',
      color: whitelabel?.profile?.color,
      backgroundColor: whitelabel.profile?.backgroundColor,
    },
    name: whitelabel?.name,
    description: null,
    instance: whitelabel?.instance,
    channels: null,
    restrict: false,
    commonName: whitelabel?.commonName,
    sha256Fingerprint: whitelabel?.sha256Fingerprint,
    mobileIos: whitelabel?.mobileIos,
    mobileAndroid: whitelabel?.mobileAndroid,
    privacyPolicyUrls: [],
    termsAndConditionsUrls: [],
    hosts: whitelabel?.hosts,
    url: whitelabel?.hosts,
    urlDevelopment: whitelabel?.urlDevelopment,
    urlStaging: whitelabel?.urlStaging,
    settings: {
      allowSignUps: true,
    },
  };
}

function WhitelabelCreate() {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const { whitelabel: defaultWhitelabel } = useDefaultWhitelabel();

  const [whitelabel, setWhitelabel, hasChanges] = useUpdatedData(
    defaultWhitelabel
  );
  const [validation] = useValidation(whitelabel, validateWhitelabel);

  useEffect(() => {
    if (defaultWhitelabel?._id) {
      setWhitelabel(
        castForUpdate(formatDefaultWhitelabel(defaultWhitelabel)),
        true
      );
    }
  }, [defaultWhitelabel?._id]);

  function resetData() {
    setWhitelabel(formatDefaultWhitelabel(defaultWhitelabel), true);
  }

  async function onSave() {
    if (validation) {
      return;
    }

    try {
      await window.Alert.confirm({
        title: 'Hey, do you know what your doing?',
        message:
          'Creating a whitelabel also requires updating other parts of the web and mobile application.  Make sure you know what you are doing, and you are authorized to do this.',
      });
    } catch (err) {
      // user cancelled;
      return;
    }

    try {
      setSaving(true);
      setError(null);
      await pause();
      const savedWhitelabel = await getAdminClient().mutate<{
        createWhitelabel: { _id: string };
      }>({
        mutation: createWhitelabelMutation,
        variables: {
          whiteLabel: whitelabel,
        },
      });

      resetData();
      window.Toast.show(`${(whitelabel as any).name} saved.`);

      if (savedWhitelabel.data?.createWhitelabel) {
        history.push(
          routes.portalManagementWhitelabelsEdit.replace(
            ':whitelabelId',
            savedWhitelabel.data?.createWhitelabel._id
          )
        );
      }
    } catch (err) {
      setError(err);
      await window.Alert.alert({
        title: `I wasn't able to save this whitelabel.`,
        message: `See the error below and please try again.`,
        error: err,
      });
    }

    setSaving(false);
  }

  if (!whitelabel) {
    return <Loading />;
  }

  return (
    <div className={styles.WhitelabelCreate}>
      <ControlMenu>
        <Button
          testId="createWhitelabel"
          loading={saving}
          disabled={!hasChanges}
          onClick={onSave}
          variant="contained"
        >
          Create
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />
      <ErrorMessage error={validation} />

      <WhitelabelInfoEdit
        loading={saving}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(() => Promise<boolean>) | null | undefined'... Remove this comment to see the full error message
        validation={validation}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'WhiteLab... Remove this comment to see the full error message
        whitelabel={whitelabel}
        onWhitelabelUpdated={update => setWhitelabel(update)}
      />
    </div>
  );
}

export default WhitelabelCreate;
