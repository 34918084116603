import styles from './CreateProductForm.scss';
import { Dropdown, Input } from 'components';
import {
  MarkupAmountType,
  TaxAmountType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNumberTwoDecimalPlaces } from '../helpers';

const MARKUP_DROPDOWN_OPTIONS = [
  {
    label: 'abp.productsServices.createProduct.optionalRateType.fixed',
    value: MarkupAmountType.MarkupAmountTypeFixed,
  },
  {
    label: 'abp.productsServices.createProduct.optionalRateType.percentage',
    value: MarkupAmountType.MarkupAmountTypePercentage,
  },
];

const TAX_DROPDOWN_OPTIONS = [
  {
    label: 'abp.productsServices.createProduct.optionalRateType.fixed',
    value: TaxAmountType.TaxAmountTypeFixed,
  },
  {
    label: 'abp.productsServices.createProduct.optionalRateType.percentage',
    value: TaxAmountType.TaxAmountTypePercentage,
  },
];

export enum ModifierType {
  MARKUP = 'markup',
  TAX = 'tax',
  RATE = 'rate',
}

const DROPDOWN_OPTIONS = {
  [ModifierType.MARKUP]: MARKUP_DROPDOWN_OPTIONS,
  [ModifierType.TAX]: TAX_DROPDOWN_OPTIONS,
};

interface Props {
  modifierType?: TaxAmountType | MarkupAmountType;
  modifierValue?: string;
  updateDetails: (value: any, field: string) => void;
  type: ModifierType;
  currency?: SupportedPaymentCurrency;
  disabled?: boolean;
}

export function RateModifierInput({
  modifierType,
  modifierValue,
  updateDetails,
  type,
  currency,
  disabled = false,
}: Props) {
  const { t } = useTranslation();

  const isTypePercentage =
    modifierType &&
    [
      TaxAmountType.TaxAmountTypePercentage,
      MarkupAmountType.MarkupAmountTypePercentage,
    ].includes(modifierType);

  const getInputIcon = () => {
    if (isTypePercentage) {
      return 'percentage';
    }

    return '';
  };

  const handleInputChange = (value: string) => {
    if (type === ModifierType.RATE) {
      updateDetails(value, type);
    } else {
      updateDetails({ value, type: modifierType }, type);
    }
  };

  const inputError = () => {
    if (modifierValue) {
      if (!isNumberTwoDecimalPlaces(parseFloat(modifierValue))) {
        return [t('abp.productsServices.createProduct.validation')];
      }
    }

    return null;
  };

  return (
    <div className={styles.additionalRateGroup}>
      {type !== ModifierType.RATE && (
        <Dropdown
          label={t(`abp.productsServices.createProduct.${type}.label`)}
          fixedLabel
          isRequired
          testId={`${type}ModifierDropdown`}
          className={styles.additionalRateType}
          value={modifierType}
          items={DROPDOWN_OPTIONS[type]}
          onChange={item => {
            updateDetails({ value: modifierValue, type: item.value }, type);
          }}
          disabled={disabled}
        />
      )}
      <Input
        label={t('abp.productsServices.createProduct.value')}
        fixedLabel
        isRequired
        testId={`${type}ValueInput`}
        className={styles.additionalRateValue}
        showClear={false}
        icon={getInputIcon()}
        input={{ type: 'number', step: '0.01', min: 0.0 }}
        iconRight
        value={modifierValue}
        onChange={handleInputChange}
        error={inputError()}
        disabled={disabled}
      />
      {currency && !isTypePercentage && (
        <div className={styles.currencyText}>{currency}</div>
      )}
    </div>
  );
}
