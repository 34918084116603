import React, { useCallback, useContext, useMemo } from 'react';

import { Icon } from 'design-system-web';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { LaneIntegrationType } from 'common-types';
import { UserDataContext, ValidationErrorContext } from 'lane-shared/contexts';
import { arrayReorder, getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { constructWorkflow } from 'lane-shared/helpers/content';
import constructShopifyRsvpFeatures from 'lane-shared/helpers/integrations/ShopifyBurst/constructShopifyRsvpFeatures';
import { byOrder } from 'lane-shared/helpers/sort';
import { ContentWorkflowType } from 'lane-shared/types/ContentWorkflowType';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import ErrorMessage from 'components/general/ErrorMessage';
import ChannelIntegrationEditorAutoRenderer from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorAutoRenderer';
import { ChannelIntegrationEditorProps } from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import { H4, P } from 'components/typography';
import ContentWorkflowDetails from 'components/workflows/ContentWorkflowDetails';

import styles from './ShopifyBurst.scss';

export default function ShopifyBurst({
  forCreate,
  channelIntegration,
  channel,
  onUpdateChannelIntegration,
  definition,
}: ChannelIntegrationEditorProps) {
  const errors = useContext(ValidationErrorContext);
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();
  const settings = channelIntegration.settings as LaneIntegrationType.ShopifyBurst;
  const rsvpWorkflows = useMemo(
    () => (settings.rsvpWorkflows || []).sort(byOrder) as ContentWorkflowType[],
    [settings.rsvpWorkflows]
  );

  const diyWorkflows = useMemo(
    () => (settings.diyWorkflows || []).sort(byOrder) as ContentWorkflowType[],
    [settings.diyWorkflows]
  );

  const content = {
    _id: channelIntegration._id,
    name: 'Test',
    features: constructShopifyRsvpFeatures({ creatorId: user!._id }),
    type: ContentTypeEnum.Content,
    integration: channelIntegration,
    data: {},
    isInteractive: true,
    channel,
  };

  const addWorkflow = useCallback(
    () =>
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          diyWorkflows,
          rsvpWorkflows: [
            ...rsvpWorkflows,
            constructWorkflow({
              contentId: content._id,
              userId: user!._id,
            }),
          ],
        },
      }),
    [rsvpWorkflows, diyWorkflows]
  );

  const addDiyWorkflow = useCallback(
    () =>
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows,
          diyWorkflows: [
            ...diyWorkflows,
            constructWorkflow({
              contentId: content._id,
              userId: user!._id,
            }),
          ],
        },
      }),
    [diyWorkflows, rsvpWorkflows]
  );

  const removeWorkflow = useCallback(
    (id: string) =>
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows: rsvpWorkflows.filter(n => n._id !== id),
          diyWorkflows,
        },
      }),
    [rsvpWorkflows, diyWorkflows]
  );

  const removeDiyWorkflow = useCallback(
    (id: string) =>
      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows,
          diyWorkflows: diyWorkflows.filter(n => n._id !== id),
        },
      }),
    [rsvpWorkflows, diyWorkflows]
  );

  const cloneWorkflow = useCallback(
    (id: string) => {
      // find the cloned workflow
      const workflow = rsvpWorkflows.find(workflow => workflow._id === id);

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows: [...rsvpWorkflows, { ...workflow, _id: uuid() }],
          diyWorkflows,
        },
      });
    },

    [rsvpWorkflows, diyWorkflows]
  );

  const cloneDiyWorkflow = useCallback(
    (id: string) => {
      // find the cloned workflow
      const workflow = diyWorkflows.find(workflow => workflow._id === id);

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows,
          diyWorkflows: [...diyWorkflows, { ...workflow, _id: uuid() }],
        },
      });
    },

    [rsvpWorkflows, diyWorkflows]
  );

  const workflowUpdated = useCallback(
    (id: string, update: Partial<ContentWorkflowType>) => {
      const index = rsvpWorkflows.findIndex(workflow => workflow._id === id);
      const originalWorkflow = rsvpWorkflows[index]!;

      rsvpWorkflows[index] = { ...originalWorkflow, ...update };

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows: [...rsvpWorkflows],
          diyWorkflows,
        },
      });
    },
    [rsvpWorkflows, diyWorkflows]
  );

  const workflowDiyUpdated = useCallback(
    (id: string, update: Partial<ContentWorkflowType>) => {
      const index = diyWorkflows.findIndex(workflow => workflow._id === id);
      const originalWorkflow = diyWorkflows[index]!;

      diyWorkflows[index] = { ...originalWorkflow, ...update };

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows,
          diyWorkflows: [...diyWorkflows],
        },
      });
    },
    [rsvpWorkflows, diyWorkflows]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          rsvpWorkflows: arrayReorder(
            rsvpWorkflows,
            result.source.index,
            result.destination.index
          ),
        },
      });
    },
    [rsvpWorkflows]
  );

  const onDragEndDiy = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          diyWorkflows: arrayReorder(
            diyWorkflows,
            result.source.index,
            result.destination.index
          ),
        },
      });
    },
    [diyWorkflows]
  );

  if (!channel) {
    return null;
  }

  const [longitude, latitude] = channel.address!.geo;
  const timeZone = getTimeZoneByGeoLocation({ longitude, latitude });

  function renderWorkflows(
    workflows: ContentWorkflowType[],
    validatedPathName: string,
    cloneWorkflow: (id: string) => void,
    workflowUpdated: (id: string, update: Partial<ContentWorkflowType>) => void,
    removeWorkflow: (id: string) => void
  ) {
    return workflows.map((workflow, i: number) => {
      return (
        <Draggable
          key={workflow._id}
          draggableId={workflow._id}
          index={i}
          isDragDisabled={false}
        >
          {(provided, snapshot) => (
            <li
              data-test="workflow"
              key={workflow._id}
              className={styles.workflow}
              ref={provided.innerRef}
              data-is-dragging={snapshot.isDragging}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Icon
                name="bars"
                set={ICON_SET_FONTAWESOME}
                className={styles.iconReorder}
              />
              <ContentWorkflowDetails
                key={workflow._id}
                content={content}
                channel={channel}
                timeZone={timeZone}
                className={styles.workflowDetails}
                workflow={workflow}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; content: { _id: string; name:... Remove this comment to see the full error message
                validation={errors}
                validationPath={`${validatedPathName}[${i}]`}
                onWorkflowUpdated={workflowUpdated}
                onWorkflowCloned={cloneWorkflow}
                onWorkflowDeleted={removeWorkflow}
              />
            </li>
          )}
        </Draggable>
      );
    });
  }

  if (forCreate) {
    return (
      <div>
        <p>{t('web.admin.channel.integrations.shopify-burst.create')}</p>
      </div>
    );
  }

  return (
    <div className={styles.ShopifyBurst}>
      <ErrorMessage error={errors} />
      <ChannelIntegrationEditorAutoRenderer
        forCreate={forCreate}
        channelIntegration={channelIntegration}
        channel={channel}
        onUpdateChannelIntegration={onUpdateChannelIntegration}
        definition={definition}
      />

      <H4 className={styles.workflowTitle}>
        {t`web.admin.channel.integrations.shopify-burst.rsvp-workflows`}{' '}
        <Icon
          testId="addWorkflow"
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addWorkflow}
        />
      </H4>
      <P mt={2} mb={4}>
        Default workflows that will get attached to the RSVP Content that is
        created when a Burst Interaction gets accepted. Note that you can use{' '}
        <b>{'{{burstName}}'}</b> to inject the name of the Burst, or{' '}
        <b>{'{{name}}'}</b> to inject the user name, in the email, sms, or
        notification title or body.
      </P>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="properties">
          {provided => (
            <ul className={styles.workflows} ref={provided.innerRef}>
              {renderWorkflows(
                rsvpWorkflows,
                'rsvpWorkflows',
                cloneWorkflow,
                workflowUpdated,
                removeWorkflow
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <H4 className={styles.workflowTitle}>
        {t`web.admin.channel.integrations.shopify-burst.diy-workflows`}{' '}
        <Icon
          testId="addDiyWorkflow"
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          className={styles.addIcon}
          onClick={addDiyWorkflow}
        />
      </H4>

      <P mt={2} mb={4}>
        Default workflows that will get attached to the DIY Content that is
        created when a Burst Interaction gets accepted. Note that you can use{' '}
        <b>{'{{burstName}}'}</b> to inject the name of the Burst, or{' '}
        <b>{'{{name}}'}</b> to inject the user name, in the email, sms, or
        notification title or body.
      </P>
      <DragDropContext onDragEnd={onDragEndDiy}>
        <Droppable droppableId="propertiesDiy">
          {provided => (
            <ul className={styles.workflows} ref={provided.innerRef}>
              {renderWorkflows(
                diyWorkflows,
                'diyWorkflows',
                cloneDiyWorkflow,
                workflowDiyUpdated,
                removeDiyWorkflow
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
