import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  ReferenceArea,
  Legend,
} from 'recharts';

import { dateFormatter } from 'lane-shared/helpers/formatters';
import { IconButton, DataViewControls, Flex } from 'design-system-web';
import tokens from '@viewthespace/style-dictionary/assets/json/tokens.json';

import {
  VISUALIZATION_TYPES,
  useMeterReadingList,
} from './meterReadingListContext';
import { MeterReadingDetails } from './MeterReadingDetails';
import { useTranslation } from 'react-i18next';

export const MeterReadingGraph = () => {
  const { t } = useTranslation();
  const {
    selectedSetting,
    page,
    setPage,
    setVisualizationType,
    totalCount,
    meterReadingValues,
    setEditingReading,
    exportOptions,
  } = useMeterReadingList();

  const chartData = meterReadingValues
    .filter(value => !value.isArchived)
    .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
    .map(meterReading => ({
      ...meterReading,
      unit: selectedSetting.unit,
    }));

  return (
    <div className="border-t border-t-border-subdued">
      <DataViewControls
        data={chartData}
        visualizationTypes={VISUALIZATION_TYPES}
        visualizationType="graph"
        onVisualizationTypeChange={visualizationType => {
          setVisualizationType(visualizationType);
        }}
        exportOptions={exportOptions}
      />

      <Flex>
        <IconButton
          onClick={() => setPage(page + 1)}
          disabled={page !== 0 || totalCount <= chartData.length}
          icon="ChevronLeft"
          className="self-center"
          label={t(
            'web.admin.serviceRequest.equipment.meterReadingValues.newer'
          )}
        />

        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <XAxis
              dataKey="createdAt"
              tickFormatter={createdAt => dateFormatter(createdAt, 'MM/dd')}
            />

            <YAxis unit={selectedSetting.unit} />

            <Legend
              payload={[
                {
                  value: t(
                    'web.admin.serviceRequest.equipment.meterReadingValues.valueEscalationBenchmark'
                  ),
                  type: 'plainline',
                  payload: { strokeDasharray: '' },
                  color: tokens.color.text.attention,
                },
                {
                  value: t(
                    'web.admin.serviceRequest.equipment.meterReadingValues.meterReadingDataPoints'
                  ),
                  type: 'line',
                  color: '#000',
                },
              ]}
            />

            {/* @ts-expect-error the library seems to be confused */}
            <Tooltip content={<MeterReadingDetails />} />

            {selectedSetting.upperBound && (
              <ReferenceArea
                y1={selectedSetting.upperBound}
                fill={tokens.color.text.attention}
                opacity={0.25}
              >
                <Label
                  fontSize={12}
                  position="bottom"
                  value={t(
                    'web.admin.serviceRequest.equipment.meterReadingValues.ceiling',
                    {
                      value: `${selectedSetting.upperBound}${selectedSetting.unit}`,
                    }
                  )}
                />
              </ReferenceArea>
            )}

            {selectedSetting.lowerBound && (
              <ReferenceArea
                y2={selectedSetting.lowerBound}
                fill={tokens.color.text.attention}
                opacity={0.25}
              >
                <Label
                  fontSize={12}
                  position="top"
                  value={t(
                    'web.admin.serviceRequest.equipment.meterReadingValues.floor',
                    {
                      value: `${selectedSetting.lowerBound}${selectedSetting.unit}`,
                    }
                  )}
                />
              </ReferenceArea>
            )}

            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="value"
              stroke={tokens.color.text.primary}
              activeDot={{
                r: 8,
                cursor: 'pointer',
                onClick: (_, data) => {
                  setEditingReading(
                    // @ts-expect-error the library seems to be confused here, too
                    meterReadingValues.find(v => v.id === data.payload.id) ??
                      null
                  );
                },
              }}
              unit={selectedSetting.unit}
            />
          </LineChart>
        </ResponsiveContainer>

        <IconButton
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
          icon="ChevronRight"
          className="self-center"
        />
      </Flex>
    </div>
  );
};
