import { Line } from 'components';
import { H4, M } from 'components/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateProductForm.scss';

export function CreditPricingSummary({
  creditAmount,
}: {
  creditAmount: string;
}) {
  const { t } = useTranslation();
  const creditAmountToShow = creditAmount
    ? t('abp.productsServices.createProduct.creditSummary.numberOfCredits', {
        creditAmount,
      })
    : t('abp.productsServices.createProduct.creditSummary.emptyCredits');

  return (
    <div className={styles.creditSummaryContainer}>
      <M>{t('abp.productsServices.createProduct.creditSummary.title')}</M>
      <Line />
      <div className={styles.totalCreditsSection}>
        <H4>{t('abp.productsServices.createProduct.creditSummary.total')}</H4>
        <H4>{creditAmountToShow}</H4>
      </div>
    </div>
  );
}
