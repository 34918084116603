import React from 'react';

import { useTranslation } from 'react-i18next';

import AddSubscription from './me/AddSubscription';

import styles from './NoSubscriptions.scss';

export default function NoSubscriptions() {
  const { t } = useTranslation();

  return (
    <div className={styles.Subscription}>
      <h2>{t("It looks like you're not setup yet")}</h2>
      <AddSubscription />
    </div>
  );
}
