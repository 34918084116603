import React from 'react';

import { Icon } from 'design-system-web';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { arrayReorder } from 'lane-shared/helpers';
import { FONT_AWESOME_SOLID } from 'lane-shared/helpers/constants/icons';

import { Input, Button, Tooltip } from 'lane-web/src/components';
import { IconButton } from 'lane-web/src/components/general';

import { DEFAULT_OPTION } from '../../constants';
import { OptionType, InnerOptionsError } from '../../types';

import styles from './SimpleOptions.scss';

const SimpleOptions = ({
  options,
  setOptions,
  errors,
  addOptionTitle,
  disableDragging = false,
  hideAddOption = false,
  allowEdit = true,
}: {
  options: OptionType[];
  setOptions: (options: OptionType[]) => void;
  errors?: InnerOptionsError[] | null;
  addOptionTitle?: string;
  addOptionStyle?: any;
  disableDragging?: boolean;
  hideAddOption?: boolean;
  allowEdit?: boolean;
}) => {
  const { t } = useTranslation();

  const onOptionChange = (index: number, value: string) => {
    const newOptions = [...options];

    newOptions[index] = { ...newOptions[index], name: value, value };
    setOptions(newOptions);
  };

  function onDragEnd(result: DropResult) {
    if (!result.destination?.index) return;

    setOptions(
      arrayReorder(options, result.source.index, result.destination.index)
    );
  }

  const onOptionDelete = async (index: number) => {
    try {
      await window.Alert.confirm({
        title: t('web.admin.serviceRequest.settings.option.delete.text'),
        message: t('web.admin.serviceRequest.settings.option.action.text'),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const newOptions = [...options];

    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const onAddOptionPress = () => {
    setOptions([
      ...options,
      { ...DEFAULT_OPTION, _id: uuid(), _order: options?.length || 0 },
    ]);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="options">
          {provided => (
            <div
              ref={provided.innerRef}
              style={{ minHeight: 65 * options?.length }}
            >
              {options &&
                options.map((option: OptionType, index: number) => (
                  <Draggable
                    key={`Option-${index}`}
                    draggableId={`Option-${index}`}
                    index={index}
                    isDragDisabled={disableDragging}
                  >
                    {provided => (
                      <div
                        className={styles.optionContainer}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {!disableDragging && (
                          <Icon
                            name="grip-lines"
                            type={FONT_AWESOME_SOLID}
                            className={styles.gripIcon}
                          />
                        )}
                        <Input
                          fieldName={`optionInput-${index}`}
                          placeholder={t`web.admin.serviceRequest.settings.addOption.text`}
                          error={
                            errors?.find(error => error.optionId === option._id)
                              ?.errors
                          }
                          value={option.name}
                          showClear={false}
                          className={styles.input}
                          onChange={value => onOptionChange(index, value)}
                          testId={`option-input-${index}`}
                          readOnly={!allowEdit}
                        />

                        {allowEdit &&
                          (options?.length === 1 ? (
                            <Tooltip
                              className={styles.tooltip}
                              TooltipComponent={t`web.admin.serviceRequest.settings.issue.validation.text`}
                            >
                              <IconButton
                                icon="times"
                                testId={`remove-option-${index}`}
                                size="small"
                                disabled={options.length === 1}
                                onClick={() => onOptionDelete(index)}
                                className={styles.optionDeleteButton}
                              />
                            </Tooltip>
                          ) : (
                            <IconButton
                              icon="times"
                              testId={`remove-option-${index}`}
                              size="small"
                              disabled={options.length === 1}
                              onClick={() => onOptionDelete(index)}
                              className={styles.optionDeleteButton}
                            />
                          ))}
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!hideAddOption && (
        <Button
          variant="outlined"
          startIcon={<Icon name="plus" />}
          onClick={onAddOptionPress}
          size="small"
          testId="add-option"
          className={styles.addOptionButton}
        >
          {addOptionTitle ||
            t`web.admin.serviceRequest.settings.option.add.button.text`}
        </Button>
      )}
    </>
  );
};

export default SimpleOptions;
