import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, Button } from 'design-system-web';
import { S } from 'components/typography';

type ChannelManagementModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClick: () => Promise<void>;
  onClose?: () => void;
  title: string;
  description?: string;
  body?: React.ReactNode;
  size?: 'small' | 'large';
  disableConfirm?: boolean;
};

export function ChannelManagementModal({
  isOpen,
  setIsOpen,
  onClick,
  onClose,
  title,
  description = '',
  body = <></>,
  size = 'small',
  disableConfirm = false,
}: ChannelManagementModalProps) {
  const { t } = useTranslation();

  const onCancel = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={title}
      size={size}
      closeButtonSize="small"
      actions={
        <>
          <Button onClick={onClick} disabled={disableConfirm}>
            {t('web.admin.portalManagement.channelManagement.modal.confirm')}
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            {t('web.admin.portalManagement.channelManagement.modal.cancel')}
          </Button>
        </>
      }
    >
      <S mb={6}>{description}</S>
      {body}
    </Modal>
  );
}
