import React from 'react';

import { Flex, Label } from 'lane-web/src/components';

import { useTranslation } from 'react-i18next';

import { getMeterReadingUnitDropDown } from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingUnitOptions';
import styles from './MeterReadingInput.scss';
import { useFormContext } from 'react-hook-form';
import {
  HookFormDropdown,
  HookFormInput,
  HookFormTextarea,
} from 'components/reactHookForm';
import ValidationMessage from 'design-system-web/components/ValidationMessage/ValidationMessage';

export interface MeterReadingInputProps {
  unit: string;
  settingName: string;
}

export function EditMeterReadingInput({
  unit,
  settingName,
}: MeterReadingInputProps) {
  const { t } = useTranslation();
  const { control, register, formState } = useFormContext();

  const structuredDataSelect = getMeterReadingUnitDropDown(unit);
  const isStructuredData = !!structuredDataSelect;

  return (
    <div className="my-2">
      <Flex gap={5} direction="column">
        <Flex gap={3} className={styles.MeterReadingLabels}>
          <div className={styles.MeterReadingInput}>
            {isStructuredData && (
              <HookFormDropdown
                control={control}
                {...register('meterReading.value', { required: true })}
                testId="meter-reading-value-select-dropdown"
                label={settingName}
                items={structuredDataSelect.items}
                isRequired
                fixedLabel
                isFullWidth
              />
            )}

            {!isStructuredData && (
              <HookFormInput
                fieldName="value"
                control={control}
                {...register('meterReading.value', { required: true })}
                isRequired
                testId="meter-reading-value-input"
                label={settingName}
                fixedLabel
                ariaLabel={t(
                  'web.admin.serviceRequest.equipment.MeterReadingInput.valueFor',
                  {
                    for: settingName,
                  }
                )}
                type="number"
              />
            )}

            {/* @ts-expect-error the error object doesn't love having a dot in a field name */}
            {formState.errors.meterReading?.value && (
              <ValidationMessage
                errors={[t('web.admin.serviceRequest.field.required')]}
                doTranslation={false}
              />
            )}
          </div>

          <Label mt={5} style={{ marginRight: 'auto' }}>
            {unit}
          </Label>
        </Flex>

        <Flex direction="column">
          <Label
            mt={0}
            className={styles.InpuLabel}
            htmlFor="meter-reading-notes"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.notes')}
          </Label>
          <HookFormTextarea
            control={control}
            {...register('meterReading.notes')}
            id="meter-reading-notes"
            maxLength={4000}
            showLengthIndicator
            minRows={4}
          />
        </Flex>
      </Flex>
    </div>
  );
}
