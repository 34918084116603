import q from 'q';

import { LaneType } from 'common-types';
import { getClient } from '../../../apollo';
import { ShopifyBurstInteractionType } from '../../../types/integrations/ShopifyBurst';
import { clearDraftInteraction } from '../../mutation';
import { cancelReservableInteraction } from './reservableInteractions';

export async function cleanupDraftBurstReservations(
  draftBurstData: Partial<ShopifyBurstInteractionType>
): Promise<void> {
  const reservations = [
    draftBurstData.primaryRoomBooking,
    draftBurstData.dailySchedule,
    ...(draftBurstData.activitiesBookings || []),
  ].filter(uci => uci !== undefined) as Array<LaneType.UserContentInteraction>;

  await q.allSettled(reservations.map(cancelReservableInteraction));
}

export async function cleanupDraftBurst(
  contentId: string,
  draftBurstData: Partial<ShopifyBurstInteractionType>
): Promise<boolean> {
  await cleanupDraftBurstReservations(draftBurstData);
  await getClient().mutate({
    mutation: clearDraftInteraction,
    refetchQueries: ['getDraftInteraction'],
    variables: {
      contentId,
    },
  });

  return true;
}
