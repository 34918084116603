import './components/mjml/initMjml';

import App from './App';
import render from './render';
import './wdyr';
import './ddSpanMonitor';

render(App);

if ((module as any).hot) {
  (module as any).hot.accept(['./App'], () => {
    const NextApp = require('./App').default;

    render(NextApp);
  });
}
