import React from 'react';

import { Button } from 'design-system-web';
import { Flex } from 'lane-web/src/components';
import { Modal } from 'components/lds';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';

import { updateMeterReadingValueMutation } from 'graphql-queries';
import type { MeterReadingValueResponseType } from 'graphql-query-contracts';
import { EditMeterReadingInput } from './EditMeterReadingInput';
import styles from './MeterReadingModal.scss';
import { getMeterReadingUnitLabel } from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingUnitOptions';
import { useMeterReadingList } from '../../details/tabs/meterReadings/meterReadingListContext';

export interface MeterReadingFormProps {
  meterReading: MeterReadingValueResponseType;
  sourceType: string;
  startingMeterReadingValues?: any;
  isOpen: boolean;
  closeModal: (refetch?: boolean) => void;
}

export function EditMeterReadingModal({
  meterReading,
  sourceType,
  isOpen,
  closeModal,
}: MeterReadingFormProps) {
  const { t } = useTranslation();
  const { channel, selectedSetting, refetch } = useMeterReadingList();

  const form = useForm({
    defaultValues: {
      meterReading: {
        meterReadingId: meterReading.id,
        name: selectedSetting.name,
        unit: selectedSetting.unit,
        sourceType,
        value: meterReading.value,
        notes: meterReading.notes,
      },
    },
  });

  const [updateMeterReading] = useMutation(updateMeterReadingValueMutation);

  const onSave = form.handleSubmit(async data => {
    try {
      const { meterReading } = data;

      await updateMeterReading({
        variables: {
          updateMeterReadingValue: {
            id: meterReading.meterReadingId,
            value: Number(meterReading.value),
            notes: meterReading.notes,
            sourceType,
          },
          channelId: channel?._id || '',
        },
      });

      window.Toast.show(
        t(
          'web.admin.serviceRequest.equipment.MeterReadingInput.update.successToast'
        )
      );

      closeModal(true);
      refetch();
    } catch (err) {
      window.Toast.show(
        t('web.admin.serviceRequest.equipment.MeterReadingInput.submit.error')
      );
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closeModal();
      }}
      className={styles.modalContainer}
      title={t(
        'web.admin.serviceRequest.equipment.meterReadingValues.edit.title'
      )}
      actions={
        <Flex className={styles.modalFooter}>
          <Button
            variant="primary"
            onClick={onSave}
            testId="meterReadingModalConfirmation"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.update')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            testId="meterReadingModalCancel"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.cancel')}
          </Button>
        </Flex>
      }
    >
      <Flex gap={5} direction="column">
        <Flex direction="column" gap={4}>
          <FormProvider {...form}>
            <EditMeterReadingInput
              key={meterReading.id}
              settingName={
                selectedSetting.name ||
                t(getMeterReadingUnitLabel(selectedSetting.unit))
              }
              unit={selectedSetting.unit}
            />
          </FormProvider>
        </Flex>
      </Flex>
    </Modal>
  );
}
