import React from 'react';

import { AdminPage, PageHeader } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { CreateChargeForm } from './CreateChargeForm';

import styles from './styles.scss';

export function CreateChargePage() {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();

  const chargesInvoicesLink = routes.channelAdminCharges.replace(':id', id);

  return (
    <AdminPage className={styles.createChargePage}>
      <PageHeader
        header={t('abp.charges.createCharge.heading')}
        headerLevel="h3"
        breadcrumbs={[
          {
            label: t('abp.charges.breadcrumbs.charges'),
            url: chargesInvoicesLink,
          },
          { label: t('abp.charges.breadcrumbs.createCharge') },
        ]}
        externalPadding={[3, 0]}
      />
      <div className={styles.createChargeForm}>
        <CreateChargeForm saveAndCancelRedirectLink={chargesInvoicesLink} />
      </div>
    </AdminPage>
  );
}
