import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  useFlag,
  useChannelProfileQuery,
  useChannelSelectorSections,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { Icon, Button } from 'design-system-web';
import ThemeContext from 'lane-shared/contexts/ThemeContext';
import { getDisplayName } from 'lane-shared/helpers';
import useLocationOrPrimaryLocation from 'lane-shared/hooks/location/useLocationOrPrimaryLocation';
import { DocumentType } from 'lane-shared/types/DocumentType';

import Input from '../form/Input';
import Modal from '../lds/Modal';
import { H4 } from '../typography';
import ChannelSearchCircleListView from './ChannelSearchCircleListView';

import styles from './ChannelSelectorDropdown.scss';

type ChannelSelectorDropdownProps = {
  className?: string;
  style?: React.CSSProperties;
  wrapperClassName?: string;
  channelId?: string | null;
  selectedChannelId?: string | null;
  channels: any[] | undefined;
  showAllLocations: boolean;
  allLocationsSelected?: boolean;
  onChannelSelected?: (channel: DocumentType) => void;
  onAllLocationsSelected?: () => void;
};

export function ChannelSelectorDropdown({
  className,
  style,
  wrapperClassName,
  channelId,
  selectedChannelId,
  channels = [],
  showAllLocations,
  onChannelSelected = () => {},
  onAllLocationsSelected = () => {},
}: ChannelSelectorDropdownProps) {
  const { t } = useTranslation();
  const { location } = useLocationOrPrimaryLocation();
  const theme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const sectionUIMemberUpdate = useFlag(
    FeatureFlag.SectionUIMemberUpdate,
    false
  );

  const { channel } = useChannelProfileQuery({
    channelId: sectionUIMemberUpdate ? selectedChannelId : channelId,
  });

  const { searchSections } = useChannelSelectorSections({
    channels,
    location,
    showAllLocations,
    search,
  });

  function selectAllLocations() {
    if (onAllLocationsSelected) {
      onAllLocationsSelected();
    }

    setIsOpen(false);
  }

  function selectChannel(channel: any) {
    onChannelSelected(channel);
    setIsOpen(false);
  }

  return (
    <span className={cx(styles.wrapper, wrapperClassName)}>
      {sectionUIMemberUpdate ? (
        <Button
          endIcon={<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />}
          startIcon={<Icon name="Building" />}
          variant="secondary"
          size="medium"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedChannelId
            ? getDisplayName(channel)
            : t('web.admin.content.sections.allworkplaces')}
        </Button>
      ) : (
        <div
          className={cx(styles.ChannelSelectorDropdown, className)}
          data-is-selected={!!channelId}
          role="button"
          tabIndex={0}
          style={style}
          onClick={() => setIsOpen(true)}
        >
          <Icon name="map-pin" className={styles.icon} />
          <H4 className={styles.channelName}>
            {getDisplayName(channel) || t('Loading...')}
          </H4>
        </div>
      )}

      {isOpen && (
        <Modal
          size="large"
          title={t('Locations')}
          className={styles.modal}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        >
          <Input
            value={search}
            onChange={search => setSearch(search)}
            icon="search"
            placeholder="Search…"
            type="text"
            showClear
          />

          {searchSections.map(section => (
            <div className={styles.section} key={section.name}>
              {!section.allLocations && (
                <h1 key={section.name}>{section.name}</h1>
              )}
              {section.data.map((channel: any) => {
                if (channel.allLocations) {
                  return (
                    <div
                      key="allLocations"
                      className={styles.allLocations}
                      onClick={selectAllLocations}
                      role="presentation"
                    >
                      <div
                        className={styles.circle}
                        style={{ backgroundColor: theme.primary }}
                      />
                      <h1>{t('All Locations')}</h1>
                    </div>
                  );
                }

                return (
                  <ChannelSearchCircleListView
                    key={channel._id}
                    channel={channel}
                    onClick={selectChannel}
                    className={styles.listItem}
                  />
                );
              })}
            </div>
          ))}
        </Modal>
      )}
    </span>
  );
}
