import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';

import { useAuth0 } from '@auth0/auth0-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { pause } from 'lane-shared/helpers';
import { emitAuthEventForAuth0 } from 'lane-shared/helpers/auth0/emitAuthEventForAuth0';
import { convertTo62, convertToUUID } from 'lane-shared/helpers/convertId';
import { useFlag } from 'lane-shared/hooks';
import { useSetReservableDateRangeUiType } from 'lane-shared/hooks/useSetReservableDateRangeUiType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import i18n, { DefaultLocale } from 'localization';

import MaintenancePage from 'components/maintenance/MaintenancePage';

import RedirectPage from 'pages/rebrand/RebrandRedirectPage';

import { AppRoutes } from './AppRoutes';
import Providers from './Providers';
import { ModalContextProvider } from './contexts/ModalContext';
import './helpers/setAppHeight';
import useSetDocumentTitle from './hooks/useSetDocumentTitle';
import useOAuth2Params from 'hooks/useOAuth2Params';
import setSharedCookiesForWebView from 'helpers/setSharedCookiesForWebView';

import './static/style/style.scss';
import './static/style/tailwind.css';

import { useSetChannelLDAttributes } from 'hooks';
import { LaunchDarklyContextProvider } from './LaunchDarklyContextProvider';

declare global {
  interface Window {
    convertToUUID: (id: string) => string;
    convertTo62: (id: string) => string;
  }
}
window.convertToUUID = convertToUUID;
window.convertTo62 = convertTo62;

library.add(fab, far, fal, fad, fas);

function withProviders(Component: any) {
  return function (props: any) {
    return (
      <LaunchDarklyContextProvider>
        <Providers>
          <Component {...props} />
        </Providers>
      </LaunchDarklyContextProvider>
    );
  };
}

export default withProviders(function App() {
  const { user, isLoggingIn, authWarnings, setAuthWarnings } = useContext(
    UserDataContext
  );

  const { whitelabel } = useContext(AppContext);

  useOAuth2Params();
  useSetChannelLDAttributes();
  useSetReservableDateRangeUiType();
  useSetDocumentTitle({ whitelabel });

  const { t } = useTranslation();
  const maintenanceMode = useFlag(FeatureFlag.MaintenanceMode, false);
  const [, setCookie] = useCookies(['jti', 'token']);

  useEffect(() => {
    i18n.changeLanguage(user?.locale || DefaultLocale);
  }, [user?.locale]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchAuth0Token() {
      try {
        const auth0Token = await getAccessTokenSilently({
          detailedResponse: true,
          timeoutInSeconds: 5,
        });

        await emitAuthEventForAuth0(auth0Token.id_token);
        const { activate_session_id } = jwtDecode<{
          activate_session_id: string;
        }>(auth0Token.id_token);

        await setSharedCookiesForWebView(
          auth0Token.id_token,
          activate_session_id,
          setCookie
        );
      } catch (ex) {
        // TODO: It will throw error here when token not found
      }
    }

    if (isAuthenticated) {
      fetchAuth0Token();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    async function showWarnings() {
      try {
        // Pause to allow user to transition from auth stack to main stack
        await pause(1000);
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        await window.Toast.show(t(authWarnings.join(' ')));
      } catch (err) {
        return;
      }

      setAuthWarnings(null);
    }

    if (authWarnings && !isLoggingIn) {
      showWarnings();
    }
  }, [JSON.stringify(authWarnings), isLoggingIn, setAuthWarnings, t]);

  if (maintenanceMode) {
    return <MaintenancePage />;
  }

  if (window.location.host === 'app.joinlane.com') {
    return <RedirectPage />;
  }

  return (
    <ModalContextProvider>
      <AppRoutes />
    </ModalContextProvider>
  );
});
