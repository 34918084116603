import React from 'react';

import { Document, Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';

import { Equipment, type ClientPmSchedule } from 'graphql-query-contracts';

import { styles } from './styles';

type ScheduleDetailsPDFProps = {
  scheduleData?: ClientPmSchedule;
  createdUserName: string;
  equipmentData: Equipment[];
  recurrenceLabel: string;
  t: (val: string, opts?: any) => string;
};

const line = () => (
  <Svg height="10" width="550" style={styles.line}>
    <Line
      x1="1000"
      y1="0"
      x2="0"
      y2="0"
      strokeWidth={1}
      stroke="rgb(128,128,128)"
    />
  </Svg>
);

const stepTypeText = (type: string, subtype: string) => {
  if (type === 'instruction') {
    return 'Instruction';
  }

  if (type === 'acknowledge') {
    return 'Acknowledge';
  }

  if (type === 'response' && subtype === 'long') {
    return 'Long answer';
  }

  if (type === 'response' && subtype === 'short') {
    return 'Short answer';
  }

  if (type === 'response' && subtype === 'numerical') {
    return 'Numerical answer';
  }

  if (type === 'choice' && subtype === 'multiple') {
    return 'Multiple choice - Multi select';
  }

  if (type === 'choice' && subtype === 'single') {
    return 'Multiple choice - Single select';
  }

  if (type === 'meter') {
    return 'Meter reading';
  }

  return '';
};

export const ScheduleDetailsPDF = ({
  scheduleData,
  createdUserName,
  equipmentData,
  recurrenceLabel,
  t,
}: ScheduleDetailsPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Title & Version */}
        <Text style={styles.scheduleHeader}>{scheduleData?.title}</Text>
        <Text style={styles.subHeader}>
          {t(
            'web.admin.workOrder.preventiveMaintenance.schedule.details.updatedBy',
            {
              date: simpleDate(scheduleData?.updatedAt),
              name: createdUserName,
            }
          )}
        </Text>
        {line()}

        {/* Schedule Details */}
        <>
          <Text style={styles.sectionHeader}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.heading'
            )}
          </Text>
          <View style={styles.dualView}>
            <View>
              <Text style={styles.subHeader}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.schedule.details.repeats'
                )}
              </Text>
              <Text style={styles.infoText}>{recurrenceLabel}</Text>
            </View>
            <View>
              <Text style={styles.subHeader}>
                {t(
                  'web.admin.workOrder.preventiveMaintenance.schedule.details.timeToComplete'
                )}
              </Text>
              <Text style={styles.infoText}>{scheduleData?.timeToComplete}</Text>
            </View>
          </View>
          <View style={styles.dualView}>
            <Text style={styles.subHeader}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.schedule.details.nextDueDate'
              )}
            </Text>
          </View>
          <View style={styles.dualView}>
            <Text style={styles.infoText}>
              {simpleDate(scheduleData?.nextDueDate!)}
            </Text>
          </View>
          <View style={styles.dualView}>
            <Text style={styles.subHeader}>
              {t(
                'web.admin.workOrder.preventiveMaintenance.schedule.details.notes'
              )}
            </Text>
          </View>
          <View style={styles.dualView}>
            <Text style={styles.infoText}>
              {scheduleData?.notes}
            </Text>
          </View>
        </>
        {line()}

        {/* Equipment */}
        <>
          <Text style={styles.sectionHeader}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.heading'
            )}
          </Text>
          {equipmentData?.map(eq => (
            <View style={styles.equipmentSection} key={eq.id}>
              <View style={styles.dualView}>
                <View>
                  <Text style={styles.subHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.category'
                    )}
                  </Text>
                  <Text style={styles.infoText}>{eq.category}</Text>
                  <Text style={styles.subHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.name'
                    )}
                  </Text>
                  <Text style={styles.infoText}>{eq.name}</Text>
                </View>
                <View>
                  <Text style={styles.subHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location'
                    )}
                  </Text>
                  <Text style={styles.infoText}>{eq.location}</Text>
                  <Text style={styles.subHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.floor'
                    )}
                  </Text>
                  <Text style={styles.infoText}>{eq.floor || ' '}</Text>
                  <Text style={styles.subHeader}>
                    {t(
                      'web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.suite'
                    )}
                  </Text>
                  <Text style={styles.infoText}>{eq.suite || ' '}</Text>
                </View>
              </View>
            </View>
          ))}
        </>
        {line()}

        {/* Steps */}
        <>
          <Text style={styles.sectionHeader}>
            {t(
              'web.admin.workOrder.preventiveMaintenance.schedule.details.steps'
            )}
          </Text>
          {scheduleData?.stepTemplate?.map((step, index) => (
            <View style={styles.stepSection} key={index}>
              <Text style={[styles.infoText, styles.stepIndex]}>{index + 1}.</Text>
              <View>
                <Text style={styles.infoText}>
                  {stepTypeText(
                    step?.type!,
                    step?.options?.subtype || ''
                  )}
                </Text>
                <Text style={styles.infoText}>{step?.name}</Text>
                {step?.options?.description && (
                  <Text style={styles.infoText}>
                    {step?.options?.description}
                  </Text>
                )}
                {step?.options?.choices?.map((choice, index) => (
                  <Text key={index} style={styles.infoText}>
                    {choice}
                  </Text>
                ))}
              </View>
            </View>
          ))}
        </>

        {/* Footer */}
        <View style={styles.footer}>
          <Text>
            {new Date().toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            })}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              t(
                'web.admin.workOrder.pdf.pageCounter',
                { pageNumber, totalPages }
              )
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};
