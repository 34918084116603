import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { resendVerificationEmail } from 'helpers';
import { useTranslation } from 'react-i18next';

import { ContentRendererContext, UserDataContext } from 'lane-shared/contexts';
import useHasMetRequirements from 'lane-shared/hooks/features/useHasMetRequirements';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';
import { RequirementsEnum } from 'lane-shared/types/features/RequirementsFeatureProperties';

import { Alert, AlertType } from 'components/lds';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';
import { H5 } from 'components/typography';

import styleMap from '../../styleMap';
import CompanyForm from './CompanyForm';
import FullNameForm from './FullNameForm';
import PhoneForm from './PhoneForm';
import RequirementModal from './RequirementModal';
import RequirementsList from './RequirementsList';

import styles from './RequirementsBlock.scss';

export default function RequirementsBlock({
  className,
  style,
  ...otherProps
}: WebBlockProps) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const { content, editMode, updateBlockContext } = useContext(
    ContentRendererContext
  );
  const props = useEditModeProps(otherProps);

  const [
    currentModalView,
    setCurrentModalView,
  ] = useState<RequirementsEnum | null>();

  const { allRequirementsMet } = useHasMetRequirements({
    content,
    user,
    isEditMode: editMode,
  });

  if (allRequirementsMet) {
    return null;
  }

  const modalContent = {
    [RequirementsEnum.FullName]: (
      <FullNameForm
        onSave={() => {
          setCurrentModalView(null);
          window.Toast.show(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.notifications.fullName'
            )
          );
        }}
      />
    ),
    [RequirementsEnum.CompanyName]: (
      <CompanyForm
        onSave={() => {
          setCurrentModalView(null);
          window.Toast.show(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.notifications.companyName'
            )
          );
        }}
      />
    ),
    [RequirementsEnum.PhoneNumber]: (
      <PhoneForm
        onSave={() => {
          setCurrentModalView(null);
          window.Toast.show(
            t(
              'web.admin.channel.content.layout.editor.components.requirementsBlock.notifications.phoneNumber'
            )
          );
        }}
      />
    ),
    // These requirements don't have dedicated modal views.
    [RequirementsEnum.VerifiedEmail]: null,
    [RequirementsEnum.ContentInteraction]: null,
    // Profile picture flow uses the file upload dialog.
    [RequirementsEnum.ProfilePicture]: null,
  };

  const handleSendVerificationEmail = (): void => {
    const primaryLogin = user?.logins.find(
      login => login.isPrimary && login.type === UserLoginTypeEnum.Email
    );

    if (primaryLogin?.key) {
      resendVerificationEmail(primaryLogin.key);
      updateBlockContext({ isVerificationEmailSent: true });
    }
  };

  return (
    <div
      className={cx(className, styles.RequirementsBlock)}
      style={styleMap(false, style)}
      {...props}
    >
      <H5 mb={3}>
        {t(
          'web.admin.channel.content.layout.editor.components.requirementsBlock.header'
        )}
      </H5>

      <Alert type={AlertType.info} className={styles.alert} fullWidth>
        {t(
          'web.admin.channel.content.layout.editor.components.requirementsBlock.alert'
        )}
      </Alert>

      <RequirementsList
        content={content}
        editMode={editMode}
        onClickRequirement={(requirement: RequirementsEnum) => {
          if (requirement === RequirementsEnum.VerifiedEmail) {
            handleSendVerificationEmail();

            return;
          }

          setCurrentModalView(requirement);
        }}
      />

      <RequirementModal
        className={styles.modal}
        visible={Boolean(currentModalView)}
        onClose={() => setCurrentModalView(null)}
      >
        {currentModalView && modalContent[currentModalView]
          ? modalContent[currentModalView]
          : null}
      </RequirementModal>
    </div>
  );
}
