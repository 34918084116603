import React, { useEffect, useContext } from 'react';

import {
  Button as LegacyButton,
  ControlMenu,
  ErrorMessage,
  CreatedBy,
  Loading,
  Flex,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getClient } from 'lane-shared/apollo';
import { deleteSection } from 'lane-shared/graphql/mutation';
import { getSection } from 'lane-shared/graphql/query';
import { castForUpdate, pause } from 'lane-shared/helpers';
import { undoDynamicTranslation } from 'lane-shared/helpers/dynamicLanguages';

import ChannelAdminContext from '../../ChannelAdminContext';

import { SectionForm } from '../components/SectionForm';
import { SectionFormLegacy } from '../components/SectionFormLegacy';
import { SectionEditLegacy } from '../components/SectionEditLegacy';
import { BreadCrumbs } from 'components/lds';
import { routes } from 'lane-shared/config';
import { H3 } from 'components/typography';
import { Button, TextButton } from 'design-system-web';
import { useUpdateSection } from 'lane-shared/hooks/useUpdateSection';

import styles from './styles.scss';

export default function ChannelSectionEdit() {
  const { t } = useTranslation();
  const history = useHistory();
  const { sectionId } = useParams<{ sectionId: string }>();
  const { channel, refetchChannel } = useContext(ChannelAdminContext);
  const { data, loading, refetch } = useQuery(getSection, {
    client: getClient(),
    skip: !sectionId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: sectionId,
    },
  });

  const newSectionUIIsEnabled = useFlag(FeatureFlag.SectionUI, false);
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  useEffect(() => {
    if (data?.section && channel?.settings) {
      const untranslatedSection = undoDynamicTranslation({
        model: data.section,
        columns: ['name'],
        channelSettings: channel!.settings,
      });

      setSection(castForUpdate(untranslatedSection));
    }
  }, [data?.section, channel?.settings]);

  const {
    onSectionUpdated,
    error,
    section,
    updating,
    validation,
    saveSection,
    setSection,
    setError,
    setUpdating,
  } = useUpdateSection(data?.section);

  async function onUndo() {
    await pause();
    await refetch();
  }

  async function removeSection() {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.edit.removeSection.title', {
          sectionName: (section as any)?.name,
        }),
        message: t('web.admin.content.sections.edit.removeSection.message', {
          sectionName: (section as any)?.name,
        }),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    try {
      setError(null);
      setUpdating(true);
      await pause();
      await getClient().mutate({
        mutation: deleteSection,
        variables: { id: (section as any)?._id },
      });

      refetchChannel();

      history.push(sectionIndexRoute);
      // tell the menu to update.
    } catch (err) {
      setError(err);
    }

    setUpdating(false);
  }

  const cancelAction = async () => {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.new.cancelTitle'),
        message: `${t('web.admin.content.sections.new.cancelMesage')}`,
      });
    } catch (err) {
      // user cancelled
      return;
    }

    history.push(sectionShowRoute);
  };

  const sectionIndexRoute = routes.channelAdminSections.replace(
    ':id',
    channel?.slug!
  );

  const sectionShowRoute = routes.channelAdminSectionsView
    .replace(':id', channel?.slug!)
    .replace(':sectionId', sectionId);

  const breadCrumbs = [
    {
      label: t('web.admin.content.sections.breadcrumbs.index'),
      url: sectionIndexRoute,
    },
    {
      label: (section as any)?.name,
      url: sectionShowRoute,
    },
    {
      label: t('web.admin.content.sections.breadcrumbs.edit'),
    },
  ];

  if (!channel || !section) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  if (newSectionUIIsEnabled) {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <BreadCrumbs links={breadCrumbs} />
        </div>
        <H3>{t('web.admin.content.sections.edit.title')}</H3>
        <SectionForm
          validation={validation}
          channel={channel}
          section={section}
          onSectionUpdated={onSectionUpdated}
        />
        <Flex direction="row" align="center" m={[5, 0, 0, 0]}>
          <Button
            onClick={async () => {
              await saveSection();
              history.push(sectionShowRoute);
            }}
            size="large"
            variant="primary"
            testId="sectionCreateButton"
          >
            {t('Update')}
          </Button>
          <TextButton onClick={cancelAction}>{t('Cancel')}</TextButton>
        </Flex>
      </div>
    );
  }

  if (!newSectionUIIsEnabled && areContentTagsEnabled) {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <BreadCrumbs links={breadCrumbs} />
        </div>
        <H3>{t('web.admin.content.sections.edit.title')}</H3>
        <SectionFormLegacy
          validation={validation}
          channel={channel}
          section={section}
          onSectionUpdated={onSectionUpdated}
        />
        <Flex direction="row" align="center" m={[5, 0, 0, 0]}>
          <Button
            onClick={async () => {
              await saveSection();
              history.push(sectionShowRoute);
            }}
            size="large"
            variant="primary"
            testId="sectionCreateButton"
          >
            {t('Update')}
          </Button>
          <TextButton onClick={cancelAction}>{t('Cancel')}</TextButton>
        </Flex>
      </div>
    );
  }

  return (
    <div className={styles.ChannelSectionEdit}>
      <ControlMenu mb={0}>
        <hr />
        <LegacyButton
          disabled={!section}
          loading={loading || updating}
          onClick={onUndo}
        >
          {t`web.admin.content.sections.edit.undo`}
        </LegacyButton>

        <LegacyButton loading={loading || updating} onClick={removeSection}>
          {t`web.admin.content.sections.edit.delete`}
        </LegacyButton>

        <LegacyButton
          disabled={!section}
          loading={loading || updating}
          onClick={saveSection}
          variant="contained"
        >
          {t`web.admin.content.sections.edit.save`}
        </LegacyButton>
      </ControlMenu>

      <ErrorMessage error={validation} />
      <ErrorMessage error={error} />

      {section && !areContentTagsEnabled && (
        <SectionEditLegacy
          validation={validation}
          channel={channel}
          section={section}
          onSectionUpdated={onSectionUpdated}
        />
      )}

      <CreatedBy className={styles.createdBy} object={data?.section} forAdmin />
    </div>
  );
}
