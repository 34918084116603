import React, { useState, useCallback } from 'react';

import { Icon, PopupMenu, Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { useAssignableMembers } from 'lane-shared/domains/workOrder/hooks/useAssignableMembers';
import {
  updateServiceRequestStatusByIdMutation,
  getServiceRequestByIdQuery,
  updateServiceRequestMutation,
} from 'graphql-queries';
import { getWorkOrderInteraction } from 'lane-shared/graphql/workOrders';
import {
  getDisplayName,
  hasPermission,
  safeConvertToUUID,
} from 'lane-shared/helpers';
import {
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM,
} from 'lane-shared/helpers/constants/permissions';
import { convertTo62, convertToUUID } from 'lane-shared/helpers/convertId';
import { simpleDate } from 'lane-shared/helpers/formatters';
import {
  useChannelServiceRequestFeaturesContext,
  useFlag,
} from 'lane-shared/hooks';
import { ServiceRequestStatusEnum } from 'lane-web/src/domains/workOrder/serviceRequest/components/types/serviceRequestStatus';
import { Flex, TabStrip, Loading } from 'lane-web/src/components';
import { TabItem } from 'lane-web/src/components/general/TabStrip';
import { BreadCrumbs, Modal } from 'lane-web/src/components/lds';

import { H3 } from 'components/typography';

import AssignedMemberDropdown from './components/AssignedMemberDropdown';
import PDFExportDetailsModal from './components/PDFExportDetailsModal';
import { StaffTeamsMultiSelectDropdown } from './components/StaffTeamsMultiSelectDropdown';
import ServiceRequestStatusDropdown from './components/StatusDropdown';

import styles from './styles.scss';
import { DetailsTab } from './tabs/details/Details';
import { BillingTab } from './tabs/billing/Billing';
import { EquipmentTab } from './tabs/equipment/Equipment';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { UserType } from 'lane-shared/types/User';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { DisplayMetadata } from 'service-contracts/gen/vts/activate/space_admin/work-order/v2/service-request';
import { CustomQuestion } from 'graphql-query-contracts';
import { ActivityTab } from './tabs/activity/Activity';
import { CommentsTab } from './tabs/comments/Comments';

enum ServiceRequestDetailsTabsEnum {
  Details = 'details',
  Billing = 'billing',
  Equipment = 'equipment',
  Activity = 'activity',
  Comments = 'comments',
}

type Props = {
  channel: any;
  user: UserType;
};

export function ServiceRequestDetails({ channel, user }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const { serviceRequestId } = useParams<{ serviceRequestId: string }>();
  const [loading, setLoading] = useState(false);
  const [cancelSRModalOpen, setCancelSRModalOpen] = useState(false);
  const [PDFExportModalOpen, setPDFExportModalOpen] = useState(false);

  const hasWorkOrderInvoicingEnabled = useFlag(
    FeatureFlag.WorkOrdersServiceRequestInvoicing,
    false
  );

  const isBillingProductsAndServicesEnabled = useFlag(
    FeatureFlag.ProductsAndServices,
    false
  );

  const isBillingChargesEnabled = useFlag(FeatureFlag.ChargesInvoice, false);

  const isAuditLogsEnabled = useFlag(FeatureFlag.WorkOrdersAuditLogs, false);

  const isCommentsEnabled = useFlag(FeatureFlag.WorkOrderComments, false);

  const isEnabledCesLinksForServiceRequests = useFlag(
    FeatureFlag.EnableCesLinksForServiceRequests,
    false
  );

  const hasCanBeAssignedPermission = hasPermission(
    user?.roles,
    [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM],
    channel?._id
  );

  const hasAssignPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNEE],
      channel?._id
    );

  const hasAssignTeamPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_ASSIGNTEAM],
      channel?._id
    );

  const userCanEditServiceRequest =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_DATA],
      channel?._id,
      false
    );

  const closeModal = () => {
    setCancelSRModalOpen(false);
  };

  const { data: serviceRequestResponse, refetch } = useQuery(
    getServiceRequestByIdQuery,
    {
      variables: { serviceRequestId },
    }
  );
  const serviceRequestData = serviceRequestResponse?.serviceRequest;
  const { data: interactionData, refetch: refetchInteraction } = useQuery(
    getWorkOrderInteraction,
    {
      variables: {
        id: serviceRequestData?.extRefId
          ? convertToUUID(serviceRequestData?.extRefId)
          : null,
      },
      skip: !serviceRequestData?.extRefId,
      fetchPolicy: 'network-only',
    }
  );

  const interactionServiceRequestPDFData = {
    status: serviceRequestData?.status,
    phone: interactionData?.workOrderInteraction?.user?.profile?.phone || '',
    customData: serviceRequestData?.customQuestions,
    submittedBy: serviceRequestData?.createdBy?.name,
    assignedMember: getDisplayName(serviceRequestData?.assignee),
    company: serviceRequestData?.company?.name,
  };

  const onServiceRequestUpdated = useCallback(
    async (fields: any) => {
      try {
        if (isEnabledCesLinksForServiceRequests) {
          fields.suiteIds =
            serviceRequestData?.suiteIds?.map(
              (suite: { sourceEntityId: string }) => suite?.sourceEntityId
            ) || [];
        } else {
          fields = {
            floor: serviceRequestData.floor,
            suite: serviceRequestData.suite,
            suiteIds:
              serviceRequestData?.suiteIds?.map(
                (suite: { sourceEntityId: string }) => suite?.sourceEntityId
              ) || [],
            ...fields,
          };
        }

        await getClient().mutate({
          mutation: updateServiceRequestMutation,
          variables: {
            updateServiceRequest: {
              serviceRequestId: convertToUUID(
                serviceRequestData.serviceRequestId
              ),
              description: serviceRequestData.description,
              category: serviceRequestData.category,
              issue: serviceRequestData.issue,
              location: serviceRequestData.location,
              company: serviceRequestData.company?.id,
              assignee: serviceRequestData?.assignee?.id,
              assigneeGroups: serviceRequestData?.assigneeGroups,
              isBillable: serviceRequestData?.isBillable,
              notes: serviceRequestData.notes,
              hours: serviceRequestData.hours,
              equipment: serviceRequestData.equipment,
              status: serviceRequestData.status,
              customQuestions: serviceRequestData.customQuestions.map(
                (customQuestion: CustomQuestion) => ({
                  Question: customQuestion.Question,
                  Answer: customQuestion.Answer,
                })
              ),
              ...fields,
            },
          },
        });
        refetch();
        refetchInteraction();
        await window.Toast.show(t('web.admin.serviceRequest.toast.success'));
      } catch (err: any) {
        throw new Error(err);
      }
    },
    [
      refetch,
      refetchInteraction,
      serviceRequestData?.assignee,
      serviceRequestData?.category,
      serviceRequestData?.company,
      serviceRequestData?.description,
      serviceRequestData?.floor,
      serviceRequestData?.issue,
      serviceRequestData?.location,
      serviceRequestData?.serviceRequestId,
      serviceRequestData?.suite,
      serviceRequestData?.assigneeGroups,
      serviceRequestData?.isBillable,
      serviceRequestData?.notes,
      serviceRequestData?.hours,
    ]
  );

  const updateServiceRequestStatus = async () => {
    closeModal();
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: updateServiceRequestStatusByIdMutation,
        variables: {
          id: safeConvertToUUID(serviceRequestData?.serviceRequestId),
          status: ServiceRequestStatusEnum.Cancelled,
          skip: !serviceRequestData?.serviceRequestId,
        },
      });
      refetch();
      refetchInteraction();
      await window.Toast.show(
        t('web.admin.serviceRequest.status.update.success')
      );
    } catch (err) {
      await window.Alert.alert({
        title: t('web.admin.serviceRequest.cancelling.failure.message'),
        error: err,
      });
    }

    setLoading(false);
  };

  async function unassignServiceRequest() {
    try {
      await window.Alert.confirm({
        title: t('web.staff.serviceRequest.unassign.modal.title'),
        message: t('web.staff.serviceRequest.unassign.modal.message'),
        confirmText: t`web.staff.serviceRequest.unassign.modal.button.text`,
      });
    } catch (err) {
      return;
    }

    try {
      onServiceRequestUpdated({ assignee: '' });
    } catch (err) {
      // do nothing
    }
  }

  const [assignableUsers, _, assignableTeams] = useAssignableMembers(
    channel?._id,
    serviceRequestData?.groupRolesIds?.length
      ? serviceRequestData?.groupRolesIds?.map((e: string | null | undefined) =>
          convertToUUID(e)
        )
      : undefined
  );

  let assigneedMember: any = assignableUsers?.find(
    (user: any) =>
      safeConvertToUUID(user.value) === serviceRequestData?.assignee?.id
  );
  const staffUser = {
    label: getDisplayName(user),
    value: user?._id ? convertTo62(user?._id) : user?._id,
    profile: user?.profile,
  };

  const unAssignOption = {
    label: t`web.admin.workOrder.shared.unassign`,
    value: 'unassign',
    profile: {
      name: t`web.admin.workOrder.shared.unassign`,
    },
  };

  const assignMyselfOption = {
    label: t`web.admin.workOrder.preventiveMaintenance.task.details.assignMyself`,
    value: user?._id ? convertTo62(user?._id) : '',
    profile: {
      name: t`web.admin.workOrder.preventiveMaintenance.task.details.assignMyself`,
    },
  };

  const currentlyAssigned =
    serviceRequestData?.assignee && serviceRequestData?.assignee?.id
      ? assignableUsers.filter(
          assignee =>
            assignee.value === convertTo62(serviceRequestData.assignee.id)
        )
      : [];

  const assigneeOptions = [
    ...(!hasAssignPermission && serviceRequestData?.assignee
      ? currentlyAssigned
      : []),
    ...(serviceRequestData?.assignee ? [unAssignOption] : []),
    ...(hasCanBeAssignedPermission &&
    (!serviceRequestData?.assignee?.id ||
      convertTo62(serviceRequestData?.assignee?.id) !== user?._id)
      ? [assignMyselfOption]
      : []),
    ...(hasAssignPermission ? assignableUsers : []),
  ];

  if (serviceRequestData?.assignee?.id === safeConvertToUUID(user?._id)) {
    assigneedMember = staffUser;
  } else if (serviceRequestData?.assignee === null) {
    assigneedMember = null;
  }

  const onTeamSelectionChange = (newTeams: any) => {
    if (!assignableTeams.length) {
      return;
    }

    if (newTeams) {
      const selectedOptions = assignableTeams.filter(item =>
        newTeams.find((option: any) => item.value === option.value)
      );
      const assigneeGroups = selectedOptions.length
        ? selectedOptions.map((user: { value: string | null | undefined }) =>
            convertToUUID(user.value)
          )
        : [];

      onServiceRequestUpdated({
        assigneeGroups,
      });
    } else {
      onServiceRequestUpdated({
        assigneeGroups: [],
      });
    }
  };

  const tabs: TabItem[] = [
    {
      label: t('web.admin.serviceRequest.tabs.details'),
      value: ServiceRequestDetailsTabsEnum.Details,
    },
    ...((channel?.settings?.hasWorkOrderEquipmentEnabled &&
      serviceRequestFeatures.updateServiceRequest) ||
    (serviceRequestData?.equipment?.length > 0 &&
      serviceRequestFeatures.updateServiceRequest)
      ? [
          {
            label: t('web.admin.serviceRequest.tabs.equipment'),
            value: ServiceRequestDetailsTabsEnum.Equipment,
          },
        ]
      : []),
  ];

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  if (
    serviceRequestFeatures.updateServiceRequest &&
    channel?.settings?.hasBillingPaymentsEnabled &&
    hasWorkOrderInvoicingEnabled &&
    isBillingProductsAndServicesEnabled &&
    isBillingChargesEnabled &&
    isPropertyChannel
  ) {
    tabs.splice(1, 0, {
      label: t('web.admin.serviceRequest.tabs.billing'),
      value: ServiceRequestDetailsTabsEnum.Billing,
    });
  }

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]);

  const actionArray = [
    {
      label: t('web.admin.serviceRequest.export'),
      onClick: () => {
        setPDFExportModalOpen(true);
      },
    },
  ];

  if (isAuditLogsEnabled) {
    tabs.push({
      label: t('web.admin.serviceRequest.tabs.activity'),
      value: ServiceRequestDetailsTabsEnum.Activity,
    });
  }

  if (isCommentsEnabled) {
    tabs.push({
      label: t('web.admin.serviceRequest.tabs.comments'),
      value: ServiceRequestDetailsTabsEnum.Comments,
    });
  }

  if (
    serviceRequestFeatures.updateServiceRequest &&
    userCanEditServiceRequest
  ) {
    actionArray.unshift({
      label: t('web.admin.serviceRequest.edit'),
      onClick: () => {
        history.push(
          routes.channelAdminWorkOrdersServiceRequestEdit
            .replace(':id', channel?.slug)
            .replace(':serviceRequestId', serviceRequestData?.serviceRequestId)
        );
      },
    });
  }

  const getAssignedTeams = () => {
    if (serviceRequestData?.assigneeGroups?.length && assignableTeams?.length) {
      return assignableTeams.filter(team =>
        serviceRequestData.assigneeGroups.find(
          (item: string) => safeConvertToUUID(item) === team.value
        )
      );
    }

    return [];
  };

  const handleAssignMember = (assigned: { value: string }) => {
    if (
      assigned !== undefined &&
      assigned?.value !== '' &&
      assigned?.value !== 'unassign'
    ) {
      onServiceRequestUpdated({
        assignee: convertToUUID(assigned?.value),
      });
    } else if (assigned?.value === 'unassign') {
      unassignServiceRequest();
    }
  };

  let intProvider = '';
  let intFriendlyId = '';

  if (serviceRequestData?.displayMetadata) {
    const integratedMetadata: DisplayMetadata = Array.isArray(
      serviceRequestData.displayMetadata
    )
      ? serviceRequestData.displayMetadata[0]
      : null;
    const { provider, friendlyId } = integratedMetadata || {};

    intProvider = provider || '';
    intFriendlyId = friendlyId || '';
  }

  if (!serviceRequestData || loading) return <Loading fullscreen />;

  return (
    <div className={styles.ServiceRequestDetailsPage}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.title'),
            url: routes.channelAdminWorkOrdersServiceRequests.replace(
              ':id',
              channel?.slug
            ),
          },
          {
            label: `${serviceRequestData?.userFriendlyID}`,
          },
        ]}
      />
      <Flex
        className={styles.serviceRequestDetailsHeader}
        justify="space-between"
        direction="column"
      >
        <div>
          <Flex align="center" justify="space-between">
            <H3>{serviceRequestData?.userFriendlyID}</H3>
            <PDFExportDetailsModal
              channel={channel}
              PDFExportModalOpen={PDFExportModalOpen}
              setPDFExportModalOpen={setPDFExportModalOpen}
              serviceRequestData={{
                ...serviceRequestData,
                ...interactionServiceRequestPDFData,
              }}
            />
            <Flex>
              <PopupMenu
                trigger={
                  <Button
                    variant="secondary"
                    endIcon={<Icon name="chevron-down" />}
                    testId="actionsButton"
                  >
                    {t('web.admin.serviceRequest.equipment.action.title')}
                  </Button>
                }
                items={actionArray.map(option => ({
                  label: option.label,
                  onSelect: option.onClick,
                }))}
              />
            </Flex>

            {cancelSRModalOpen && (
              <Modal
                isOpen={cancelSRModalOpen}
                onClose={closeModal}
                size="small"
                title={t('web.admin.serviceRequest.cancel.modal.header')}
                testId="cancelServiceRequestModal"
              >
                <p style={{ margin: '0 0 27px 38px' }}>
                  {t('web.admin.serviceRequest.cancel.modal.action.message')}
                </p>
                <Button
                  variant="secondary"
                  size="large"
                  testId="cancelWOButton"
                  onClick={updateServiceRequestStatus}
                >
                  {t('web.admin.serviceRequest.cancel.modal.button.text')}
                </Button>
              </Modal>
            )}
          </Flex>
          {intProvider && intFriendlyId && (
            <div className={styles.displayMetadata}>
              <span>
                {`${intProvider} ${t('shared.common.id')}`}: {intFriendlyId}
              </span>
            </div>
          )}
          <span>
            {t('web.admin.serviceRequest.createdOn') +
              (intProvider ? ' Activate' : '')}
            : {simpleDate(serviceRequestData.createdAt)}
          </span>
        </div>
        <Flex m={4} gap={5} className={styles.FieldContainer}>
          <ServiceRequestStatusDropdown
            serviceRequestData={serviceRequestData}
            refetch={refetch}
            pillSize="md"
            disableEdit={
              !serviceRequestFeatures.updateServiceRequest ||
              !(
                user?.isSuperUser ||
                hasPermission(
                  user?.roles,
                  [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS],
                  channel?._id,
                  false
                )
              )
            }
          />
          {serviceRequestFeatures.updateServiceRequest && (
            <div className={styles.teamsMultiSelect}>
              <StaffTeamsMultiSelectDropdown
                label={t`web.admin.serviceRequest.team.assignment.text`}
                items={assignableTeams.map((value: any) => ({
                  ...value,
                  isDisabled:
                    !hasAssignTeamPermission ||
                    serviceRequestData?.status ===
                      ServiceRequestStatusEnum.Closed,
                }))}
                isSearchable
                value={getAssignedTeams()}
                onChange={onTeamSelectionChange}
                placeholder={t`web.admin.serviceRequest.select.teams`}
                disabled={!hasAssignTeamPermission}
              />
            </div>
          )}
          {serviceRequestFeatures.updateServiceRequest && (
            <AssignedMemberDropdown
              disabled={
                serviceRequestData?.status ===
                  ServiceRequestStatusEnum.Closed ||
                !(hasAssignPermission || hasCanBeAssignedPermission)
              }
              users={assigneeOptions}
              setAssignedMember={handleAssignMember}
              assignedMember={assigneedMember}
              placeholder={t`web.admin.serviceRequest.select.assignee`}
            />
          )}
        </Flex>
      </Flex>
      <Flex direction="column">
        <TabStrip
          tabs={tabs}
          selected={selectedTab}
          onSelectTab={setSelectedTab}
          className={styles.ServiceRequestDetailsTabStrip}
          fullWidth
          skipLabelTranslation
        />
        <div className={styles.ServiceRequestDetailsTabStripUnderline} />
      </Flex>
      {selectedTab.value === ServiceRequestDetailsTabsEnum.Details && (
        <DetailsTab
          channel={channel}
          user={user}
          serviceRequestData={serviceRequestData}
          serviceRequestFeatures={serviceRequestFeatures}
          onServiceRequestUpdated={onServiceRequestUpdated}
        />
      )}
      {selectedTab.value === ServiceRequestDetailsTabsEnum.Billing && (
        <BillingTab
          testId="tabBilling"
          channel={channel}
          user={user}
          serviceRequestData={serviceRequestData}
          serviceRequestFeatures={serviceRequestFeatures}
          onSubmitCharge={() =>
            onServiceRequestUpdated({
              isBillable: true,
            })
          }
        />
      )}
      {selectedTab.value === ServiceRequestDetailsTabsEnum.Equipment && (
        <EquipmentTab
          testId="tabEquipment"
          channel={channel}
          user={user}
          serviceRequestData={serviceRequestData}
          serviceRequestFeatures={serviceRequestFeatures}
          onServiceRequestUpdated={onServiceRequestUpdated}
          isEquipmentEnabled={channel?.settings?.hasWorkOrderEquipmentEnabled}
        />
      )}
      {selectedTab.value === ServiceRequestDetailsTabsEnum.Activity && (
        <ActivityTab testId="tabActivity" serviceRequestId={serviceRequestId} />
      )}
      {selectedTab.value === ServiceRequestDetailsTabsEnum.Comments && (
        <CommentsTab testId="tabComments" serviceRequestId={serviceRequestId} />
      )}
    </div>
  );
}
