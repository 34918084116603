import { useEffect, useContext } from 'react';

import { uniq, union } from 'lodash';

import config from 'lane-shared/config';
import { AppContext, UserDataContext } from 'lane-shared/contexts';
import { DEFAULT_WHITELABEL_ID } from 'lane-shared/helpers/constants';
import { convertTo62 } from 'lane-shared/helpers/convertId';

import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';

declare global {
  interface Window {
    pendo: any;
    zE: (w: string, i: string) => void;
  }
}

export default function Pendo() {
  const { user } = useContext(UserDataContext);
  const { channel } = useContext(ChannelAdminContext);
  const { whitelabel } = useContext(AppContext);

  function initializePendo() {
    if (
      typeof window !== 'undefined' &&
      window?.pendo?.initialize &&
      user &&
      channel &&
      whitelabel
    ) {
      const base62Id = convertTo62(user._id);
      const base62WhitelabelId = convertTo62(whitelabel._id);
      const isDefaultWhitelabel =
        base62WhitelabelId === convertTo62(DEFAULT_WHITELABEL_ID);

      const groupRoles = user.roles
        ?.filter(({ groupRole }) =>
          groupRole.channel?.hierarchy?.hierarchyDown?.includes(channel._id)
        )
        .map(({ groupRole }) => groupRole);
      const roles = uniq(groupRoles.map(({ name }) => name));
      const permissions = union(
        ...groupRoles.map(({ permissions }) => permissions)
      );

      const visitor = {
        id: base62Id,
        email: user.profile?.email,
        full_name: user.name,
        locale: user.locale,
        roles,
        permissions,
        app: 'Activate',
        channel_name: channel.name,
        channel_id: channel._id,
        created_date: user._created,
      };

      window.pendo.initialize({
        visitor,
        account: {
          id: isDefaultWhitelabel ? null : base62WhitelabelId,
          name: isDefaultWhitelabel ? null : whitelabel.name,
        },
      });

      // If visitor already exists in Pendo, we will push an update for prop changes
      window.pendo.updateOptions({ visitor });
    } else {
      setTimeout(initializePendo, 1000);
    }
  }

  function hideZendeskWidget() {
    if (typeof window !== 'undefined' && window?.zE) {
      window.zE('webWidget', 'hide');
    } else {
      setTimeout(hideZendeskWidget, 1000);
    }
  }

  useEffect(() => {
    initializePendo();
  }, [user, channel, whitelabel]);

  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://content.p.activate.vts.com/agent/static/${config.pendo.apiKey}/pendo.js`;
    document.body.appendChild(script);

    // Installing Zendesk after Pendo ensures only Pendo Widget will appear
    const scriptZendesk = document.createElement('script');

    scriptZendesk.src =
      'https://static.zdassets.com/ekr/snippet.js?key=3589faac-2d4a-4039-9328-d58ad6ba0a27';
    scriptZendesk.id = 'ze-snippet';
    scriptZendesk.async = true;
    scriptZendesk.defer = true;
    // Delay for Zendesk so it does not appear while Pendo is still loading
    setTimeout(function () {
      document.body.appendChild(scriptZendesk);
    }, 1000);

    // If Pendo widget is hidden through exclude rules, Zendesk appears
    hideZendeskWidget();
  }, [window]);

  return null;
}
