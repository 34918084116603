import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MultiselectField } from 'components';
import { Button } from 'design-system-web';
import { Flex } from 'components/layout';
import styles from './CredentialTemplateSelector.scss';
import { Template } from './types';

type SelectorProps = {
  id: string;
  dropdownItems: any;
  multiSelectItems: any;
  dropdownCallback?: (id: string, value: string) => void;
  multiSelectCallback?: (id: string, value: Template[]) => void;
  dropdownValue?: string;
  dropdownLabel?: string;
  removable?: boolean;
  removeCallback?: (id: string) => void;
  disableDropdown?: boolean;
  disableMultiSelect?: boolean;
  multiSelectValues: Template[];
  multiSelectLabel?: string;
  disableRemoveButton?: boolean;
};

export function Selector({
  disableDropdown,
  disableMultiSelect,
  dropdownCallback,
  dropdownItems,
  dropdownLabel,
  dropdownValue,
  id,
  multiSelectCallback,
  multiSelectItems,
  multiSelectLabel,
  multiSelectValues,
  removable,
  removeCallback,
  disableRemoveButton,
}: SelectorProps) {
  const { t } = useTranslation();

  function handleRemove() {
    removeCallback?.(id);
  }

  function onDropdownChange(value: string) {
    dropdownCallback?.(id, value);
  }

  function onMultiSelectChange(value: any[]) {
    multiSelectCallback?.(id, value);
  }

  return (
    <Flex p={[6, 4]} gap={4} direction="column" className={styles.container}>
      <Dropdown
        disabled={disableDropdown}
        doTranslation={false}
        placeholder="Select a channel"
        fixedLabel
        isRequired
        items={dropdownItems}
        label={dropdownLabel}
        onValueChange={value => onDropdownChange(value)}
        value={dropdownValue}
        isFullWidth
      />
      <MultiselectField
        disabled={disableMultiSelect}
        doTranslation={false}
        fixedLabel
        isRequired
        items={multiSelectItems}
        label={multiSelectLabel}
        onChange={value => onMultiSelectChange(value)}
        value={multiSelectValues}
        isFullWidth
      />
      {removable && (
        <Button
          variant="text"
          size="large"
          onClick={() => handleRemove()}
          disabled={disableRemoveButton}
          style={{ color: '#c7200a', width: 'fit-content' }}
        >
          {t('Remove')}
        </Button>
      )}
    </Flex>
  );
}
