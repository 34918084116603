import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import { Flex, Label, Dropdown, IconButton, Button, Box } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW } from 'lane-shared/helpers/constants/permissions';
import { UserType } from 'lane-shared/types/User';

import { getEquipmentForExport } from 'graphql-queries';
import { Equipment } from 'graphql-query-contracts';

import { H4, H5, P } from 'components/typography';

import styles from './index.scss';

type Props = {
  channel: any;
  entityData: { equipment: string[] };
  onEntityUpdated: (fields: any) => void;
  allowEditing?: boolean;
  isEquipmentEnabled?: boolean;
  user?: UserType;
};
export function EntityEquipments({
  channel,
  entityData,
  onEntityUpdated,
  allowEditing = true,
  isEquipmentEnabled = true,
  user,
}: Props) {
  const { t } = useTranslation();
  const [selectedEquipments, setSelectedEquipments] = useState<
    { category?: string; equipment?: Equipment }[]
  >([]);
  const [equipmentMap, setEquipmentMap] = useState<Map<string, Equipment[]>>(
    new Map<string, Equipment[]>()
  );
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [showErrors, setShowErrors] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (!channel?._id) return;

    getEquipments();
  }, [channel?._id]);

  const hasEquipmentAccess =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_EQUIPMENT_VIEW],
      channel?._id,
      false
    );

  const getEquipments = async () => {
    const { data } = await getClient().query({
      query: getEquipmentForExport,
      variables: {
        channelId: channel?._id,
        includeArchived: true,
      },
      fetchPolicy: 'network-only',
    });
    const equipment: Equipment[] = data?.getEquipmentForExport?.equipment || [];

    setEquipments(equipment);
    const eqMap = new Map<string, Equipment[]>();

    equipment.forEach(eq => {
      if (eqMap.has(eq.category)) {
        const arr = eqMap.get(eq.category);

        arr!.push(eq);
      } else if (!eq.isArchived) {
        eqMap.set(eq.category, [eq]);
      }
    });

    setEquipmentMap(eqMap);
  };

  useEffect(() => {
    if (equipments?.length === 0) return;

    resetSelectedEquipment();
  }, [equipments.length, JSON.stringify(entityData.equipment)]);

  const resetSelectedEquipment = () => {
    const pmEqs = entityData.equipment
      .map((eqId: string) => {
        const eq = equipments.find(eq => eq.id === eqId);

        return eq ? { category: eq.category, equipment: eq } : undefined;
      })
      .filter((eq: any) => eq !== undefined) as {
      category: string;
      equipment: Equipment;
    }[];

    setSelectedEquipments(pmEqs);
  };

  const handleEquipmentCategoryChange = (category: any, index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = category.value;
    newSelectedEquipments[index]!.equipment = undefined;
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleEquipmentChange = (equipment: any, index: number) => {
    const selectedEq = selectedEquipments[index];
    const category = selectedEq?.category;

    if (!category) return;

    const equipmentOptions = equipmentMap.get(category);
    const equipmentData = equipmentOptions!.find(
      equipmentOption => equipmentOption.id === equipment.value
    );

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments[index]!.category = equipmentData?.category;
    newSelectedEquipments[index]!.equipment = equipmentData;
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleAddEquipment = () => {
    if (selectedEquipments[selectedEquipments.length - 1]?.equipment === null)
      return;

    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.push({ category: undefined, equipment: undefined });
    setSelectedEquipments(newSelectedEquipments);
  };

  const handleRemoveEquipment = (index: number) => {
    const newSelectedEquipments = [...selectedEquipments];

    newSelectedEquipments.splice(index, 1);
    setSelectedEquipments(newSelectedEquipments);
  };

  const onSave = () => {
    const error = selectedEquipments.some(
      selectedEquipment =>
        !selectedEquipment.category || !selectedEquipment.equipment
    );

    if (error) {
      setShowErrors(true);

      return;
    }

    setEditMode(false);
    onEntityUpdated({
      equipment: selectedEquipments.map(eq => eq.equipment?.id),
    });
    resetSelectedEquipment();
  };

  const onCancel = () => {
    resetSelectedEquipment();
    setEditMode(false);
  };

  return (
    <>
      <Flex align="center">
        <H4> {t`web.admin.equipment.heading`}</H4>
        {!editMode && allowEditing && isEquipmentEnabled && (
          <Button
            startIcon={<Icon name="pencil" />}
            size="small"
            className={styles.EditButton}
            onClick={() => setEditMode(true)}
          >
            {t`web.admin.equipment.edit`}
          </Button>
        )}
      </Flex>
      {editMode ? (
        <>
          <Flex
            direction="column"
            gap={2}
            className={styles.EntityEquipmentList}
          >
            {selectedEquipments.length > 0 && (
              <Flex className={styles.EquipmentLabels}>
                <Label>{t`web.admin.equipment.category.selectLabel`}</Label>
                <Label>{t`web.admin.equipment.equipment.selectLabel`}</Label>
              </Flex>
            )}
            {selectedEquipments.map((selectedEquipment, index) => (
              <Flex key={index} gap={3} className={styles.EquipmentLabels}>
                <div className={styles.EquipmentDropdown}>
                  <Dropdown
                    id="select-equipment-category"
                    testId="select-equipment-category"
                    value={selectedEquipment.category}
                    isFullWidth
                    items={
                      Array.from(equipmentMap.keys()).map(category => ({
                        label: category,
                        value: category,
                      })) || []
                    }
                    onChange={value =>
                      handleEquipmentCategoryChange(value, index)
                    }
                    invalid={showErrors && !selectedEquipment.category}
                  />
                </div>
                <div className={styles.EquipmentDropdown}>
                  <Dropdown
                    id="select-equipment"
                    testId="select-equipment"
                    value={selectedEquipment.equipment?.id}
                    isFullWidth
                    disabled={!selectedEquipment.category}
                    items={
                      selectedEquipment.category &&
                      equipmentMap.has(selectedEquipment.category)
                        ? equipmentMap
                            .get(selectedEquipment.category)!
                            .filter(
                              ({ id }) =>
                                !selectedEquipments.some(
                                  ({ equipment }) =>
                                    equipment?.id === id &&
                                    equipment?.id !==
                                      selectedEquipment.equipment?.id &&
                                    !equipment.isArchived
                                )
                            )
                            .map(equipment => ({
                              label: equipment.name,
                              value: equipment.id,
                            }))
                        : []
                    }
                    onChange={value => handleEquipmentChange(value, index)}
                    invalid={showErrors && !selectedEquipment.equipment}
                  />
                </div>
                <IconButton
                  icon="times"
                  testId="remove-option"
                  size="small"
                  onClick={() => handleRemoveEquipment(index)}
                  className={styles.optionDeleteButton}
                />
              </Flex>
            ))}
            <Button
              variant="text-icon"
              startIcon={<Icon name="plus" />}
              onClick={handleAddEquipment}
              fullWidth={false}
              size="small"
              testId="add-option"
              interfaceStyle="light"
              className={styles.addEquipmentButton}
            >
              {t`web.admin.equipment.add`}
            </Button>
          </Flex>
          <Flex gap={3}>
            <Button variant="activate-contained" size="medium" onClick={onSave}>
              {t`web.admin.equipment.save`}
            </Button>
            <Button size="medium" onClick={onCancel}>
              {t`web.admin.equipment.cancel`}
            </Button>
          </Flex>
        </>
      ) : (
        entityData?.equipment?.length > 0 && (
          <Flex direction="column" m={[2, 0, 0, 0]}>
            <Box className={styles.EntityEquipmentGrid}>
              <H5>{t`web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.category`}</H5>
              <H5>{t`web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.equipment`}</H5>
              <H5>{t`web.admin.workOrder.preventiveMaintenance.schedule.details.equipment.location`}</H5>
            </Box>
            {selectedEquipments.map(
              ({ equipment }, index) =>
                equipment && (
                  <Box key={index} className={styles.EntityEquipmentGrid}>
                    <P>{equipment.category}</P>
                    {hasEquipmentAccess && isEquipmentEnabled ? (
                      <Link
                        to={routes.channelAdminWorkOrdersEquipmentDetails
                          .replace(':id', channel?.slug)
                          .replace(':equipmentId', equipment.id)}
                      >
                        {equipment.name}
                      </Link>
                    ) : (
                      <P>{equipment.name}</P>
                    )}
                    <P>{equipment.location}</P>
                  </Box>
                )
            )}
          </Flex>
        )
      )}
    </>
  );
}
