import { EntityTypeEnum } from '../../types/attachment';

export enum ImporterModules {
  WORK_ORDERS = 'workOrders',
}

export enum ImporterEntityTypes {
  Equipment = 'EQUIPMENT',
  ServiceRequest = 'SERVICEREQUEST',
  Schedule = 'SCHEDULE',
  Task = 'TASK',
  EquipmentMapping = 'EQUIPMENTMAPPING',
  AssigneeGroupMapping = 'ASSIGNEEGROUPMAPPING',
}

export const ImporterEntities = {
  [ImporterEntityTypes.ServiceRequest]: {
    type: ImporterEntityTypes.ServiceRequest,
    friendlyName: 'web.admin.importer.workorders.serviceRequest.friendlyName',
    description: 'web.admin.importer.workorders.serviceRequest.description',
    required: false,
  },
  [ImporterEntityTypes.Equipment]: {
    type: ImporterEntityTypes.Equipment,
    friendlyName: 'web.admin.importer.workorders.equipment.friendlyName',
    description: 'web.admin.importer.workorders.equipment.description',
    required: false,
  },
  [ImporterEntityTypes.Schedule]: {
    type: ImporterEntityTypes.Schedule,
    friendlyName: 'web.admin.importer.workorders.schedule.friendlyName',
    description: 'web.admin.importer.workorders.schedule.description',
    required: false,
  },
  [ImporterEntityTypes.Task]: {
    type: ImporterEntityTypes.Task,
    friendlyName: 'web.admin.importer.workorders.task.friendlyName',
    description: 'web.admin.importer.workorders.task.description',
    required: false,
  },
  [ImporterEntityTypes.EquipmentMapping]: {
    type: ImporterEntityTypes.EquipmentMapping,
    friendlyName: 'web.admin.importer.workorders.equipmentMapping.friendlyName',
    description: 'web.admin.importer.workorders.equipmentMapping.description',
    required: false,
  },
  [ImporterEntityTypes.AssigneeGroupMapping]: {
    type: ImporterEntityTypes.AssigneeGroupMapping,
    friendlyName:
      'web.admin.importer.workorders.assigneeGroupMapping.friendlyName',
    description:
      'web.admin.importer.workorders.assigneeGroupMapping.description',
    required: false,
  },
};

export function ImporterEntityTypesGetFriendlyName(
  entityType: ImporterEntityTypes
) {
  for (const key in ImporterEntities) {
    if (
      Object.prototype.hasOwnProperty.call(ImporterEntities, key) &&
      ImporterEntities[key as keyof typeof ImporterEntities].type === entityType
    ) {
      return ImporterEntities[key as keyof typeof ImporterEntities]
        .friendlyName;
    }
  }

  return '';
}

export const ImportJobDefinitions = [
  {
    module: ImporterModules.WORK_ORDERS,
    attachmentType: EntityTypeEnum.ImporterAttachment,
    description: 'web.admin.importer.workorders.description',
    friendlyName: 'web.admin.importer.workorders.friendlyName',
    entitySections: [
      {
        sectionName: 'web.admin.importer.workorders.sectionName',
        entities: [
          ImporterEntities[ImporterEntityTypes.ServiceRequest],
          ImporterEntities[ImporterEntityTypes.Equipment],
          ImporterEntities[ImporterEntityTypes.Schedule],
          ImporterEntities[ImporterEntityTypes.Task],
        ],
      },
      {
        sectionName: 'web.admin.importer.workorders.mappingSectionName',
        entities: [
          ImporterEntities[ImporterEntityTypes.EquipmentMapping],
          ImporterEntities[ImporterEntityTypes.AssigneeGroupMapping],
        ],
      },
    ],
  },
];

export const getImporterEntityByType = (entityType: ImporterEntityTypes) => {
  return ImporterEntities[entityType];
};
