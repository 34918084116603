import React from 'react';

import { Input, RadioGroup } from 'components';
import { useTranslation } from 'react-i18next';

import {
  TaxDetails,
  TaxAmountType,
  TaxPolicy,
} from 'lane-shared/domains/billingPayments/types';
import {
  TAX_VALUE_CONVERSION,
  TAX_TYPE_OPTIONS,
  DEFAULT_TAX_DETAILS,
} from 'lane-shared/domains/billingPayments/constants';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';

import CardContainer from 'components/cards/CardContainer';
import CurrencyInput from 'components/form/CurrencyInput';
import Dropdown from 'components/form/Dropdown';
import { H5, M } from 'components/typography';

import styles from '../styles.scss';
import { TaxAndBusinessNumbers } from './TaxAndBusinessNumbers';

interface TaxInformationProps {
  taxDetails: TaxDetails | null;
  updateTaxDetails: (tax: TaxDetails) => void;
}

const taxPolicyItems = [
  {
    id: TaxPolicy.EXCLUSIVE,
    text: 'abp.billingAndPaymentSettings.tax.exclusivePricing',
    subtext: 'abp.billingAndPaymentSettings.tax.exclusiveSubtext',
  },
  {
    id: TaxPolicy.INCLUSIVE,
    text: 'abp.billingAndPaymentSettings.tax.inclusivePricing',
    subtext: 'abp.billingAndPaymentSettings.tax.inclusiveSubtext',
  },
];

const taxPolicySchema = {
  id: 'id',
  text: 'text',
  subtext: 'subtext',
};

export function TaxInformation({
  taxDetails,
  updateTaxDetails,
}: TaxInformationProps) {
  const { t } = useTranslation();

  const isEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();

  const updateTaxDetailDefaults = () => ({
    ...DEFAULT_TAX_DETAILS,
    ...taxDetails,
  });

  function handleBusinessNumberChange(value: { name: string; number: string }) {
    updateTaxDetails({
      ...updateTaxDetailDefaults(),
      businessTaxName: value.name,
      businessTaxNumber: value.number,
    });
  }

  function handleTaxNumberChange(value: { name: string; number: string }) {
    updateTaxDetails({
      ...updateTaxDetailDefaults(),
      taxDisplayName: value.name,
      taxNumber: value.number,
    });
  }

  return (
    <CardContainer className={styles.PaymentSettings}>
      <H5 className={styles.SectionHeader}>
        {t('abp.billingAndPaymentSettings.tax.header')}
      </H5>

      <div className={styles.SectionContainer}>
        <M mt={2} className={styles.FieldLabel}>
          <label>{t('abp.billingAndPaymentSettings.tax.displayName')}</label>
        </M>
        <div className={styles.TaxInformation}>
          <Input
            type="text"
            value={taxDetails?.taxName || DEFAULT_TAX_DETAILS.taxName}
            onChange={value =>
              updateTaxDetails({
                ...updateTaxDetailDefaults(),
                taxName: value,
              })
            }
            testId="taxNameInput"
          />
        </div>
      </div>

      <div className={styles.SectionContainer}>
        <M mt={2} className={styles.FieldLabel}>
          <label>{t('abp.billingAndPaymentSettings.tax.defaultAmount')}</label>
        </M>
        <div className={styles.TaxTypeValueContainer}>
          <Dropdown
            testId="taxTypeDropdown"
            className={styles.TaxTypeDropdown}
            items={TAX_TYPE_OPTIONS}
            value={taxDetails?.taxAmountType}
            onChange={option =>
              updateTaxDetails({
                ...updateTaxDetailDefaults(),
                taxAmountType: option.value,
              })
            }
          />
          {taxDetails?.taxAmountType ===
            TaxAmountType.TaxAmountTypePercentage && (
            <Input
              type="number"
              icon="Percent"
              iconRight
              value={
                (taxDetails?.taxAmountValue ||
                  DEFAULT_TAX_DETAILS.taxAmountValue) / TAX_VALUE_CONVERSION
              }
              onChange={value =>
                updateTaxDetails({
                  ...updateTaxDetailDefaults(),
                  taxAmountValue: Number(value) * TAX_VALUE_CONVERSION,
                })
              }
              testId="taxAmountValueInput"
              className={styles.TaxValue}
            />
          )}
          {taxDetails?.taxAmountType === TaxAmountType.TaxAmountTypeFixed && (
            <CurrencyInput
              value={taxDetails?.taxAmountValue}
              min={0}
              onValueChange={value =>
                updateTaxDetails({
                  ...updateTaxDetailDefaults(),
                  taxAmountValue: value,
                })
              }
              testId="taxAmountValueInput"
              className={styles.TaxValue}
            />
          )}
        </div>
      </div>

      <div className={styles.SectionContainer}>
        <M mt={2} className={styles.FieldLabel}>
          <label>{t('abp.billingAndPaymentSettings.tax.pricingPolicy')}</label>
        </M>
        <div className={styles.TaxInclusionRadioGroup}>
          <RadioGroup
            doTranslate
            schema={taxPolicySchema}
            items={taxPolicyItems}
            selected={taxDetails?.taxPolicy || DEFAULT_TAX_DETAILS.taxPolicy}
            onChange={value =>
              updateTaxDetails({
                ...updateTaxDetailDefaults(),
                taxPolicy: value as TaxPolicy,
              })
            }
          />
        </div>
      </div>

      {isEuComplianceEnabled ? (
        <div className={styles.SectionContainer}>
          <TaxAndBusinessNumbers
            businessInfo={{
              name:
                taxDetails?.businessTaxName ||
                DEFAULT_TAX_DETAILS.businessTaxName,
              number:
                taxDetails?.businessTaxNumber ||
                DEFAULT_TAX_DETAILS.businessTaxNumber,
            }}
            taxInfo={{
              name:
                taxDetails?.taxDisplayName ||
                DEFAULT_TAX_DETAILS.taxDisplayName,
              number: taxDetails?.taxNumber || DEFAULT_TAX_DETAILS.taxNumber,
            }}
            onBusinessNumberChange={handleBusinessNumberChange}
            onTaxNumberChange={handleTaxNumberChange}
          />
        </div>
      ) : (
        <>
          <div className={styles.SectionContainer}>
            <M mt={2} className={styles.FieldLabel}>
              <label>
                {t('abp.billingAndPaymentSettings.tax.businessDisplayName')}
              </label>
            </M>
            <div className={styles.TaxInformation}>
              <Input
                type="text"
                value={
                  taxDetails?.businessTaxName ||
                  DEFAULT_TAX_DETAILS.businessTaxName
                }
                onChange={value =>
                  updateTaxDetails({
                    ...updateTaxDetailDefaults(),
                    businessTaxName: value,
                  })
                }
                testId="businessTaxNameInput"
              />
            </div>
          </div>

          <div className={styles.SectionContainer}>
            <M mt={2} className={styles.FieldLabel}>
              <label>
                {t('abp.billingAndPaymentSettings.tax.businessNumber')}
              </label>
            </M>
            <div className={styles.TaxInformation}>
              <Input
                type="text"
                value={
                  taxDetails?.businessTaxNumber ||
                  DEFAULT_TAX_DETAILS.businessTaxNumber
                }
                onChange={value =>
                  updateTaxDetails({
                    ...updateTaxDetailDefaults(),
                    businessTaxNumber: value,
                  })
                }
                testId="businessTaxNumberInput"
              />
            </div>
          </div>
        </>
      )}
    </CardContainer>
  );
}
