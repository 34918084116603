import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';

import PropertyComponent from 'components/builder/properties/input/Property';
import { H4 } from 'components/typography';

import { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { CheckPermissionsButton } from './CheckPermissionsButton/CheckPermissionsButton';

import styles from './YardiIntegrationEditor.scss';
import {
  IntegrationInputs,
  IntegrationType,
  PaymentFields,
  RequiredFieldsForPaymentPermissions,
} from 'lane-shared/helpers/constants/integrations/Yardi';

type RenderInputProps = {
  channel: ChannelIntegrationEditorProps['channel'];
  channelIntegration: ChannelIntegrationEditorProps['channelIntegration'];
  properties: PropertiesInterface;
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  property: PropertyType;
  onUpdateChannelIntegration: ChannelIntegrationEditorProps['onUpdateChannelIntegration'];
};

function isCheckPermissionDisabled(
  properties: PropertiesInterface,
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings']
): boolean {
  return RequiredFieldsForPaymentPermissions().some(
    property => !(property in properties) || !settings?.[property]
  );
}

const isFinancialsEnabled = (
  properties: PropertiesInterface,
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'],
  key: string
): boolean => {
  if (!properties[key]) {
    return false;
  }

  const { financialEnabled } = settings || {};
  const fields = PaymentFields();

  if (financialEnabled) {
    fields.forEach(field => {
      properties[field].validators?.push({
        name: 'Required',
        value: true,
      });
    });

    return true;
  }

  fields.forEach(field => {
    if (properties?.[field]?.validators?.length) {
      properties[field].validators = [];
    }
  });

  return false;
};

export const YardiCommercial = ({
  channelIntegration,
  onUpdateChannelIntegration,
  definition,
  channel,
}: ChannelIntegrationEditorProps) => {
  const { t } = useTranslation();
  const settings = channelIntegration?.settings;
  const properties = definition?.properties;

  useEffect(() => {
    isCheckPermissionDisabled(properties, settings);
  });

  const isSyncPastUsersEnabled = (
    properties: PropertiesInterface,
    settings: ChannelIntegrationEditorProps['channelIntegration']['settings'],
    key: string
  ): boolean => {
    if (!properties[key]) {
      return false;
    }

    const { syncPastUsersEnabled } = settings || {};

    if (syncPastUsersEnabled) {
      properties.daysPostMoveOut.validators?.push(
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        }
      );

      if (Number.isNaN(settings.daysPostMoveOut)) {
        settings.daysPostMoveOut = undefined;
      }

      return true;
    }

    if (properties?.daysPostMoveOut?.validators?.length) {
      properties.daysPostMoveOut.validators = [];
    }

    return false;
  };

  const isSyncFutureUsersEnabled = (
    properties: PropertiesInterface,
    settings: any,
    key: string
  ): boolean => {
    if (!properties[key]) {
      return false;
    }

    const { syncFutureUsersEnabled } = settings || {};

    if (syncFutureUsersEnabled) {
      properties.daysPriorMoveIn.validators?.push(
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        }
      );

      if (Number.isNaN(settings.daysPriorMoveIn)) {
        settings.daysPriorMoveIn = undefined;
      }

      return true;
    }

    if (properties?.daysPriorMoveIn?.validators?.length) {
      properties.daysPriorMoveIn.validators = [];
    }

    return false;
  };

  return (
    <>
      <H4 mb={4}>
        {t('web-admin-integrations-yardi-commercial-settings-header')}
      </H4>
      <div>
        {Object.entries(properties).map(([key, property]) => {
          if (
            [
              'financialUsername',
              'financialPassword',
              'checkPermissionsButton',
            ].includes(key) &&
            !isFinancialsEnabled(properties, settings, key)
          ) {
            return null;
          }

          if (
            ['daysPostMoveOut'].includes(key) &&
            !isSyncPastUsersEnabled(properties, settings, key)
          ) {
            return null;
          }

          if (
            ['daysPriorMoveIn'].includes(key) &&
            !isSyncFutureUsersEnabled(properties, settings, key)
          ) {
            return null;
          }

          return (
            <div key={key}>
              <div className={styles.property}>
                <IntegrationsComponent
                  type={key as IntegrationInputs}
                  props={{
                    channel,
                    channelIntegration,
                    properties,
                    settings,
                    property,
                    onUpdateChannelIntegration,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const IntegrationsComponent = ({
  type,
  props,
}: {
  type: IntegrationInputs;
  props: RenderInputProps;
}) => {
  switch (type) {
    case IntegrationInputs.CheckPermissionButton:
      return (
        <CheckPermissionsButton
          channel={props.channel}
          provider={IntegrationType.Commercial}
          settings={props.settings}
          disabled={isCheckPermissionDisabled(props.properties, props.settings)}
          requiredPropertiesForPermissions={RequiredFieldsForPaymentPermissions()}
          isFinancialsEnabled={isFinancialsEnabled(
            props.properties,
            props.settings,
            type
          )}
        />
      );
    default:
      return (
        <PropertyComponent
          object={props.channelIntegration.settings}
          channel={props.channel}
          label={props.property.friendlyName || props.property.name || type}
          property={props.property}
          propertyKey={type}
          value={props.channelIntegration.settings?.[type]}
          onChange={(value: any) =>
            props.onUpdateChannelIntegration({
              settings: {
                ...props.channelIntegration.settings,
                [type]: value,
              },
            })
          }
          onPropertyChange={() => null}
        />
      );
  }
};
