import React from 'react';

import { useTranslation } from 'react-i18next';

import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  ChargePartial,
  currencyToPaymentCurrency,
  Invoice,
  TaxPolicy,
} from 'lane-shared/domains/billingPayments/types';
import {
  getPricing,
  PricingParams,
} from 'lane-shared/domains/productCatalog/helpers/getPricing';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { M, H4 } from 'components/typography';

import useUserLocale from 'hooks/useUserLocale';

import styles from './styles.scss';

export function InvoiceTotal({
  invoice,
  charges,
}: {
  invoice: Invoice | undefined;
  charges: ChargePartial[];
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const currencyCode = currencyToPaymentCurrency(invoice?.currency);
  const formatCurrency = currencyFormatter({
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });
  const { taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: invoice?.groupId,
  });
  const taxTotalLabel = t('abp.invoice.details.totalPriceIncludingTax', {
    taxName: taxDetails?.taxName || t('abp.invoice.details.tax').toLowerCase(),
  });

  let invoiceSubtotal = 0;
  let invoiceTax = 0;
  let invoiceTotal = 0;
  const taxInclusive = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;

  charges.forEach(charge => {
    // skip charge if voided
    if (charge.status === 'VOIDED') return;

    // no items
    if (charge.items.length === 0) {
      invoiceSubtotal += charge.amount;
      invoiceTotal += charge.amount;

      return;
    }

    charge.items.forEach(item => {
      const params: PricingParams = {
        currency: currencyCode,
        locale,
        priceComponents: item.product,
      };

      const { grossTax, listPrice, netPrice } = getPricing(params);

      invoiceSubtotal += listPrice * item.quantity;
      invoiceTax += grossTax * item.quantity;
      invoiceTotal += netPrice * item.quantity;
    });
  });

  if (taxInclusive) {
    invoiceSubtotal += invoiceTax;
  }

  return (
    <div className={styles.invoiceTotalContainer}>
      <div className={styles.invoiceTotalGrid}>
        <M className={styles.item}>{t('abp.invoice.details.subtotal')}</M>
        <M className={styles.item}>{formatCurrency(invoiceSubtotal)}</M>
        <M className={styles.item}>
          {taxDetails?.taxName || t('abp.invoice.details.tax')}
          {taxInclusive ? ` ${t('abp.invoice.details.tax.included')}` : ''}
        </M>
        <M className={styles.item}>{formatCurrency(invoiceTax)}</M>
        <H4 className={styles.item}>{taxTotalLabel}</H4>
        <div className={styles.invoiceTotal}>
          <H4 className={styles.item}>
            {`${currencyCode} ${formatCurrency(invoiceTotal)}`}
          </H4>
        </div>
      </div>
    </div>
  );
}
