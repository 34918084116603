import React, { useCallback, useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { friendlyMinutes } from 'lane-shared/helpers/formatters';
import { useReservableAvailableWindow } from 'lane-shared/hooks/features/reservable/useReservableAvailableWindow';
import { useReservableDateRange } from 'lane-shared/hooks/features/reservable/useReservableDateRange';
import { ReservableFeatureProperties } from 'lane-shared/types/features/ReservableFeatureProperties';
import { TimeAvailabilityFeatureProperties } from 'lane-shared/types/features/TimeAvailabilityFeatureProperties';

import DateRangeInput, { DateRange } from 'components/form/DateRangeInput';
import { DateRangeInputSkeletonLoader } from 'components/form/DateRangeInput/DateRangeInputSkeletonLoader';

import { ReservableFeatureProps } from '../ReservableInput';

import styles from './ReservableDateRange.scss';
import classNames from 'classnames';
import { Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

const TRANSLATION_KEYS = {
  maxDuration: 'content.feature.reservable.dateRange.maxDuration',
};

interface Props {
  contentId: LaneType.UUID;
  intervalMinutes: number;
  onInteractionChange: ReservableFeatureProps['onInput'];
  userGroupRoleIds: string[];
  maxDuration: number;
  reservableFeature: ReservableFeatureProperties;
  timeZone?: string;
  timeAvailabilityFeature?: TimeAvailabilityFeatureProperties;
  bufferTimeConfig: LaneType.BufferTime | undefined;
}

export function ReservableDateRange({
  contentId,
  intervalMinutes,
  onInteractionChange,
  timeAvailabilityFeature,
  bufferTimeConfig,
  reservableFeature,
  userGroupRoleIds,
  timeZone = 'UTC',
  maxDuration,
}: Props) {
  const { t } = useTranslation();

  const [referenceDate, setReferenceDate] = useState(() => {
    return DateTime.now().setZone(timeZone).startOf('day').toJSDate();
  });

  const handleDateChange = (date: Date) => {
    setReferenceDate(date);
  };

  const onChangeDateRange = useCallback(
    (dateRange: DateRange) => {
      if (!dateRange) {
        onInteractionChange({ startDate: undefined, endDate: undefined });

        return;
      }

      onInteractionChange({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    },
    [onInteractionChange]
  );

  const reservableRange = useReservableAvailableWindow({
    reservableFeature,
    userGroupRoleIds,
    timeZone,
  });

  const {
    isLoading,
    onTimeChange,
    displayableTimes,
    dateRange,
    unavailableDates,
    unavailableWeekdays,
  } = useReservableDateRange(
    contentId,
    referenceDate,
    intervalMinutes,
    userGroupRoleIds,
    timeAvailabilityFeature,
    bufferTimeConfig,
    timeZone,
    onChangeDateRange,
    maxDuration,
    reservableRange
  );

  const maxDurationLabel = `${t(
    TRANSLATION_KEYS.maxDuration
  )} ${friendlyMinutes(maxDuration, 'long')}`;

  const showEmptyComponent =
    !dateRange || displayableTimes.startTimes.every(s => s.disabled);

  return (
    <div>
      <div className={styles.maxDuration}>{maxDurationLabel}</div>
      {isLoading ? (
        <DateRangeInputSkeletonLoader />
      ) : (
        <DateRangeInput
          referenceDate={referenceDate}
          onDateChange={handleDateChange}
          onTimeChange={onTimeChange}
          startTimes={displayableTimes.startTimes}
          endTimes={displayableTimes.endTimes}
          dateRange={dateRange}
          timeZone={timeZone}
          reservableRange={reservableRange}
          disabledWeekDays={unavailableWeekdays}
          unavailableDateRanges={unavailableDates}
          emptyComponent={
            showEmptyComponent && (
              <div className={classNames(styles.noAvailabilityWrapper)}>
                <Icon
                  name="info-circle"
                  className={styles.infoIcon}
                  set={ICON_SET_FONTAWESOME}
                  type="far"
                />
                <p className={styles.noAvailable}>
                  {t('shared.content.feature.reservable.timeSlots.noAvailability')}
                </p>
              </div>
            )
          }
        />
      )}
    </div>
  );
}
