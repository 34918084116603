import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
 
import { routes } from 'lane-shared/config';

export const useAutoSetupNavigation = () => {
  const history = useHistory();

  const goToRecentJobsPage = useCallback(
    (keyword?: string) => {
      const location = {
        pathname: routes.portalManagementRecentJobs,
        ...(keyword && { search: `?keyword=${keyword}` }),
      };

      history.push(location);
    },
    [history]
  );

  const goToPropertiesPage = useCallback(
    (channelSlug: string) => {
      history.push(routes.channelAdminProperties.replace(':id', channelSlug));
    },
    [history]
  );

  return { goToRecentJobsPage, goToPropertiesPage };
};
