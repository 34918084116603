import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, Flex, Input, TextArea } from 'components';
import { useTranslation } from 'react-i18next';
import { ClientTask } from 'graphql-query-contracts';
import { H4, P } from 'components/typography';

import styles from './EntityNotesAndHours.scss';
import {
  useChannelServiceRequestFeaturesContext,
  useFlag,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

export function EntityNotesAndHours({
  entityData,
  onEntityUpdated,
  hideHours,
}: {
  entityData: ClientTask;
  onEntityUpdated: (fields: any) => void;
  hideHours?: boolean;
}) {
  const { t } = useTranslation();
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const [editNotes, setEditNotes] = useState(false);
  const [notes, setNotes] = useState('');
  const [hours, setHours] = useState<string | number | undefined | null>('');
  const [minutes, setMinutes] = useState<string | number | undefined | null>(
    ''
  );
  const [editHours, setEditHours] = useState(false);

  useEffect(() => {
    if (entityData?.notes && entityData?.notes.length > 0) {
      setNotes(entityData.notes);
    }

    setHours(entityData.hours);
    setMinutes(entityData.minutes);
  }, [entityData]);

  const parseValue = (value: string) => {
    const parsedValue = parseInt(value, 10);

    return !isEmptyValue(parsedValue) ? String(parsedValue) : '';
  };

  const isEmptyValue = (value: string | number | undefined | null) =>
    value === undefined ||
    value === null ||
    value === '' ||
    Number.isNaN(value);

  const isCommentsEnabled = useFlag(FeatureFlag.WorkOrderComments, false);

  return (
    <div className={styles.main}>
      {!hideHours && serviceRequestFeatures.updateServiceRequest && (
        <div className={styles.hoursContainer}>
          <Flex>
            <H4>{t('web.admin.workOrders.notesAndHours.taskTime')}</H4>
            {!editHours && (
              <Button
                startIcon={<Icon name="pencil" />}
                size="small"
                className={styles.EditButton}
                onClick={() => setEditHours(true)}
              >
                {t`web.admin.equipment.edit`}
              </Button>
            )}
          </Flex>
          {(!isEmptyValue(hours) || !isEmptyValue(minutes)) && !editHours && (
            <Flex gap={1} m={[5, 0, 0, 0]}>
              {!isEmptyValue(hours) && (
                <P>
                  {t(
                    'web.admin.workOrders.notesAndHours.taskTime.hours-count',
                    {
                      count: Number(hours),
                    }
                  )}
                </P>
              )}
              {!isEmptyValue(minutes) &&
                !(minutes === 0 && !isEmptyValue(hours)) && (
                  <P>
                    {t(
                      'web.admin.workOrders.notesAndHours.taskTime.minutes-count',
                      {
                        count: Number(minutes),
                      }
                    )}
                  </P>
                )}
            </Flex>
          )}
          {editHours && (
            <Flex
              direction="column"
              className={styles.hoursInputContainer}
              gap={4}
              m={[5, 0, 0, 0]}
            >
              <Input
                type="number"
                onChange={value => setHours(parseValue(value))}
                value={hours}
                rightText={t(
                  'web.admin.workOrders.notesAndHours.taskTime.field.hours.unit'
                )}
                label={t(
                  'web.admin.workOrders.notesAndHours.taskTime.field.hours'
                )}
                fixedLabel
                input={{ min: 0 }}
                testId="hoursField"
              />
              <Input
                type="number"
                onChange={value => setMinutes(parseValue(value))}
                value={minutes}
                rightText={t(
                  'web.admin.workOrders.notesAndHours.taskTime.field.minutes.unit'
                )}
                label={t(
                  'web.admin.workOrders.notesAndHours.taskTime.field.minutes'
                )}
                fixedLabel
                input={{ min: 0, max: 59, step: 1 }}
                testId="minutesField"
              />
              <Flex gap={3}>
                <Button
                  variant="activate-contained"
                  testId="saveHours"
                  onClick={() => {
                    onEntityUpdated({
                      hours: Number(hours),
                      minutes: Number(minutes),
                    });
                    setEditHours(false);
                  }}
                  disabled={isEmptyValue(hours) && isEmptyValue(minutes)}
                >
                  {t`web.admin.workOrders.notesAndHours.taskTime.button.save`}
                </Button>
                <Button
                  testId="cancelHours"
                  onClick={() => {
                    setEditHours(false);
                    setHours(
                      entityData?.hours !== null ? entityData?.hours : ''
                    );
                    setMinutes(
                      entityData?.minutes !== null ? entityData?.minutes : ''
                    );
                  }}
                >{t`web.admin.workOrders.notesAndHours.taskTime.button.cancel`}</Button>
              </Flex>
            </Flex>
          )}
        </div>
      )}
      {!isCommentsEnabled && (
        <div className={styles.notesContainer}>
          <Flex>
            <H4>{t`web.admin.serviceRequest.notes`}</H4>
            {serviceRequestFeatures.updateServiceRequest && !editNotes && (
              <Button
                startIcon={<Icon name="pencil" />}
                size="small"
                className={styles.EditButton}
                onClick={() => setEditNotes(true)}
                testId="editNotesButton"
              >
                {t`web.admin.equipment.edit`}
              </Button>
            )}
          </Flex>
          {notes.length > 0 && !editNotes && (
            <Flex m={[5, 0, 0, 0]}>
              <P className={styles.multiLineText}>{notes}</P>
            </Flex>
          )}
          {editNotes && (
            <Flex direction="column" gap={4} m={[5, 0, 0, 0]}>
              <TextArea
                value={notes}
                onChange={value => setNotes(value)}
                showLengthIndicator={false}
                minRows={5}
                maxLength={3_000}
                className={styles.notesTextArea}
                placeholder={t(
                  'web.admin.workOrders.notesAndHours.notes.textArea.placeholder'
                )}
                testId="notesField"
              />
              <Flex gap={3} className={styles.actionButtons}>
                <Button
                  variant="activate-contained"
                  testId="saveNotes"
                  onClick={() => {
                    onEntityUpdated({ notes });
                    setEditNotes(false);
                  }}
                >
                  {t`Save`}
                </Button>
                <Button
                  testId="cancelNotes"
                  onClick={() => {
                    setEditNotes(false);
                    setNotes(
                      entityData?.notes?.length ? entityData?.notes : ''
                    );
                  }}
                >{t`Cancel`}</Button>
              </Flex>
            </Flex>
          )}
        </div>
      )}
    </div>
  );
}
