import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { ErrorMessage } from 'lane-web/src/components/general';
import { AdminPage } from 'lane-web/src/components/layout';

import { PageHeader } from 'components/layout';

import { CurrencySetting } from './CurrencySetting';
import { Invoice } from './Invoice';
import { TaxInformation } from './TaxInformation';
import { Refund } from './Refund';

import styles from './styles.scss';

export function PaymentSettings({
  channel,
}: {
  channel: Pick<ChannelType, '_id'> | undefined;
}) {
  const { t } = useTranslation();
  const {
    paymentSettingsError,
    currency,
    updateCurrency,
    taxDetails,
    updateTaxDetails,
    invoiceDueInDays,
    updateInvoiceDueInDays,
    settingsUnchanged,
    updatePaymentSettings,
    invoiceDisplayName,
    updateInvoiceDisplayName,
    isInvoicingDisabled,
    updateIsInvoicingDisabled,
    failureRefund,
    cancellationRefund,
    updateFailureRefund,
    updateCancellationRefund,
  } = useBillingPaymentSettingsForChannel({ channelId: channel?._id });

  const disableSave =
    !!taxDetails?.businessTaxName !== !!taxDetails?.businessTaxNumber ||
    !!taxDetails?.taxDisplayName !== !!taxDetails?.taxNumber;

  const enableRefund = useFlag(FeatureFlag.PaymentRefund, false);

  async function updateWithToast() {
    const success = await updatePaymentSettings();

    if (success) {
      window.Toast.show(t('abp.billingAndPaymentSettings.updateSuccess'));
      window.location.reload();
    }
  }

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        headerLevel="h3"
        header={t('abp.billingAndPaymentSettings.pageHeader')}
      />
      <div className={styles.settingsContainer}>
        <TaxInformation
          taxDetails={taxDetails}
          updateTaxDetails={updateTaxDetails}
        />
      </div>
      <div className={styles.settingsContainer}>
        <CurrencySetting currency={currency} updateCurrency={updateCurrency} />
      </div>
      <div className={styles.settingsContainer}>
        <Invoice
          invoiceDueInDays={invoiceDueInDays}
          updateInvoiceDueInDays={updateInvoiceDueInDays}
          invoiceDisplayName={invoiceDisplayName}
          updateInvoiceDisplayName={updateInvoiceDisplayName}
          isInvoicingDisabled={isInvoicingDisabled}
          updateIsInvoicingDisabled={updateIsInvoicingDisabled}
        />
      </div>
      {enableRefund && (
        <div className={styles.settingsContainer}>
          <Refund
            failureRefund={failureRefund}
            cancellationRefund={cancellationRefund}
            updateFailureRefund={updateFailureRefund}
            updateCancellationRefund={updateCancellationRefund}
          />
        </div>
      )}
      <div className={styles.saveButton}>
        <Button
          disabled={settingsUnchanged || disableSave}
          variant="contained"
          onClick={() => updateWithToast()}
        >
          {t('abp.billingAndPaymentSettings.save')}
        </Button>
      </div>

      {paymentSettingsError && (
        <ErrorMessage error={t(paymentSettingsError.message)} />
      )}
    </AdminPage>
  );
}
