import { useMemo } from 'react';

import { getTimeZoneFromPort } from 'lane-shared/helpers/integrations/ShopifyBurst';
import isRegionLocationFilter from 'lane-shared/helpers/integrations/ShopifyBurst/isRegionLocationFilter';
import {
  LocationValue,
  RegionValue,
} from 'lane-shared/hooks/integrations/shopify/locationFilter';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

export default function useBurstRoomsTimeZone({
  filters,
  ports,
  selectedPort,
}: {
  filters: (LocationValue | RegionValue)[];
  ports?: ShopifyChannelType[];
  selectedPort?: ShopifyChannelType;
}) {
  return useMemo(() => {
    if (
      filters?.length === 1 &&
      filters[0] &&
      !isRegionLocationFilter(filters[0]) &&
      ports?.length
    ) {
      return getTimeZoneFromPort(
        ports.find(({ _id }) => _id === filters[0]!._id)
      );
    }

    return getTimeZoneFromPort(selectedPort);
  }, [filters, selectedPort, ports]);
}
