import React from 'react';

import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'lane-web/src/components/form';

type Props = {
  control: Control<any>;
  name: string;
  onValueChange?: any;
};

export function HookFormToggle({ control, name, onValueChange }: Props) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const answer = typeof value === 'boolean' ? value : value === t`Yes`;

        return (
          <Toggle
            onChange={e => {
              onChange(e);

              if (onValueChange) {
                onValueChange(e);
              }
            }}
            value={answer}
          />
        );
      }}
    />
  );
}
