import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { NotFoundError } from 'activate-errors';

const PROVIDERS = {
    MAILCHIMP_3_0: 'MAILCHIMP_3_0',
};

export const queryMailChimpConfigMetadata = gql`
  query getConfigMetadataForMailChimp(
    $channelId: UUID!
    $provider: String!
    $organizationId: String
    $serverDomain: String
    $applicationId: String
    $apiKey: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      organizationId: $organizationId
      serverDomain: $serverDomain
      applicationId: $applicationId
      apiKey: $apiKey
    ) {
      data
    }
  }
`;


export async function getMailchimpAudienceMetadata(
  provider: string,
  channelId: string,
  settings: any
) {
  let result;

  try {
    if (provider === PROVIDERS.MAILCHIMP_3_0) {
      result = await getClient().query({
        query: queryMailChimpConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          serverDomain: settings.serverDomain,
          apiKey: settings.apiKey,
          organizationId: settings.organizationId,
          applicationId: settings.applicationId,
        },
      });
    }

    if (result?.errors && result?.errors?.length > 0) {
      return Promise.reject(result?.errors[0]);
    }

    const metadata = result?.data?.getConfigMetadata?.data;

    if (!metadata) {
      return Promise.reject(new NotFoundError());
    }

    return metadata;
  } catch (err) {
    Promise.reject(err);
  }
}
