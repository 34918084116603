import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { useAuth0WebRedirectURL } from '../../../hooks/useAuth0WebRedirectURL';
import logout from '../../../helpers/logout';
import { useAuth0Context } from 'lane-shared/contexts/Auth0Context';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

export function useAuthClearSession() {
  const { logout: auth0Logout, isAuthenticated } = useAuth0();

  const redirectURL = useAuth0WebRedirectURL();
  const { setIsAuth0LogoutInProgress } = useAuth0Context();
  const clearSessionCallback = useFlag(FeatureFlag.ClearSessionCallback, false);

  return useCallback(async () => {
    if (isAuthenticated) {
      setIsAuth0LogoutInProgress(true);
      if (clearSessionCallback) {
        await logout({ emitLogout: true, emitAuthTokenInvalid: true });
        await auth0Logout({
          openUrl: false,
        });
        auth0Logout({
          logoutParams: { returnTo: redirectURL },
        });
      } else {
        await auth0Logout({
          openUrl: false,
        });
        await logout({ emitLogout: true, emitAuthTokenInvalid: true });
        auth0Logout({
          logoutParams: { returnTo: redirectURL },
        });
      }
    }
  }, [isAuthenticated, auth0Logout, redirectURL]);
}
