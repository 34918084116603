import { Column } from 'design-system-web';
import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';

export const buildCSVRows = (rows: any[], columns: any[]) => {
  const csvRows = [];

  const header = columns?.map(({ header }: { header: string }) => header);

  csvRows.push(header);

  rows.forEach(row => {
    const values = columns?.map(({ key, renderCell, renderForCSV }) => {
      if (renderForCSV) {
        return renderForCSV(row[key], row);
      }

      if (renderCell) {
        return renderCell(row[key], row);
      }

      return row[key];
    });

    csvRows.push(values);
  });

  return csvRows;
};

export const exportCSV = (
  rows: any[],
  columns: any[],
  filename: string,
  tableKey?: string
) => {
  const visibleColumns = getTableVisibleColumns(columns, tableKey);
  const orderedColumns = getTableReOrderedColumns(visibleColumns, tableKey);

  const csvRows = buildCSVRows(rows, orderedColumns);
  const csv = Papa.unparse(csvRows);

  makeFileDownload({
    name: `${filename}`,
    contents: csv,
    type: 'application/csv',
  });

  return csv;
};

export const getTableVisibleColumns = (columns: Column[], tableKey?: string) => {
  let storedValue;

  if (tableKey) {
    storedValue = localStorage.getItem(`${tableKey}.columnVisibility`);
  }

  let reportColumns: Column[] = [];

  if (storedValue) {
    // coloumn.id =>visible true/false
    const columnVisibility = JSON.parse(storedValue);

    columns.map(column => {
      if (columnVisibility[column.key]) {
        reportColumns.push(column);
      }
    });
  } else {
    reportColumns = columns;
  }

  return reportColumns;
};

export const getTableReOrderedColumns = (columns: Column[], tableKey?: string) => {
  let storedValue;

  if (tableKey) {
    storedValue = localStorage.getItem(`${tableKey}.columnOrder`);
  }

  let reportColumns: Column[] = [];

  if (!storedValue) {
    return columns;
  }

  const columnOrder = JSON.parse(storedValue);

  reportColumns = columnOrder
    .map((key: string) => {
      const column = columns.find(column => column.key === key);
      return column || null;
    })
    .filter((column: any) => column !== null);

  return reportColumns;
};
