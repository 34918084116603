import React, { useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl, fromNow } from 'lane-shared/helpers/formatters';
import { canDeleteUserRole } from 'lane-shared/helpers/user';
import { useDeleteUserRole } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';

import { Loading, Tooltip } from 'lane-web/src/components';
import CircleListView from 'lane-web/src/components/lane/CircleListView';

import styles from './UserChannelGroupRoleListView.scss';

type UserChannelGroupRoleListViewProps = {
  className?: string;
  style?: React.CSSProperties;
  channel: any;
  user: UserType | null;
  adminUser: UserType | null;
  refetch: () => Promise<any>;
};

export default function UserChannelGroupRoleListView({
  className,
  style,
  channel,
  user: groupRoleUser,
  adminUser: loggedInUser,
  refetch,
}: UserChannelGroupRoleListViewProps) {
  const { t } = useTranslation();

  const handleToast = (message: string) => {
    window.Toast.show(<p>{t(message)}</p>);
  };

  const handleAlert = (title: string, message: string, error: any) => {
    window.Alert.alert({
      title: t(title),
      message: t(message),
      error,
    });
  };

  const { deleteUserRole, loading: isDeletingRole, data } = useDeleteUserRole(
    handleToast,
    handleAlert
  );

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);

  const canUserDelete = (role: UserGroupRoleType): boolean => {
    return canDeleteUserRole(role, loggedInUser!, groupRoleUser!);
  };

  const handleDeleteClick = (userGroupRole: UserGroupRoleType): void => {
    if (!canUserDelete(userGroupRole)) {
      return;
    }

    removeUser(userGroupRole);
  };

  async function removeUser(userGroupRole: UserGroupRoleType) {
    await window.Alert.confirm({
      title: t(
        `Remove ${groupRoleUser?.name} from ${userGroupRole.groupRole.name}?`
      ),
      message: t(
        `Are you sure you want to remove ${groupRoleUser?.name} from ${userGroupRole.groupRole.name} in ${channel.name}?`
      ),
    });

    const successMessage = `${groupRoleUser?.name} is now removed from ${userGroupRole.groupRole.name} in ${channel.name}.`;

    deleteUserRole(userGroupRole._id, successMessage);
  }

  const renderDeleteIcon = (role: UserGroupRoleType) => {
    if (canUserDelete(role)) {
      return (
        <Icon
          className={styles.icon}
          name="times"
          onClick={() => handleDeleteClick(role)}
          testId="delete-times-icon"
        />
      );
    }

    return (
      <Tooltip
        TooltipComponent={t(
          'You do not have permission to remove member from this team.'
        )}
      >
        <Icon
          className={styles.iconDisabled}
          name="times"
          testId="delete-times-icon"
          disabled
        />
      </Tooltip>
    );
  };

  return (
    <CircleListView
      className={cx(styles.UserChannelGrupRoleListView, className)}
      style={style}
      image={imageUrl(channel.profile.image)}
      logo={imageUrl(channel.profile.logo)}
    >
      <Link
        data-test="channelLink"
        to={routes.channel.replace(':id', channel.slug)}
      >
        <h3>{getDisplayName(channel)}</h3>
      </Link>
      <ul className={styles.roles}>
        {channel.roles?.map((role: UserGroupRoleType) => {
          if (isDeletingRole) return <Loading />;

          return (
            <li data-test="memberRole" key={role._id}>
              <div>
                <h1>{role.name || role.groupRole.name}</h1>
                <p>{role.description}</p>
                <p className={styles.joined}>
                  Joined {fromNow(role._created)}.
                </p>
              </div>
              <div>{renderDeleteIcon(role)}</div>
            </li>
          );
        })}
      </ul>
    </CircleListView>
  );
}
