import { routes } from 'lane-shared/config';
import {
  BLOCK_LINK_TYPES,
  BLOCK_LINK_PREFIX,
} from 'lane-shared/helpers/constants/blocks';
import { LinkType } from 'lane-shared/types/baseTypes/LinkType';

import history from './history';

export default async function linkHandler({
  value,
  switchChannel,
}: {
  value: LinkType;
  switchChannel: (channelId: string) => void;
}) {
  let uri = typeof value.uri === 'string' ? value.uri : value.uri.en;

  const isChannelUri = uri.includes('channel');
  const channelId = uri.split('/').pop();

  switch (value.type) {
    case BLOCK_LINK_TYPES.ROUTE:
      // this is an in app route.
      if (isChannelUri && channelId) {
        try {
          await switchChannel(channelId);
        } catch (error) {
          window.Alert.alert({
            title: 'Something went wrong',
            error,
          });
        }

        history.push(routes.home);
      } else {
        history.push(uri);
      }

      return;
    case BLOCK_LINK_TYPES.PHONE:
      if (!uri.includes(BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.PHONE])) {
        uri = BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.PHONE] + uri;
      }

      break;
    case BLOCK_LINK_TYPES.EMAIL:
      if (!uri.includes(BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.EMAIL])) {
        uri = BLOCK_LINK_PREFIX[BLOCK_LINK_TYPES.EMAIL] + uri;
      }

      break;
    case BLOCK_LINK_TYPES.WEB:
    case BLOCK_LINK_TYPES.WEB_IN_APP:
    default:
      break;
  }

  window.open(uri, '_blank');
}
