import React, { useContext, useState, useEffect, useMemo } from 'react';

import { scrollTo } from 'helpers';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { ChannelsContext } from 'lane-shared/contexts';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import {
  RoomSize as RoomSizeType,
  filterRoomBySize2,
} from 'lane-shared/helpers/integrations/ShopifyBurst/burstRoomSize';
import {
  useBatchRoomBookingSection,
  useBurstLocationFiltersAndMetatag,
  RegionValue,
  LocationValue,
} from 'lane-shared/hooks/integrations/shopify/locationFilter';
import useAggregatedUnavailableDatesForSections from 'lane-shared/hooks/integrations/shopify/useAggregatedUnavailableDatesForSections';
import useBurstRoomsTimeZone from 'lane-shared/hooks/integrations/shopify/useBurstRoomsTimeZone';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { useShopifyPortsQuery } from 'lane-shared/hooks/integrations/shopify/useShopifyPortsQuery';
import {
  BurstDraftInteractionData,
  SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Loading, SearchBar } from 'components/general';
import BurstAvailableDateFilter from 'components/integrations/ShopifyBurst/components/BurstAvailableDateFilter';
import RoomSize from 'components/integrations/ShopifyBurst/components/RoomSize';
import { Box, Flex } from 'components/layout';
import { H2, L, TextLink } from 'components/typography';

import StandardContentCard from '../../../../cards/StandardContentCard';
import Chip from '../../../../general/Chip';
import { BurstCreatePageProps } from '../../components/BurstCreateFlow';
import LocationFilter from '../../components/LocationFilter';

import styles from './BurstDefaultView.scss';

function getTimeZoneFromPort(port: any) {
  const [longitude, latitude] = port?.address?.geo || [null, null];

  return getTimeZoneByGeoLocation({ longitude, latitude });
}

function setZoneKeepTime(isoTimeStamp: string, timeZone: string) {
  const date = DateTime.fromISO(isoTimeStamp, { setZone: true });
  const newDate = DateTime.local().setZone(timeZone).set(date.toObject());

  return newDate.toISO();
}

export default function BurstDefaultView({
  wizardState,
  onDataUpdated,
  goToNextStep,
}: BurstCreatePageProps) {
  useEffect(
    () =>
      scrollTo(window.document.scrollingElement, { top: 0, behavior: 'auto' }),
    []
  );
  const [isDiyConfirmed, setIsDiyConfirmed] = useState(false);
  const [search, setSearch] = useState('');
  const [attendeesRange, setAttendeesRange] = useState<
    RoomSizeType | undefined
  >();
  const [isDIYFilterEnabled, setIsDIYFilterEnabled] = useState(false);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<
    LaneType.DateTimeRange | undefined
  >();

  const [locationFilters, setLocations] = useState<
    (LocationValue | RegionValue)[]
  >([]);
  const channelData = useContext(ChannelsContext);
  const shopifyParentChannelId = channelData?.primaryChannel?.parent?._id;

  const { ports } = useShopifyPortsQuery(shopifyParentChannelId);

  const {
    burstIntegrationSettings,
    unavailableDatesAsDateRanges,
    maxDate,
    diyQuestionsContent,
  } = useShopifyBurstContext();

  const timeZone = useBurstRoomsTimeZone({
    ports,
    selectedPort: wizardState.data.port,
    filters: locationFilters,
  });

  const dates = useMemo(() => {
    return {
      startDate: dateRange
        ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
          setZoneKeepTime(dateRange.startDate, timeZone)
        : undefined,
      endDate: dateRange
        ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
          setZoneKeepTime(dateRange.endDate, timeZone)
        : undefined,
      minDate: DateTime.local()
        .setZone(timeZone)
        .startOf('day')
        .plus({ weeks: SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE })
        .toJSDate(),
    };
  }, [timeZone, dateRange]);

  const searchOptions = {
    search,
    dates,
    shopifyParentChannelId,
    roomBookingSectionId: burstIntegrationSettings?.roomBookingSection?._id,
  };

  const { filterOptions, regionMetatag } = useBurstLocationFiltersAndMetatag({
    ...searchOptions,
    ports,
  });

  const { sectionContent, loading } = useBatchRoomBookingSection({
    ...searchOptions,
    locationFilters,
    regionMetatag,
  });

  const unavailableDatesResult = useAggregatedUnavailableDatesForSections({
    ports,
    sectionId: searchOptions.roomBookingSectionId,
    timeZone,
    locationFilters,
  });

  const { items: filteredSectionList } = filterRoomBySize2(
    sectionContent,
    attendeesRange
  );

  useEffect(() => {
    setDateRange(wizardState.data.dateRange);
  }, [wizardState.data.dateRange]);

  useEffect(() => {
    // redirection for DIY is moved here because of async nature created in BurstCreateFlow
    if (wizardState.data.isDiyBurst && isDiyConfirmed) {
      goToNextStep();
    }
  }, [wizardState.data.isDiyBurst, isDiyConfirmed]);

  function selectContent(content: any) {
    const update: Partial<BurstDraftInteractionData> = {
      roomId: content._id,
      isDiyBurst: undefined,
    };

    const port = ports.find(({ _id }) => _id === content.channel._id);
    const timeZone = getTimeZoneFromPort(port);

    update.port = port;
    update.portTimeZone = timeZone;
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ startDate: string; endDate: string; } | un... Remove this comment to see the full error message
    update.dateRange = dateRange
      ? {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
          startDate: setZoneKeepTime(dateRange.startDate, timeZone),
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
          endDate: setZoneKeepTime(dateRange.endDate, timeZone),
        }
      : undefined;

    onDataUpdated(update);
  }

  function diyBurstHandler() {
    const port = undefined;
    const portTimeZone = getTimeZoneFromPort(port);

    onDataUpdated({
      port,
      portTimeZone,
      isDiyBurst: true,
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ startDate: string; endDate: string; } | un... Remove this comment to see the full error message
      dateRange: dateRange
        ? {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
            startDate: setZoneKeepTime(dateRange.startDate, portTimeZone),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
            endDate: setZoneKeepTime(dateRange.endDate, portTimeZone),
          }
        : undefined,
    });
    setIsDiyConfirmed(true);
  }

  const isDiyContent = diyQuestionsContent ? 1 : 0;

  return (
    <div className={styles.container}>
      <Flex align="center" className={styles.searchChips} gap={4}>
        <SearchBar
          className={styles.search}
          searchOptions={{ search }}
          onSearchChange={v => setSearch(v.toLowerCase())}
        />
        <BurstAvailableDateFilter
          timeZone={timeZone}
          className={styles.chip}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | Date' is not assignable to type 'st... Remove this comment to see the full error message
          startDate={dates.startDate || dates.minDate}
          endDate={dates.endDate}
          onChange={dateRange =>
            setDateRange(
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateRange | undefined' is not as... Remove this comment to see the full error message
              dateRange.startDate && dateRange.endDate ? dateRange : undefined
            )
          }
          minDate={dates.minDate}
          maxDate={unavailableDatesResult?.maxDate || maxDate}
          unavailableDateRanges={
            unavailableDatesResult?.unavailableTimeRanges ||
            unavailableDatesAsDateRanges
          }
        />
        <LocationFilter
          filterOptions={filterOptions}
          ports={ports}
          selectedLocations={locationFilters}
          onChange={items => setLocations(items)}
          className={styles.chip}
        />
        <RoomSize
          onChange={setAttendeesRange}
          attendeesRange={attendeesRange}
          className={styles.chip}
        />
        {diyQuestionsContent && (
          <Chip
            onClick={e => {
              // this removes focus which is useful only in dropdowns
              e.currentTarget.blur();
              setIsDIYFilterEnabled(!isDIYFilterEnabled);
            }}
            selected={isDIYFilterEnabled}
            label={t('DIY')}
            className={styles.chip}
          />
        )}
      </Flex>
      <div className={styles.line} />
      <Flex align="center" gap={4}>
        <L className={styles.availabilityOfRooms}>
          {isDIYFilterEnabled || filteredSectionList.length + isDiyContent === 1
            ? t('1 room available')
            : t('{{sectionContentlength}} rooms available', {
                sectionContentlength: filteredSectionList.length + isDiyContent,
              })}
        </L>
      </Flex>
      <H2 mt={4} mb={4}>
        {t('Burst rooms')}
      </H2>
      <L className={styles.availabilityOfRooms}>
        {t('Use the filters to narrow down your burst room options.')}
      </L>
      <div className={styles.space} />
      <L className={styles.availabilityOfRooms}>
        {t('Having trouble finding a Port that works for your team’s needs?')}
      </L>
      <L className={styles.availabilityOfRooms}>
        {t('Reach out to the Burst Agency for')}
        <TextLink href="https://airtable.com/shrXEZelHazxUunH8" target="_blank">
          <L className={styles.inlineLink}>{t('help')}.</L>
        </TextLink>
      </L>
      <div className={styles.space} />
      <L className={styles.availabilityOfRooms}>
        {t('To learn more about bursting please visit the')}
        <TextLink
          href="https://vault.shopify.io/pages/13312-Bursting"
          target="_blank"
        >
          <L className={styles.inlineLink}>Vault.</L>
        </TextLink>
      </L>
      <Flex className={styles.roomBookings} gap={4}>
        {loading ? <Loading fullscreen /> : null}

        {!isDIYFilterEnabled &&
          filteredSectionList.map(({ content }) => (
            <span
              key={content?._id}
              onClick={() => {
                selectContent(content);
                goToNextStep();
              }}
              role="button"
              tabIndex={0}
              className={styles.cardWrapper}
            >
              <StandardContentCard content={content} />
            </span>
          ))}
        {diyQuestionsContent && (
          <span
            onClick={diyBurstHandler}
            role="button"
            tabIndex={0}
            className={styles.cardWrapper}
          >
            <StandardContentCard content={diyQuestionsContent} />
          </span>
        )}
        {filteredSectionList.length + isDiyContent === 0 && (
          <Box width="full">
            <Flex
              direction="column"
              justify="center"
              align="center"
              className={styles.noRoomBookings}
            >
              <L variant="secondary" className={styles.emptyStateText}>
                {t('There are no available rooms')}
              </L>
            </Flex>
          </Box>
        )}
      </Flex>
    </div>
  );
}
