import React, { useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { imageUrl } from 'lane-shared/helpers/formatters';

import Button from 'components/general/Button';
import Image from 'components/general/Image';
import { Typography } from 'components/lds';

import styles from './AndroidSmartBanner.scss';

export default function AndroidSmartBanner() {
  const { t } = useTranslation();
  const [playStorePrompt, setPlayStorePrompt] = useState<any>(null);
  const [hidePrompt, setHidePrompt] = useState<boolean>(false);
  const [initializedPrompt, setInitializedPrompt] = useState<boolean>(false);
  const { whitelabel } = useContext(AppContext);

  function updateWidget() {
    playStorePrompt.prompt();
    setHidePrompt(true);
  }

  function initializeChromeInstallPrompt(e: any) {
    // if component mounted add widegt
    if (!initializedPrompt) {
      setInitializedPrompt(true);
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setPlayStorePrompt(e);
    }
  }

  useEffect(() => {
    window.addEventListener(
      'beforeinstallprompt',
      initializeChromeInstallPrompt
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        initializeChromeInstallPrompt
      );
    };
  }, []);

  // playStorePrompt only appears once Google Chrome has detected
  // that this device can install the app (either app is installed or incompatible)
  return playStorePrompt && !hidePrompt ? (
    <div className={styles.banner}>
      <Icon
        className={styles.icon}
        onClick={() => setHidePrompt(true)}
        name="times"
        set={ICON_SET_FONTAWESOME}
      />
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'alt' is missing in type '{ className: an... Remove this comment to see the full error message */}
      <Image className={styles.logo} src={imageUrl(whitelabel.profile.logo)} />
      <div>
        <Typography type="s" mode="primary" className={styles.appText}>
          {whitelabel.profile.name}
        </Typography>
        <Typography type="xs" mode="secondary">
          {t('GET - On the Play Store')}
        </Typography>
      </div>
      <Button className={styles.viewButton} onClick={updateWidget}>
        <Typography mode="primary" type="s">
          {t('View')}
        </Typography>
      </Button>
    </div>
  ) : null;
}
