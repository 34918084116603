import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import routes from 'lane-shared/config/routes';
import {
  UserDataContext,
  ChannelsContext,
  AppContext,
} from 'lane-shared/contexts';
import {
  useOnboardUser,
  useRegisterOnboardedUser,
} from 'lane-shared/hooks/onboarding';
import { useFetchUserBeforeOnboardEnabled } from 'lane-shared/hooks/useFetchUserBeforeOnboardEnabled';

import {
  Onboarding,
  UserOnboardingData,
} from '../../components/onboarding/Onboarding';
import {
  isAlreadyExistsError,
  isChannelFiltersError,
  isChannelMaxUsersError,
  isNotFoundError,
  isUserCreatedError,
  isUserGroupRoleMaxUsersError,
} from './utils';

const TRANSLATION_KEYS = {
  verifyEmailError: 'shared.onboarding.onboard.verifyEmail.error',
  channelFiltersError: 'shared.onboarding.signUp.channelFiltersError',
  alreadyExistsError: 'shared.onboarding.signUp.alreadyExistsError',
  channelMaxUsersError: 'shared.onboarding.signUp.channelMaxUsersError',
  userGroupRoleMaxUsersError:
    'shared.onboarding.signUp.userGroupRoleMaxUsersError',
  notFoundError: 'shared.onboarding.signUp.notFoundError',
  defaultError: 'shared.onboarding.signUp.error',
};

export const SignUpPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, refetch: refetchUser } = useContext(UserDataContext);
  const { refetch: refetchChannels } = useContext(ChannelsContext);
  const { transition } = useContext(AppContext);
  const fetchUserBeforeOnboardEnabled = useFetchUserBeforeOnboardEnabled();

  const { user: auth0User } = useAuth0();
  const { signUpUser } = useRegisterOnboardedUser();
  const { onboardUser } = useOnboardUser(user?._id);

  const translateErrorMessageFromCodedError = (err: any) => {
    if (isChannelMaxUsersError(err)) {
      return t(TRANSLATION_KEYS.channelMaxUsersError);
    }

    if (isChannelFiltersError(err)) {
      return t(TRANSLATION_KEYS.channelFiltersError);
    }

    if (isUserGroupRoleMaxUsersError(err)) {
      return t(TRANSLATION_KEYS.userGroupRoleMaxUsersError);
    }

    if (isNotFoundError(err)) {
      return t(TRANSLATION_KEYS.notFoundError);
    }

    if (isAlreadyExistsError(err)) {
      return t(TRANSLATION_KEYS.alreadyExistsError);
    }

    return undefined;
  };

  const doSignupAndRedirect = async (data: UserOnboardingData) => {
    const email = auth0User?.email;

    await signUpUser(data, email);
    await refetchUser();
    await refetchChannels();

    history.push(routes.home);
  };

  const doOnboardAndRedirect = async (data: UserOnboardingData) => {
    await onboardUser(data);
    await refetchUser();
    await refetchChannels();

    transition();
    history.push(routes.home);
  };

  const handleOnOnboarding = async (data: UserOnboardingData) => {
    /*
     * CONTEXT:
     * I was working locally and encountered a scenario where the `user` would
     * not be populated. In order to populate the user, `refetchUser` can be called.
     * This allowed us to choose correctly between "Onboard" or "Signup" based
     * on the user's existence.
     *
     * I have not added tests for this module since it won't be enabled until we
     * encounter a test scenario.
     *
     * Planned Removal: July 1, 2024 - if you stumble into this
     * after that date, you can feel free to remove this code.
     */

    async function fetchUser() {
      try {
        const u = await ((refetchUser as any) as () => Promise<any>)();

        return u?.data?.me.user;
      } catch (_) {
        return undefined;
      }
    }

    const currentUser = fetchUserBeforeOnboardEnabled
      ? await fetchUser()
      : user;

    if (currentUser?._id) {
      await doOnboardAndRedirect(data);

      return;
    }

    await doSignupAndRedirect(data);
  };

  const handleOnboardingError = async (error: any) => {
    let translatedError;

    if (isUserCreatedError(error)) {
      await refetchUser();

      translatedError = translateErrorMessageFromCodedError(error);
    }

    return translatedError || t(TRANSLATION_KEYS.defaultError);
  };

  return (
    <Onboarding
      onOnboarding={handleOnOnboarding}
      onOnboardingError={handleOnboardingError}
    />
  );
};
