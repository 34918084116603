import React, { useState, useMemo } from 'react';

import { Button, Input, MultiselectField } from 'components';
import { ChannelExperienceTypeEnum } from 'lane-shared/types/ChannelType';
import { useTranslation } from 'react-i18next';
import { object, string, ValidationError } from 'yup';

import { useMutation } from '@apollo/client';

import { unitErrorCodeTypes } from 'activate-errors';
import {
  GetUnitQueryResponse,
  updateUnit,
  UpdateUnitMutationResponse,
} from 'lane-shared/graphql/units';
import {
  getAggregatedValidationMessage,
  getErrorCodes,
  getErrorMessagesFromCodes,
} from 'lane-shared/helpers';

// import { dates } from 'lane-shared/helpers/constants';
// import { dateFormatter } from 'lane-shared/helpers/formatters';
import { PageNavigationAlert } from 'components/general';
import { H4 } from 'components/typography';

import { useGetFloorFieldOptions } from '../hooks/useGetFloorsFieldOptions';
import { UnitInputFields } from '../types';

import styles from './styles.scss';
import { getSharedTranslationKeys } from '../utils';

type Props = {
  channelId: string;
  channelExperienceType: ChannelExperienceTypeEnum | null | undefined;
  unit: GetUnitQueryResponse['unit'];
  setEdit: (value: boolean) => void;
  setIsUnitUpdated: (value: boolean) => void;
};

export const EditUnit = ({
  channelId,
  channelExperienceType,
  unit,
  setEdit,
  setIsUnitUpdated,
}: Props) => {
  const { t } = useTranslation();
  const { createPage, editPage, detailsPage } = getSharedTranslationKeys(
    channelExperienceType
  );
  const [editUnit, setEditUnit] = useState<UnitInputFields>({
    name: unit.name,
    description: unit.description,
    floors: unit.floors.map((floor: any) => ({
      label: floor.name,
      value: floor.id,
    })),
  });

  const [updateUnitFields, setUpdateUnitFields] = useState<UnitInputFields>();
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationError | null>(null);

  const [updateUnitMutation] = useMutation<UpdateUnitMutationResponse>(
    updateUnit
  );

  const { options } = useGetFloorFieldOptions(channelId);

  const unitInputLabel = t(createPage.form.name.label);

  const unitMinValidationMessage = t(createPage.form.name.minValidation);

  const isPristine = useMemo(() => {
    return (
      updateUnitFields === undefined ||
      (updateUnitFields.name?.length === 0 &&
        updateUnitFields.description?.length === 0)
    );
  }, [updateUnitFields]);

  const getValidationMessage = (path: string) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError,
      path
    );

    if (!validationMessage) {
      return null;
    }

    return [validationMessage];
  };

  const unitValidator = object().shape({
    name: string()
      .label(unitInputLabel)
      .trim()
      .required()
      .min(1, unitMinValidationMessage),
  });

  const handleOnChange = (value: any, fieldName: string) => {
    setEditUnit((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));

    // To track just changed values
    setUpdateUnitFields((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleOnUpdate = async () => {
    try {
      unitValidator.validateSync(
        { name: editUnit.name },
        { abortEarly: false }
      );
      setValidationError(null);
      await updateUnitMutation({
        variables: {
          propertyId: channelId,
          unitId: unit.id,
          unit: {
            ...editUnit,
            floors: editUnit.floors.map((floor: { value: any }) => floor.value),
          },
        },
      });

      window.Toast.show(t(editPage.successToast));

      setEdit(false);
      setIsUnitUpdated(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationError(error);
      } else {
        const codes = getErrorCodes(error);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          unitErrorCodeTypes,
          t('shared.units.errors.generic')
        );

        window.Toast.show(errorMessage);
      }
    }
  };

  const handleOnCancel = async () => {
    setEdit(false);
  };

  const editCTAs = () => {
    return (
      <>
        <Button
          testId="saveUnitButton"
          variant="contained"
          disabled={isPristine}
          onClick={handleOnUpdate}
        >
          {t('web.pages.portal.admin.units.details.edit.saveButton')}
        </Button>
        <Button
          testId="cancelButton"
          variant="outlined"
          onClick={handleOnCancel}
        >
          {t('web.pages.portal.admin.units.details.edit.cancelButton')}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.unitEdit}>
      <PageNavigationAlert when={!isPristine} testId="pageNavigationAlert" />
      <div className={styles.header}>
        <H4>{t(detailsPage.header)}</H4>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.childWidth}>
          <Input
            fieldName="name"
            label={t(createPage.form.name.label)}
            fixedLabel
            testId="nameInput"
            value={editUnit.name}
            error={getValidationMessage('name')}
            onChange={value => handleOnChange(value, 'name')}
            placeholder={t(createPage.form.name.placeholder)}
            isRequired
          />
        </div>
        <div className={styles.childWidth}>
          <MultiselectField
            label={t('web.admin.channel.units.create.form.floors.label')}
            fixedLabel
            isFullWidth
            testId="floorsDropdown"
            placeholder={t(
              'web.admin.channel.units.create.form.floors.placeholder'
            )}
            noOptionsMessage={t(
              'web.admin.channel.units.create.form.floors.noFloorsFound'
            )}
            isSearchable
            items={options}
            onChange={value => handleOnChange(value, 'floors')}
            value={editUnit.floors}
            doTranslation={false}
          />
        </div>
        <div className={styles.childWidth}>
          <Input
            fieldName="description"
            label={t('web.admin.channel.units.create.form.description.label')}
            fixedLabel
            testId="descriptionInput"
            value={editUnit.description}
            error={getValidationMessage('description')}
            onChange={value => handleOnChange(value, 'description')}
            placeholder={t(
              'web.admin.channel.units.create.form.description.placeholder'
            )}
          />
        </div>
      </div>
      {/* <div>
        <Label className={styles.label}>
          {t('web.pages.portal.admin.units.details.dateCreated')}
        </Label>
        <M>
          {dateFormatter(unit?.createdAtDatetime!, dates.LONG_MONTH_DATE_YEAR)}
        </M>
      </div> */}
      <div className={styles.buttonContainer}>{editCTAs()}</div>
    </div>
  );
};
