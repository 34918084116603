import React, { createContext, useState, useMemo } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import snakeCase from 'lodash/snakeCase';

import type {
  Equipment,
  MeterReadingValueResponseType,
  SortDirection,
  StringSearchType,
} from 'graphql-query-contracts';
import { MeterReadingSetting } from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  getMeterReadingValueForExport,
  searchMeterReadingValue,
} from 'graphql-queries';
import type {
  Column,
  PageSizeType,
  VisualizationType,
} from 'design-system-web';
import type { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { exportCSV } from 'lane-web/src/domains/workOrder/helpers/exportCSV';
import { Flex, Tooltip } from 'components';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { routes } from 'lane-shared/config';
import { Icon } from 'design-system-web';
import { M } from 'components/typography';
import styles from './MeterReadingTable.scss';

export const VISUALIZATION_TYPES: VisualizationType[] = ['table', 'graph'];

const PER_PAGE = 50;

type MeterReadingListType = {
  channel: UseChannelForAdminQueryResponse['channel'];
  equipmentId: string;
  allSettings: MeterReadingSetting[];
  selectedSetting: MeterReadingSetting;
  setSelectedSetting: (setting: MeterReadingSetting) => void;
  editingReading: MeterReadingValueResponseType | null;
  setEditingReading: (
    meterReading: MeterReadingValueResponseType | null
  ) => void;
  meterReadingValues: MeterReadingValueResponseType[];
  isLoading: boolean;
  totalCount: number;
  page: number;
  setPage: (value: number) => void;
  perPage: PageSizeType;
  setPerPage: (value: PageSizeType) => void;
  setSortBy: (value: string) => void;
  setSortDirection: (value: SortDirection) => void;
  setKeyword: (value: string) => void;
  visualizationType: VisualizationType;
  setVisualizationType: (visualizationType: VisualizationType) => void;
  refetch: () => void;
  exportOptions: { label: string; onClick: () => Promise<void> }[];
  columns: Column<MeterReadingValueResponseType>[];
};

const MeterReadingListContext = createContext<MeterReadingListType | undefined>(
  undefined
);

function MeterReadingListProvider({
  children,
  equipment,
  channel,
}: {
  children: React.ReactNode;
  equipment: Equipment;
  channel: UseChannelForAdminQueryResponse['channel'];
}) {
  const { t } = useTranslation();
  const allSettings = compact(equipment.meterReadingSettings);

  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<PageSizeType>(PER_PAGE);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    'desc' as SortDirection
  );
  const [keyword, setKeyword] = useState<string>('');
  const [selectedSetting, setSelectedSetting] = useState<MeterReadingSetting>(
    allSettings[0]
  );
  const [visualizationType, setVisualizationType] = useState<VisualizationType>(
    'table'
  );
  const [
    editingReading,
    setEditingReading,
  ] = useState<MeterReadingValueResponseType | null>(null);

  const { data, loading: isLoading, refetch } = useQuery(
    searchMeterReadingValue,
    {
      fetchPolicy: 'network-only',
      variables: {
        channelId: channel?._id,
        search: {
          sortBy: {
            key: snakeCase(sortBy),
            dir: sortDirection,
          },
          ...(keyword !== ''
            ? {
                search: {
                  type: 'like' as StringSearchType,
                  value: keyword,
                },
              }
            : {}),
        },
        pagination: {
          start: ((page || 0) as number) * perPage,
          perPage,
        },
        meterReadingId: selectedSetting && convertToUUID(selectedSetting.id),
        includeArchived: true,
      },
    }
  );

  const totalCount = data?.searchMeterReadingValue?.pageInfo?.total ?? 0;
  const meterReadingValues = compact(
    data?.searchMeterReadingValue?.meterReadingValues
  );

  const columns = [
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.createdAt`,
      key: 'createdAt',
      type: 'date',
      renderForCSV: (_: any, row: any) => new Date(row.createdAt),
    },
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.value`,
      key: 'value',
      renderCell: (value: string, row: any) => {
        if (row.isArchived) {
          return (
            <Flex gap={4} className={styles.valueBox}>
              <Flex direction="column">
                <M className={styles.valueCrossed}>{value}</M>
                <M className={styles.valueUnitCrossed}>
                  {selectedSetting.unit}
                </M>
              </Flex>
              <Tooltip
                TooltipComponent={t(
                  'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.value.archived.tooltip'
                )}
                placement="top"
              >
                <Icon
                  name="info-circle"
                  className={styles.infoIcon}
                  set={ICON_SET_FONTAWESOME}
                  type="far"
                />
              </Tooltip>
            </Flex>
          );
        }

        return (
          <Flex direction="column">
            <M>{value}</M>
            <M className={styles.valueUnit}>{selectedSetting.unit}</M>
          </Flex>
        );
      },
      renderForCSV: (value: any) => `${value} ${selectedSetting.unit}`,
    },
    {
      key: 'createdBy',
      header: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.createdBy'
      ),
      type: 'text',
      renderCell: (_: any, row: any) => row.createdBy?.name,
      renderForCSV: (_: any, row: any) => row.createdBy?.name,
    },
    {
      header: t`web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.taskId`,
      key: 'taskUserFriendlyId',
      renderCell: (taskUserFriendlyId: string, row: any) => (
        <Link
          to={routes.channelAdminWorkOrdersPMTaskDetails
            .replace(':id', channel?.slug)
            .replace(':taskId', row?.taskId)}
        >
          {taskUserFriendlyId}
        </Link>
      ),
      renderForCSV: (taskUserFriendlyId: any) => taskUserFriendlyId,
    },
    {
      key: 'notes',
      header: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.notes'
      ),
      type: 'text',
      renderCell: (notes: any) => <M className={styles.notesBox}>{notes}</M>,
      renderForCSV: (notes: any) => notes,
    },
  ];

  const [fetchMeterReadingsForExport] = useLazyQuery(
    getMeterReadingValueForExport,
    {
      variables: {
        meterReadingId: selectedSetting?.id,
        channelId: channel?._id,
        includeArchived: false,
      },
      fetchPolicy: 'network-only',
    }
  );

  const handleExportToCSV = async () => {
    const csvData = await fetchMeterReadingsForExport();

    const meterReadingValueCSVData =
      csvData?.data?.getMeterReadingValueForExport?.meterReadingValues || [];

    const fileNamePrefix = selectedSetting?.name || selectedSetting?.unit;

    exportCSV(
      meterReadingValueCSVData,
      columns,
      `${fileNamePrefix}-${new Date().toISOString()}.csv`
    );
  };

  const exportOptions = [
    {
      label: t('web.admin.serviceRequest.equipment.meterReadingValues.csvName'),
      onClick: handleExportToCSV,
    },
  ];

  const providerValues = useMemo(
    () => ({
      channel,
      isLoading,
      totalCount,
      meterReadingValues,
      page,
      setPage,
      perPage,
      setPerPage,
      setSortBy,
      setSortDirection,
      setKeyword,
      setVisualizationType,
      visualizationType,
      refetch,
      equipmentId: equipment.id,
      allSettings,
      selectedSetting,
      setSelectedSetting,
      editingReading,
      setEditingReading,
      exportOptions,
      columns,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      channel._id,
      isLoading,
      totalCount,
      JSON.stringify(meterReadingValues),
      page,
      perPage,
      visualizationType,
      equipment.id,
      JSON.stringify(selectedSetting),
      JSON.stringify(editingReading),
    ]
  );

  return (
    <MeterReadingListContext.Provider value={providerValues}>
      {children}
    </MeterReadingListContext.Provider>
  );
}

function useMeterReadingList() {
  const context = React.useContext(MeterReadingListContext);

  if (context === undefined) {
    throw new Error(
      'useMeterReadingList must be used within a MeterReadingListProvider'
    );
  }

  return context;
}

export { MeterReadingListProvider, useMeterReadingList };
