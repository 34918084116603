// @ts-nocheck FIXME: Ignored due failing CI after React/React Native update
import React, { useMemo, useState, useEffect, useContext } from 'react';

import { ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { AnalyticsContext } from 'lane-shared/contexts';
import { getLibraryOptions } from 'lane-shared/helpers';
import {
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED,
  ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED,
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED,
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED,
  ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGED,
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGE_FAILED,
} from 'lane-shared/helpers/constants/analytics';
import { useFlag } from 'lane-shared/hooks';
import {
  ChannelType,
  ActiveChannelTypeEnum,
} from 'lane-shared/types/ChannelType';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { Typography } from 'lane-web/src/components/lds';

import { Table, NativeFilterTypes, QueryString } from 'design-system-web';
import MediaPickerModal from 'components/lane/MediaPickerModal';
import { Flex } from 'components/layout';

import ArchiveModal from './components/ArchiveModal';
import { ChangeParentModal } from './components/ChangeParentModal';
import { ChannelTypeChangeDropdown } from './components/ChannelTypeChangeDropdown';
import ChannelTypeChangeModal from './components/ChannelTypeChangeModal';
import {
  INITIAL_SEARCH_PARAMS,
  CHANNEL_NAME,
  PARENT_CHANNEL,
  CHANNEL_TYPE,
  LOCATION,
  LOGO,
  OPTION_ITEMS,
} from './constants';
import useChannelData from './hooks/useChannelData';
import {
  ChannelManagementErrorType,
  doesChannelRowHaveParent,
  formattedAddress,
  getChannelInfoById,
  updateUserStatuses,
} from './utils';
import useQueryString from 'hooks/useQueryString';

import styles from './styles.scss';
import { TurnToLiveOrDraftModal } from './components/TurnToLiveOrDraftModal';

export default function ChannelManagement() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useQueryString<QueryString>(
    INITIAL_SEARCH_PARAMS
  );

  const analytics = useContext(AnalyticsContext);

  const showChangeParent = useFlag(FeatureFlag.ChangeParent, false);
  const showGoLive = useFlag(FeatureFlag.ChannelManagementGoLive, false);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedChannelType, setSelectedChannelType] = useState<
    ActiveChannelTypeEnum | string
  >('');
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [
    isChannelTypeChangeModalOpen,
    setIsChannelTypeChangeModalOpen,
  ] = useState<boolean>(false);
  const [
    isChangeParentModalOpen,
    setIsChangeParentModalOpen,
  ] = useState<boolean>(false);
  const [
    selectedParentChannel,
    setSelectedParentChannel,
  ] = useState<ChannelType | null>(null);
  const [isLiveModalOpen, setIsLiveModalOpen] = useState<boolean>(false);
  const [isUsersToActive, setIsUsersToActive] = useState<boolean>(false);
  const [error, setError] = useState<
    ChannelManagementErrorType | ChannelManagementErrorType[]
  >(null);
  const [showMediaLibrary, setShowMediaLibrary] = useState<boolean>(false);
  const [internalMedia, setMedia] = useState<null | DocumentType>(null);
  const [rowActionInfo, setRowActionInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filters = [
    {
      key: 'type',
      type: NativeFilterTypes.Multiselect,
      label: t(
        'web.admin.portalManagement.channelManagement.column.channelType'
      ),
      options: Object.keys(ActiveChannelTypeEnum).map(type => ({
        label: type,
        value: type,
      })),
    },
  ];

  const {
    channelData: { data, loading, error: queryError, refetch },
    deleteChannel,
    updateChannelInfo,
    checkChannelExists,
  } = useChannelData(searchParams, filters);

  const captureAnalytics = (eventName: string, payload: any) => {
    analytics.track(eventName, payload);
  };

  useEffect(() => {
    setError(queryError ?? null);
  }, [JSON.stringify(queryError)]);

  useEffect(() => {
    captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_PAGE_VISITED, {});
  }, [window.location.pathname]);

  useEffect(() => {
    const totalChannels = data?.channelsForBlocksAndTargeting?.pageInfo?.total;

    setSearchParams({ total: totalChannels });

    if (!queryError) setError(null);
  }, [JSON.stringify(data?.channelsForBlocksAndTargeting?.pageInfo)]);

  useEffect(() => {
    // TODO: This doesn't target the navigation clicks only the change in the page property in the url search params which could be improved
    captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_TABLE_PAGE_NAVIGATED, {
      currentPage: searchParams?.page,
    });
  }, [searchParams?.page]);

  const channelInfo = data?.channelsForBlocksAndTargeting?.items || [];

  const tableRows = useMemo(
    () =>
      channelInfo.map((channel: ChannelType) => ({
        _id: channel._id,
        channel,
        [LOGO.key]: {
          image: channel?.profile?.image,
          logo: channel?.profile?.logo,
          name: channel?.name,
        },
        [CHANNEL_NAME.key]: {
          channelName: channel.name,
          channelSlug: channel?.slug,
          channelId: channel._id,
          captureAnalytics,
        },
        [CHANNEL_TYPE.key]: channel.type,
        [PARENT_CHANNEL.key]: {
          parentChannelName: channel?.parent?.name,
          parentChannelSlug: channel?.parent?.slug,
          channelId: channel._id,
          captureAnalytics,
        },
        [LOCATION.key]: formattedAddress(channel.address),
      })),
    [channelInfo]
  );

  const showVisibleColumnsWithCorrectHeaderProps = () => {
    const editingValues = OPTION_ITEMS.filter(
      ({ isVisibleByDefault }) => isVisibleByDefault
    ).map(({ value }) => value);

    return OPTION_ITEMS.sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter(
        ({ isDefaultNonEditableColumn, value }) =>
          isDefaultNonEditableColumn || editingValues.includes(value)
      )
      .map(({ key, header, disableSorting, renderCell }) => ({
        key,
        header: t(header),
        disableSorting,
        ...(renderCell && { renderCell }),
      }));
  };

  const onSelectionChange = (selectedRowIds: string[]) => {
    setSelectedRows((previousSelection: any[]) => {
      const previousSelectionIds = previousSelection.map(r => r._id);

      return selectedRowIds.map(id =>
        previousSelectionIds.includes(id)
          ? previousSelection.find(r => r._id === id)
          : getChannelInfoById(channelInfo, id)
      );
    });
  };

  const archiveChannel = async () => {
    try {
      if (rowActionInfo?.channel?._id) {
        await deleteChannel(rowActionInfo?.channel._id);
        window.Toast.show(
          <p>
            {t(
              'web.admin.portalManagement.channelManagement.archiveChannel.successToast',
              { channelName: rowActionInfo?.channel?.name }
            )}
          </p>
        );

        // EDGE CASE: When deleting the only record on the page, it should go to the previous page after deletion
        const lastPage = Math.floor(
          Number(searchParams.total) / Number(searchParams.pageSize)
        );

        if (tableRows.length === 1 && lastPage !== 0) {
          setSearchParams({ page: lastPage - 1 });
        }

        captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETED, {
          deletedChannelId: rowActionInfo?.channel?._id,
          deletedChannelName: rowActionInfo?.channel?.name,
        });

        refetch();
        setSelectedRows([]);
      }
    } catch (err: any) {
      captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_DELETE_FAILED, {
        attemptedChannelId: rowActionInfo?.channel?._id,
        attemptedChannelName: rowActionInfo?.channel?.name,
        errorMessage: err?.message,
      });

      setError(err);
    }

    setIsArchiveModalOpen(false);
  };

  const updateChannelTypes = async (
    channelType: ActiveChannelTypeEnum | string = ''
  ) => {
    const validChannelIds: string[] = [];

    for (const channel of selectedRows) {
      const isChildChannel = !!channel?.parent?._id;

      validChannelIds.push(
        ...(isChildChannel ? [channel?._id] : channel?.hierarchy?.hierarchyDown)
      );
    }

    const validChannelIdsNoDuplicates = validChannelIds.filter(
      (item, index) => validChannelIds.indexOf(item) === index
    );

    setIsChannelTypeChangeModalOpen(false);
    setIsLoading(true);

    const updateResponse = await Promise.allSettled(
      validChannelIdsNoDuplicates.map(channelId =>
        // TODO: check channel exists returns archived channels as well, need to replace this with a method checkChannelExistsAndNotArchived
        checkChannelExists(channelId).then(
          ({ data: { checkIfChannelExists } }) => {
            if (checkIfChannelExists) {
              return updateChannelInfo(channelId, {
                type: channelType || selectedChannelType,
              });
            }

            return undefined;
          }
        )
      )
    );

    const changedParentChannelNames = [];
    const changedChannelIds = [];

    for (const res of updateResponse) {
      if (res.status === 'fulfilled' && res.value) {
        const name = selectedRows.find(
          r => r._id === res?.value?.data.updateChannel._id
        )?.name;

        if (name) changedParentChannelNames.push(name);

        changedChannelIds.push(res?.value?.data.updateChannel._id);
      }

      if (res.status === 'rejected') {
        // TODO: once checkChannelExistsAndNotArchived is implemented remove this condition
        if (res?.reason?.message !== 'This resource was not found. NF01') {
          captureAnalytics(
            ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGE_FAILED,
            {
              selectedChannels: selectedRows.map(s => ({
                id: s?._id,
                name: s?.name,
              })),
              error: res?.reason,
            }
          );
          setError(
            (
              prev: ChannelManagementErrorType | ChannelManagementErrorType[]
            ) => {
              let errors: ChannelManagementErrorType[] = [];

              if (prev) {
                if (Array.isArray(error)) {
                  errors = errors.concat(prev);
                } else {
                  errors.push(prev as ChannelManagementErrorType);
                }
              }

              errors.push(res?.reason);

              return errors;
            }
          );
        }
      }
    }

    setIsLoading(false);

    if (changedParentChannelNames.length > 0) {
      window.Toast.show(
        <p>
          {t(
            'web.admin.portalManagement.channelManagement.changeType.successToast',
            {
              channelNames: changedParentChannelNames.join(', '),
              channelType: channelType || selectedChannelType,
            }
          )}
        </p>
      );
    }

    if (changedChannelIds.length > 0) {
      captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_TYPE_CHANGED, {
        changedChannelIds,
      });
    }

    refetch();
  };

  const onMediaSelected = async (media: any) => {
    try {
      await updateChannelInfo(rowActionInfo?.channel?._id, {
        profile: {
          ...rowActionInfo?.channel?.profile,
          logo: (media && media._id) || null,
        },
      });

      window.Toast.show(
        <p>
          {
            (t(
              'web.admin.portalManagement.channelManagement.changeLogo.successToast'
            ),
            { channelName: rowActionInfo?.channel?.name })
          }
        </p>
      );

      captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGED, {
        changedChannelId: rowActionInfo?.channel?._id,
        changedChannelName: rowActionInfo?.channel?.name,
      });

      setMedia(null);
      refetch();
    } catch (err: any) {
      captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LOGO_CHANGE_FAILED, {
        attemptedChannelId: rowActionInfo?.channel?._id,
        attemptedChannelName: rowActionInfo?.channel?.name,
        errorMessage: err?.message,
      });

      setError(err);
    }
  };

  const onParentSelected = async () => {
    // Clear any previous errors
    setError(null);

    if (selectedParentChannel?._id) {
      try {
        await updateChannelInfo(rowActionInfo?.channel?._id, {
          parent: {
            _id: selectedParentChannel._id,
          },
        });

        window.Toast.show(
          <p>
            {t(
              'web.admin.portalManagement.channelManagement.changeParent.successToast',
              {
                channelName: rowActionInfo?.channel?.name,
                parentChannelName: selectedParentChannel.name,
              }
            )}
          </p>
        );

        captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGED, {
          changedChannelId: rowActionInfo?.channel?._id,
          changedChannelName: rowActionInfo?.channel?.name,
        });

        setSelectedParentChannel(null);
        refetch();
      } catch (err: any) {
        captureAnalytics(
          ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_CHANGE_FAILED,
          {
            attemptedChannelId: rowActionInfo?.channel?._id,
            attemptedChannelName: rowActionInfo?.channel?.name,
            errorMessage: err?.message,
          }
        );

        setError(err);
      }

      setIsChangeParentModalOpen(false);
    }
  };

  const onTurnToDraftOrLive = async () => {
    // Clear any previous errors
    setError(null);

    const isSettingToLive = !rowActionInfo?.channel.isLive;
    const translationKey = isSettingToLive ? 'goLive' : 'turnToDraft';

    try {
      await updateChannelInfo(rowActionInfo?.channel?._id, {
        isLive: isSettingToLive,
      });

      if (isSettingToLive && isUsersToActive) {
        // Set all user statuses in channel to Active
        await updateUserStatuses(rowActionInfo?.channel?._id);
      }

      window.Toast.show(
        <p>
          {t(
            `web.admin.portalManagement.channelManagement.${translationKey}.modal.successToast`,
            {
              channelName: rowActionInfo?.channel?.name,
            }
          )}
        </p>
      );

      captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGED, {
        changedChannelId: rowActionInfo?.channel?._id,
        changedChannelName: rowActionInfo?.channel?.name,
        status: isSettingToLive ? 'Live' : 'Draft',
      });

      setIsUsersToActive(false);
      refetch();
    } catch (err: any) {
      captureAnalytics(
        ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_STATUS_CHANGE_FAILED,
        {
          attemptedChannelId: rowActionInfo?.channel?._id,
          attemptedChannelName: rowActionInfo?.channel?.name,
          errorMessage: err?.message,
          attemptedStatus: isSettingToLive ? 'Live' : 'Draft',
        }
      );

      setError(err);
    }

    setIsLiveModalOpen(false);
  };

  const rowActions = [
    {
      label: t(
        'web.admin.portalManagement.channelManagement.action.changeLogo'
      ),
      onClick: (rowinfo: any) => {
        setShowMediaLibrary(true);
        setRowActionInfo(rowinfo);
      },
    },
    {
      label: t(
        'web.admin.portalManagement.channelManagement.action.deleteChannel'
      ),
      onClick: (rowinfo: any) => {
        setIsArchiveModalOpen(true);
        setRowActionInfo(rowinfo);
      },
    },
    ...(showChangeParent
      ? [
          {
            label: t(
              'web.admin.portalManagement.channelManagement.action.changeParent'
            ),
            onClick: (rowinfo: any) => {
              setRowActionInfo(rowinfo);
              setIsChangeParentModalOpen(true);
            },
            isDisabled: (rowInfo: any) => !doesChannelRowHaveParent(rowInfo),
          },
        ]
      : []),
    ...(showGoLive
      ? [
          {
            label: t(
              'web.admin.portalManagement.channelManagement.action.turnToDraft'
            ),
            onClick: (rowinfo: any) => {
              setRowActionInfo(rowinfo);
              setIsLiveModalOpen(true);
            },
            isHidden: (rowInfo: any) => !rowInfo.channel.isLive,
          },
          {
            label: t(
              'web.admin.portalManagement.channelManagement.action.goLive'
            ),
            onClick: (rowinfo: any) => {
              setRowActionInfo(rowinfo);
              setIsLiveModalOpen(true);
            },
            isHidden: (rowInfo: any) => rowInfo.channel.isLive,
          },
        ]
      : []),
  ];

  const bulkActions = [
    {
      renderAction: () => (
        <ChannelTypeChangeDropdown
          isSelectionActive={selectedRows.length > 0}
          setIsChannelTypeChangeModalOpen={setIsChannelTypeChangeModalOpen}
          setError={setError}
          setSelectedChannelType={setSelectedChannelType}
          isOnlyChildChannelsSelected={
            selectedRows.filter(c => !!c?.parent?._id).length ===
            selectedRows.length
          }
          updateChannelTypes={updateChannelTypes}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      {error && <ErrorMessage error={error} />}
      <Table
        isSelectable
        onSelectionChange={onSelectionChange}
        columns={showVisibleColumnsWithCorrectHeaderProps()}
        data={tableRows}
        isLoading={loading || isLoading}
        totalRows={Number(searchParams.total)}
        hasKeywordFilter
        rowSelection={selectedRows.map(r => r._id)}
        getRowId={(row: ChannelType) => row._id}
        rowActionsLabel="Actions"
        rowActions={rowActions}
        filters={filters}
        tableKey="portalManagement.channelManagementTable"
        showColumnVisibility
        bulkActions={bulkActions}
        pagination="server"
        queryStringsEnabled
      />
      <ArchiveModal
        selectedChannelName={rowActionInfo?.channel?.name}
        isOpen={isArchiveModalOpen}
        setIsOpen={setIsArchiveModalOpen}
        archiveChannel={archiveChannel}
      />
      <ChannelTypeChangeModal
        selectedChannelNames={selectedRows.map(({ name }) => name || '')}
        isOpen={isChannelTypeChangeModalOpen}
        setIsOpen={setIsChannelTypeChangeModalOpen}
        updateChannelTypes={updateChannelTypes}
      />
      {Number(searchParams.total) === 0 && (
        <Flex className={styles.noSearchMessageContainer} justify="center">
          <Typography type="h5" mode="primary">
            {t('web.admin.portalManagement.channelManagement.noResults')}
          </Typography>
        </Flex>
      )}
      <MediaPickerModal
        libraries={getLibraryOptions({ channel: rowActionInfo?.channel })}
        storageKey={`MediaPickerButton${rowActionInfo?.channel?._id}`}
        isOpen={showMediaLibrary}
        setIsOpen={setShowMediaLibrary}
        setMedia={setMedia}
        internalMedia={internalMedia}
        onMediaSelected={media => onMediaSelected(media)}
      />
      <ChangeParentModal
        selectedChannel={rowActionInfo}
        isOpen={isChangeParentModalOpen}
        setIsOpen={setIsChangeParentModalOpen}
        selectedParentChannel={selectedParentChannel}
        setSelectedParentChannel={setSelectedParentChannel}
        onParentSelected={onParentSelected}
      />
      <TurnToLiveOrDraftModal
        selectedChannel={rowActionInfo}
        isOpen={isLiveModalOpen}
        setIsOpen={setIsLiveModalOpen}
        onConfirmClick={onTurnToDraftOrLive}
        isUsersToActive={isUsersToActive}
        setIsUsersToActive={setIsUsersToActive}
      />
    </div>
  );
}
