import { gql } from '@apollo/client';

export const refundTransactionMutation = gql`
  mutation refundTransaction(
    $refundTransactionRequest: RefundTransactionRequest!
  ) {
    accounts {
      refundTransaction(refundTransactionRequest: $refundTransactionRequest) {
        transactionId
      }
    }
  }
`;
