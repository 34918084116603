import React from 'react';

import { Button } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import styles from './AccessManagementEditor.scss';

type LoadSettingsButtonProps = {
  loading?: boolean;
  error?: string;
  callback: () => void;
  disabled: boolean;
  textContent?: string;
  doTranslation?: boolean;
};

export function LoadSettingsButton({
  loading,
  error,
  callback,
  disabled,
  textContent = 'web.admin.channel.integrations.access.access-control-settings.load-cards',
  doTranslation = true,
}: LoadSettingsButtonProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.property}>
      <Button
        variant="secondary"
        size="large"
        loading={loading}
        onClick={() => callback()}
        testId="buttonLoadMetadataFields"
        disabled={disabled}
      >
        {doTranslation ? t(textContent) : textContent}
      </Button>
      {error ? (
        <div className={styles.bottomBox}>
          <p key={error} className={styles.error} data-test="errorMessage">
            {error}
          </p>
        </div>
      ) : null}
    </div>
  );
}
