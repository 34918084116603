import { useMemo } from 'react';

import isRegionLocationFilter from 'lane-shared/helpers/integrations/ShopifyBurst/isRegionLocationFilter';

import { RegionValue, LocationValue } from './types';

export default function useSelectedLocationFilterLabelData(
  filters: (RegionValue | LocationValue)[],
  portName?: string
) {
  return useMemo(() => {
    const labelData = {
      label: '',
      count: 0,
    };

    if (portName) {
      labelData.label = portName;

      return labelData;
    }

    const { regions, locations } = filters.reduce<{
      regions: RegionValue[];
      locations: LocationValue[];
    }>(
      (result, filter) => {
        if (isRegionLocationFilter(filter)) {
          result.regions.push(filter);
        } else {
          result.locations.push(filter);
        }

        return result;
      },
      {
        regions: [],
        locations: [],
      }
    );

    const isSingleRegion =
      regions.length === 1 &&
      regions[0]?.locations?.length === locations.length;

    if (isSingleRegion) {
      labelData.label = regions[0]?.label!;

      return labelData;
    }

    const locationsLength = locations.length;

    if (locationsLength > 1) {
      labelData.label = `{{ count }} Selected`;
      labelData.count = locationsLength;
    }

    if (locationsLength === 1 && filters[0]) {
      labelData.label = filters[0].label;
    }

    if (!labelData.label) {
      labelData.label = 'Location';
    }

    return labelData;
  }, [filters, portName]);
}
