import React, { useEffect, useMemo, useState } from 'react';

import {
  AdminPage,
  Button,
  Input,
  PageNavigationAlert,
  PageHeader,
  MultiselectField,
} from 'components';
import { history } from 'helpers';
import { useFormServerErrorHandler } from 'hooks';
import { useTranslation } from 'react-i18next';
import { object, string, ValidationError } from 'yup';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { unitErrorCodeTypes } from 'activate-errors';
import {
  createUnit,
  CreateUnitMutationResponse,
} from 'lane-shared/graphql/units';
import { getAggregatedValidationMessage } from 'lane-shared/helpers';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { useGetFloorFieldOptions } from '../hooks/useGetFloorsFieldOptions';
import {
  UnitInputFields,
  UnitInputFieldKey,
  ServerErrorCodeToFieldMapping,
} from '../types';

import styles from './styles.scss';
import { getSharedTranslationKeys } from '../utils';

type Props = {
  channel: Pick<ChannelType, '_id' | 'name' | 'slug' | 'experienceType'>;
};

export const CreateUnit = ({ channel }: Props) => {
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const { t } = useTranslation();
  const { createPage } = getSharedTranslationKeys(channel.experienceType);
  const [unitInputFields, setUnitInputFields] = useState<UnitInputFields>({
    name: '',
    description: '',
    floors: [],
  });
  const [isUnitCreated, setIsUnitCreated] = useState(false);
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationError | null>(null);
  const { options, error, loading: isFetchingFloors } = useGetFloorFieldOptions(
    channel._id
  );

  const genericServerErrorMessage = t('shared.units.errors.generic');
  const floorsDropdownNoOptionsMessage = error
    ? genericServerErrorMessage
    : t('web.admin.channel.units.create.form.floors.noFloorsFound');

  const showToast = (message: string) => {
    window.Toast.show(message);
  };

  const {
    serverFormFieldErrors,
    resetServerFormFieldErrors,
    handleServerError,
  } = useFormServerErrorHandler({
    errorCodeTypes: unitErrorCodeTypes,
    errorCodeToFormFieldMap: ServerErrorCodeToFieldMapping,
    show: showToast,
    defaultErrorMessage: genericServerErrorMessage,
  });

  const [
    createUnitMutation,
    { loading },
  ] = useMutation<CreateUnitMutationResponse>(createUnit);

  const unitInputLabel = t(createPage.form.name.label);

  const unitMinValidationMessage = t(createPage.form.name.minValidation);

  const unitValidator = object().shape({
    name: string()
      .label(unitInputLabel)
      .trim()
      .required()
      .min(1, unitMinValidationMessage),
  });

  const isPristine = useMemo(() => {
    return (
      unitInputFields.name.length === 0 &&
      unitInputFields.description.length === 0
    );
  }, [unitInputFields]);

  useEffect(() => {
    if (isUnitCreated) {
      history.push(
        routes.channelAdminUnitsListView.replace(':id', channel.slug)
      );
    }
  }, [isUnitCreated, channel.slug]);

  const handleOnChange = (
    key: keyof UnitInputFields,
    value: UnitInputFields[UnitInputFieldKey]
  ) => {
    setUnitInputFields({
      ...unitInputFields,
      [key]: value,
    });
  };

  const handleOnCreate = async () => {
    try {
      unitValidator.validateSync(
        { name: unitInputFields.name },
        { abortEarly: false }
      );
      setValidationError(null);
      resetServerFormFieldErrors();

      const unitInput = {
        ...unitInputFields,
        floors: unitInputFields.floors.map(floor => floor.value),
      };

      await createUnitMutation({
        variables: { propertyId: channel._id, unit: unitInput },
      });

      window.Toast.show(t(createPage.successToast));

      setIsUnitCreated(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationError(error);
      } else {
        handleServerError(error);
      }
    }
  };

  const handleOnCancel = async () => {
    const url = routes.channelAdminUnitsListView.replace(':id', channel.slug);

    history.push(url);
  };

  const getErrorMessage = (field: UnitInputFieldKey) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError,
      field
    );

    if (validationMessage) {
      return [validationMessage];
    }

    // check server errors
    const serverErrorMessages = serverFormFieldErrors[field];

    if (serverErrorMessages) {
      return [serverErrorMessages];
    }

    return [];
  };

  const renderCTAButtons = () => {
    return (
      <>
        <Button
          onClick={handleOnCreate}
          testId="createButton"
          variant="contained"
          disabled={isPristine}
          loading={loading}
        >
          {t('web.admin.channel.units.create.createButton')}
        </Button>
        <Button
          onClick={handleOnCancel}
          testId="cancelButton"
          variant="outlined"
        >
          {t('web.admin.channel.units.create.cancelButton')}
        </Button>
      </>
    );
  };

  if (!unitsFloorsEnabled) return null;

  return (
    <AdminPage className={styles.adminPage}>
      <PageNavigationAlert
        when={!isPristine && !isUnitCreated}
        testId="pageNavigationAlert"
      />
      <PageHeader
        headerLevel="h3"
        header={t(createPage.header, {
          propertyChannelName: channel.name,
        })}
        breadcrumbs={[
          {
            label: t(createPage.breadcrumbs.back),
            url: routes.channelAdminUnitsListView.replace(':id', channel.slug),
          },
          {
            label: t(createPage.breadcrumbs.current),
          },
        ]}
      />
      <div className={styles.addUnitContainer}>
        <div className={styles.formContainer}>
          <Input
            fieldName="name"
            label={unitInputLabel}
            fixedLabel
            testId="nameInput"
            value={unitInputFields.name}
            error={getErrorMessage('name')}
            onChange={value => handleOnChange('name', value)}
            placeholder={t(createPage.form.name.placeholder)}
            isRequired
          />
          <Input
            fieldName="description"
            label={t('web.admin.channel.units.create.form.description.label')}
            fixedLabel
            testId="descriptionInput"
            value={unitInputFields.description}
            error={getErrorMessage('description')}
            onChange={value => handleOnChange('description', value)}
            placeholder={t(
              'web.admin.channel.units.create.form.description.placeholder'
            )}
          />
          <MultiselectField
            label={t('web.admin.channel.units.create.form.floors.label')}
            fixedLabel
            isFullWidth
            isSearchable
            testId="floorsDropdown"
            placeholder={t(
              'web.admin.channel.units.create.form.floors.placeholder'
            )}
            noOptionsMessage={floorsDropdownNoOptionsMessage}
            doTranslation={false}
            value={unitInputFields.floors}
            onChange={value => handleOnChange('floors', value)}
            items={options}
            isLoading={isFetchingFloors}
          />
          <div className={styles.buttonContainer}>{renderCTAButtons()}</div>
        </div>
      </div>
    </AdminPage>
  );
};
