import React, { useEffect, useContext } from 'react';

import cx from 'classnames';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import confirmEmail from 'lane-shared/graphql/user/confirmEmail';
import sendMagicLinkMutation from 'lane-shared/graphql/user/sendMagicLink';
import {
  useMagicLinksFeatureEnabled,
  useOnboardingV2Enabled,
} from 'lane-shared/hooks';

import { Text } from 'components/typography';

import SignUpBuildingV2 from './SignUpBuildingV2';
import SignUpForm, { SignUpUserData } from './SignUpForm';
import SignupCompany from './SignupCompany';
import SignupConfirm from './SignupConfirm';
import SignupValidateEmail from './SignupValidateEmail';
import SignupDetailsLegacy from './__legacy__/SignupDetailsLegacy';

import styles from './Signup.scss';

const TRANSLATION_KEYS = {
  ssoError: 'web.signup.sso.error',
};

export default function Signup() {
  const magicLinksFeatureFlag = useMagicLinksFeatureEnabled();
  const isOnboardingV2 = useOnboardingV2Enabled();

  const history = useHistory();
  const { state } = useLocation<{
    invite?: { _id: string };
    channel?: { _id: string };
    email?: string;
  }>();
  const { t } = useTranslation();

  const {
    name,
    email,
    password,
    updateSignUp,
    shouldSkipCompanySelection,
    resetSignUp,
    oAuth,
  } = useSignUpContext();

  const { transition, whitelabel } = useContext(AppContext);

  const searchParams = qs.parse(history?.location?.search);

  async function finishSignUp() {
    resetSignUp();
    transition();
  }

  useEffect(() => {
    if (state?.invite?._id) {
      updateSignUp({ inviteId: state.invite._id });
    }

    if (state?.channel) {
      updateSignUp({ building: (state as any).channel });
    }
  }, []);

  const isOAuthSignUp = Boolean(oAuth);

  function renderSignUpCompany() {
    if (!name || !email || !password) {
      return <Redirect to={routes.signUp} />;
    }

    if (!isOAuthSignUp && shouldSkipCompanySelection) {
      return <Redirect to={routes.signUpBuilding} />;
    }

    return <SignupCompany onNext={finishSignUp} />;
  }

  const handleSSOSuccess = () => {
    const redirectRoute = shouldSkipCompanySelection
      ? routes.signUpBuilding
      : routes.signUpCompany;

    history.push({
      pathname: redirectRoute,
      search: qs.stringify(searchParams),
    });
  };

  function handleSSOError(err: Error | null) {
    if (err) {
      window.Toast.show(<Text>{t(TRANSLATION_KEYS.ssoError)}</Text>);
    }
  }

  function handleManualSignUp(data: SignUpUserData) {
    const { fullName, email, password, emailOptIn } = data;

    updateSignUp({ name: fullName, email, password, emailOptIn });

    const routeToRedirectTo = shouldSkipCompanySelection
      ? routes.signUpBuilding
      : routes.signUpCompany;

    history.push(routeToRedirectTo);
  }

  function renderSignUpForm() {
    if (!isOnboardingV2) {
      return (
        <SignupDetailsLegacy
          onNext={async options => {
            if (options?.isSSOUsed) {
              history.push(
                shouldSkipCompanySelection
                  ? routes.signUpBuilding
                  : routes.signUpCompany
              );
            } else {
              sendMagicLink();
            }
          }}
        />
      );
    }

    const canDisplaySignUpForm = Boolean(state?.email || email);

    if (canDisplaySignUpForm) {
      return (
        <SignUpForm
          userData={{
            email: state?.email || email,
            name,
          }}
          whitelabel={whitelabel}
          onManualSubmit={handleManualSignUp}
          onSSOSuccess={handleSSOSuccess}
          onSSOError={handleSSOError}
        />
      );
    }

    return <Redirect to={routes.login} />;
  }

  async function sendMagicLink() {
    try {
      const confirmEmailResponse = await getClient().query({
        fetchPolicy: 'no-cache',
        query: confirmEmail,
        variables: {
          email,
        },
      });

      if (confirmEmailResponse?.data?.confirmEmail && magicLinksFeatureFlag) {
        await getClient().mutate({
          mutation: sendMagicLinkMutation,
          variables: {
            email,
          },
        });
        history.push(routes.magicLinkRequest, { submitted: true });
      } else {
        const routeToRedirectTo = shouldSkipCompanySelection
          ? routes.signUpBuilding
          : routes.signUpCompany;

        history.push({
          pathname: routeToRedirectTo,
          search: qs.stringify(searchParams),
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  const canContinueToSignUpBuilding = name && email && password;

  return (
    <div
      className={cx({
        [styles.signupLegacy]: !isOnboardingV2,
        [styles.container]: isOnboardingV2,
      })}
    >
      <Route exact path={routes.signUp} render={() => renderSignUpForm()} />
      <Route exact path={routes.signUpBuilding}>
        {!canContinueToSignUpBuilding ? (
          <Redirect to={routes.signUp} />
        ) : (
          <SignUpBuildingV2 onNext={finishSignUp} />
        )}
      </Route>
      <Route exact path={routes.signUpCompany}>
        {renderSignUpCompany()}
      </Route>
      <Route
        exact
        path={routes.signUpConfirm}
        render={() => <SignupConfirm onNext={finishSignUp} />}
      />

      <Route
        exact
        path={routes.signUpValidateEmail}
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'onLogin' is missing in type '{}' but req... Remove this comment to see the full error message
        render={() => <SignupValidateEmail />}
      />
    </div>
  );
}
