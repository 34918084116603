import React from 'react';

import { Icon } from 'design-system-web';

import { colors } from 'lane-shared/config/colors';

type Props = {
  contentPublishedAt?: Date;
  draftUpdatedAt: Date;
};

/**
 * Displays an icon to indicate if a generated content is published
 */
export default function PublicationStatus({
  contentPublishedAt,
  draftUpdatedAt,
}: Props) {
  let icon = 'circle';

  if (contentPublishedAt) {
    if (draftUpdatedAt > contentPublishedAt) {
      icon = 'history';
    } else {
      icon = 'check-circle';
    }
  }

  const color = contentPublishedAt ? colors.success : colors.charcoal[4];

  return <Icon name={icon} set="FontAwesome" type="far" style={{ color }} />;
}
