import React, { useState, useEffect } from 'react';

import {
  Loading,
  ErrorMessage,
  Pagination,
  IconButton,
  ContextMenu,
  Checkbox,
} from 'components';
import { useTranslation } from 'react-i18next';

import { graphql } from '@apollo/client/react/hoc';

import { getClient } from 'lane-shared/apollo';
import { resendInvite, deleteInvite } from 'lane-shared/graphql/mutation';
import { queryChannelInvitesByGroupRole } from 'lane-shared/graphql/query';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';

import styles from './TeamInvites.scss';

const PER_PAGE = 25;

function TeamView({ groupRole, data, channel, isSendInviteOpen }: any) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inviteOpen, setInviteOpen] = useState(isSendInviteOpen);

  if (inviteOpen !== isSendInviteOpen) {
    setInviteOpen(isSendInviteOpen);
    data.refetch();
  }

  useEffect(() => {
    data.refetch({
      channelId: channel?._id,
      groupRoleId: groupRole?._id,
      pagination: {
        start: page * PER_PAGE,
        perPage: PER_PAGE,
      },
    });
  }, [page]);

  if (!data || !data.invitesOnChannel) {
    return <Loading />;
  }

  if (data.error) {
    return <ErrorMessage error={data.error} />;
  }

  async function onRemoveInvite(invite: any) {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.removeInvite.prompt.title',
          {
            userName: invite.name,
            userGroup: groupRole.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.removeInvite.prompt.message',
          {
            userName: invite.name,
            userGroup: groupRole.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      await getClient().mutate({
        mutation: deleteInvite,
        variables: {
          id: invite._id,
        },
      });

      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabInvites.button.removeInvite.success.message'
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.removeInvite.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    await data.refetch();
    setSelectedIds([]);
    setLoading(false);
  }

  async function onResendInvite(invite: any) {
    setLoading(true);

    try {
      await getClient().mutate({
        mutation: resendInvite,
        variables: {
          id: invite._id,
        },
      });

      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabInvites.button.resendInvite.success.message'
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.resendInvite.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    await data.refetch();
    setLoading(false);
  }

  async function bulkRemove() {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkRemove.prompt.title',
          {
            totalUsers: selectedIds.length,
            userGroup: groupRole.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkRemove.prompt.message',
          {
            totalUsers: selectedIds.length,
            userGroup: groupRole.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      for (const id of selectedIds) {
        await getClient().mutate({
          mutation: deleteInvite,
          variables: {
            id,
          },
        });
      }

      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkRemove.success.message'
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkRemove.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    await data.refetch();
    setSelectedIds([]);
    setLoading(false);
  }

  async function bulkResend() {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkResend.prompt.title',
          {
            totalUsers: selectedIds.length,
            userGroup: groupRole.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkResend.prompt.message',
          {
            totalUsers: selectedIds.length,
            userGroup: groupRole.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      for (const id of selectedIds) {
        await getClient().mutate({
          mutation: resendInvite,
          variables: {
            id,
          },
        });
      }

      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkResend.success.message'
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkResend.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabInvites.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    await data.refetch();
    setSelectedIds([]);
    setLoading(false);
  }

  function toggleSelected(id: any) {
    setSelectedIds(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      selectedIds.includes(id)
        ? selectedIds.filter(i => i !== id)
        : [...selectedIds, id]
    );
  }

  const { pageInfo } = data.invitesOnChannel;
  const invites = data.invitesOnChannel.items;

  return (
    <div className={styles.TeamInvites}>
      <Pagination
        page={page}
        perPage={PER_PAGE}
        loading={data.loading}
        total={pageInfo.total}
        onPage={page => {
          setPage(page);
          setSelectedIds([]);
        }}
      />

      <table>
        <thead>
          <tr>
            <th>
              <ContextMenu
                menuClassName={styles.menu}
                items={[
                  <button
                    key="remove"
                    className={styles.menuOption}
                    onClick={bulkRemove}
                  >
                    {t(
                      'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkRemove'
                    )}
                  </button>,
                  <button
                    key="resend"
                    className={styles.menuOption}
                    onClick={bulkResend}
                  >
                    {t(
                      'web.admin.channel.teamManagement.team.view.tabInvites.button.bulkResend'
                    )}
                  </button>,
                ]}
              >
                <IconButton
                  inverted
                  icon="menu"
                  selected={false}
                  disabled={loading || selectedIds.length === 0}
                />
              </ContextMenu>
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.name'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.email'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.sent'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.status'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.expires'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.remove'
              )}
            </th>
            <th>
              {t(
                'web.admin.channel.teamManagement.team.view.tabInvites.table.resend'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {invites.map((invite: any) => (
            <tr key={invite._id}>
              <td>
                {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
                <Checkbox
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                  selected={selectedIds.includes(invite._id)}
                  onChange={() => toggleSelected(invite._id)}
                  name={invite._id}
                />
              </td>
              <td>{invite.name}</td>
              <td>{invite.email}</td>
              <td>{simpleDate(invite.sent)}</td>
              <td>{invite.status}</td>
              <td>{fromNow(invite.expires)}</td>
              <td>
                <IconButton
                  className={styles.removeButton}
                  onClick={() => onRemoveInvite(invite)}
                  icon="times"
                  inverted
                  disabled={loading}
                />
              </td>
              <td>
                <IconButton
                  onClick={() => onResendInvite(invite)}
                  icon="sync"
                  iconSet="FontAwesome"
                  inverted
                  disabled={loading}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {loading && <Loading className={styles.loading} />}
    </div>
  );
}

export default graphql(queryChannelInvitesByGroupRole, {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'groupRole' does not exist on type '{}'.
  options: ({ groupRole, channel }) => ({
    skip: !channel?._id && !groupRole?._id,
    variables: {
      channelId: channel?._id,
      groupRoleId: groupRole?._id,
    },
  }),
})(TeamView);
