import React, { useState } from 'react';

import {
  Button,
  ControlMenu,
  Input,
  Label,
  TextArea,
  WhitelabeledLogo,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { submitFeedbackMutation } from 'lane-shared/apollo/local';
import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';

import AuthForm from 'components/auth/AuthForm';

import styles from './Support.scss';

export default function Support() {
  const { t } = useTranslation();
  const signUpData = useSignUpContext();
  const [email, setEmail] = useState(signUpData.email || '');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  async function onSubmit() {
    setLoading(true);

    try {
      await submitFeedbackMutation({
        comment: message,
        data: { email, ...filterSignUpData(signUpData) },
      });
    } catch (err) {
      window.Alert.alert({
        title: `Error sending feedback.`,
        error: err,
      });
    }

    setMessageSent(true);
    setLoading(false);
  }

  function filterSignUpData(signUpData: any) {
    const data = {};

    for (const [key, value] of Object.entries(signUpData)) {
      // filter out nulls, empty string and functions
      if (value && typeof value !== 'function') {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = value;
      }

      // remove extra data from the building object
      if (key === 'building' && value) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = {
          name: signUpData[key].name,
          _id: signUpData[key]._id,
        };
      }
    }

    return data;
  }

  return (
    <div className={styles.Support}>
      <WhitelabeledLogo />
      <AuthForm className={styles.form}>
        <h1>Contact Support</h1>
        <p>
          {t(
            "Tell us more about your problem and we'll get back to you as as soon as possible"
          )}
        </p>
        <div>
          {messageSent ? (
            <>
              <h2 className={styles.messageSentHeader}>
                {t('Thank you for your feedback')}
              </h2>
              <Link to={routes.signUp} className={styles.signUpLink}>
                {t('Return to sign up')}
              </Link>
            </>
          ) : (
            <>
              <Input
                className={styles.input}
                type="email"
                placeholder="email@address.com"
                onChange={setEmail}
                value={email}
                icon="mail"
                autoComplete={false}
              />

              <Label className={styles.label}>{t('How can we help?')}</Label>
              <TextArea
                value={message}
                onChange={message => setMessage(message)}
                placeholder={t('Describe your issue here')}
                className={styles.textarea}
                maxLength={2048}
                minRows={5}
              />

              <ControlMenu className={styles.menu}>
                <Button
                  loading={loading}
                  onClick={onSubmit}
                  variant="contained"
                >
                  {t('Submit')}
                </Button>
              </ControlMenu>
            </>
          )}
        </div>
      </AuthForm>
    </div>
  );
}
