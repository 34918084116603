import React, { useState } from 'react';

import cx from 'classnames';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  MarkupAmountType,
  TaxAmountType,
  ProductType,
  ChargeCode,
  AddItemType,
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
  Product,
} from 'lane-shared/domains/billingPayments/types';

import {
  useCategoryListQuery,
  useProductsListQuery,
  useChargeCodeListQuery,
} from 'lane-web/src/pages/portal/admin/channel/products-services/helpers/index';

import { Item } from 'components/form/Dropdown/Dropdown';

// See TODO comment at the bottom of file which will fix this eslint-disable

import {
  ProductCategory,
  ProductType as gqlProductType,
} from 'graphql-query-contracts';

import styles from '../addProductToChargeStyles.scss';
import useUserLocale from 'hooks/useUserLocale';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import type { Sort } from 'design-system-web';

export function AddProductCategoryAndProductSelection({
  formType,
  productDetails,
  productType,
  handleProductDetailsUpdate,
  currency,
  inclusivePricing = false,
}: {
  formType: AddItemType | undefined;
  productDetails: AddProductToChargeDetails;
  productType: ProductType;
  handleProductDetailsUpdate: Function;
  currency: SupportedPaymentCurrency;
  inclusivePricing?: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const { data: categoryList } = useCategoryListQuery({
    page: 0,
    pageSize: 100,
    productType: convertToGqlProductType(productType),
  });

  const productsList = useProductsListQuery({
    page: 0,
    pageSize: 100,
    productCategoryId: productDetails.productCategoryId,
  });

  function getCategoryItems(): Item<string>[] | undefined {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[] | undefined;

    if (listOfCategories) {
      const dropdownCategoryItems = listOfCategories.map(productCategory => {
        return {
          label: productCategory.name,
          value: productCategory.productCategoryId,
        };
      });

      return dropdownCategoryItems;
    }

    return undefined;
  }

  const onCategorySelection = (item: Item<string>) => {
    const listOfCategories = categoryList?.listProductCategories
      .productCategory as ProductCategory[];
    const selectedCategory = listOfCategories.find(
      productCategory => productCategory.productCategoryId === item.value
    );

    if (selectedCategory) {
      handleProductDetailsUpdate({
        ...productDetails,
        productCategoryId: selectedCategory.productCategoryId,
        productCategoryName: selectedCategory.name,
        productCategoryType: selectedCategory.productType,
      });
    }
  };

  function getProductItems(): Item<string>[] | undefined {
    const listOfProducts = productsList.data?.listProducts.products;

    if (listOfProducts) {
      const dropdownProductItems = listOfProducts.map(product => {
        return {
          label: product.name,
          value: product.id,
        };
      });

      return dropdownProductItems;
    }

    return undefined;
  }

  const onProductSelection = (item: Item<string>) => {
    const selectedProduct = productsList.data?.listProducts.products.find(
      product => product.id === item.value
    );

    if (selectedProduct) {
      // TODO: TM-18527 - Update to use the generated types correctly
      const priceComponents = productToPriceComponents(
        (selectedProduct as unknown) as Product
      );
      const { netPrice, rate } = getPricing({
        currency,
        locale,
        priceComponents,
        inclusivePricing,
      });

      handleProductDetailsUpdate({
        ...productDetails,
        productId: selectedProduct.id,
        productGroupId: selectedProduct.groupId,
        name: selectedProduct.name,
        description: selectedProduct.description,
        rate: rate.toString(),
        markup: {
          type:
            selectedProduct?.markup?.markupAmountType ||
            MarkupAmountType.MarkupAmountTypePercentage,
          value: selectedProduct?.markup?.value.toString() || '',
        },
        tax: {
          type:
            selectedProduct.tax?.taxAmountType ||
            TaxAmountType.TaxAmountTypePercentage,
          value: selectedProduct?.tax?.value.toString() || '',
        },
        originalPrice: undefined,
        chargeCode: selectedProduct.chargeCode?.chargeCode || '',
        chargeCodeId: selectedProduct.chargeCode?.id || '',
        netPrice: netPrice.toString(),
      });
    }
  };

  const [inputChargeCode, setInputchargeCode] = useState<string>('');
  const chargeCodeSort: Sort = { id: 'charge_code', direction: 'asc' };
  const chargeCodeList = useChargeCodeListQuery(
    0,
    100,
    chargeCodeSort,
    inputChargeCode
  );

  function getChargeCodeItems(): Item<string>[] {
    const listOfChargeCodes = chargeCodeList.data?.accounts?.listChargeCode
      ?.chargeCodes as ChargeCode[] | undefined;

    if (listOfChargeCodes) {
      const dropdownChargeCodeItems = listOfChargeCodes.map(chargeCode => {
        return {
          label: chargeCode.chargeCode,
          value: chargeCode.id,
        };
      });

      return dropdownChargeCodeItems;
    }

    return [];
  }

  const fetchChargeCodesForInput = async (inputValue: string) => {
    setInputchargeCode(inputValue);

    return getChargeCodeItems();
  };

  const onChargeCodeSelection = (item: Item<string>) => {
    const listOfChargeCodes = chargeCodeList.data?.accounts?.listChargeCode
      ?.chargeCodes as ChargeCode[] | undefined;

    const selectedChargeCode = listOfChargeCodes?.find(
      chargeCode => chargeCode.id === item.value
    );

    if (selectedChargeCode) {
      handleProductDetailsUpdate({
        ...productDetails,
        chargeCodeId: selectedChargeCode.id,
        chargeCode: selectedChargeCode.chargeCode,
      });
    }
  };

  return (
    <>
      <Dropdown
        testId="categoryDropdown"
        doTranslation={false}
        className={cx(styles.categoryAndProductDropdown)}
        value={productDetails.productCategoryId}
        placeholder={t(
          'abp.productsServices.createProduct.category.placeholder'
        )}
        items={getCategoryItems()}
        onChange={onCategorySelection}
        label={t('abp.charges.addProduct.modal.categoryInputLabel')}
        isRequired
        fixedLabel
      />
      {formType === AddItemType.EXISTING ? (
        <>
          <Dropdown
            testId="productDropdown"
            doTranslation={false}
            className={cx(styles.categoryAndProductDropdown)}
            value={productDetails.productId}
            placeholder={t(
              'abp.charges.addProduct.modal.productInputPlaceholder'
            )}
            items={getProductItems()}
            onChange={onProductSelection}
            label={t('abp.charges.addProduct.modal.productInputLabel')}
            isRequired
            fixedLabel
            disabled={Boolean(!productDetails.productCategoryId)}
          />

          <Input
            className={styles.inputFields}
            label={t('abp.charges.addProduct.modal.chargeCodeInputPlaceholder')}
            fixedLabel
            value={productDetails.chargeCode}
            testId="chargeCodeInput"
            disabled
            onChange={() => {}}
          />
        </>
      ) : (
        <>
          <Input
            className={styles.categoryAndProductDropdown}
            label={t('abp.charges.addProduct.modal.productInputLabel')}
            fixedLabel
            isRequired
            value={productDetails.name}
            onChange={(value: string) =>
              handleProductDetailsUpdate(value, 'name')
            }
            testId="productInput"
          />

          <Dropdown
            id="chargeCodeDropdown"
            doTranslation={false}
            className={cx(styles.categoryAndProductDropdown)}
            value={{
              label: productDetails.chargeCode,
              value: productDetails.chargeCodeId,
            }}
            placeholder={t(
              'abp.charges.addProduct.modal.chargeCodeInputPlaceholder'
            )}
            label={t('abp.charges.addProduct.modal.chargeCodeInputLabel')}
            onChange={onChargeCodeSelection}
            defaultOptions={getChargeCodeItems()}
            fixedLabel
            loadOptions={fetchChargeCodesForInput}
          />
        </>
      )}
    </>
  );
}

// TODO: TM-15574 - update the list categories hook to use the domain enum vs the gql enum
function convertToGqlProductType(productType: ProductType): gqlProductType {
  switch (productType) {
    case ProductType.PRODUCT_TYPE_SERVICE_LABOUR:
      return gqlProductType.ProductTypeServiceLabour;
    case ProductType.PRODUCT_TYPE_SERVICE_MATERIALS:
      return gqlProductType.ProductTypeServiceMaterials;
    default:
      return gqlProductType.ProductTypeServiceLabour;
  }
}
