import { RRule, Frequency } from 'rrule';

import {
  RecurrenceInterval,
  ChargeDetails,
} from 'lane-shared/domains/billingPayments/types';

export function validateRecurrenceEndDate(charge: ChargeDetails): boolean {
  if (charge.endDateOption === 'on') {
    if (!charge.endDate) return false;

    // check if rucurrence has atleast 2
    const freqRRULE = frequencyToRRuleFrequency(charge.frequency);

    const rule = new RRule({
      freq: freqRRULE,
      dtstart: charge.startDate,
      until: charge.endDate,
    });

    const count = rule.all().length;

    if (count < 2) {
      return false;
    }
  }

  if (charge.endDateOption === 'after') {
    if (charge.totalCounts <= 1) return false;
  }

  return true;
}

export function getMinimumEndDate(charge: ChargeDetails): Date {
  const freqRRULE = frequencyToRRuleFrequency(charge.frequency);
  const rule = new RRule({
    freq: freqRRULE,
    dtstart: charge.startDate,
    count: 2,
  });

  return rule.all()[1];
}

function frequencyToRRuleFrequency(
  frequency: RecurrenceInterval
): Frequency | undefined {
  switch (frequency) {
    case RecurrenceInterval.RECURRENCE_INTERVAL_DAILY: {
      return RRule.DAILY;
    }

    case RecurrenceInterval.RECURRENCE_INTERVAL_WEEKLY: {
      return RRule.WEEKLY;
    }

    case RecurrenceInterval.RECURRENCE_INTERVAL_MONTHLY: {
      return RRule.MONTHLY;
    }

    case RecurrenceInterval.RECURRENCE_INTERVAL_YEARLY: {
      return RRule.YEARLY;
    }
  }

  return undefined;
}
