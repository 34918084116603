import React, { useContext, useEffect, useState } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { useChannelAdminContext } from 'hooks';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import FullWebhookFragment from 'lane-shared/graphql/fragments/FullWebhookFragment';
import PublicUserFragment from 'lane-shared/graphql/fragments/PublicUserFragment';
import { pause } from 'lane-shared/helpers';
import WebhookTypeDefinition, {
  WebhookType,
} from 'lane-shared/helpers/webhooks/WebhookType';
import { useUpdatedData } from 'lane-shared/hooks';

import Property from 'components/builder/properties/input/Property';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import updateWebhookMutation from 'components/webhooks/updateWebhookMutation';

import styles from './WebhookCreate.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  webhookId: string;
};

export default function WebhookEdit({ className, style, webhookId }: Props) {
  const { user } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();
  const [
    webhook,
    setWebhook,
    hasChanges,
    getPatch,
  ] = useUpdatedData<WebhookType | null>(null);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const webhookQuery = useQuery(
    gql`
      ${PublicUserFragment}
      ${FullWebhookFragment}
      query getWebhook($webhookId: UUID!) {
        webhook(_id: $webhookId) {
          ...FullWebhookFragment
        }
      }
    `,
    {
      skip: !webhookId,
      variables: {
        webhookId,
      },
    }
  );

  useEffect(() => {
    if (webhookQuery.data?.webhook) {
      setWebhook(webhookQuery.data.webhook, true);
    }
  }, [webhookQuery.data?.webhook]);

  function updateWebhook(update: Partial<WebhookType>) {
    setWebhook(update);
  }

  async function saveWebhook() {
    const validator = WebhookTypeDefinition.buildSchema();

    const webhookData = {
      ...webhook,
      ...getPatch(),
    };

    try {
      await validator.validate(webhookData, { abortEarly: false });
      setValidation(null);
    } catch (err) {
      setValidation(err);

      return;
    }

    try {
      setLoading(true);
      setError(null);

      await pause();
      await getClient().mutate({
        mutation: updateWebhookMutation,
        variables: {
          webhook: {
            _id: webhookId,
            ...getPatch(),
          },
        },
      });

      window.Toast.show('Webhook updated!');
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={cx(styles.WebhookCreate, className)} style={style}>
      <ControlMenu>
        <hr />
        <Button
          loading={loading}
          disabled={!hasChanges}
          variant="contained"
          onClick={saveWebhook}
        >
          Update
        </Button>
      </ControlMenu>
      <ErrorMessage error={error} />
      <ErrorMessage error={validation} />

      {webhook &&
        Object.entries(WebhookTypeDefinition.properties).map(
          ([key, property]) =>
            property.hidden ? null : (
              <div key={key} className={styles.property}>
                <Property
                  object={webhook}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
                  channel={channel}
                  label={property.friendlyName || property.name || key}
                  user={user}
                  property={property}
                  propertyKey={key}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  value={webhook[key]}
                  onChange={value => updateWebhook({ [key]: value })}
                  onPropertyChange={({ key, value }) =>
                    updateWebhook({ [key]: value })
                  }
                />
              </div>
            )
        )}
    </div>
  );
}
