import React, { Dispatch, SetStateAction } from 'react';

import { Button, Input, IconButton } from 'components';
import { useTranslation } from 'react-i18next';
import { P, Subtitle } from 'components/typography';

import {
  RiseMigrationFeaturesListType,
  RiseMigrationFeaturesType,
} from '../../types';
import { FaPlusCircle } from 'react-icons/fa';

import { AllowedFeatures as RiseMigrationAllowedFeatures } from 'graphql-query-contracts/src/__generated__/graphql';

import styles from '../../styles.scss';

export function MigrateCatUsers({
  features,
  setFeatures,
  allowedFeatures,
  isFirstMigration,
  feature,
}: {
  features: RiseMigrationFeaturesType;
  setFeatures: Dispatch<SetStateAction<RiseMigrationFeaturesType>>;
  allowedFeatures?: RiseMigrationAllowedFeatures;
  isFirstMigration: boolean;
  feature: RiseMigrationFeaturesListType;
}) {
  const { key, name, description } = feature;
  const value = features[key as keyof RiseMigrationFeaturesType];

  const { t } = useTranslation();

  function addCatUsers() {
    const users = [...features.migrateCatUsers];

    users.push('');
    setFeatures({ ...features, migrateCatUsers: users });
  }

  function handleChangeCatUsers(user: string, index: number) {
    const users = [...features.migrateCatUsers];

    if (index > -1) {
      users[index] = user;
    }

    setFeatures({ ...features, migrateCatUsers: users });
  }

  function removeCatUsers(index: number) {
    const users = [...features.migrateCatUsers];

    if (index > -1) {
      users.splice(index, 1);
    }

    setFeatures({ ...features, migrateCatUsers: users });
  }

  const isMigrateCatUsersDisabled = !allowedFeatures?.migrateCatUsers;
  const variant = isMigrateCatUsersDisabled ? 'secondary' : 'primary';

  return (
    <div key={key} className={styles.CatUsers}>
      <div className={styles.CatUsersTitle}>
        <P variant={variant}>{name}</P>
        {isFirstMigration && (
          <Subtitle variant={variant}>{description}</Subtitle>
        )}
      </div>
      <Button
        startIcon={<FaPlusCircle />}
        onClick={() => addCatUsers()}
        className={styles.SourceButton}
        disabled={isMigrateCatUsersDisabled}
      >
        {t('web.admin.channel.autoSetupWizard.features.addCatUser')}
      </Button>
      {(value as string[]).map((user: string, index: number) => {
        return (
          <div className={styles.InputRow} key={`input-row-users-${index}`}>
            <Input
              value={user}
              onChange={(value: string) => handleChangeCatUsers(value, index)}
              placeholder={t`web.admin.channel.autoSetupWizard.features.catUser`}
              className={styles.SourceInput}
            />
            <IconButton
              icon="times"
              inverted
              onClick={() => {
                removeCatUsers(index);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
