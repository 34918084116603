import { useReducer, useState } from 'react';

import * as yup from 'yup';

import { validateFullName, validatePasswordV2 } from 'lane-shared/validation';

export interface FormValues {
  fullName: string;
  email: string;
  password: string;
  emailOptIn: boolean;
}

interface FormErrors {
  fullName?: string;
  password?: string;
}

type FullNameAction = { type: 'UPDATE_FULL_NAME'; value: string };
type PasswordAction = { type: 'UPDATE_PASSWORD'; value: string };
type EmailOptInAction = {
  type: 'UPDATE_EMAIL_OPT_IN_OPTION';
  value: boolean;
};

function signUpReducer(
  state: FormValues,
  action: FullNameAction | PasswordAction | EmailOptInAction
) {
  switch (action.type) {
    case 'UPDATE_FULL_NAME':
      return {
        ...state,
        fullName: action.value,
      };
    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: action.value,
      };
    case 'UPDATE_EMAIL_OPT_IN_OPTION':
      return {
        ...state,
        emailOptIn: action.value,
      };
  }
}

export function useSignUpForm({
  initialValues,
}: {
  initialValues: FormValues;
}) {
  const [state, dispatch] = useReducer(signUpReducer, initialValues);
  const [errors, setErrors] = useState<FormErrors | null>(null);
  const [validationRequiredFields, setValidationRequiredFields] = useState<
    Set<string>
  >(new Set());

  function _updateError(errorsToUpdate: FormErrors) {
    const fields = Object.keys(errorsToUpdate);
    const updatedErrors = fields.length > 0 ? errorsToUpdate : null;

    setErrors(updatedErrors);
  }

  function _unsetErrorField(name: 'fullName' | 'password') {
    if (errors) {
      const { [name]: _, ...otherErrors } = errors;

      _updateError(otherErrors);
    }
  }

  function _validateField(name: string, value?: string) {
    try {
      if (name === 'fullName') {
        validateFullName.validateSync(value || state.fullName);
        _unsetErrorField(name);
      } else if (name === 'password') {
        validatePasswordV2.validateSync(value || state.password);
        _unsetErrorField(name);
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const updatedErrors = { ...errors, [name]: err.message };

        setErrors(updatedErrors);
      }
    }
  }

  function validate(name: string) {
    setValidationRequiredFields(list => {
      list.add(name);

      return list;
    });

    _validateField(name);
  }

  function setFieldValue(name: string, value?: string) {
    if (name === 'fullName' && value !== undefined) {
      if (validationRequiredFields.has(name)) {
        _validateField('fullName', value);
      }

      dispatch({ type: 'UPDATE_FULL_NAME', value });
    } else if (name === 'password' && value !== undefined) {
      if (validationRequiredFields.has(name)) {
        _validateField('password', value);
      }

      dispatch({ type: 'UPDATE_PASSWORD', value });
    } else if (name === 'emailOptIn') {
      dispatch({
        type: 'UPDATE_EMAIL_OPT_IN_OPTION',
        value: !state.emailOptIn,
      });
    }
  }

  return { ...state, setFieldValue, validate, errors };
}
