import { useState, useEffect } from 'react';

import gql from 'graphql-tag';
import * as yup from 'yup';

import { useQuery } from '@apollo/client';

import { getClient } from '../../apollo';
import { getUserIntegration } from '../../graphql/user';
import { validationShape } from '../../helpers/integrations/AngusServiceRequests';

const workOrderInfoQuery = gql`
  query WorkOrderInfo($contentId: UUID!) {
    workOrderTypes(contentId: $contentId) {
      _id
      name
      canCancel
    }

    workOrderRequesterLocations(contentId: $contentId) {
      _id
      name
      floors {
        _id
        name
        suites {
          _id
          name
        }
      }
    }
  }
`;

export default function useAngusWorkOrder({ content, buildingId, floor }: any) {
  const [workOrder, setWorkOrder] = useState({
    description: '',
    requestTypeRefNum: null,
    locationRefNum: null,
    requesterRefNum: null,
  });

  const [validation, setValidation] = useState<Error | null>(null);

  function updateWorkOrder(props: any) {
    setWorkOrder(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  async function queryUserIntegration() {
    if (!content) {
      return;
    }

    const userIntegrationData = await getClient().query({
      query: getUserIntegration,
      variables: {
        channelIntegrationId: content.integration._id,
      },
    });

    const { userIntegration } = userIntegrationData.data;

    updateWorkOrder({ ...workOrder, requesterRefNum: userIntegration.key });
  }

  const workOrderQueryResult = useQuery(workOrderInfoQuery, {
    fetchPolicy: 'network-only',
    skip: !content?._id,
    variables: {
      contentId: content?._id,
    },
  });

  useEffect(() => {
    if (content) {
      queryUserIntegration();
    }
  }, [content?._id]);

  function getBuilding(buildings: any) {
    if (buildings.length > 1 && buildingId) {
      return buildings.find((building: any) => building._id === buildingId);
    }

    return buildings[0];
  }

  const requestTypes = workOrderQueryResult?.data?.workOrderTypes || [];

  const buildings =
    workOrderQueryResult?.data?.workOrderRequesterLocations || [];

  const building = getBuilding(buildings);
  const floors = building?.floors || [];
  const suites = floor?.suites || [];

  function validate(workOrder: any) {
    try {
      yup.object().shape(validationShape).validateSync(workOrder);
      setValidation(null);

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  return {
    workOrder,
    updateWorkOrder,
    requestTypes,
    buildings,
    building,
    floors,
    suites,
    validate,
    validation,
  };
}
