import React, { useState } from 'react';

import type { UserLoginMethod } from 'lane-shared/hooks/auth/useExistingOAuthUserLogins';
import { useExistingOAuthUserLogins } from 'lane-shared/hooks/auth/useExistingOAuthUserLogins';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { routes } from 'lane-shared/config';

import EmailForm from './EmailForm';

import styles from './index.scss';
import { Redirect } from 'react-router-dom';

enum LoginError {
  SIGN_UP_NOT_ALLOWED = 'SIGN_UP_NOT_ALLOWED',
}

type Props = {
  onError: () => void;
  onSuccess: (_: {
    email: string;
    isLoginEmailExist: boolean;
    oAuthLoginMethods: UserLoginMethod[];
  }) => void;
  whitelabel: WhiteLabelType;
};

export default function LoginEmail({ onError, onSuccess, whitelabel }: Props) {
  const isAuth0Enabled = useAuth0Enabled();
  const [error, setError] = useState<LoginError | undefined>(undefined);
  const [email, setEmail] = useState<null | string>(null);
  const { fetchUserLoginMethods, isLoading } = useExistingOAuthUserLogins({
    onError,
    onSuccess({ isLoginEmailExist, oAuthLoginMethods }) {
      const { allowSignUps } = whitelabel.settings;

      if (!isLoginEmailExist && !allowSignUps) {
        setError(LoginError.SIGN_UP_NOT_ALLOWED);

        return;
      }

      if (email) {
        onSuccess({
          email,
          isLoginEmailExist,
          oAuthLoginMethods,
        });
      }
    },
  });

  function handleSubmit(email: string) {
    setError(undefined);
    setEmail(email);
    fetchUserLoginMethods(email);
  }

  if (isAuth0Enabled) {
    return <Redirect to={routes.identifier} />;
  }

  return (
    <div className={styles.container}>
      <EmailForm
        onSubmit={handleSubmit}
        isLoading={isLoading}
        error={{
          isInviteOnly: error === LoginError.SIGN_UP_NOT_ALLOWED,
        }}
      />
    </div>
  );
}
