import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'components/general';
import { Modal } from 'components/lds';
import { H4 } from 'components/typography';

import styles from './ErrorModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  errorHeader: any;
  errorMessage: any;
};

enum ModalView {
  ERROR = 'error',
}

export default function ErrorModal({
  isOpen,
  onClose,
  errorHeader,
  errorMessage,
}: Props) {
  const { t } = useTranslation();
  const [currentModalView, _setCurrentModalView] = useState<ModalView>(
    ModalView.ERROR
  );

  function onCloseErrorModal() {
    if (onClose) {
      onClose();
    }
  }

  const views = {
    [ModalView.ERROR]: {
      content: (
        <>
          <div className={styles.errorTitle}>
            <Icon
              size="medium"
              className={styles.errorIcon}
              name="times-circle"
              type="far"
              set="FontAwesome"
            />
            <H4>{t(errorHeader)}</H4>
          </div>
          <ErrorMessage error={errorMessage} />
        </>
      ),
      actions: [],
    },
  };

  const { content, actions } = views[currentModalView];

  return (
    <Modal
      className={styles.errorModal}
      size="small"
      isOpen={isOpen}
      onClose={onCloseErrorModal}
      actions={actions}
    >
      <div className={styles.modalContent}>{content}</div>
    </Modal>
  );
}
