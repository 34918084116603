import React, { useEffect, useRef, useState } from 'react';

import {
  ControlMenu,
  ChannelCircleListView,
  Button,
  IconButton,
  Loading,
  EmptyState,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';

// eslint-disable-next-line @nx/enforce-module-boundaries
import checkCreateChannelPermissions from 'lane-infrastructure/helpers/channel/checkCreateChannelPermissions';

import { getClient } from 'lane-shared/apollo';
import {
  queryChannels,
  deleteChannelMutation,
} from 'lane-shared/graphql/channel';
import { getDisplayName } from 'lane-shared/helpers';
import { shortAddress } from 'lane-shared/helpers/formatters';

import styles from './styles.scss';

function ChannelSubChannels({ channel, user }: any) {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [enableAddChannel, setEnableAddChannel] = useState(false);
  const reFetchRef = useRef(null);

  useEffect(() => {
    try {
      // is this user allowed to create channels
      checkCreateChannelPermissions({
        channel,
        me: { _id: user._id, isSuperUser: user.isSuperUser },
        userGroupRoles: user.roles,
      });

      setEnableAddChannel(true);
    } catch {
      setEnableAddChannel(false);
    }
  }, [channel, user]);

  async function removeChild(child: any) {
    try {
      await window.Alert.confirm({
        title: t('web.admin.channel.subChannel.removeAlert.title', {
          subChannel: getDisplayName(child),
        }),
        message: t('web.admin.channel.subChannel.removeAlert.message', {
          subChannel: getDisplayName(child),
          channel: getDisplayName(channel),
        }),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      await getClient().mutate({
        mutation: deleteChannelMutation,
        variables: {
          channelId: child?._id,
        },
      });
    } catch (err) {
      window.Toast.show(
        t('web.admin.channel.subChannel.removeAlert.error', {
          message: err.message,
        })
      );

      return;
    }

    window.Toast.show(
      t('web.admin.channel.subChannel.removeAlert.success', {
        subChannel: getDisplayName(child),
      })
    );

    if (reFetchRef?.current && typeof reFetchRef?.current === 'function') {
      // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
      return reFetchRef?.current?.();
    }
  }

  const variables = {
    pagination: {
      start: 0,
      perPage: 500,
    },
    search: {
      sortBy: {
        key: 'name',
        dir: 'asc',
      },
      includeChannelsFromHierarchy: true,
      isSub: true,
      parent: {
        _id: channel?._id,
      },
    },
  };

  return (
    <div className={styles.ChannelSubChannels}>
      <ControlMenu>
        <Link
          to="sub-channels/new"
          style={enableAddChannel ? {} : { pointerEvents: 'none' }}
        >
          <Button
            disabled={!enableAddChannel}
            loading={loading}
            variant="contained"
          >
            {t('Add')}
          </Button>
        </Link>
      </ControlMenu>
      <Query
        query={queryChannels}
        variables={variables}
        fetchPolicy="network-only"
      >
        {({ data, refetch }: any) => {
          if (!data || !data.channels) {
            return <Loading />;
          }

          reFetchRef.current = refetch;
          const children = data.channels.items || [];

          if (children.length === 0 && enableAddChannel) {
            return (
              <Link to="sub-channels/new">
                <EmptyState>
                  {t('web.admin.channel.subChannel.emptyState')}
                </EmptyState>
              </Link>
            );
          }

          return children.map((child: any) => (
            <div key={child._id} className={styles.channel}>
              <Link to={`/l/channel/${child.slug}/admin`}>
                <ChannelCircleListView
                  channel={child}
                  description={shortAddress(child.address)}
                />
              </Link>
              <IconButton
                testId="deleteSubChannel"
                icon="times"
                inverted
                disabled={loading}
                className={styles.remove}
                onClick={() => removeChild(child)}
              />
            </div>
          ));
        }}
      </Query>
    </div>
  );
}

export default ChannelSubChannels;
