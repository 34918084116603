import React from 'react';
import { Column } from 'design-system-web';
import {
  currencyToPaymentCurrency,
  currencyFromJSON,
} from 'lane-shared/domains/billingPayments/types';
import {
  ChargeBillingReport,
  SortBy as gqlSort,
} from 'graphql-query-contracts';
import { dates } from 'lane-shared/helpers/constants';
import {
  simpleCurrencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';

import { SortDirection as gqlSortDirection } from 'graphql-resolver-contracts';

export function getChargeBillingReportColumns(
  t: any,
  charge_month_format: string
): Column<ChargeBillingReport>[] {
  return [
    {
      key: 'payer.id',
      header: t('abp.chargeBillingReports.customer'),
      renderForCSV: (cell: string, row) => {
        return row.payer?.id || cell;
      },
      disableSorting: true,
      disableVisibilityToggle: true,
    },
    {
      key: 'name',
      header: t('abp.chargeBillingReports.name'),
    },
    {
      key: 'description',
      header: t('abp.chargeBillingReports.description'),
      disableSorting: true,
    },
    {
      key: 'chargeCode',
      header: t('abp.chargeBillingReports.chargeCode'),
    },
    {
      key: 'totalAmount',
      header: t('abp.chargeBillingReports.totalAmount'),
      renderCell: (cell: number, row) => (
        <span>
          {simpleCurrencyFormatter(
            cell,
            currencyToPaymentCurrency(currencyFromJSON(row.currency))
          )}
        </span>
      ),
      renderForCSV: (cell: number, row) => {
        return simpleCurrencyFormatter(
          cell,
          currencyToPaymentCurrency(currencyFromJSON(row.currency))
        );
      },
    },
    {
      key: 'createdAt',
      header: t('abp.chargeBillingReports.createdDate'),
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, dates.SIMPLE_DATE)}</span>
      ),
      renderForCSV: (cell: string, _) => {
        return dateFormatter(cell, dates.DATE_ISO_8601);
      },
    },
    {
      key: 'entity.type',
      header: t('abp.chargeBillingReports.entityType'),
      renderCell: (cell: string) => t(`abp.entityType.${cell}`),
      renderForCSV: (cell: string, _) => {
        return cell;
      },
      disableSorting: true,
    },
    {
      key: 'entityDisplayId',
      header: t('abp.chargeBillingReports.entityDisplayId'),
      disableSorting: true,
    },
    {
      key: 'entityDisplayType',
      header: t('abp.chargeBillingReports.entityDisplayType'),
      disableSorting: true,
    },
    {
      key: 'glCode',
      header: t('abp.chargeBillingReports.glCode'),
    },
    {
      key: 'leaseId',
      header: t('abp.chargeBillingReports.leaseId'),
      disableSorting: true,
    },
    {
      key: 'propertyId',
      header: t('abp.chargeBillingReports.propertyId'),
      disableSorting: true,
    },
    {
      key: 'suiteId',
      header: t('abp.chargeBillingReports.suiteId'),
      disableSorting: true,
    },
    {
      key: 'chargeMonth',
      header: t('abp.chargeBillingReports.createdMonth'),
      renderCell: (cell: string) => (
        <span>{dateFormatter(cell, 'MMM 1, yyyy')}</span>
      ),
      renderForCSV: (cell: string, _) => {
        return dateFormatter(cell, charge_month_format);
      },
    },
  ];
}

export function normalizeSort(key: string, direction: string): gqlSort {
  const sort: gqlSort = {
    dir: direction === 'asc' ? gqlSortDirection.Asc : gqlSortDirection.Desc,
    key: normalizeSortKey(key),
  };

  return sort;
}

function normalizeSortKey(key: string): string {
  switch (key) {
    case 'name':
      return 'name';
    case 'chargeCode':
      return 'charge_code';
    case 'glCode':
      return 'gl_code';
    case 'totalAmount':
      return 'amount';
    default:
      return 'created_at';
  }
}
