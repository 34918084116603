/* eslint-disable react/forbid-component-props */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Flex, Input, TextArea } from 'design-system-web';
import { RadioGroup } from 'components';
import { StickyFooter } from 'components/general';
import { EmptyPageView } from 'components/layout';
import { DangerousTranslate } from 'components/DangerousTranslate';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { useLazyQuery } from '@apollo/client';
import {
  createMeterReadingValueMutation,
  updateMeterReadingValueMutation,
  getMeterReadingValuesByMeterReadingIdsQuery,
} from 'graphql-queries';
import { type StepExecution } from 'graphql-query-contracts';

import styles from './StepExecutionForm.scss';
import { PreviousMeterReading } from './PreviousMeterReading';

export interface StepExecutionFormProps {
  channel: any;
  steps: StepExecution[];
  onStepChange: (step: StepExecution) => void;
  disabled?: boolean;
  task?: any;
  scheduleId?: string;
}

export const StepExecutionForm = ({
  channel,
  steps,
  onStepChange,
  disabled = false,
  task,
  scheduleId,
}: StepExecutionFormProps) => {
  const [previousValues, setPreviousValues] = useState<any>([]);
  const [localSteps, setLocalSteps] = useState<StepExecution[]>(steps);
  const [saveText, setSaveText] = useState<string>('');
  const { t } = useTranslation();

  const [fetchMeterReadingValues, { data }] = useLazyQuery(
    getMeterReadingValuesByMeterReadingIdsQuery,
    {
      onCompleted: () => {
        if (
          data?.getMeterReadingValuesByMeterReadingIds?.meterReadingValues &&
          data?.getMeterReadingValuesByMeterReadingIds?.meterReadingValues
            ?.length > 0
        ) {
          const newPreviousValues =
            data?.getMeterReadingValuesByMeterReadingIds?.meterReadingValues ??
            [];

          setPreviousValues(newPreviousValues);
        }
      },
      fetchPolicy: 'network-only',
    }
  );

  const getPreviousValues = () => {
    fetchMeterReadingValues({
      variables: {
        meterReadingIds: steps
          .map(step => step.meterReadingId)
          .filter(
            meterReadingId =>
              meterReadingId !== undefined && meterReadingId !== null
          ),
      },
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
      getPreviousValues();
  }, [steps, localSteps]);

  const getStepHeader = (step: StepExecution) => {
    if (step.type === 'instruction') {
      return t('web.admin.workOrder.steps.headers.instruction');
    }

    if (step.type === 'acknowledge') {
      return t('web.admin.workOrder.steps.headers.acknowledge');
    }

    if (step.type === 'response') {
      if (step.options?.subtype === 'short') {
        return t('web.admin.workOrder.steps.headers.response.short');
      }

      if (step.options?.subtype === 'long') {
        return t('web.admin.workOrder.steps.headers.response.long');
      }

      if (step.options?.subtype === 'numerical') {
        return t('web.admin.workOrder.steps.headers.response.numerical');
      }
    }

    if (step.type === 'choice') {
      if (step.options?.subtype === 'single') {
        return t('web.admin.workOrder.steps.headers.choice.single');
      }

      if (step.options?.subtype === 'multiple') {
        return t('web.admin.workOrder.steps.headers.choice.multi');
      }
    }

    if (step.type === 'meter') {
      return t('web.admin.workOrder.steps.headers.meter');
    }

    return '';
  };

  const handleAcknowledgeChange = (step: StepExecution) => {
    const currentResponse = step?.response?.[0];
    const newResponse = currentResponse === 'true' ? 'false' : 'true';

    onStepChange({ ...step, response: [newResponse] });
    updateSaveText();
  };

  const handleResponseChange = (step: StepExecution, value: string) => {
    onStepChange({ ...step, response: [value] });
    updateSaveText();
  };

  const handleMultiChoiceChange = (step: StepExecution, choice: string | null) => {
    const newResponse = step.response?.includes(choice)
      ? step.response?.filter(res => res !== choice)
      : [...(step.response || []), choice];

    onStepChange({ ...step, response: newResponse });
    updateSaveText();
  };

  const handleSingleChoiceChange = (
    step: StepExecution,
    choice: string
  ) => {
    if (disabled) return;

    onStepChange({ ...step, response: [choice] });
    updateSaveText();
  };

  const handleMeterChange = (step: StepExecution, value: string) => {
    const tempStep = { ...step, response: [value.toString()] };
    const newSteps = localSteps.map(ls => {
      if (ls.id === tempStep.id) return tempStep;

      return ls;
    });

    setLocalSteps(newSteps);
  };

  const saveMeterChange = () => {
    localSteps.forEach(ls => {
      const actualValue = steps.find(s => s.id === ls.id)?.response?.[0];

      if (ls.type === 'meter') {
        if (actualValue !== undefined && actualValue !== null) {
          onStepChange(ls);
          updateMeterReading(ls.meterReadingId, ls, ls.response?.[0]);
        } else {
          onStepChange(ls);
          createMeterReading(ls.meterReadingId, ls, ls.response?.[0]);
        }
      }
    });
    updateSaveText();
    getPreviousValues();
  };

  const getLocalStepResponse = (step: StepExecution) => {
    const localStep = localSteps.find(ls => ls.id === step.id);

    return localStep?.response?.[0];
  };

  const createMeterReading = async (mid: any, step: any, val: any) => {
    if (!val) return;

    const meterReadingValuesToSave: any = {
      meterReadingId: mid,
      sourceType: MeterReadingValueType.TASK,
      value: parseFloat(val),
      notes: '',
      taskId: convertToUUID(task?.id),
      taskUserFriendlyId: task?.userFriendlyID,
      stepExecutionId: step.id,
    };

    await getClient().mutate({
      mutation: createMeterReadingValueMutation,
      variables: {
        createMeterReadingValue: meterReadingValuesToSave,
        channelId: channel?._id || '',
      },
    });

    window.Toast.show(
      t('web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast')
    );
  };

  const updateMeterReading = async (mid: any, step: any, val: any) => {
    if (!val) return;

    const meterReadingValuesToSave: any = {
      id: step.id,
      value: parseFloat(val),
      notes: '',
      sourceType: MeterReadingValueType.TASK,
    };

    await getClient().mutate({
      mutation: updateMeterReadingValueMutation,
      variables: {
        updateMeterReadingValue: meterReadingValuesToSave,
        channelId: channel?._id || '',
      },
    });

    window.Toast.show(
      t('web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast')
    );
  };

  const updateSaveText = () => {
    setSaveText(
      t('web.admin.workOrder.steps.autosave', {
        time: new Date().toLocaleTimeString(),
      })
    );
  };

  return (
    <Flex direction="column">
      {steps.length === 0 && (
        <EmptyPageView
          icon="tools"
          title={t(
            'web.admin.workOrder.preventiveMaintenance.task.emptySteps.title'
          )}
          containerStyle={styles.overrideEmptyPageViewMinHeight}
          message={
            <DangerousTranslate
              translationKey="web.admin.workOrder.preventiveMaintenance.task.emptySteps.subtext"
              values={{}}
            />
          }
          primaryButton={{
            href: routes.channelAdminWorkOrdersPMScheduleEdit
              .replace(':id', channel?.slug)
              .replace(':scheduleId', task?.schedule?.id || scheduleId),
            label: t`web.admin.workOrder.preventiveMaintenance.schedule.details.editSchedule`,
          }}
        />
      )}
      {/* Steps List */}
      {steps.map((step: StepExecution, index) => (
        <Flex key={step.id} direction="row" className={styles.executionStep}>
          <Flex direction="column" className={styles.rowNumber}>
            {index + 1}.
          </Flex>
          <Flex direction="column" className={styles.questionBody}>
            <span className={styles.questionText}>{getStepHeader(step)}</span>
            <span className={styles.questionText}>{step.name}</span>

            {/* Acknowledge Type */}
            {step.type === 'acknowledge' ? (
              <Checkbox
                className={styles.checkbox}
                disabled={disabled}
                selected={!!step.response && step.response[0] === 'true'}
                value={!!step.response && step.response[0] === 'true'}
                onChange={() => handleAcknowledgeChange(step)}
              />
            ) : null}

            {/* Response Type */}
            {step.type === 'response' &&
            step.options?.subtype !== 'numerical' ? (
              <TextArea
                className={styles.textArea}
                disabled={disabled}
                value={step.response?.[0] || ''}
                onChange={res => handleResponseChange(step, res)}
                minRows={step.options!.subtype === 'long' ? 3 : 1}
              />
            ) : null}

            {/* Numerical Response Type */}
            {step.type === 'response' &&
            step.options?.subtype === 'numerical' ? (
              <Flex direction="row" className={styles.numericalResponse}>
                <Input
                  className={styles.numericalInput}
                  disabled={disabled}
                  value={step.response?.[0] || ''}
                  type="number"
                  helperText={t(
                    'web.admin.workOrder.steps.numericalHelperText'
                  )}
                  onChange={res => handleResponseChange(step, res)}
                />
                <span className={styles.numericalUnit}>
                  {step.options?.unit}
                </span>
              </Flex>
            ) : null}

            {/* Multiple Choice Type */}
            {step.type === 'choice' && step.options?.subtype === 'multiple' ? (
              <Flex direction="column">
                {step.options?.choices?.map((choice, choiceIndex) => (
                  <Flex key={choiceIndex} direction="row">
                    <Checkbox
                      className={styles.multiChoiceBox}
                      disabled={disabled}
                      selected={
                        !!step.response && step.response.includes(choice)
                      }
                      value={!!step.response && step.response.includes(choice)}
                      onChange={() => handleMultiChoiceChange(step, choice)}
                    />
                    <span className={styles.multiChoiceText}>{choice}</span>
                  </Flex>
                ))}
              </Flex>
            ) : null}

            {/* Single Choice Type */}
            {step.type === 'choice' && step.options?.subtype === 'single' ? (
              <Flex direction="column">
                <RadioGroup
                  items={
                    step.options?.choices?.map(choice => ({
                      id: choice,
                      text: choice,
                    })) || []
                  }
                  onChange={res => handleSingleChoiceChange(step, res)}
                  selected={step.response?.[0] || null}
                  doTranslate={false}
                />
              </Flex>
            ) : null}

            {/* Meter Type */}
            {step.type === 'meter' ? (
              <Flex direction="column">
                <span className={styles.meterDesc}>
                  {step.options?.description}
                </span>

                <Flex direction="row" className={styles.meterBody}>
                  <Input
                    className={styles.meterInput}
                    disabled={disabled}
                    value={getLocalStepResponse(step) || ''}
                    fixedLabel
                    label="Value"
                    onChange={res => handleMeterChange(step, res)}
                  />
                  <span className={styles.meterUnit}>{step.options?.unit}</span>
                </Flex>
                <PreviousMeterReading step={step} previousValues={previousValues} />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      ))}

      {/* Footer */}
      {!disabled && steps.length !== 0 && (
        <>
          <div className={styles.footerPadding} />
          <StickyFooter
            submitText="Save"
            cancelText="Cancel"
            detailText={saveText}
            handleSubmit={saveMeterChange}
            handleCancel={() => {}}
            enableSubmit
          />
        </>
      )}
    </Flex>
  );
};
