import { useQuery } from '@apollo/client';
import Dropdown from 'components/form/Dropdown';
import Input from 'components/form/Input';
import { ToggleView } from 'components/general';
import type { ChannelIntegrationEditorProps } from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import { Alert, AlertType } from 'components/lds';
import { Tooltip } from 'design-system-web/components/Tooltip/Tooltip';
import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
  AccessControlServiceDefinitions,
  AccessControlServiceEntity,
  IntegrationPropertyType,
} from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import { useFlag } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rightArrow from '../../../../static/img/right-arrow.svg';
import styles from './TenantMapping.scss';

type PropertyProps = {
  property: IntegrationPropertyType;
  value: any;
  label?: string;
  onChange: (value: any) => void;
  keys?: string[];
  channel: ChannelType;
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  metadataLoading?: boolean;
  forCreate?: boolean;
  tenantKey: 'geneaTenantId' | 'acsTenantId';
  acsProviderValue: string;
};

type BuildingTenant = {
  label: string;
  value: any;
};

const integrationWithACG: string[] = ['GENEA_2_0'];

export const TenantMapping = ({
  property,
  label,
  onChange,
  channel,
  settings,
  metadataLoading,
  tenantKey,
  acsProviderValue,
}: PropertyProps) => {
  const {
    data: buildingTenants,
  }: {
    data?: { getBuildingTenantChannels: any };
  } = useQuery(getBuildingTenantChannels, {
    variables: { channelId: convertToUUID(channel._id) },
  });

  const { t } = useTranslation();

  const accessGroupIDsDetails =
    AccessControlServiceDefinitions[
      acsProviderValue as AccessControlServiceEntity
    ]?.visitorAccessGroupMappings?.accessGroupIDs;

  const [buildingTenantList, setBuildingTenantList] = useState<any>([]);
  const [IgsTenantlist, setIgsTenantList] = useState([]);

  const acgEnhancementEnabled = useFlag(
    FeatureFlag.TenantConfigurationEnhancements,
    false
  );

  useEffect(() => {
    if (!metadataLoading) {
      setIgsTenantList(property?.[tenantKey]);
    }
  }, [metadataLoading, settings.locationUuid, property?.[tenantKey]]);

  function getVtsTenantACGMapping(IGSValue: string) {
    const vtsTenantMapACGs = settings?.visitorAccessGroupMappings?.reduce(
      (accumulator: string[], visitorAccessGroupMapping: any) => {
        if (visitorAccessGroupMapping?.geneaTenantIDs?.includes(IGSValue)) {
          accumulator.push(...visitorAccessGroupMapping.accessGroupIDs);
        }

        return accumulator;
      },
      []
    );

    return vtsTenantMapACGs;
  }

  useMemo(() => {
    if (buildingTenants?.getBuildingTenantChannels && !metadataLoading) {
      const buildingTenantList = buildingTenants?.getBuildingTenantChannels
        ?.map((location: { channelId: string; name: string }) => {
          const IGSValue =
            settings?.tenantMappings?.find(
              (item: { vtsTenantId: string }) =>
                convertToUUID(item.vtsTenantId) ===
                convertToUUID(location.channelId)
            )?.[tenantKey] || '';
          const vtsTenantMapACGs = getVtsTenantACGMapping(IGSValue);
          const vtsTenantMapACGDetails =
            accessGroupIDsDetails?.filter(
              (acgDetail: { label: string; value: string }) => {
                return vtsTenantMapACGs?.includes(acgDetail?.value);
              }
            ) || [];

          return {
            label: `${location.name}`,
            value: convertToUUID(location.channelId),
            IGSValue,
            mappedTenantACGDetails: [...vtsTenantMapACGDetails],
          };
        })
        .sort((a: BuildingTenant, b: BuildingTenant) =>
          acgEnhancementEnabled ? a.label.localeCompare(b.label) : 0
        );

      setBuildingTenantList(buildingTenantList);
    }
  }, [
    metadataLoading,
    property?.[tenantKey],
    settings?.tenantMappings,
    settings?.visitorAccessGroupMappings,
    buildingTenants,
  ]);

  const handleChange = (
    nextLaneTenant: {
      label?: string;
      value: any;
      isDisabled?: boolean | undefined;
    },
    id: number
  ) => {
    if (buildingTenantList[id]?.IGSValue !== nextLaneTenant?.value) {
      const updatedTenantList = [...buildingTenantList];
      const prevIgsValue = buildingTenantList[id]?.IGSValue;

      updatedTenantList[id].IGSValue = nextLaneTenant?.value;
      setBuildingTenantList(updatedTenantList);

      const TenantPayload = updatedTenantList
        ?.filter(item => item?.IGSValue)
        .map(item => {
          if (acgEnhancementEnabled) {
            return {
              vtsTenantId: item?.value,
              [tenantKey]: item.IGSValue,
              prevIgsValue,
            };
          }

          return {
            vtsTenantId: item?.value,
            [tenantKey]: item.IGSValue,
          };
        });

      onChange(TenantPayload);
    }
  };

  const renderCollapseView = () => {
    return (
      <div>
        <div className={styles.parentDescription}>
          Map this property’s tenants to tenant records in the Access Control
          System to accurately enable visitors credentials.
        </div>
        <div className={styles.count}>
          {buildingTenantList?.filter(
            (item: { IGSValue: string }) => item?.IGSValue
          ).length || 0}{' '}
          Tenants mapped
        </div>
      </div>
    );
  };

  const [unmappedTenantsACGExist, setUnmappedTenantsACGExist] = useState(false);

  useMemo(() => {
    if (
      integrationWithACG.includes(acsProviderValue) &&
      acgEnhancementEnabled
    ) {
      setUnmappedTenantsACGExist(
        !buildingTenantList?.every(
          (item: { mappedTenantACGDetails: any }) =>
            item?.mappedTenantACGDetails?.length > 0
        )
      );
    }
  }, [buildingTenantList]);

  return (
    <div className={styles.tenantMapping}>
      <div className={styles.mappingBox}>
        <ToggleView
          testId="TenantMapping"
          title={<div className={styles.parentTitle}>{label}</div>}
          collapsedViewContent={renderCollapseView()}
          defaultExpanded
          className={styles.customToggel}
        >
          <div className={styles.parentDescription}>
            {t(
              'web.admin.channel.integrations.access.access-control-settings.tenant-mapping-subtitle'
            )}
          </div>
          {unmappedTenantsACGExist && acgEnhancementEnabled && (
            <Alert type={AlertType.warning} fullWidth>
              {t(
                'web.admin.channel.integrations.access.access-control-settings.unmapped-tenant-exists.warn'
              )}
            </Alert>
          )}
          {buildingTenantList?.map(
            (
              item: {
                value: string;
                IGSValue: any;
                label: string;
                mappedTenantACGDetails: any;
              },
              index: number
            ) => {
              return (
                <div className={styles.flexItem} key={index}>
                  <div className={styles.mappedContent}>
                    <div className={styles.dropdownLabel}>
                      Map this tenant...
                    </div>
                    {acgEnhancementEnabled &&
                    item?.mappedTenantACGDetails?.length > 0 ? (
                      <Tooltip
                        TooltipComponent={
                          <TooltipMessage
                            mappedTenantACGDetails={
                              item?.mappedTenantACGDetails
                            }
                          />
                        }
                        placement="bottom"
                      >
                        <Input
                          disabled
                          value={item?.label}
                          onChange={() => null}
                          showClear={false}
                        />
                      </Tooltip>
                    ) : (
                      <Input
                        disabled
                        showWarning={
                          acgEnhancementEnabled &&
                          integrationWithACG.includes(acsProviderValue)
                        }
                        value={item?.label}
                        onChange={() => null}
                        showClear={false}
                      />
                    )}
                  </div>
                  <img
                    src={rightArrow as string}
                    alt="rightArrow"
                    className={styles.rightArrow}
                  />
                  <div className={styles.mappedContent}>
                    <div className={styles.dropdownLabel}>with this one</div>
                    <Dropdown
                      items={IgsTenantlist}
                      value={item?.IGSValue || ''}
                      onChange={value => handleChange(value, index)}
                      invalid={
                        acgEnhancementEnabled &&
                        item?.IGSValue?.trim()?.length === 0
                      }
                      errors={
                        acgEnhancementEnabled &&
                        item?.IGSValue?.trim()?.length === 0
                          ? [
                              t(
                                'web.admin.channel.integrations.access.access-control-settings.missing-mapping'
                              ),
                            ]
                          : []
                      }
                      isFullWidth
                      placeholder="Not mapped"
                      testId={`igs-tenant-${index}`}
                    />
                  </div>
                </div>
              );
            }
          )}
        </ToggleView>
      </div>
    </div>
  );
};

const TooltipMessage = (props: any) => {
  const { t } = useTranslation();
  const message = useMemo(() => {
    let acgsNames = '';

    props?.mappedTenantACGDetails?.forEach((acg: { label: string }) => {
      acgsNames += `${acg?.label}, `;
    });
    acgsNames = acgsNames.slice(0, -2);

    return acgsNames;
  }, [props?.mappedTenantACGDetails]);

  return (
    <>
      {t(
        'web.admin.channel.integrations.access.access-control-settings.tenant-mapping-tooltip',
        {
          count: props?.mappedTenantACGDetails?.length,
          message,
        }
      )}
    </>
  );
};
