import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'design-system-web';
import { useQuery } from '@apollo/client';
import { contentsForIntegration } from 'lane-shared/graphql/content';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { Content } from 'graphql-query-contracts';

export function DeleteIntegrationModal({
  isLoading,
  channelIntegrationName,
  isModalOpen,
  setIsModalOpen,
  onDeleteIntegration,
  channelIntegration,
}: {
  isLoading: boolean;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  channelIntegrationName: string;
  onDeleteIntegration: () => void;
  channelIntegration: any;
}): JSX.Element | null {
  const { t } = useTranslation();
  const { data: integrationContent } = useQuery<{
    contentsForIntegration: Content[];
  }>(contentsForIntegration, {
    variables: {
      _id: safeConvertToUUID(channelIntegration?._id),
    },
    skip: !channelIntegration?._id,
  });

  const count = integrationContent?.contentsForIntegration.length || 0;
  let modalContent;

  if (count < 1) {
    modalContent = t('web.admin.channel.integrations.modal.no-related-content');
  } else if (count === 1) {
    modalContent = t(
      'web.admin.channel.integrations.modal.single-related-content'
    );
  } else {
    modalContent = t('web.admin.channel.integrations.modal.related-content', {
      count,
    });
  }

  return (
    <Modal
      size="large"
      isOpen={isModalOpen}
      title={`${t(
        'web.admin.channel.integrations.modal.deactivate'
      )} ${channelIntegrationName}?`}
      onClose={() => {
        setIsModalOpen(false);
      }}
      actions={
        <>
          <Button
            size="large"
            loading={isLoading}
            variant="primary"
            onClick={() => {
              onDeleteIntegration();
              setIsModalOpen(false);
            }}
          >
            {t`Yes`}
          </Button>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            {t`No`}
          </Button>
        </>
      }
    >
      {modalContent}
    </Modal>
  );
}
