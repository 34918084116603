import { gql } from '@apollo/client';

export const listTransactionsQuery = gql`
  query listTransactions($listTransactionsRequest: ListTransactionsRequest!) {
    accounts {
      listTransactions(listTransactionsRequest: $listTransactionsRequest) {
        transactions {
          transactionId
          paidBy
          productName
          paymentType
          amount
          currency
          createdAt
          updatedAt
          status
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;
