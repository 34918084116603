import React from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { PropertyType } from 'lane-shared/types/properties/Property';

import { Button, Flex } from 'lane-web/src/components';

import { DEFAULT_INPUT } from '../constants';
import { CustomPropertyType } from '../types';
import CollapsedInput from './inputs/CollapsedInput';
import CustomInput from './inputs/CustomInput';

import styles from './CustomData.scss';

type Props = {
  customInputs: CustomPropertyType[];
  onCustomInputsChange: (fields: PropertyType[]) => void;
  onCustomDataSave?: (field: CustomPropertyType) => void;
  onCustomDataDelete: (field: CustomPropertyType) => void;
  disableSave?: boolean;
};

export default function CustomData({
  customInputs,
  onCustomInputsChange,
  onCustomDataSave,
  onCustomDataDelete,
  disableSave,
}: Props) {
  const { t } = useTranslation();

  function addInput(): void {
    const newInput: CustomPropertyType = {
      ...DEFAULT_INPUT,
      _id: uuid(),
      name: `customInput_${customInputs.length}`,
      friendlyName: `custom input ${customInputs.length}`,
    };

    onCustomInputsChange([...customInputs, newInput]);
  }

  function onInputChange(input: CustomPropertyType): void {
    const inputs = [...customInputs];
    const inputIndex = inputs.findIndex(i => input._id === i._id);

    inputs[inputIndex] = input;
    onCustomInputsChange(inputs);
  }

  function onEditInput(input: CustomPropertyType): void {
    const inputs = [...customInputs];
    const inputIndex = inputs.findIndex(i => input._id === i._id);

    // @ts-ignore
    inputs[inputIndex].isEditing = true;
    onCustomInputsChange(inputs);
  }

  return (
    <div>
      <Flex direction="column">
        {customInputs.map(input => (
          <div className={styles.customInputContainer} key={input._id}>
            {input.isEditing ? (
              <CustomInput
                input={input}
                onChange={onInputChange}
                onSave={input =>
                  onCustomDataSave &&
                  onCustomDataSave({ ...input, isEditing: false })
                }
                onDelete={onCustomDataDelete}
                disableSave={disableSave}
              />
            ) : (
              <CollapsedInput field={input} onEdit={onEditInput} />
            )}
          </div>
        ))}
      </Flex>
      <Button
        startIcon={<Icon name="plus" />}
        onClick={addInput}
        className={styles.addInputButton}
        testId="add-custom-question"
      >
        {t`web.admin.serviceRequest.customData.button.text`}
      </Button>
    </div>
  );
}
