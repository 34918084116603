import React from 'react';

import { Icon, TextButton } from 'design-system-web';
import { Button, FileInput } from 'components';
import { makeFileDownload } from 'helpers';
import { useTranslation } from 'react-i18next';

import {
  ImporterEntityTypes,
  ImporterEntityTypesGetFriendlyName,
} from 'lane-shared/domains/importer/jobDefinition';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { AttachmentResponse } from 'lane-shared/types/attachment';
import { CSVImportProps } from 'lane-shared/types/integrations/FileImport';

import { Flex } from 'components/layout';
import { H5, S } from 'components/typography';

import { FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from './styles.scss';

export const FileUpload = ({
  title,
  description,
  attachmentFiles,
  setAttachmentFile,
  entityType,
}: {
  title: string;
  description: string;
  attachmentFiles: AttachmentResponse[];
  setAttachmentFile: (attachmentFiles: any[]) => void;
  entityType: ImporterEntityTypes;
}) => {
  const { t } = useTranslation();

  const addAttachment = async (file: any) => {
    const attachmentsToAdd = {
      file,
      thumbnailSignedUrl: URL.createObjectURL(file),
    };
    const newAttachmentFiles = [attachmentsToAdd];

    setAttachmentFile(newAttachmentFiles);
  };

  const deleteAttachment = async (index: number) => {
    const newAttachmentFile = [...attachmentFiles];

    newAttachmentFile?.splice(index, 1);
    setAttachmentFile(newAttachmentFile);
  };

  const downloadAttachment = async (index: number) => {
    const element = document.createElement('a');

    element.href = URL.createObjectURL(attachmentFiles[index].file as Blob);
    element.click();
  };

  const handleDownloadSampleCSV = () => {
    makeFileDownload({
      name: `sample-${t(ImporterEntityTypesGetFriendlyName(entityType))}.csv`,
      contents: CSVImportProps[entityType],
      type: 'application/csv',
    });
  };

  return (
    <Flex className={styles.adminPage} gap={4}>
      <Flex direction="column" gap={2}>
        <H5>{t(title)}</H5>
        <S variant="secondary">{t(description)}</S>
      </Flex>
      {!attachmentFiles ||
        (attachmentFiles?.length === 0 && (
          <Flex direction="column" className={styles.attachments} gap={1}>
            <Flex direction="row" className={styles.fileInput} gap={4}>
              <FileInput
                accept="*/*"
                type={FileReturnTypeEnum.File}
                onFileSelected={addAttachment}
              >
                <Button size="small" variant="contained">
                  {t('web.admin.importer.workorders.upload.csv')}
                </Button>
              </FileInput>
              <TextButton variant="secondary" onClick={handleDownloadSampleCSV}>
                <S className={styles.buttonAttachmentsLabel}>
                  {t('web.admin.importer.workorders.download.sample.csv')}
                </S>
              </TextButton>
            </Flex>
          </Flex>
        ))}
      {attachmentFiles?.map((attachment, index) => {
        const attachmentObj = attachment as any;

        return (
          <Flex
            direction="row"
            key={index}
            className={styles.attachmentName}
            gap={4}
            justify="space-between"
          >
            <H5>{attachmentObj.file.name}</H5>
            <Flex direction="row">
              <div
                data-test="downloadAttachment"
                role="button"
                className={styles.deleteIcon}
                onClick={() => downloadAttachment(index)}
                tabIndex={0}
              >
                <Icon
                  className={styles.emptyImageIcon}
                  set={ICON_SET_FONTAWESOME}
                  name="download"
                  type="far"
                />
              </div>
              <div
                data-test="deleteAttachment"
                role="button"
                className={styles.deleteIcon}
                onClick={() => deleteAttachment(index)}
                tabIndex={0}
              >
                <Icon
                  className={styles.emptyImageIcon}
                  set={ICON_SET_FONTAWESOME}
                  name="trash-alt"
                  type="far"
                />
              </div>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
