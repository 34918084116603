import React, { useEffect, useState } from 'react';

import { Flex, Label } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { ChipSelect, ChipStyle } from 'lane-web/src/components/ads';

import { updatePMTaskStatusMutation } from 'graphql-queries';
import { ClientTask } from 'graphql-query-contracts';
import { TaskStatusEnum } from '../../types/taskStatus';

import { Size } from 'components/ads/chipSelect/ChipSelect';

import styles from './PMTaskStatusDropdown.scss';
import { getTaskStatusLabel } from 'lane-shared/domains/workOrder/helpers';

const chipListColor: { [key: string]: ChipStyle } = {
  [TaskStatusEnum.InProgress]: ChipStyle.Blue,
  [TaskStatusEnum.Created]: ChipStyle.Green,
  [TaskStatusEnum.Completed]: ChipStyle.Grey,
  [TaskStatusEnum.OnHold]: ChipStyle.Yellow,
  [TaskStatusEnum.Withdrawn]: ChipStyle.Grey,
};

type Props = {
  taskData: ClientTask;
  isTaskList: boolean;
  refetch?: () => void;
  pillSize?: Size;
  disableEdit?: boolean;
};

const getChipType = (status: string) => chipListColor[status] || ChipStyle.Red;

export function PMTaskStatusDropdown({
  taskData,
  isTaskList,
  refetch,
  pillSize,
  disableEdit = false,
}: Props) {
  const { t } = useTranslation();
  const [TaskStatus, setTaskStatus] = useState({
    label: getTaskStatusLabel(taskData?.status),
    value: taskData?.status ?? '',
    type: getChipType(taskData?.status!),
  });
  const [statusOptions, setStatusOptions] = useState<
    {
      label: string;
      value: string;
      type: ChipStyle;
    }[]
  >([]);

  useEffect(() => {
    if (taskData?.nextStatuses) {
      const availableStatuses =
        taskData?.nextStatuses.map((singleStatus: string | null) => ({
          label: getTaskStatusLabel(singleStatus),
          value: singleStatus as string,
          type: getChipType(singleStatus as string),
        })) || [];

      availableStatuses.push(TaskStatus);
      setStatusOptions(availableStatuses);
    }
  }, [TaskStatus, taskData?.nextStatuses]);

  useEffect(() => {
    if (taskData?.status) {
      setTaskStatus({
        label: getTaskStatusLabel(taskData?.status),
        value: taskData?.status,
        type: getChipType(taskData?.status),
      });
    }
  }, [taskData?.status]);

  const updateTaskStatus = async (value: string) => {
    try {
      await getClient().mutate({
        mutation: updatePMTaskStatusMutation,
        variables: {
          task: {
            id: convertToUUID(taskData?.id),
            status: value,
          },
        },
      });
      setTaskStatus({
        label: getTaskStatusLabel(value),
        value,
        type: getChipType(value),
      });

      if (refetch) {
        await refetch();
      }

      await window.Toast.show(
        t(
          'web.admin.workOrder.preventiveMaintenance.task.status.update.success'
        )
      );
    } catch (err) {
      await window.Alert.alert({
        title: t(
          'web.admin.workOrder.preventiveMaintenance.task.status.update.failure'
        ),
        error: err,
      });
    }
  };

  return (
    <div className={styles.chipSelectContainer}>
      {!isTaskList && (
        <Label mt={0}>
          {t('web.admin.workOrder.preventiveMaintenance.task.status')}{' '}
        </Label>
      )}
      <Flex className={styles.statusDropdown}>
        <ChipSelect
          testId={`statusSelect-${taskData?.id}`}
          anchorClassName={styles.anchor}
          value={TaskStatus.value}
          list={statusOptions}
          onChange={updateTaskStatus}
          size={pillSize}
          isDisabled={disableEdit}
        />
      </Flex>
    </div>
  );
}
