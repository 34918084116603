import { ImporterEntityTypes } from 'lane-shared/domains/importer/jobDefinition';

export const IMPORT_SHEETS = [
  'PM Schedules list',
  'Equipment list',
  'ServiceRequestImports',
  'TasksImports',
];

export const IMPORT_TYPE_MAPPING: { [key: string]: ImporterEntityTypes } = {
  'PM Schedules list': ImporterEntityTypes.Schedule,
  'Equipment list': ImporterEntityTypes.Equipment,
  'ServiceRequestImports': ImporterEntityTypes.ServiceRequest,
  'TasksImports': ImporterEntityTypes.Task,
};

export const HYPERCARE_TO_SCHEDULE_MAPPING: { [key: string]: string } = {
  'Schedule ID': 'schedule_id',
  'Schedule title': 'title',
  'Notes': 'notes',
  'Assignee': 'assignee',
  'Estimated time to complete (hrs)': 'time_to_complete',
  'Repeats': 'repeats',
  'Number of days in advance the task is created': 'days_ahead',
  'First due date': 'next_due_date',
  'End date': 'until_date',
  'Location': 'location',
  'Steps': 'steps',
  'Meter reading': 'meter_reading',
  'Created By': 'created_by',
  'Created At': 'created_at',
  'Updated By': 'updated_by',
  'Updated At': 'updated_at',
};

export const HYPERCARE_TO_EQUIPMENT_MAPPING: { [key: string]: string } = {
  'Equipment ID': 'equipment_id',
  'Equipment name': 'name',
  'Category': 'category',
  'Status': 'status',
  'Location': 'location',
  'Floor': 'floor',
  'Suite (tenant space)': 'suite',
  'Make': 'make',
  'Model': 'model',
  'Asset #': 'asset',
  'Serial #': 'serial',
  'Warranty expiration date': 'warranty_expiration_date',
  'Install date': 'install_date',
  'Additional notes': 'notes',
  'Created By': 'created_by',
  'Created At': 'created_at',
  'Updated By': 'updated_by',
  'Updated At': 'updated_at',
};

export const HYPERCARE_TO_IMPORT_MAPPING: { [key: string]: any } = {
  'PM Schedules list': HYPERCARE_TO_SCHEDULE_MAPPING,
  'Equipment list': HYPERCARE_TO_EQUIPMENT_MAPPING,
  'ServiceRequestImports': {},
  'TasksImports': {},
};
