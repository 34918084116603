import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { ErrorMessage } from 'components';
import { useChannelAdminContext } from 'hooks';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { pause } from 'lane-shared/helpers';
import WebhookTypeDefinition, {
  WebhookType,
} from 'lane-shared/helpers/webhooks/WebhookType';

import Property from 'components/builder/properties/input/Property';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import createWebhookMutation from 'components/webhooks/createWebhookMutation';

import history from 'helpers/history';

import styles from './WebhookCreate.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default function WebhookCreate({ className, style }: Props) {
  const [webhook, setWebhook] = useState<Partial<WebhookType>>({});
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { user } = useContext(UserDataContext);
  const { channel } = useChannelAdminContext();

  function updateWebhook(update: Partial<WebhookType>) {
    setWebhook(prevState => ({ ...prevState, ...update }));
  }

  async function createWebhook() {
    const validator = WebhookTypeDefinition.buildSchema();

    // set the channel
    const webhookData = {
      ...webhook,
      channel: {
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        _id: channel._id,
      },
    };

    try {
      await validator.validate(webhookData, { abortEarly: false });
      setValidation(null);
    } catch (err) {
      setValidation(err);

      return;
    }

    try {
      setLoading(true);
      setError(null);

      await pause();
      const { data } = await getClient().mutate({
        mutation: createWebhookMutation,
        variables: {
          libraryItem: {
            channel: {
              _id: channel?._id,
            },
            webhook: webhookData,
          },
        },
      });

      window.Toast.show('Webhook created!');
      history.push(`${data.createWebhook.webhook._id}/edit`);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={cx(styles.WebhookCreate, className)} style={style}>
      <ControlMenu>
        <hr />
        <Button loading={loading} variant="contained" onClick={createWebhook}>
          Create
        </Button>
      </ControlMenu>
      <ErrorMessage error={error} />
      <ErrorMessage error={validation} />
      {Object.entries(WebhookTypeDefinition.properties).map(([key, property]) =>
        property.hidden ? null : (
          <div key={key} className={styles.property}>
            <Property
              object={webhook}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
              channel={channel}
              label={property.friendlyName || property.name || key}
              user={user}
              property={property}
              propertyKey={key}
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={webhook[key]}
              onChange={value => updateWebhook({ [key]: value })}
              onPropertyChange={({ key, value }) =>
                updateWebhook({ [key]: value })
              }
            />
          </div>
        )
      )}
    </div>
  );
}
