import React from 'react';

import { routes } from 'lane-shared/config';

import MagicLink from 'components/authV2/MagicLink';

import WelcomeTemplate from 'pages/user/WelcomeTemplate';

import styles from './MagicLinkPage.scss';

const SLIDE = {
  title: 'Tap into your workplace',
  subTitle:
    'Access your building, book rooms, discover amenities, and join our community.',
};

export default function MagicLinkPage({ history }: any) {
  function navigateToLogin() {
    history.push(routes.login);
  }

  function navigateToManualLogin() {
    history.push(routes.manualLogin);
  }

  return (
    <WelcomeTemplate portalSize="small" slide={SLIDE}>
      <div className={styles.magicLinkPage}>
        <MagicLink
          magicLinkSent={history?.location?.state?.submitted}
          navigateBack={navigateToLogin}
          navigateToManualLogin={navigateToManualLogin}
        />
      </div>
    </WelcomeTemplate>
  );
}
