import React, { useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useMutation, isApolloError } from '@apollo/client';

import { deleteChannelRelationship } from 'lane-shared/graphql/mutation';

import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';

export function useRemoveTenantRelationship() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [deleteMutation] = useMutation(deleteChannelRelationship);

  const removeTenant = async ({
    relationshipId,
    tenantName,
  }: {
    relationshipId: string;
    tenantName: string;
  }): Promise<boolean> => {
    try {
      await window.Alert.confirm({
        title: t('shared.admin.channel.relationships.removeModal.title'),
        message: (
          <Trans
            i18nKey="shared.admin.channel.relationships.removeModal.message"
            values={{ tenant: tenantName }}
          />
        ),
        confirmText: t(
          'shared.admin.channel.relationships.removeModal.confirmText'
        ),
        cancelText: t(
          'shared.admin.channel.relationships.removeModal.cancelText'
        ),
      });

      setIsLoading(true);

      await deleteMutation({
        variables: {
          id: relationshipId,
        },
        refetchQueries: [queryChannelsByRelationship],
      });

      window.Toast.show(
        t('shared.admin.channel.relationships.removeModal.success')
      );

      return true;
    } catch (err) {
      if (isApolloError(err)) {
        const errorMessage = t(
          'shared.admin.channel.relationships.removeModal.error'
        );

        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    removeTenant,
    isLoading,
  };
}
