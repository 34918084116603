import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { AppContext } from 'lane-shared/contexts';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { validateEmail } from 'lane-shared/validation';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import { H4, S } from 'components/typography';

import styles from './EmailForm.scss';

type Props = {
  isLoading: boolean;
  onSubmit: (email: string) => void;
  error: {
    isInviteOnly: boolean;
  };
};

function EmailForm({ isLoading, onSubmit, error: { isInviteOnly } }: Props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<null | string>();
  const { t } = useTranslation();

  const { whitelabel } = useContext(AppContext);
  const {
    translationKeys: { emailLabel, connectionTitleLabel },
  } = useContinueAsGuest();

  const TRANSLATION_KEYS = {
    title: connectionTitleLabel,
    subtitle: 'web.login.loginEmail.subtitle',
    label: emailLabel,
    button: 'web.login.loginEmail.button',
    inviteOnlyError: 'web.login.loginEmail.error.inviteOnly',
  };

  useEffect(() => {
    if (isInviteOnly) {
      const message = t(TRANSLATION_KEYS.inviteOnlyError);

      setError(message);
    }
  }, [t, isInviteOnly]);

  function handleChange(value: string) {
    if (error) {
      setError(null);
    }

    setEmail(value);
  }

  function validateEmailInput() {
    try {
      const trimmedEmail = email.trim();

      validateEmail.validateSync({ email: trimmedEmail });
    } catch (err) {
      if (err instanceof ValidationError) {
        setError(err.message);
      }
    }
  }

  function onEnterKeyPress() {
    try {
      const trimmedEmail = email.trim();

      validateEmail.validateSync({ email: trimmedEmail });
      onSubmit(trimmedEmail);
    } catch (err) {
      if (err instanceof ValidationError) {
        setError(err.message);
      }
    }
  }

  function handleSubmit() {
    const trimmedEmail = email.trim();

    onSubmit(trimmedEmail);
  }

  return (
    <div>
      <H4 className={styles.title} mb={4}>
        {t(TRANSLATION_KEYS.title, { whiteLabelName: whitelabel.name })}
      </H4>
      <S>{t(TRANSLATION_KEYS.subtitle)}</S>
      <div className={styles.formContainer}>
        <Input
          onBlur={() => validateEmailInput()}
          onChange={handleChange}
          onFocus={() => setError(null)}
          onSubmit={onEnterKeyPress}
          value={email}
          showClear={false}
          label={t(TRANSLATION_KEYS.label)}
          fieldName="email"
          error={error ? [error] : null}
        />
        <Button
          color="inherit"
          disabled={!email || !!error}
          loading={isLoading}
          onClick={handleSubmit}
          size="large"
          type="button"
          variant="contained"
          testId="button-continue"
        >
          {t(TRANSLATION_KEYS.button)}
        </Button>
      </div>
    </div>
  );
}

export default EmailForm;
