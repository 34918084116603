import localization from 'localization';
import { TaskStatusEnum } from '../constants';

const i18n = localization.cloneInstance();

/**
 * Gets the correct status label for tasks
 * @param status
 * @returns
 */
export function getTaskStatusLabel(status?: string | null) {
  if (!status) return '';

  if (
    !Object.values(TaskStatusEnum).includes(
      status as TaskStatusEnum
    )
  )
    return status;

  const statusKey = status.replace(/\s/g, '').toLowerCase();

  return i18n.t(`shared.workOrders.tasks.status.${statusKey}`);
}
