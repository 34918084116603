import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { useOnboardUser } from 'lane-shared/hooks/onboarding/useOnboardUser';
import routes from 'lane-shared/config/routes';
import { useUserDataContext } from 'lane-shared/hooks';
import { AppContext } from 'lane-shared/contexts';

import {
  Onboarding,
  UserOnboardingData,
} from '../../components/onboarding/Onboarding';
import { isChannelFiltersError, isUnverifiedEmailError } from './utils';

const TRANSLATION_KEYS = {
  serverError: 'shared.onboarding.onboard.error',
  verifyEmailError: 'shared.onboarding.onboard.verifyEmail.error',
  channelFiltersError: 'shared.onboarding.signUp.channelFiltersError',
};

export const OnboardingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { transition } = useContext(AppContext);
  const { user, refetch: refetchUser } = useUserDataContext();
  const { onboardUser } = useOnboardUser(user?._id);

  const handleOnOnboarding = async (data: UserOnboardingData) => {
    await onboardUser(data);
    await refetchUser();

    transition();

    const query = qs.parse(history?.location?.search.substring(1));

    if (query.contentPath) {
      history.push(`${query.contentPath}`);
    } else {
      history.push(routes.home);
    }
  };

  const handleOnboardingError = async (error: Error) => {
    if (isUnverifiedEmailError(error)) {
      return t(TRANSLATION_KEYS.verifyEmailError);
    }

    if (isChannelFiltersError(error)) {
      return t(TRANSLATION_KEYS.channelFiltersError);
    }

    return t(TRANSLATION_KEYS.serverError);
  };

  return (
    <Onboarding
      onOnboarding={handleOnOnboarding}
      onOnboardingError={handleOnboardingError}
    />
  );
};
