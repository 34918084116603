import React, { ReactNode, useContext } from 'react';

import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useFlag } from 'lane-shared/hooks';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import qs from 'qs';

import { AppContext } from 'lane-shared/contexts';
import { useOnboardingContext } from 'lane-shared/contexts/OnboardingContext/useOnboardingContext';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';

import { OnboardingBuildingLegacy } from './OnboardingBuildingLegacy';
import { OnboardingBuilding } from './OnboardingBuilding';
import { OnboardingCompanyLegacy } from './OnboardingCompanyLegacy';
import { OnboardingCompany } from './OnboardingCompany';
import { OnboardingProfile } from './OnboardingProfile';
import { OnboardingWithInvite } from './OnboardingWithInvite';

const renderOnboardingBuildingOrInvite = ({
  OnboardingBuilding,
  OnboardingWithInvite,
  Redirect,
}: {
  OnboardingBuilding?: ReactNode;
  OnboardingWithInvite?: ReactNode;
  Redirect: ReactNode;
}) => {
  if (OnboardingWithInvite) {
    return OnboardingWithInvite;
  }

  if (OnboardingBuilding) {
    return OnboardingBuilding;
  }

  return Redirect;
};

export const OnboardingSteps = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { whitelabel } = useContext(AppContext);
  const simpleTrack = useSimpleTrack();

  const enhancedOnboardingFeatureFlag = useFlag(
    FeatureFlag.EnhancedOnboardingChannelSearch,
    false
  );

  const {
    data: { building, company, profile },
    errors,
    onboardingState,
    handlers,
  } = useOnboardingContext();

  const handleProfileContinue = (data: {
    fullName: string;
    marketingOptIn: boolean;
  }) => {
    simpleTrack('onboarding.profile.continue', { optIn: data.marketingOptIn });
    handlers.updateProfile(data);

    const url = building ? `${path}/building` : `${path}/company`;

    const query = qs.parse(history?.location?.search.substring(1));

    history.push({
      pathname: url,
      search: qs.stringify({ contentPath: query.contentPath }),
    });
  };

  const handleBuildingComplete = (building: ChannelType, fullName: string) => {
    simpleTrack('onboarding.building.createAccount', {
      buildingId: building._id,
    });

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleCompanyComplete = (
    company: ChannelType,
    building: ChannelType,
    fullName: string
  ) => {
    simpleTrack('onboarding.building.createAccount', {
      buildingId: building._id,
      companyId: company._id,
    });

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
      company,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleBuildingInviteComplete = (
    building: ChannelType,
    fullName: string,
    company?: ChannelType
  ) => {
    simpleTrack('onboarding.building.createAccount', {
      buildingId: building._id,
      companyId: company?._id,
    });

    const accountData = {
      fullName,
      marketingOptIn: profile.isMarketingOptInSelected,
      building,
      company,
    };

    handlers.completeOnboarding(accountData);
  };

  const handleOnChange = () => {
    handlers.onBuildingOrCompanySelectionChange();
  };

  const onboardingBuildingRender = enhancedOnboardingFeatureFlag ? (
    <OnboardingBuilding
      errorMessage={errors.completionError}
      userName={profile.fullName || ''}
      onboardingState={onboardingState}
      onComplete={handleBuildingComplete}
      onClear={handleOnChange}
    />
  ) : (
    <OnboardingBuildingLegacy
      errorMessage={errors.completionError}
      userName={profile.fullName || ''}
      onboardingState={onboardingState}
      onComplete={handleBuildingComplete}
      onClear={handleOnChange}
    />
  );

  const onboardingCompanyRender = enhancedOnboardingFeatureFlag ? (
    <OnboardingCompany
      errorMessage={errors.completionError}
      userName={profile.fullName || ''}
      onboardingState={onboardingState}
      whitelabel={whitelabel}
      onComplete={handleCompanyComplete}
      onClear={handleOnChange}
    />
  ) : (
    <OnboardingCompanyLegacy
      errorMessage={errors.completionError}
      userName={profile.fullName || ''}
      onboardingState={onboardingState}
      whitelabel={whitelabel}
      onComplete={handleCompanyComplete}
      onClear={handleOnChange}
    />
  );

  return (
    <Switch>
      <Route exact path={`${path}/profile`}>
        <OnboardingProfile
          defaultUserName={profile.fullName}
          onContinue={handleProfileContinue}
          whitelabel={whitelabel}
        />
      </Route>
      <Route exact path={`${path}/company`}>
        {profile.fullName ? (
          onboardingCompanyRender
        ) : (
          <Redirect to={`${path}/profile`} />
        )}
      </Route>
      <Route exact path={`${path}/building`}>
        {renderOnboardingBuildingOrInvite({
          OnboardingWithInvite:
            profile.fullName && building ? (
              <OnboardingWithInvite
                building={building}
                company={company}
                userName={profile.fullName}
                onboardingState={onboardingState}
                errorMessage={errors.completionError}
                onComplete={handleBuildingInviteComplete}
                onClear={handleOnChange}
              />
            ) : undefined,
          OnboardingBuilding: profile.fullName
            ? onboardingBuildingRender
            : undefined,
          Redirect: <Redirect to={`${path}/profile`} />,
        })}
      </Route>
    </Switch>
  );
};
