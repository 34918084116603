import React from 'react';

import { useTranslation } from 'react-i18next';

import PropertyComponent from 'components/builder/properties/input/Property';
import { H4 } from 'components/typography';

import { ChannelIntegrationEditorProps } from './ChannelIntegrationEditorProps';

import styles from './YardiIntegrationEditor/YardiIntegrationEditor.scss';

export const RealPage = ({
  channelIntegration,
  onUpdateChannelIntegration,
  definition,
  channel,
}: ChannelIntegrationEditorProps) => {
  const { t } = useTranslation();
  const settings = channelIntegration?.settings;
  const properties = definition?.properties;
  const isSyncPastUsersEnabled = (
    properties: any,
    settings: any,
    key: string
  ): boolean => {
    const syncPastUsersProperty = properties[key];

    if (!syncPastUsersProperty) {
      return false;
    }

    const { syncPastUsersEnabled } = settings || {};

    if (syncPastUsersEnabled) {
      properties.daysPostMoveOut.validators.push(
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        }
      );

      if (Number.isNaN(settings.daysPostMoveOut)) {
        settings.daysPostMoveOut = undefined;
      }

      return true;
    }

    if (properties?.daysPostMoveOut?.validators.length) {
      properties.daysPostMoveOut.validators = [];
    }

    return false;
  };

  return (
    <>
      <H4 mb={4}>{t('web-admin-integrations-realpage-settings-header')}</H4>
      <div>
        {Object.entries(properties).map(([key, property]) => {
          if (
            ['daysPostMoveOut'].includes(key) &&
            !isSyncPastUsersEnabled(properties, settings, key)
          ) {
            return null;
          }

          return (
            <div key={key}>
              <div className={styles.property}>
                {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ property: PropertyType<PropertyOptionType>... Remove this comment to see the full error message */}
                <PropertyComponent
                  object={channelIntegration.settings}
                  channel={channel}
                  label={property.friendlyName || property.name || key}
                  property={property}
                  propertyKey={key}
                  value={channelIntegration.settings?.[key]}
                  onChange={(value: any) =>
                    onUpdateChannelIntegration({
                      settings: {
                        ...channelIntegration.settings,
                        [key]: value,
                      },
                    })
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
