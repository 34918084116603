import { Header } from '../components/Header';
import { OnboardingBuildingInputLegacy } from './OnboardingBuildingInput';
import styles from './index.scss';
import { Button } from 'design-system-web';
import { ErrorMessage } from 'components';
import { H5, Text } from 'components/typography';
import useBuildingsSearch from 'lane-shared/contexts/SignUpContext/useBuildingSearch';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType } from 'lane-shared/types/ChannelType';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MINIMUM_BUILDING_SEARCH_LENGTH = 2;
const TRANSLATION_KEYS = {
  header: 'web.onboarding.building.header',
  privateBuildingError: 'web.onboarding.building.privateAccess.error',
  serverError: 'web.onboarding.building.server.error',
  button: 'shared.onboarding.building.continueButton',
};

type Props = {
  onboardingState: 'loading' | 'idle' | 'error';
  errorMessage?: string;
  userName: string;
  onComplete: (building: ChannelType, userName: string) => void;
  onClear: () => void;
};

export function OnboardingBuildingLegacy({
  onboardingState,
  errorMessage,
  userName,
  onComplete,
  onClear,
}: Props) {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [building, setBuilding] = useState<ChannelType | null>(null);

  const {
    translationKeys: { locationPrompt, locationPlaceholder },
  } = useContinueAsGuest();
  const { buildings, error, loading } = useBuildingsSearch({
    skip: searchText.length < MINIMUM_BUILDING_SEARCH_LENGTH,
    search: searchText,
  });

  useEffect(() => {
    if (error) {
      window.Toast.show(<Text>{t(TRANSLATION_KEYS.serverError)}</Text>);
    }
  }, [error]);

  const handleBuildingSearchChange = (text: string) => {
    setSearchText(text);
  };

  const handleBuildingSelectionChange = (building: ChannelType | null) => {
    setBuilding(building);

    if (building === null) {
      onClear();
    }
  };

  const handleCreateAccount = () => {
    if (building) {
      onComplete(building, userName);
    }
  };

  const privateBuildingError = building?.inviteOnly
    ? new Error(t(TRANSLATION_KEYS.privateBuildingError))
    : null;

  return (
    <div className={styles.container}>
      <Header userName={userName} />
      {privateBuildingError && <ErrorMessage error={privateBuildingError} />}
      {errorMessage && <ErrorMessage error={errorMessage} />}
      <H5>{building ? t(locationPlaceholder) : t(locationPrompt)}</H5>
      <div className={styles.searchContainer}>
        <OnboardingBuildingInputLegacy
          data={{
            building,
            buildingSearch: searchText,
            buildings,
            whiteLabelName: 'whitelabelName',
          }}
          config={{
            isLoading: loading,
          }}
          handlers={{
            onBuildingSearchTextChange: handleBuildingSearchChange,
            onBuildingSelectionChange: handleBuildingSelectionChange,
          }}
        />
        {building && (
          <Button
            className={styles.button}
            fullWidth
            onClick={handleCreateAccount}
            size="large"
            type="submit"
            loading={onboardingState === 'loading'}
          >
            {t(TRANSLATION_KEYS.button)}
          </Button>
        )}
      </div>
    </div>
  );
}
