import Papa from 'papaparse';

import { LaneType } from 'common-types';
import { formatBurstDateRange } from 'lane-shared/helpers/integrations/ShopifyBurst/formatters';
import i18n from 'localization';
import { ShopifyBurstInteractionType } from 'lane-shared/types/integrations/ShopifyBurst';
import { ShopifyRsvpDraftInteractionData } from 'lane-shared/types/integrations/ShopifyRsvp';

import makeFileDownload from 'lane-web/src/helpers/makeFileDownload';

function getRsvpTranslatedHeaders() {
  return {
    Name: i18n.t('Name'),
    Email: i18n.t('Email'),
    IsAttending: i18n.t('Is Attending'),
    SpecialRequests: i18n.t('Special Requests'),
    Compliance: i18n.t('Compliance'),
  } as const;
}

export default function exportBurstRsvpsToCSV(
  burstData: ShopifyBurstInteractionType,
  rsvpInteractions: Array<LaneType.UserContentInteraction>
) {
  const rsvpTranslatedHeaders = getRsvpTranslatedHeaders();

  const rsvpInteractionsCsvData = rsvpInteractions
    .filter(rsvpInteraction =>
      Boolean((rsvpInteraction?.data as any)?._ShopifyRsvp)
    )
    .filter(rsvpInteraction =>
      burstData.attendees.find(
        attendee => (rsvpInteraction as any).user?._id === attendee._id
      )
    )
    .map(rsvpInteraction => {
      const data = (rsvpInteraction?.data as any)
        ?._ShopifyRsvp as ShopifyRsvpDraftInteractionData;

      return {
        [rsvpTranslatedHeaders.Name]: (rsvpInteraction as any)?.user?.profile
          ?.name,
        [rsvpTranslatedHeaders.Email]: (rsvpInteraction as any)?.user?.profile
          ?.email,
        [rsvpTranslatedHeaders.IsAttending]: rsvpInteraction.status,
        [rsvpTranslatedHeaders.SpecialRequests]: data?.specialRequests,
        [rsvpTranslatedHeaders.Compliance]: data?.isComplyRegulations,
      };
    });

  const rsvpCsvData = rsvpInteractionsCsvData.concat(
    burstData.attendees
      .filter(
        attendee =>
          !rsvpInteractions.find(
            rsvpInteraction =>
              (rsvpInteraction as any).user?._id === attendee._id
          )
      )
      .map(attendee => ({
        [rsvpTranslatedHeaders.Name]: attendee.profile?.name,
        [rsvpTranslatedHeaders.Email]: attendee.profile?.email,
        [rsvpTranslatedHeaders.IsAttending]: i18n.t('Not Responded.'),
        [rsvpTranslatedHeaders.SpecialRequests]: '',
      }))
  );

  makeFileDownload({
    name: `${burstData.name} -- ${formatBurstDateRange(
      burstData.dateRange,
      burstData.portTimeZone
    )}.RSVP.csv`,
    contents: Papa.unparse(rsvpCsvData),
    type: 'application/csv',
  });
}
