import React from 'react';
import { Flex } from 'lane-web/src/components';
import { TinyTaskList } from 'lane-web/src/domains/workOrder/preventiveMaintenance/task/components/TinyTaskList';
import { TinyServiceRequestList } from 'lane-web/src/domains/workOrder/serviceRequest/components/TinyServiceRequestList';

import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

import styles from './LogHistory.scss';

export interface EquipmentDetailsProps {
  searchID: string;
  channel?: UseChannelForAdminQueryResponse['channel'];
}

function LogHistoryTab({ channel, searchID }: EquipmentDetailsProps) {
  return (
    <Flex direction="column" className={styles.EquipmentDetailsPage} gap={5}>
      {channel?.settings?.hasWorkOrderPreventiveMaintenanceEnabled && (
        <Flex className={styles.HistoryDetailsBlocks}>
          <TinyTaskList
            parentType="EQUIPMENT"
            searchID={searchID}
            channel={channel}
            showTitle
          />
        </Flex>
      )}
      {channel?.settings?.hasWorkOrderServiceRequestsEnabled && (
        <Flex className={styles.HistoryDetailsBlocks}>
          <TinyServiceRequestList
            searchID={searchID}
            channel={channel}
            showTitle
          />
        </Flex>
      )}
    </Flex>
  );
}

export default LogHistoryTab;
