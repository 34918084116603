import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Input, M, H5 } from 'design-system-web';
import { Dropdown } from 'components';
import { Flex } from 'components/layout';

import { Mapping } from '../types';
import styles from '../styles.scss';

type Props = {
  mapping: Mapping;
  setMapping: any;
};

const TRANSFORMS = [
  'none',
  'date',
  'index',
  'hours_to_number',
  'date_range_start',
  'date_range_end',
];

export const MappingDisplay = ({ mapping, setMapping }: Props) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const updateMappingSource = (key: string, value: string) => {
    const newMapping = { ...mapping };

    newMapping[key].keys = value.split(', ');
    setMapping(newMapping);
  };

  const updateMappingTransforms = (key: string, value: string) => {
    const newMapping = { ...mapping };

    if (value) {
      newMapping[key].transforms = value.split(', ');
    } else {
      newMapping[key].transforms = [];
    }

    setMapping(newMapping);
  };

  const updateMappingDefault = (key: string, value: string) => {
    const newMapping = { ...mapping };

    newMapping[key].default = value;
    setMapping(newMapping);
  };

  return (
    <div className={styles.mappingContainer}>
      <Button variant="text" onClick={() => setIsCollapsed(!isCollapsed)}>
        <H5>{t('web.admin.sidebar.dataImport.converter.currentMapping')}</H5>
        <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
      </Button>
      {isCollapsed ? null : (
        <Flex direction="row" className={styles.mappingRow}>
          <M className={styles.mappingHeader}>
            {t('web.admin.sidebar.dataImport.converter.mapping.importKey')}
          </M>
          <M className={styles.mappingText}>
            <Icon name="arrow-right" />
          </M>
          <M className={styles.mappingHeader}>
            {t('web.admin.sidebar.dataImport.converter.mapping.source')}
          </M>
          <M className={styles.mappingHeader}>
            {t(
              'web.admin.sidebar.dataImport.converter.mapping.transformations'
            )}
          </M>
          <M className={styles.mappingHeader}>
            {t('web.admin.sidebar.dataImport.converter.mapping.default')}
          </M>
        </Flex>
      )}
      {isCollapsed
        ? null
        : Object.keys(mapping).map(key => (
            <Flex key={key} direction="row" className={styles.mappingRow}>
              <Input
                className={styles.mappingInput}
                value={key}
                onChange={() => null}
                showClear={false}
              />
              <M className={styles.mappingText}>
                <Icon name="arrow-right" />
              </M>
              <Input
                className={styles.mappingInput}
                value={mapping[key].keys.join(', ')}
                onChange={value => updateMappingSource(key, value)}
                showClear={false}
              />
              <Dropdown
                className={styles.mappingInput}
                items={TRANSFORMS.map(transform => ({
                  label: t(
                    `web.admin.sidebar.dataImport.converter.transforms.${transform}`
                  ),
                  value: transform,
                }))}
                value={mapping[key]?.transforms?.join(', ')}
                onChange={item => updateMappingTransforms(key, item?.value)}
                placeholder="Select Transformation"
              />
              <Input
                className={styles.mappingInput}
                value={mapping[key]?.default || ''}
                onChange={value => updateMappingDefault(key, value)}
                showClear={false}
              />
            </Flex>
          ))}
    </div>
  );
};
