import { useChannelsByRelationshipQuery } from 'lane-shared/hooks';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { useEffect } from 'react';
import { queryChannels } from 'lane-shared/graphql/channel';
import { useQuery } from '@apollo/client';

export function useRelatedChannels(
  channelId: string,
  type: ChannelTypeEnum
): ChannelType[] {
  const isPropertyChannel = type === ChannelTypeEnum.Property;
  const { items, fetchNextPage, pageInfo } = useChannelsByRelationshipQuery({
    perPage: 25,
    skip: channelId === '',
    relatedTo: isPropertyChannel
      ? {
          _id: channelId,
        }
      : {
          sortBy: { key: 'name', dir: 'asc' },
          isSub: false,
        },
    channel: isPropertyChannel
      ? null
      : {
          _id: channelId,
        },
  });

  useEffect(() => {
    if (items.length < pageInfo.total) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return items.map(item => (isPropertyChannel ? item.channel : item.relatedTo));
}

export function useChannelsFromHierarchy(channelId: string | undefined) {
  const { data: buildings } = useQuery(queryChannels, {
    skip: !channelId,
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        start: 0,
        perPage: 500,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        includeChannelsFromHierarchy: true,
        isSub: false,
        parent: {
          _id: channelId,
        },
      },
    },
  });

  return buildings?.channels?.items || [];
}
