import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { SignUpContextType } from 'lane-shared/contexts/SignUpContext/SignUpContextType';
import getInvite, {
  GetInviteResponse,
} from 'lane-shared/graphql/query/getInvite';
import { loginEmailExists } from 'lane-shared/graphql/user';
import { useOnboardingV2Enabled } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import { Loading } from '../../../components';
import WhitelabelInviteWrapper from './WhitelabelInviteWrapper';

type Props = {
  user?: UserType;
  inviteId: string;
};

type EmailExistsQueryData = {
  loginEmailExists: boolean;
};

function GetInviteDataAndRedirectUser({ user, inviteId }: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [invite, setInvite] = useState<GetInviteResponse['invite']>();
  const { updateSignUp } = useSignUpContext();
  const client = getClient();
  const isOnboardingV2Enabled = useOnboardingV2Enabled();

  useEffect(() => {
    async function fetchInvite() {
      try {
        setLoading(true);
        const { data } = await client.query<GetInviteResponse>({
          query: getInvite,
          variables: { id: inviteId },
        });

        setLoading(false);
        setInvite(data?.invite);
      } catch (ex) {
        console.error(`Error while fetching invite:${ex}`);
        updateSignUp({ inviteError: ex });
        (history as any).push(routes.signUp);
      }
    }

    fetchInvite();
  }, []);

  useEffect(() => {
    async function getUserExists(email: string) {
      try {
        const { data } = await client.query<EmailExistsQueryData>({
          query: loginEmailExists,
          variables: { email },
        });

        if (data.loginEmailExists) {
          history.push(routes.manualLogin);
        } else {
          continueWithSignUp();
        }
      } catch (err) {
        console.error(`Error while fetching user:${err}`);
        updateSignUp({ inviteError: err });
        continueWithSignUp();
      }
    }

    if (invite) {
      initializeData(invite);

      if (user) {
        continueLoggedIn();
      } else {
        if (isOnboardingV2Enabled) {
          getUserExists(invite.email);

          return;
        }

        continueWithSignUp();
      }
    }
  }, [invite, user]);

  async function initializeData(invite: GetInviteResponse['invite']) {
    const { name, _id, channel, email, company } = invite;
    const updatedSignUp: Partial<SignUpContextType> = {
      inviteId: _id,
      name,
      email,
      building: undefined,
      shouldSkipCompanySelection: false,
    };

    if (channel) {
      updatedSignUp.shouldSkipCompanySelection = true;

      if (company) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetInviteResponseChannelType' is not assigna... Remove this comment to see the full error message
        updatedSignUp.building = channel;
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetInviteResponseChannelType' is not assigna... Remove this comment to see the full error message
        updatedSignUp.company = company;
      } else {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'GetInviteResponseChannelType' is not assigna... Remove this comment to see the full error message
        updatedSignUp.building = channel;
      }
    }

    updateSignUp(updatedSignUp);
  }

  function continueLoggedIn() {
    history.push(routes.home);
  }

  function continueWithSignUp() {
    history.push(routes.signUp);
  }

  if (loading) {
    return (
      <WhitelabelInviteWrapper>
        <Loading />
      </WhitelabelInviteWrapper>
    );
  }

  return null;
}

export default GetInviteDataAndRedirectUser;
