/* eslint-disable no-console */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelJS from 'exceljs';

import { Icon } from 'design-system-web';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { ImporterEntityTypes } from 'lane-shared/domains/importer/jobDefinition';

import { Button, FileInput } from 'components';
import { Flex } from 'components/layout';
import { H5, S } from 'components/typography';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from './styles.scss';
import {
  HYPERCARE_TO_IMPORT_MAPPING,
  IMPORT_SHEETS,
  IMPORT_TYPE_MAPPING,
} from './constants';

type props = {
  handleAttachments: (
    type: ImporterEntityTypes,
    attachmentFiles: any[]
  ) => void;
};

export const WorkbookUpload = ({ handleAttachments }: props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>(null);
  const [filename, setFilename] = useState<string>('');

  const handleUpload = async (
    file: FileReturnType,
    name: string
  ) => {
    await processWorkbook(file);

    setFile(file);
    setFilename(name);
  };

  const handleDownload = () => {
    const element = document.createElement('a');

    element.href = URL.createObjectURL(file as Blob);
    element.click();
  };

  const handleDelete = () => {
    setFile(null);
    setFilename('');
  };

  const processWorkbook = async (file: FileReturnType) => {
    const workbook = new ExcelJS.Workbook();
    const arrayBuffer = await (file as File).arrayBuffer();

    await workbook.xlsx.load(arrayBuffer);

    for (const sheetName of IMPORT_SHEETS) {
      const worksheet = workbook.getWorksheet(sheetName);

      if (!worksheet) {
        continue;
      }

      let csvContent = '';

      worksheet?.eachRow((row, rowNumber) => {
        const rowData = (row as any)?.values?.slice(1);

        if (rowNumber === 1) {
          csvContent += `${convertHeadersForImport(rowData, sheetName)}\n`;
        } else {
          csvContent += `${rowData?.join(',')}\n`;
        }
      });

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const csvFile = new File([blob], `${sheetName}.csv`, { type: 'text/csv' });
      const attachments = {
        file: csvFile,
        thumbnailSignedUrl: URL.createObjectURL(csvFile),
      };

      handleAttachments(IMPORT_TYPE_MAPPING[sheetName], [attachments]);
    }
  };

  const convertHeadersForImport = (headers: string[], sheetName: string) => {
    return headers?.map(header => {
      return HYPERCARE_TO_IMPORT_MAPPING[sheetName][header] || header;
    })?.join(',');
  };

  return (
    <Flex className={styles.adminPage} gap={4}>
      {/* Description */}
      <Flex direction="column" gap={2}>
        <H5>{t('web.admin.importer.workorders.workbooks.friendlyName')}</H5>
        <S variant="secondary">
          {t('web.admin.importer.workorders.workbooks.description')}
        </S>
      </Flex>

      {/* Uploader */}
      {!filename ? (
        <Flex direction="column" className={styles.attachments} gap={1}>
          <Flex direction="row" className={styles.fileInput} gap={4}>
            <FileInput
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type={FileReturnTypeEnum.File}
              onFileSelected={handleUpload}
            >
              <Button size="small" variant="contained">
                {t('web.admin.importer.workorders.upload.csv')}
              </Button>
            </FileInput>
          </Flex>
        </Flex>
      ) : null}

      {/* File Information */}
      {filename ? (
        <Flex
          direction="row"
          gap={4}
          className={styles.attachmentName}
          justify="space-between"
        >
          <H5>{filename}</H5>
          <Flex direction="row">
            {/* Download Button */}
            <div
              data-test="downloadAttachment"
              role="button"
              onClick={handleDownload}
              tabIndex={0}
            >
              <Icon
                className={styles.emptyImageIcon}
                set={ICON_SET_FONTAWESOME}
                name="download"
                type="far"
              />
            </div>

            {/* Delete Button */}
            <div
              data-test="deleteAttachment"
              role="button"
              onClick={handleDelete}
              tabIndex={0}
            >
              <Icon
                className={styles.emptyImageIcon}
                set={ICON_SET_FONTAWESOME}
                name="trash-alt"
                type="far"
              />
            </div>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
