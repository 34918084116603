import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import styleMap from 'components/renderers/v5/styleMap';

import styles from './AccessControlBlock.scss';

/**
 * @deprecated
 */
export default function AccessControlBlock({
  style,
  className,
  editMode,
  colorScheme,
}: any) {
  // We want to hide this block if we're in web
  if (!editMode) return null;

  // Except when we are in the builder section, previewing mobile
  return (
    <div
      className={cx(styles.AccessControlBlock, className)}
      style={styleMap(false, style)}
    >
      <div className={styles.iconWrapper} data-colorscheme={colorScheme}>
        <Icon className={styles.icon} name="lock" />
      </div>
    </div>
  );
}
