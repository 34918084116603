import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import {
  Invoice,
  currencyFromJSON,
} from 'lane-shared/domains/billingPayments/types';
import { CurrencyFormatMap } from 'lane-shared/domains/billingPayments/constants';

import { dates } from 'lane-shared/helpers/constants';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';
import dateFormatter from 'lane-shared/helpers/formatters/dateFormatter';

import { Chip, ChipStyle } from 'components/ads';
import Label from 'components/general/Label';
import { H2 } from 'components/typography';

import styles from './styles.scss';
import { Button } from 'design-system-web';

interface InvoiceCardViewProps {
  invoice: Invoice;
  showAutopay?: boolean;
}

const TWO_DECIMAL_PLACES = 2;

export function InvoiceCardView({
  invoice,
  showAutopay,
}: InvoiceCardViewProps) {
  const statusMap = getInvoiceStatus(invoice.status, invoice, showAutopay);
  const invoiceIsPaid = invoice.status === 'PAID';
  const history = useHistory();

  const pillColor = statusMap[1] as ChipStyle;

  const { t } = useTranslation();

  return (
    <div className={styles.cardItem}>
      <div className={styles.lineItemHeader}>
        <span className={`${statusMap[1] === 'red' ? styles.red : ''}`}>
          <H2 className={`${styles.amount}`}>
            {CurrencyFormatMap[currencyFromJSON(invoice.currency)]}{' '}
            {invoice.amount.toFixed(TWO_DECIMAL_PLACES)}
          </H2>
        </span>
        <div>
          <Chip value={statusMap[0]} type={pillColor} size="xs" />
        </div>
      </div>
      <div className={styles.lineItem}>
        <div className={styles.item}>
          <Label h3>{t('abp.routes.accountInvoices.to')}</Label>
          {invoice.externalPayerName}
        </div>
        <div className={styles.item}>
          <Label h3>{t('abp.routes.accountInvoices.from')}</Label>
          {invoice.groupName}
        </div>
      </div>
      <div className={styles.lineItem}>
        <div className={styles.item}>
          <Label h3>{t('abp.routes.accountInvoices.invoiceNumber')}</Label>
          {invoice.displayId ? (
            <span title={invoice.displayId}>
              {invoice.displayId.length > 20
                ? `${invoice.displayId.substring(0, 20)}...`
                : invoice.displayId}
            </span>
          ) : (
            '-'
          )}
        </div>
        <div className={styles.item}>
          <Label h3>{t('abp.routes.accountInvoices.due')}</Label>
          {invoice.dueDate
            ? dateFormatter(invoice.dueDate, dates.LONG_MONTH_DATE_YEAR)
            : '-'}
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.button}>
          <Button
            onClick={() => {
              history.push(
                routes.meCompanyInvoiceDetails.replace(
                  ':invoiceDisplayId',
                  invoice.displayId
                ),
                { invoice }
              );
            }}
            size="large"
            variant="text"
            testId="ViewInvoiceButton"
          >
            {t('abp.invoice.details.viewInvoice')}
          </Button>
        </div>
        <div className={styles.button}>
          <Button
            disabled={invoiceIsPaid}
            onClick={() => {
              history.push(routes.meInvoicePayment, { invoice });
            }}
            size="large"
            variant="secondary"
            testId="PayInvoiceButton"
          >
            {t('abp.routes.accountInvoices.pay')}
          </Button>
        </div>
      </div>
    </div>
  );
}
