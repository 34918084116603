import React from 'react';

import {
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { Table } from 'design-system-web';

import { useTableColumnsAddedProducts } from './useTableColumnsAddedProducts';

import styles from '../addItemsToChargeStyles.scss';

export function AddedItemsTable({
  listOfProducts,
  currency,
  editProduct,
  removeProduct,
}: {
  listOfProducts: AddProductToChargeDetails[];
  currency: SupportedPaymentCurrency;
  editProduct: Function;
  removeProduct: Function;
}) {
  const chargeColumns = useTableColumnsAddedProducts({
    currency,
    editProduct,
    removeProduct,
  });

  return (
    <div className={styles.addedProductsTable} data-test="AddedProductsTable">
      <Table
        columns={chargeColumns}
        data={listOfProducts}
        disableSorting
        isLoading={false}
      />
    </div>
  );
}
