import React, { useContext, useEffect, useState } from 'react';

import { Table, getPageSizeFromQueryString, Sort } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { useQueryString } from 'hooks';
import { exportCSV } from './helpers/exportCSV';
import { GroupType as gqlGroupType } from 'graphql-resolver-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { getClient } from 'lane-shared/apollo';

import { listBillingReportsQuery } from 'graphql-queries';

import {
  useChargeCodesOptions,
  useChargeReportsFilters,
  BillingReportsQueryString,
  DEFAULT_BILLING_REPORTS_FILTER_PARAMS,
} from './hooks';

import {
  getChargeBillingReportColumns,
  normalizeSort,
} from './helpers/useListBillingReportsTableColumns';

import ChannelAdminContext from '../ChannelAdminContext';
import { buildChargeReportFilters } from './helpers/buildChargeReportFilters';

export function BillingReportsTable() {
  const { t } = useTranslation();
  const { channel } = useContext(ChannelAdminContext);
  const [sort, setSort] = useState<Sort>({
    id: 'createdAt',
    direction: 'asc',
  });

  const reportColumns = getChargeBillingReportColumns(t, 'yyyy-MM-01');
  const chargeCodes = useChargeCodesOptions(channel?._id);
  const tableCacheKey = 'chargeBillingReports';

  const [channelId, setChannelId] = useState<string | null>(null);

  const [loadbillingReportsList, { data, loading }] = useLazyQuery(
    listBillingReportsQuery
  );

  const [
    filterParams,
    setFilterParams,
  ] = useQueryString<BillingReportsQueryString>(
    DEFAULT_BILLING_REPORTS_FILTER_PARAMS,
    ['tab']
  );

  useEffect(() => {
    if (channel) {
      setChannelId(channel?._id);

      loadbillingReportsList({
        variables: {
          group: {
            id: convertToUUID(channel?._id),
            type: gqlGroupType.GroupTypeActivateChannel,
          },
          filters: buildChargeReportFilters(filterParams),
          pagination: {
            start:
              ((filterParams?.page || 0) as number) *
              getPageSizeFromQueryString(filterParams?.pageSize),
            perPage: getPageSizeFromQueryString(filterParams?.pageSize),
          },
          forExport: false,
          sort: normalizeSort(sort.id, sort.direction),
        },
      });
    }
  }, [
    channel?._id,
    loadbillingReportsList,
    filterParams?.pageSize,
    filterParams?.page,
    filterParams?.chargeCode,
    filterParams?.createdAt,
    filterParams?.payer,
    sort.id,
    sort.direction,
  ]);

  const totalReports = data?.accounts?.listBillingReports?.pageInfo?.total;

  useEffect(() => {
    if (totalReports) {
      setFilterParams({
        ...filterParams,
        total: totalReports,
      });
    }
  }, [data?.accounts?.listBillingReports?.pageInfo?.total]);

  const handleExportToCSV = async () => {
    const { data } = await getClient().query({
      query: listBillingReportsQuery,
      variables: {
        group: {
          id: convertToUUID(channel?._id),
          type: gqlGroupType.GroupTypeActivateChannel,
        },
        filters: buildChargeReportFilters(filterParams),
        forExport: true,
      },
      fetchPolicy: 'network-only',
    });

    exportCSV(
      data?.accounts?.listBillingReports?.reports || [],
      reportColumns,
      `${tableCacheKey}-${new Date().toISOString()}.csv`,
      tableCacheKey
    );
  };

  const exportOptions = [
    {
      label: t('abp.chargeBillingReports.exportCsv'),
      onClick: handleExportToCSV,
    },
  ];

  return (
    <Table
      columns={reportColumns}
      data={data?.accounts?.listBillingReports?.reports || []}
      isLoading={loading}
      pagination="server"
      totalRows={Number(filterParams.total)}
      queryStringsEnabled
      exportOptions={exportOptions}
      onSortChange={setSort}
      filters={useChargeReportsFilters(chargeCodes, channelId)}
      showColumnVisibility
      tableKey={tableCacheKey}
    />
  );
}
