import { useChannelAdminContext } from 'hooks';
import { updateProductMutation } from '../helpers/updateProductMutation';
import styles from './CreateProductForm.scss';
import { routes } from 'lane-shared/config';
import { CreateProductFormInputFields } from './CreateProductFormInputFields';
import convertToUUID from 'lane-shared/helpers/convertId/convertToUUID';
import { CreateProductSummary } from './CreateProductSummary';
import { Button, ErrorMessage } from 'components';
import { GraphQLError } from 'graphql';
import useUserLocale from 'hooks/useUserLocale';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { getProductQuery } from 'lane-shared/domains/billingPayments/helpers/getProductQuery';
import {
  NewProductDetails,
  currencyToPaymentCurrency,
  TaxPolicy,
  ProductType,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isNumberTwoDecimalPlaces } from '../helpers';
import { useLazyQuery } from '@apollo/client';
import { H4 } from 'design-system-web';
import { CreditPricingSummary } from './CreditPricingSummary';

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
};

export function EditProductForm({ breadcrumbs }: Props) {
  const { channel } = useChannelAdminContext();
  const { id, productId }: { id: string; productId: string } = useParams();
  const history = useHistory();
  const locale = useUserLocale();
  const [pageError, setPageError] = useState<
    readonly GraphQLError[] | Error | undefined
  >(undefined);
  const [loadProduct, { data }] = useLazyQuery(getProductQuery, {
    fetchPolicy: 'network-only',
  });

  const {
    paymentSettingsError,
    taxDetails,
    currency,
  } = useBillingPaymentSettingsForChannel({ channelId: channel?._id });

  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;
  const { t } = useTranslation();
  const [newProductDetails, setNewProductDetails] = useState<NewProductDetails>(
    {
      productCategoryId: '',
      productCategoryName: '',
      productType: ProductType.PRODUCT_TYPE_SERVICE_LABOUR,
      chargeCodeId: undefined,
      name: '',
      description: '',
      rate: '',
      markup: undefined,
      tax: undefined,
      taxDefault: undefined,
    }
  );

  useEffect(() => {
    if (channel) {
      loadProduct({
        variables: {
          getProductRequest: {
            productId,
            group: {
              id: convertToUUID(channel._id),
              type: GroupType.GroupTypeActivateChannel,
            },
          },
        },
      });
    }
  }, [productId, channel, loadProduct]);

  useEffect(() => {
    if (data) {
      const product = data?.getProduct.product;
      const productDetails = {
        productCategoryId: product.productCategory.productCategoryId,
        productCategoryName: product.productCategory.name,
        chargeCodeId: product.chargeCode?.id,
        name: product.name,
        description: product.description,
        rate: product.amount,
        productType: product.productCategory.productType,
        creditAmount: product.creditAmount,
        netPrice: product.sellingPrice?.netPrice,
      } as NewProductDetails;

      if (product.markup?.value > 0) {
        productDetails.markup = {
          value: product.markup.value,
          type: product.markup.markupAmountType,
        };
      }

      if (product.tax?.value > 0) {
        productDetails.tax = {
          value: product.tax.value,
          type: product.tax.taxAmountType,
        };
      }

      setNewProductDetails(productDetails);
    }
  }, [data]);

  if (!channel) return null;

  const product = data?.getProduct.product;

  if (!product) return null;

  const handleProductDetailsUpdate = (value: any, field: string) => {
    setNewProductDetails({ ...newProductDetails, [field]: value });
  };

  const handleSaveProduct = async () => {
    const priceComponents = priceInputToComponents(newProductDetails);
    const { rate, grossTax, grossMarkup, listPrice, netPrice } = getPricing({
      currency: currencyToPaymentCurrency(currency),
      locale,
      priceComponents,
      inclusivePricing,
    });

    if (inclusivePricing) {
      newProductDetails.rate = rate.toString();
    }

    try {
      const result = await updateProductMutation(
        productId,
        newProductDetails,
        { grossTax, grossMarkup, listPrice, netPrice },
        channel?._id || ''
      );

      if (result.errors) {
        setPageError(result.errors);
      }

      window.Toast.show(t('abp.productsServices.editProduct.successToast'));
      history.goBack();
    } catch (err: any) {
      window.Toast.show(t('abp.productsServices.editProduct.failToast'));
      setPageError(err);
    }
  };

  const handleCancel = async () => {
    history.goBack();
  };

  const isSaveButtonDisabled = () => {
    const { name, rate, markup, tax, netPrice } = newProductDetails;

    // Check if rate and netPrice are greater than or equal to 0
    const rateIsCorrect =
      (Boolean(rate) && Number(rate) >= 0) ||
      (Boolean(netPrice) && Number(netPrice) >= 0);

    // Check if markup is greater than or equal to 0 and has two or fewer decimal places
    const markupRateCorrect =
      !markup ||
      (markup.value &&
        Number(markup.value) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(markup.value)));

    // Check if tax is greater than or equal to 0 and has two or fewer decimal places
    const taxRateCorrect =
      !tax ||
      (tax.value &&
        Number(tax.value) >= 0 &&
        isNumberTwoDecimalPlaces(parseFloat(tax.value)));

    // Check if a name is entered
    const nameEntered = Boolean(name);

    // Check if rate and netPrice have two or fewer decimal places
    const amountsAre2dp =
      isNumberTwoDecimalPlaces(parseFloat(rate)) &&
      (netPrice ? isNumberTwoDecimalPlaces(parseFloat(netPrice)) : true);

    // Return false if all conditions are met, otherwise return true
    return !(
      rateIsCorrect &&
      markupRateCorrect &&
      taxRateCorrect &&
      nameEntered &&
      amountsAre2dp
    );
  };

  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    id
  );

  const defaultBreadcrumbs = [
    {
      label: t('adp.productsServices.breadcrumb'),
      url: productServicesLink,
    },
    {
      label: t(
        `abp.productsServices.editProduct.breadcrumb.${newProductDetails.productType}`
      ),
    },
  ];
  const paymentCurrency = currencyToPaymentCurrency(currency);

  return (
    <AdminPage>
      <PageHeader
        header={t(
          `abp.productsServices.editProduct.heading.${newProductDetails.productType}`
        )}
        headerLevel="h3"
        breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
      />
      <div className={styles.createProductPage}>
        <CreateProductFormInputFields
          productDetails={newProductDetails}
          updateProductDetails={handleProductDetailsUpdate}
          inclusivePricing={inclusivePricing}
          currency={paymentCurrency}
          edit
        />
        <div className={styles.productPricingSummary}>
          <H4>
            {t('abp.productsServices.createProduct.pricingSummary.title')}
          </H4>
          <CreateProductSummary
            productDetails={newProductDetails}
            currency={paymentCurrency}
            inclusivePricing={inclusivePricing}
          />
          {newProductDetails.creditAmount !== undefined && (
            <CreditPricingSummary
              creditAmount={newProductDetails.creditAmount}
            />
          )}
        </div>
      </div>
      <div className={styles.buttonAndErrorGroup}>
        {pageError && <ErrorMessage error={pageError} />}
        {paymentSettingsError && <ErrorMessage error={paymentSettingsError} />}
        <div className={styles.buttonGroup}>
          <Button
            testId="saveNewProductButton"
            variant="contained"
            onClick={() => handleSaveProduct()}
            disabled={isSaveButtonDisabled()}
          >
            {t('abp.productsServices.createProduct.save')}
          </Button>
          <Button onClick={handleCancel}>{t('abp.cancel')}</Button>
        </div>
      </div>
    </AdminPage>
  );
}
