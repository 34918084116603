import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { getClient } from 'lane-shared/apollo';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

import { LaneType } from 'common-types';
import isRegionLocationFilter from '../../../helpers/integrations/ShopifyBurst/isRegionLocationFilter';
import {
  MIN_DATE,
  MAX_DATE,
  DAY_PARSE_FORMAT,
} from './constants/unavailableDates';
import getSectionUnavailableDates from './graphql/getSectionUnavailableDates';
import handleUnavailableContentFeatureDates from './helpers/handleUnavailableContentFeatureDates';
import handleUnavailableSectionFeatureDates from './helpers/handleUnavailableSectionFeatureDates';
import { LocationValue, RegionValue } from './locationFilter';
import { SectionQueryResultType } from './types/unavailableDates';

type Props = {
  sectionId?: string;
  timeZone: string;
  locationFilters: (LocationValue | RegionValue)[];
  ports?: ShopifyChannelType[];
};

export default function useAggregatedUnavailableDatesForSections({
  sectionId,
  timeZone,
  locationFilters,
  ports,
}: Props) {
  const [unavailableDatesData, setUnavailableDatesData] = useState<any>();

  useEffect(() => {
    const channelIds = locationFilters
      .filter(filter => !isRegionLocationFilter(filter))
      .map(({ _id }) => _id);

    async function fetchResults() {
      if (!sectionId) {
        return;
      }

      const sections = await Promise.all(
        channelIds.map(channelId => {
          return fetchSectionUnavailableDates({ sectionId, channelId });
        })
      );

      const { unavailableDates, maxDate, minDate } = handleUnavailableDates({
        sectionData: sections.map(sectionItem => sectionItem.data),
        minDate: MIN_DATE,
        maxDate: MAX_DATE,
      });

      setUnavailableDatesData({
        unavailableTimeRanges: unavailableDates
          .sort(([dayKeyA], [dayKeyB]) => dayKeyA.localeCompare(dayKeyB))
          .map(([dayKey]) => {
            const startDate = DateTime.fromFormat(dayKey!, DAY_PARSE_FORMAT, {
              zone: timeZone,
            });

            return {
              startDate: startDate.toJSDate(),
              endDate: startDate.endOf('day').toJSDate(),
            };
          }),
        minDate,
        maxDate,
      });
    }

    async function fetchSectionUnavailableDates({
      sectionId,
      channelId,
    }: {
      sectionId: string;
      channelId: string;
    }) {
      return await getClient().query<SectionQueryResultType>({
        query: getSectionUnavailableDates,
        fetchPolicy: 'no-cache',
        variables: {
          id: sectionId,
          searchOptions: {
            areFiltersApplied: true,
            channelId,
          },
          startDate: MIN_DATE.toJSDate(),
          endDate: MAX_DATE.toJSDate(),
        },
      });
    }

    function handleUnavailableDates({
      sectionData,
      minDate,
      maxDate,
    }: {
      sectionData: SectionQueryResultType[];
      minDate: DateTime;
      maxDate: DateTime;
    }) {
      const unavailableDates: [string, string[]][] = [];

      sectionData.forEach(sectionDataItem => {
        const dayMap = new Map<string, LaneType.UUID[]>();

        const contentFeatureResult = handleUnavailableContentFeatureDates({
          sectionContent: sectionDataItem.section.sectionContent,
          minDate,
          maxDate,
          dayMap,
          ports,
          timeZone,
        });

        const sectionFeatureResult = handleUnavailableSectionFeatureDates({
          sectionFeatureReservableAvailability:
            sectionDataItem.sectionFeatureReservableAvailability,
          sectionContent: sectionDataItem.section.sectionContent,
          minDate: contentFeatureResult?.minDate,
          maxDate: contentFeatureResult?.maxDate,
          dayMap,
          timeZone,
          ports,
        });

        minDate = sectionFeatureResult?.minDate;
        maxDate = sectionFeatureResult?.maxDate;

        const contentCount = sectionDataItem.section.sectionContent.length || 0;
        const filteredEntries = Array.from(dayMap.entries()).filter(
          ([, contentIds]) => {
            // for the calendar to be marked unavailable, all rooms must be booked on that day.
            return contentIds.length >= contentCount;
          }
        );

        unavailableDates.push(...filteredEntries);
      });

      return {
        unavailableDates,
        minDate,
        maxDate,
      };
    }

    if (locationFilters.length) {
      fetchResults();
    } else {
      setUnavailableDatesData(undefined);
    }
  }, [locationFilters, ports, sectionId, timeZone]);

  return unavailableDatesData;
}
