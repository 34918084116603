import { Header } from '../components/Header';
import { OnboardingCompanyInputLegacy } from './OnboardingCompanyInput';
import styles from './index.scss';
import { Button } from 'design-system-web';
import { ErrorMessage } from 'components';
import { H5, H6 } from 'components/typography';
import useCompanyLocationsSearch from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import useCompanyParentSearch from 'lane-shared/contexts/SignUpContext/useCompanyParentSearch';
import {
  SignUpUnsupportedLocationError,
  SignUpPrivateLocationError,
  SignUpFullAccessError,
} from 'activate-errors';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  userName: string;
  whitelabel: WhiteLabelType;
  onboardingState: 'loading' | 'idle' | 'error';
  errorMessage?: string;
  onComplete: (
    company: ChannelType,
    building: ChannelType,
    userName: string
  ) => void;
  onClear: () => void;
};

const TRANSLATION_KEYS = {
  header: 'web.onboarding.building.header',
  button: 'shared.onboarding.building.continueButton',
  companySearchTitle: 'shared.onboarding.company.name.search.title',
  companyTitle: 'shared.onboarding.company.title',
};

export const OnboardingCompanyLegacy = ({
  userName,
  whitelabel,
  onboardingState,
  errorMessage,
  onComplete,
  onClear,
}: Props) => {
  const [parentCompanySearch, setParentCompanySearch] = useState('');
  const [companyLocationSearch, setCompanyLocationSearch] = useState('');
  const [building, setBuilding] = useState<ChannelType | null>(null);
  const [company, setCompany] = useState<ChannelType | null>(null);
  const [parentCompany, setParentCompany] = useState<ChannelType | null>(null);
  const [selectionError, setSelectionError] = useState<Error | null>(null);

  const { locationHelper } = useLocation();
  const {
    isContinueAsGuestVisible,
    translationKeys: { noRecognizedOptionLabel },
  } = useContinueAsGuest();
  const { t } = useTranslation();

  const companyLocationsResult = useCompanyLocationsSearch({
    skip: !parentCompany?._id && !building?._id,
    parentCompany,
    search: companyLocationSearch,
    oAuth: undefined,
    parentBuilding: building,
  });

  const companyParentsResult = useCompanyParentSearch({
    skip: !parentCompanySearch || parentCompanySearch.length < 2,
    search: parentCompanySearch,
  });

  useEffect(() => {
    locationHelper.startWatching({
      showRequestModal: true,
      availability: LocationAvailabilityEnum.Foreground,
      disableLocationPrecision: whitelabel.disableLocationPrecision,
    });
  }, []);

  useEffect(() => {
    if (
      parentCompany &&
      companyLocationsResult.called &&
      !companyLocationsResult.loading
    ) {
      const locations = companyLocationsResult.locations;

      if (locations.length === 0) {
        // if there are no locations found
        setSelectionError(new SignUpUnsupportedLocationError());
      } else if (locations.every(l => l.relatedTo.inviteOnly)) {
        // if there are only private locations
        setSelectionError(new SignUpPrivateLocationError());
      } else if (parentCompany.inviteOnly) {
        // We know from the above conditions that we have locations for this
        // company that are not private. If the company is invite only we show
        // a message stating they won't have full access
        setSelectionError(new SignUpFullAccessError());
      }
    }
  }, [
    parentCompany,
    companyLocationsResult.called,
    companyLocationsResult.loading,
    companyLocationsResult.locations.length,
  ]);

  const handleCompanyLocationSearchTextChange = (
    companyLocationSearch: string
  ) => setCompanyLocationSearch(companyLocationSearch);

  const clearSelectionError = () => {
    setSelectionError(null);
    onClear();
  };

  const handleCompanyBuildingSelectionChange = (
    company: ChannelType | null,
    building: ChannelType | null
  ) => {
    setCompany(company);
    setBuilding(building);

    if (company === null && building === null) {
      clearSelectionError();
    }
  };

  const handleParentCompanySearchTextChange = (parentCompanySearch: string) =>
    setParentCompanySearch(parentCompanySearch);

  const handleParentCompanySelectionChange = (parentCompany: ChannelType) =>
    setParentCompany(parentCompany);

  const handleSelectionReset = () => {
    setParentCompany(null);
    setCompany(null);
    setBuilding(null);
    clearSelectionError();
  };

  const handleCreateAccount = () => {
    if (company && building) {
      onComplete(company, building, userName);
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ width: '100%' }}>
        <Header userName={userName} />
        {selectionError && <ErrorMessage error={selectionError} />}
        {errorMessage && <ErrorMessage error={errorMessage} />}
        <div>
          <H5 mb={2} className={styles.title}>
            {parentCompany
              ? t(TRANSLATION_KEYS.companyTitle)
              : t(TRANSLATION_KEYS.companySearchTitle)}
          </H5>
          <OnboardingCompanyInputLegacy
            data={{
              signupError: selectionError,
              company,
              building,
              parentCompanySearch,
              companyParentsResult,
              companyLocationSearch,
              parentCompany,
              companyLocationsResult,
            }}
            handlers={{
              resetSelection: handleSelectionReset,
              onParentCompanySearchTextChange: handleParentCompanySearchTextChange,
              onParentCompanySelectionChange: handleParentCompanySelectionChange,
              onCompanyLocationSearchTextChange: handleCompanyLocationSearchTextChange,
              onCompanyBuildingSelectionChange: handleCompanyBuildingSelectionChange,
            }}
          />
        </div>
        {company && (
          <Button
            loading={onboardingState === 'loading'}
            className={styles.button}
            fullWidth
            onClick={handleCreateAccount}
            size="large"
            type="submit"
            disabled={Boolean(companyLocationsResult.isSignUpBlocked)}
          >
            {t(TRANSLATION_KEYS.button)}
          </Button>
        )}
      </div>
      {!parentCompany && isContinueAsGuestVisible && (
        <Link to="./building">
          <H6 className={styles.footerLink}>{t(noRecognizedOptionLabel)}</H6>
        </Link>
      )}
    </div>
  );
};
