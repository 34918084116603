/* eslint-disable no-console */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExcelJS from 'exceljs';

import { routes } from 'lane-shared/config';

import { Button, M } from 'design-system-web';
import { FileInput } from 'components';
import { AdminPage, Flex, PageHeader } from 'components/layout';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import {
  HYPERCARE_IMPORTS,
  HYPERCARE_MAPPINGS,
  HYPERCARE_SOURCES,
} from './constants';
import excelFile from './fixtures/HypercareTemplate.xlsx';
import { Mapping, RowData } from './types';
import { addDropdowns, populateBillingSheets } from './utils';
import styles from './styles.scss';

type Props = {
  channel: any;
};

export const SuperConverter = ({ channel }: Props) => {
  const { t } = useTranslation();
  const [source, setSource] = useState<any>({});
  const [destination, setDestination] = useState<string>('');
  const [downloadActive, setDownloadActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLink, _setDownloadLink] = useState<HTMLAnchorElement>(
    document.createElement('a')
  );

  const pageHeaderProps = {
    header: t('web.admin.importer.workorders.list.superConverter'),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.importer.workorders.breadcrumb.data.import'),
        url: routes.channelAdminDataImportListView.replace(
          ':id',
          channel?.slug
        ),
      },
      {
        label: t('web.admin.importer.workorders.list.superConverter'),
      },
    ],
  };

  const handleUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setLoading(true);

    try {
      await buildWorkbook(file);

      setDestination(name);
    } catch (error) {
      console.log(error);
      await window.Toast.error('Failed to convert the workbook.');

      return;
    }

    setLoading(false);
    setDownloadActive(true);
    await window.Toast.show(
      'Successfully converted the workbook. Click download to save the file.'
    );
  };

  const handleDownload = async () => {
    downloadLink.download = `Converted ${destination}`;
    downloadLink.click();
  };

  const buildWorkbook = async (file: FileReturnType) => {
    const wb = new ExcelJS.Workbook();
    const arrayBuffer = await (file as File).arrayBuffer();
    const templateWorkbook = new ExcelJS.Workbook();
    const templateSource = await fetch(excelFile);
    const templateBuffer = await templateSource.arrayBuffer();

    await wb.xlsx.load(arrayBuffer);
    await templateWorkbook.xlsx.load(templateBuffer);

    // Convert and transform each sheet
    wb.eachSheet(worksheet => {
      const sheetName = worksheet.name.replace(/[\s'"]/g, '');

      if (validHypercareSheet(sheetName)) {
        convert(
          worksheet,
          HYPERCARE_IMPORTS[sheetName],
          HYPERCARE_MAPPINGS[sheetName]
        );
      }
    });

    // Append original sheets to new workbook
    wb.eachSheet(oldSheet => {
      const newName =
        oldSheet.name === 'Instructions' ? 'Instructions2' : oldSheet.name;
      const newSheet = templateWorkbook.addWorksheet(newName);

      oldSheet.eachRow((row, rowNumber) => {
        const newRow = newSheet.getRow(rowNumber);

        newRow.values = row.values;
        newRow.height = row.height;
        row.eachCell((cell, colNumber) => {
          const newCell = newRow.getCell(colNumber);

          if (cell.style) {
            newCell.style = { ...cell.style };
          }

          if (cell.style?.font) {
            newCell.style.font = { ...cell.style.font };
          }
        });
      });

      oldSheet.columns?.forEach((col, colIndex) => {
        const newCol = newSheet.getColumn(colIndex + 1);

        newCol.width = col.width;
        newCol.style = col.style || {};
      });

      newSheet.state = 'hidden';
    });

    // Append transformed sheets to new workbook
    Object.keys(source).forEach(name => {
      addJsonToWorkbook(templateWorkbook, source[name], name);
    });

    // Add dropdowns to new workbook
    addDropdowns(templateWorkbook);

    // Populate billing sheets
    populateBillingSheets(templateWorkbook);

    // Write new workbook into a file for download
    const buffer = await templateWorkbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    downloadLink.href = URL.createObjectURL(blob);
  };

  const convertSheetToRowData = (worksheet: ExcelJS.Worksheet): RowData[] => {
    const rowData: RowData[] = [];
    const rows = worksheet.getSheetValues();

    const headerRow = (rows.at(1) as any) || [];
    const valueRows = (rows.slice(2) as any) || [];

    valueRows
      .filter((row: any) => row.length > 0)
      .forEach((row: any) => {
        const data: RowData = {};

        headerRow.forEach((header: any, index: any) => {
          data[header] = row[index];
        });

        rowData.push(data);
      });

    return rowData;
  };

  const validHypercareSheet = (name: string): boolean => {
    let retVal: boolean = false;

    Object.keys(HYPERCARE_IMPORTS).forEach(sheet => {
      if (name.includes(sheet)) {
        retVal = true;
      }
    });

    return retVal;
  };

  const transformData = (str: string, index: number, transforms: string[]) => {
    let newStr = str;

    transforms.forEach(transform => {
      switch (transform) {
        case 'date': {
          const parsedDate = new Date(str);

          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : str;
          break;
        }

        case 'hours_to_number': {
          const validDate = new Date(str);

          if (Number.isNaN(validDate.getTime())) {
            newStr = '';
            break;
          }

          const hours = (str as any).getUTCHours();
          const minutes = (str as any).getUTCMinutes();

          newStr = (hours + minutes / 60).toString();
          break;
        }

        case 'index': {
          newStr = String(index);
          break;
        }

        case 'name': {
          newStr = str.length
            ? str.split(',').reverse().join(' ')
            : (str as any)?.result || '';
          break;
        }

        case 'status': {
          newStr = str.toLowerCase() === 'yes' ? 'Active' : 'Inactive';
          break;
        }

        case 'repeats': {
          if (str?.includes('1 Day(s)')) {
            newStr = 'Daily';
          } else if (str?.includes('1 Week(s)')) {
            newStr = 'Weekly';
          } else if (str?.includes('2 Week(s)')) {
            newStr = 'Bi-weekly';
          } else if (str?.includes('1 Month(s)')) {
            newStr = 'Monthly';
          } else if (str?.includes('2 Month(s)')) {
            newStr = 'Bi-monthly';
          } else if (str?.includes('3 Month(s)')) {
            newStr = 'Quarterly';
          } else if (str?.includes('6 Month(s)')) {
            newStr = 'Semi-annually';
          } else if (str?.includes('12 Month(s)')) {
            newStr = 'Annually';
          } else {
            newStr = str;
          }

          break;
        }

        case 'date_range_start': {
          const dateRange = str.split('/');
          const parsedDate = new Date(dateRange?.[0]);

          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : '-';
          break;
        }

        case 'date_range_end': {
          const dateRange = str.split('/');
          const parsedDate = new Date(dateRange?.[1]);

          newStr = !Number.isNaN(parsedDate.getTime())
            ? parsedDate.toISOString()
            : '-';
          break;
        }

        default:
          break;
      }
    });

    return newStr;
  };

  const convert = (sheetData: any, sheetName: string, mapping: Mapping) => {
    const convertedData: RowData[] = [];
    const newSource = source;
    const rawSource = convertSheetToRowData(sheetData);

    rawSource.forEach((row, index) => {
      const convertedRow: RowData = {};
      const sourceRow = row as any;

      Object.keys(mapping).forEach(key => {
        const convertedField = mapping[key];

        if (convertedField.keys.length > 0) {
          convertedField.keys.forEach(field => {
            if (sourceRow[field]) {
              convertedRow[key] = transformData(
                sourceRow[field],
                index + 1,
                convertedField.transforms || []
              );
            } else {
              convertedRow[key] = '';
            }
          });
        } else if (convertedField.default) {
          convertedRow[key] = convertedField.default;
        } else {
          convertedRow[key] = transformData(
            '',
            index + 1,
            convertedField.transforms || []
          );
        }
      });

      convertedData.push(convertedRow);
    });

    newSource[sheetName] = convertedData;
    setSource(newSource);
  };

  const addJsonToWorkbook = (
    workbook: ExcelJS.Workbook,
    data: any[],
    sheetName: string
  ): void => {
    const worksheet = workbook.addWorksheet(sheetName);
    const mapping = HYPERCARE_SOURCES[sheetName];
    const headers = Object.keys(data[0]);
    const headerNames = headers.map(header => mapping[header].header || header);
    const headerRow = worksheet.addRow(headerNames);

    headerRow.eachCell(cell => {
      cell.font = {
        name: 'Arial',
        size: 10,
        bold: true,
        color: { argb: 'FFFFFFFF' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF38761D' },
      };
    });

    data.forEach(item => {
      const row = headers.map(header => item[header]);

      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column: any) => {
      const values = column.values.filter(
        (value: any) => value !== null && value !== undefined && value !== ''
      );
      const maxLength =
        values.length > 0
          ? Math.max(
              ...values.map((value: any) =>
                value ? value.toString().length : 0
              )
            )
          : 0;

      column.width = Math.max(maxLength + 3, 16);
    });

    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: worksheet.rowCount, column: worksheet.columnCount },
    };
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader {...pageHeaderProps} />

        <Flex className={styles.bodyWrapper} direction="column" gap={5}>
          {/* Description */}
          <M variant="secondary" className={styles.description}>
            {t('web.admin.sidebar.dataImport.converter.description')}
          </M>

          {/* Upload Button */}
          <Flex direction="row" gap={3}>
            <FileInput
              accept="*/*"
              type={FileReturnTypeEnum.File}
              onFileSelected={handleUpload}
            >
              <Button
                className={styles.convertButton}
                variant="secondary"
                onClick={() => {}}
                type="submit"
              >
                {t('web.admin.sidebar.dataImport.converter.upload')}
              </Button>
            </FileInput>
            <M variant="secondary">{destination}</M>
          </Flex>

          {/* Download Button */}
          <Button
            className={styles.convertButton}
            variant="primary"
            onClick={handleDownload}
            type="submit"
            disabled={!downloadActive}
          >
            {loading
              ? 'Loading'
              : t('web.admin.sidebar.dataImport.converter.download')}
          </Button>
        </Flex>
      </div>
    </AdminPage>
  );
};
