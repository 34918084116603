import React from 'react';

import { ChannelContentList } from 'components';

import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

function ChannelNoticesList({ match }: any) {
  const channelId = match.params.id;

  return (
    <ChannelContentList
      channelId={channelId}
      contentType={ContentTypeEnum.Notice}
    />
  );
}

export default ChannelNoticesList;
