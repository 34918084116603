/* eslint-disable react/forbid-component-props */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, IconButton, Input, Label } from 'components';
import { H4 } from 'components/typography';
import { Button, Checkbox, Dropdown, Icon, TextArea } from 'design-system-web';
import _ from 'lodash';

import { MeterReadingOption, MREquipment } from './types';
import styles from './StepTemplate.scss';
import type { StepTemplate as StepTemplateType } from 'graphql-query-contracts';

interface StepTemplateProps {
  index: number;
  meterReadings: MeterReadingOption[];
  mrEquipments: MREquipment[];
  onCopyStep: (step: StepTemplateType) => void;
  onDeleteStep: (id: string) => void;
  step: StepTemplateType;
  updateStep: (step: StepTemplateType) => void;
}

export const StepTemplate = ({
  index,
  meterReadings,
  mrEquipments,
  onCopyStep,
  onDeleteStep,
  step,
  updateStep,
}: StepTemplateProps) => {
  const { t } = useTranslation();
  const [tempChoices, setTempChoices] = useState<(string | null)[]>(
    _.compact(step?.options?.choices)
  );
  const [selectedEquipment, setSelectedEquipment] = useState<string>('');

  const responseTypeOptions = [
    {
      label: t('web.admin.workOrder.steps.responseType.shortText'),
      value: 'short',
    },
    {
      label: t('web.admin.workOrder.steps.responseType.longText'),
      value: 'long',
    },
    {
      label: t('web.admin.workOrder.steps.responseType.numerical'),
      value: 'numerical',
    },
  ];

  const choiceTypeOptions = [
    {
      label: t('web.admin.workOrder.steps.responseType.singleSelect'),
      value: 'single',
    },
    {
      label: t('web.admin.workOrder.steps.responseType.multiSelect'),
      value: 'multiple',
    },
  ];

  const getHeader = () => {
    const type = t(`web.admin.workOrder.steps.${step?.type}.label`);

    return `${index + 1}. ${type}`;
  };

  const handleCopyStep = () => {
    updateStep({
      ...step,
      options: {
        ...step.options,
        choices: tempChoices,
      },
    });

    onCopyStep({
      ...step,
      options: {
        ...step.options,
        choices: tempChoices,
      },
    });
  };

  const handleUpdateDescription = (value: string) => {
    updateStep({
      ...step,
      name: value,
    });
  };

  const handleUpdateSubtype = (option: any) => {
    updateStep({
      ...step,
      options: {
        ...step.options,
        subtype: option?.value,
      },
    });
  };

  const handleChoiceUpdate = (index: number) => {
    const newChoices = step?.options?.choices?.map((choice, i) => {
      if (index === i) {
        return tempChoices[i];
      }

      return choice;
    });

    updateStep({
      ...step,
      options: {
        ...step.options,
        choices: newChoices,
      },
    });
  };

  const handleChoiceTextChange = (value: string, index: number) => {
    const choices = tempChoices.map((choice, i) => {
      if (index === i) {
        return value;
      }

      return choice;
    });

    setTempChoices(choices);
  };

  const handleChoiceRemove = (index: number) => {
    const newChoices = tempChoices.filter((choice, i) => i !== index);

    setTempChoices(newChoices);

    updateStep({
      ...step,
      options: {
        ...step.options,
        choices: newChoices,
      },
    });
  };

  const handleAddChoice = () => {
    const choices = step?.options?.choices || [];
    const newChoices = [...choices, `Option ${choices.length + 1}`];

    setTempChoices(newChoices);

    updateStep({
      ...step,
      options: {
        ...step.options,
        choices: newChoices,
      },
    });
  };

  const handleUpdateEquipment = (option: any) => {
    setSelectedEquipment(option?.value);
  };

  const handleUpdateMeterReading = (option: any) => {
    updateStep({
      ...step,
      name: option?.label,
      options: {
        ...step.options,
        unit:
          meterReadings.find(reading => reading.id === option?.value)?.unit ||
          '',
      },
      meterReadingId: option?.value,
    });
  };

  const handleUpdateMeterDescription = (value: string) => {
    updateStep({
      ...step,
      options: {
        ...step.options,
        description: value,
      },
    });
  };

  useEffect(() => {
    if (step?.type === 'meter') {
      const meterReadingForSelectedEquipment = meterReadings.find(
        reading => reading.id === step.meterReadingId
      );

      if (meterReadingForSelectedEquipment) {
        const selectedEquipment = mrEquipments.find(
          equipment =>
            equipment.id === meterReadingForSelectedEquipment.equipmentId
        );

        setSelectedEquipment(selectedEquipment?.id || '');
      }
    }
  }, [mrEquipments, meterReadings]);

  return (
    <Flex direction="row" className={styles.stepTemplate}>
      <Flex direction="column" className={styles.handlebar}>
        <Icon name="bars" />
      </Flex>
      <Flex direction="column" className={styles.stepContent}>
        <Flex direction="row" className={styles.stepHeader}>
          <H4>{getHeader()}</H4>
          <Flex direction="row">
            <IconButton
              testId="step-copy"
              icon="copy"
              onClick={() => handleCopyStep()}
            />
            <IconButton
              testId="step-delete"
              icon="trash"
              onClick={() => onDeleteStep(step.id ?? '')}
            />
          </Flex>
        </Flex>

        {/* Instruction Step */}
        {step?.type === 'instruction' ? (
          <Flex direction="column">
            <Label mt={0} className={styles.labelText}>
              {t('web.admin.workOrder.steps.description')}
            </Label>
            <TextArea
              testId="step-instruction-description"
              value={step?.name || ''}
              onChange={handleUpdateDescription}
              maxLength={1_000}
              minRows={4}
              showLengthIndicator
            />
          </Flex>
        ) : null}

        {/* Acknowledge Step */}
        {step?.type === 'acknowledge' ? (
          <Flex direction="column">
            <Label mt={0} className={styles.labelText}>
              {t('web.admin.workOrder.steps.description')}
            </Label>
            <TextArea
              testId="step-acknowledge-description"
              value={step?.name || ''}
              onChange={handleUpdateDescription}
              maxLength={1_000}
              showLengthIndicator
            />
            <Label mt={20} className={styles.previewText}>
              {t('web.admin.workOrder.steps.preview')}
            </Label>
            <Checkbox
              className={styles.checkbox}
              disabled
              selected
              value={null}
              onChange={() => {}}
            />
          </Flex>
        ) : null}

        {/* Response Step */}
        {step?.type === 'response' ? (
          <Flex direction="column">
            <Flex direction="row">
              <Flex direction="column" className={styles.promptBox}>
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.prompt')}
                </Label>
                <TextArea
                  testId="step-response-description"
                  value={step?.name || ''}
                  onChange={handleUpdateDescription}
                  maxLength={1_000}
                  showLengthIndicator
                />
              </Flex>
              <Flex direction="column" className={styles.subtypeBox}>
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.responseType')}
                </Label>
                <Dropdown
                  testId="step-response-subtype"
                  value={step?.options?.subtype || 'short'}
                  items={responseTypeOptions}
                  onChange={handleUpdateSubtype as any}
                  doTranslation={false}
                />
              </Flex>
            </Flex>
            <Label mt={0} className={styles.previewText}>
              {t('web.admin.workOrder.steps.preview')}
            </Label>
            {step?.options?.subtype === 'short' ? (
              <TextArea
                disabled
                value={t('web.admin.workOrder.steps.responseType.shortText')}
                onChange={() => {}}
                minRows={1}
                showClear={false}
              />
            ) : null}
            {step?.options?.subtype === 'long' ? (
              <TextArea
                disabled
                value={t('web.admin.workOrder.steps.responseType.longText')}
                onChange={() => {}}
                minRows={3}
                showClear={false}
              />
            ) : null}
            {step?.options?.subtype === 'numerical' ? (
              <Input disabled value={9.1} onChange={() => {}} type="number" />
            ) : null}
          </Flex>
        ) : null}

        {/* Choice Step */}
        {step?.type === 'choice' ? (
          <Flex direction="column">
            <Flex direction="row" className={styles.choiceHeader}>
              <Flex direction="column" className={styles.promptBox}>
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.prompt')}
                </Label>
                <TextArea
                  testId="step-choice-description"
                  value={step?.name || ''}
                  onChange={handleUpdateDescription}
                  maxLength={1_000}
                  showLengthIndicator
                />
              </Flex>
              <Flex direction="column">
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.responseType')}
                </Label>
                <Dropdown
                  testId="step-choice-subtype"
                  value={step?.options?.subtype || 'single'}
                  items={choiceTypeOptions}
                  onChange={handleUpdateSubtype as any}
                  doTranslation={false}
                />
              </Flex>
            </Flex>
            <Label mt={0} className={styles.optionText}>
              {t('web.admin.workOrder.steps.options')}
            </Label>
            {step?.options?.choices?.map((choice, i) => (
              <Flex
                key={`${choice}-${i}`}
                direction="row"
                className={styles.optionRow}
              >
                {step?.options?.subtype === 'single' ? (
                  <Icon name="circle" className={styles.singleSelectIcon} />
                ) : (
                  <Checkbox selected={false} value={null} onChange={() => {}} />
                )}
                <Flex direction="column" className={styles.promptBox}>
                  <TextArea
                    value={tempChoices[i] || ''}
                    onChange={(value: string) =>
                      handleChoiceTextChange(value, i)
                    }
                    onBlur={() => handleChoiceUpdate(i)}
                    showClear
                    maxLength={200}
                  />
                </Flex>
                <IconButton
                  icon="trash"
                  onClick={() => handleChoiceRemove(i)}
                />
              </Flex>
            ))}
            <Button
              variant="text"
              startIcon={<Icon name="plus" />}
              onClick={handleAddChoice}
              fullWidth={false}
              size="medium"
              testId="add-equipment-button"
              className={styles.addChoiceButton}
            >
              {t('web.admin.workOrder.steps.addOption')}
            </Button>
          </Flex>
        ) : null}

        {/* Meter Step */}
        {step?.type === 'meter' ? (
          <Flex direction="column">
            <Flex direction="row" className={styles.meterHelpText}>
              <span>{t('web.admin.workOrder.steps.meterReadingHelpText')}</span>
            </Flex>
            <Flex direction="row" className={styles.meterHeader}>
              <Flex direction="column" className={styles.equipmentDropdown}>
                <Label mt={0} className={styles.labelText}>
                  {t(
                    'web.admin.workOrder.preventiveMaintenance.schedule.Form.equipment'
                  )}
                </Label>
                <Dropdown
                  testId="step-meter-reading"
                  value={selectedEquipment}
                  items={mrEquipments.map(option => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  onChange={handleUpdateEquipment as any}
                  doTranslation={false}
                />
              </Flex>
              <Flex direction="column">
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.meterReadingType')}
                </Label>
                <Dropdown
                  testId="step-meter-reading"
                  value={step?.meterReadingId || ''}
                  items={meterReadings
                    .filter(
                      reading => reading.equipmentId === selectedEquipment
                    )
                    .map(option => ({
                      label: option.name,
                      value: option.id,
                    }))}
                  onChange={handleUpdateMeterReading as any}
                  doTranslation={false}
                />
              </Flex>
            </Flex>
            {typeof step?.options?.description !== 'undefined' ? (
              <Flex direction="column">
                <Label mt={0} className={styles.labelText}>
                  {t('web.admin.workOrder.steps.description')}
                </Label>
                <TextArea
                  testId="step-meter-description"
                  value={step?.options?.description || ''}
                  onChange={handleUpdateMeterDescription}
                  maxLength={1_000}
                  minRows={2}
                  showLengthIndicator
                />
              </Flex>
            ) : (
              <Button
                variant="text"
                startIcon={<Icon name="plus" />}
                onClick={() => handleUpdateMeterDescription('')}
                fullWidth={false}
                size="medium"
                testId="add-description-button"
                className={styles.addDescriptionButton}
              >
                {t('web.admin.workOrder.steps.addDescription')}
              </Button>
            )}
            {step?.meterReadingId ? (
              <Flex direction="column" className={styles.meterPreview}>
                <Label mt={0} className={styles.previewText}>
                  {t('web.admin.workOrder.steps.preview')}
                </Label>
                <Flex direction="row" className={styles.meterReadingPreview}>
                  <Input
                    disabled
                    value={79}
                    onChange={() => {}}
                    type="number"
                    className={styles.meterReadingInput}
                  />
                  <Label mt={0} className={styles.meterReadingUnit}>
                    {step?.options?.unit || ''}
                  </Label>
                </Flex>
              </Flex>
            ) : null}
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};
