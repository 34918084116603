import React, { useState, useMemo, useContext } from 'react';

import { ContentRenderer } from 'components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { ChannelsContext } from 'lane-shared/contexts';
import { createReservableInteraction } from 'lane-shared/graphql/integrations/ShopifyBurst/reservableInteractions';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { useContentQuery } from 'lane-shared/hooks';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { useShopifyPortsQuery } from 'lane-shared/hooks/integrations/shopify/useShopifyPortsQuery';
import useUnavailableDatesForContent from 'lane-shared/hooks/integrations/shopify/useUnavailableDatesForContent';
import {
  SHOPIFY_BURST_MAX_RANGE_SIZE,
  SHOPIFY_BURST_MIN_RANGE_SIZE,
  SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Button } from 'components/general';
import IconButton from 'components/general/IconButton';
import { Modal } from 'components/lds';

import { BurstCreatePageProps } from '../../components/BurstCreateFlow';
import BurstDateSelector from '../../components/BurstDateSelector';

import styles from './BurstContentPage.scss';

export default function BurstContentPage({
  wizardState,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
}: BurstCreatePageProps) {
  const { maxDate } = useShopifyBurstContext();
  const channelData = useContext(ChannelsContext);
  const { ports } = useShopifyPortsQuery(
    channelData.primaryChannel?.parent?._id ?? ''
  );
  const { content } = useContentQuery({
    contentId: wizardState.data.roomId,
    forPublic: true,
    includeInteractions: false,
  });

  const port = useMemo(() => {
    return (
      wizardState.data.port ||
      ports.find(port => port._id === content?.channel?._id)
    );
  }, [wizardState, ports, content]);
  const [longitude, latitude] = port?.address?.geo || [null, null];
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
  const timeZone = getTimeZoneByGeoLocation({ longitude, latitude });

  const minDate = DateTime.local()
    .setZone(timeZone)
    .startOf('day')
    .plus({ weeks: SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE })
    .toJSDate();

  const [timeAvailabilityOpen, setTimeAvailabilityOpen] = useState(false);
  const { t } = useTranslation();

  const range = {
    startDate: wizardState.data.dateRange?.startDate || minDate,
    endDate: wizardState.data.dateRange?.endDate,
  };
  const {
    unavailableDateRanges: unavailableDatesAsDateRanges,
  } = useUnavailableDatesForContent({
    content,
    timeZone,
    startDate: range.startDate,
    endDate: range.endDate || maxDate,
  });

  function updateDateRange(dateRange: LaneType.DateRange | undefined) {
    onDataUpdated({
      dateRange,
    });
  }

  async function handleRoomSelect() {
    if (!wizardState.data.port) {
      onDataUpdated({
        port,
        portTimeZone: timeZone,
      });
    }

    try {
      const interaction = await createReservableInteraction(content!, {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
        startDate: wizardState.data.dateRange?.startDate,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
        endDate: wizardState.data.dateRange?.endDate,
      });

      onDataUpdated({
        primaryRoomBooking: interaction,
      });
      goToNextStep();
    } catch (err) {
      window.Toast.show(err.message.split(':')[1]);
    }
  }

  return (
    <div>
      <div className={styles.backButtonWrapper}>
        <IconButton
          icon="chevron-left"
          iconSet="FontAwesome"
          inverted
          onClick={() => {
            onDataUpdated({
              roomId: undefined,
              port: undefined,
            });
            goToPrevStep();
          }}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <ContentRenderer
            content={content!}
            forAdmin={false}
            hideSubmissionBlocks
          />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {wizardState.data.dateRange?.endDate ? (
          <Button variant="contained" onClick={() => handleRoomSelect()}>
            {t('Select')}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setTimeAvailabilityOpen(true);
            }}
          >
            {t('See Availability')}
          </Button>
        )}
      </div>
      <Modal
        isOpen={timeAvailabilityOpen}
        onClose={() => setTimeAvailabilityOpen(false)}
        className={styles.modal}
        isCloseButtonHidden
        disableContentPadding
      >
        <BurstDateSelector
          timeZone={timeZone}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date' is not assignable to type 'string'.
          startDate={range.startDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
          endDate={range.endDate}
          minRangeSize={SHOPIFY_BURST_MIN_RANGE_SIZE}
          maxRangeSize={SHOPIFY_BURST_MAX_RANGE_SIZE}
          unavailableDateRanges={unavailableDatesAsDateRanges}
          maxDate={maxDate}
          minDate={minDate}
          onChange={interval => {
            updateDateRange(
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DateRange | undefined' is not as... Remove this comment to see the full error message
              interval.startDate && interval.endDate ? interval : undefined
            );
            setTimeAvailabilityOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
