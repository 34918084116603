import { explodeFeatures } from 'lane-shared/helpers/features';

export function checkForPaymentFeatureErrors(
  features: any
): string | undefined {
  const { paymentFeature } = explodeFeatures(features);

  if (paymentFeature?.subscriptionEnabled) {
    const totalCounts = paymentFeature.subscription?.totalCounts;
    const endDateOption = paymentFeature.subscription?.endDateOption;

    // Cannot create subscription which has total count less than 1
    if (endDateOption === 'after' && totalCounts! < 1) {
      return 'abp.publishContent.error.subscriptionTotalCountCannotBeLessThanZero';
    }
  }

  return undefined;
}
