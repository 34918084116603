import React, { useContext } from 'react';

import {
  Loading,
  RedirectRoute,
  SiteHeader,
  PageNotAvailable,
} from 'components';
import { ChannelAdmin } from 'pages';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';

import AndroidSmartBanner from 'components/navigation/AndroidSmartBanner';

import { OnboardingTestingCompany } from './onboardingTestingCompany';
import { OnboardingTestingBuilding } from './onboardingTestingBuilding';

import PortalSidebar from './PortalSidebar';
import TabRenderer from './TabRenderer';
import Channel from './channel';
import NoSubscription from './home/NoSubscriptions';
import Profile from './home/me';
import UserProfile from './home/me/UserProfile';
import ContentInteraction from './interaction';
import Notices from './notices';
import Content from './post';
import Section from './section';
import InteractionsOfSection from './section/interactionsOfSection';
import UserChannelSettings from './settings/UserChannelSettings';
import { UserChannelSettingsV2 } from './settings/UserChannelSettingsV2';
import { RequireUserOnboarded } from '../../RequireUserOnboarded';
import { UserLanguageSettings } from './settings/UserLanguageSettings';

import LegacyInviteModal from 'components/authV2/LegacyInviteModal';
import { InviteModal } from 'components/authV2/InviteModal';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';

import styles from './Portal.scss';

export default function Portal() {
  const { user } = useContext(UserDataContext);
  const { pages, channels, isReady } = useContext(ChannelsContext);
  const isUpdatedChannelSettingsEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportUpdatedChannelSettings,
    false
  );
  const enhancedOnboardingFeatureFlag = useFlag(
    FeatureFlag.EnhancedOnboardingChannelSearch,
    false
  );
  const isAuth0Enabled = useAuth0Enabled();
  const location = useLocation();

  let homeTab: any;

  if (!isReady) {
    homeTab = <Loading fullscreen />;
  } else if (pages?.[0]) {
    homeTab = (
      <TabRenderer
        tabSlug={pages[0].slug}
        channelSlug={pages[0].channel.slug}
      />
    );
  } else if (user && channels?.length === 0) {
    homeTab = <NoSubscription />;
  } else {
    homeTab = <PageNotAvailable className={styles.pageNotAvailable} />;
  }

  return (
    <div className={styles.PortalPage}>
      <section className={styles.body}>
        <AndroidSmartBanner />
        <SiteHeader />
        <section className={styles.content}>
          {isAuth0Enabled ? <InviteModal /> : <LegacyInviteModal />}
          <PortalSidebar />
          <Switch>
            {/* If the user access app.domain.com/l redirect to home to not show empty portal */}
            <RedirectRoute
              exact
              path="/l"
              render={() => null}
              redirectWhen
              redirectTo={routes.home}
            />
            {isAuth0Enabled ? (
              <Route path={routes.home}>
                <RequireUserOnboarded>{homeTab}</RequireUserOnboarded>
              </Route>
            ) : (
              <RedirectRoute
                exact
                path={routes.home}
                render={() => homeTab}
                redirectWhen={!user}
                redirectTo={routes.login}
              />
            )}
            {enhancedOnboardingFeatureFlag && (
              <Route path={routes.onboardingTestBuilding}>
                <OnboardingTestingBuilding />
              </Route>
            )}
            {enhancedOnboardingFeatureFlag && (
              <Route path={routes.onboardingTestCompany}>
                <OnboardingTestingCompany />
              </Route>
            )}
            <Route
              path={routes.customTab}
              render={({ match }) => (
                <TabRenderer
                  tabSlug={match.params.tabSlug}
                  channelSlug={match.params.channelSlug}
                />
              )}
            />
            <Route
              exact
              path={routes.addSubscription}
              component={NoSubscription}
            />
            <Route
              path={routes.content}
              render={({ match, location }) => (
                <Content match={match} location={location} />
              )}
            />
            <Route
              path={routes.section}
              render={({ match }) => <Section match={match} />}
            />
            <Route
              path={routes.channelNotices}
              render={({ match }) => <Notices match={match} />}
            />
            <Route
              path={routes.user}
              render={({ match }) => <UserProfile match={match} />}
            />
            <Route
              exact
              path={routes.interactionsOfSection}
              render={({ match }) => <InteractionsOfSection match={match} />}
            />
            <Route path={routes.me}>
              {isAuth0Enabled && (
                <RequireUserOnboarded>
                  <Profile />
                </RequireUserOnboarded>
              )}
              {!isAuth0Enabled &&
                (user === null ? (
                  <Redirect
                    to={{
                      pathname: routes.login,
                      state: { from: { pathname: location.pathname } },
                    }}
                  />
                ) : (
                  <Profile />
                ))}
            </Route>
            <Route
              path={routes.meChannelSettings}
              component={
                isUpdatedChannelSettingsEnabled
                  ? UserChannelSettingsV2
                  : UserChannelSettings
              }
            />
            <Route
              path={routes.languageSettings}
              component={UserLanguageSettings}
            />
            <Route path={routes.interaction} component={ContentInteraction} />
            <Route path={routes.channelAdmin} component={ChannelAdmin} />
            <Route
              path={routes.channel}
              render={({ match }) => <Channel match={match} user={user} />}
            />
          </Switch>
        </section>
      </section>
    </div>
  );
}
