/* istanbul ignore file */
import { useEffect, useMemo, useRef } from 'react';

import * as LDClient from 'launchdarkly-js-client-sdk';

import useLaunchDarklyUser from 'lane-shared/hooks/useLaunchDarklyUser';
import ILaunchDarklyClient, {
  LaunchDarklyClientEventEnum,
  LaunchDarklyEventCallback,
  LaunchDarklyEvents,
  LDFlagSet,
} from 'lane-shared/types/LaunchDarkly';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';

type LDFlagChangeEvent = {
  [key: string]: { current: any; previous: any | undefined };
};

type LaunchDarklyWebClientConfig = {
  clientId: string;
  whitelabelName?: string;
  onReady: () => void;
};

export default function useLaunchDarklyWebClient({
  clientId,
  whitelabelName,
  onReady,
}: LaunchDarklyWebClientConfig): ILaunchDarklyClient {
  const client = useRef<ReturnType<typeof LDClient.initialize> | undefined>(
    undefined
  );
  const { lDUser, setUser, unsetUser, setAttribute } = useLaunchDarklyUser({
    platform: PlatformEnum.Web,
    onChange: lDUser => {
      if (client.current) {
        client.current.identify(lDUser);
      }
    },
    whiteLabelName: whitelabelName,
  });
  const handleReadyRef = useRef<(() => void) | undefined>(undefined);
  const handleChangeRef = useRef<(() => void) | undefined>(undefined);

  const eventHandlersRef = useRef<
    Array<[event: LaunchDarklyEvents, cb: LaunchDarklyEventCallback]>
  >([]);

  useEffect(() => {
    return () => {
      if (client.current) {
        if (handleReadyRef.current) {
          client.current.off('ready', handleReadyRef.current);
          handleReadyRef.current = undefined;
        }

        if (handleChangeRef.current) {
          client.current.off('change', handleChangeRef.current);
          handleChangeRef.current = undefined;
        }

        client.current.close();
        client.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    function handleReady() {
      eventHandlersRef.current
        .filter(([e]) => e === LaunchDarklyClientEventEnum.ready)
        .forEach(([_, cb]) => cb(client.current!.allFlags()));
      onReady();
    }

    handleReadyRef.current = handleReady;

    function handleChange(e: LDFlagChangeEvent) {
      const nextFlags = Object.keys(e).reduce<LDFlagSet>((acc, k) => {
        acc[k] = e[k]!.current;

        return acc;
      }, {});

      eventHandlersRef.current
        .filter(([e]) => e === LaunchDarklyClientEventEnum.change)
        .forEach(([_, cb]) => cb(nextFlags));
    }

    client.current = LDClient.initialize(clientId, lDUser);
    client.current.on('ready', handleReady);
    client.current.on('change', handleChange);
  }, []);

  const launchDarklyClient = useMemo<ILaunchDarklyClient>(() => {
    return {
      on(event: `${LaunchDarklyClientEventEnum}`, cb) {
        eventHandlersRef.current = [...eventHandlersRef.current, [event, cb]];
      },
      off(event: `${LaunchDarklyClientEventEnum}`, cb) {
        eventHandlersRef.current = eventHandlersRef.current.filter(
          ([e, handler]) => !(e === event && handler === cb)
        );
      },
      setUser,
      unsetUser,
      setAttribute,
    };
  }, [setUser, unsetUser, setAttribute]);

  return launchDarklyClient;
}
