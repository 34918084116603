import React, { useEffect } from 'react';

import { HIDAppleWalletAdditionalProperties } from 'lane-shared/helpers/integrations/AccessManagement/identityProvider';
import {
  AccessControlServiceEntity,
  CCureVisitorConfigProperties,
  GeneaVisitorConfigProperties,
  GenetecVisitorConfigProperties,
  KastleServiceProperties,
  SipassVisitorConfigProperties,
} from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import type {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';

import PropertyComponent from 'components/builder/properties/input/Property';
import { H4, Text } from 'components/typography';

import { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { LoadSettingsButton } from './LoadSettingsButton';

import styles from './AccessManagementEditor.scss';
import { CredentialTemplateSelector } from '../CredentialTemplateSelector/CredentialTemplateSelector';
import { AccessControlMapping } from 'components/integrations/ChannelIntegrationEditor/AccessControlMapping/AccessControlMapping';
import { TenantMapping } from 'components/integrations/ChannelIntegrationEditor/TenantMapping/TenantMapping';
import { CredentialRange } from 'components/integrations/ChannelIntegrationEditor/CredentialRange/CredentialRange';
import { ConfigurableInputField } from 'components/integrations/ChannelIntegrationEditor/ConfigurableInputField/ConfigurableInputField';

type RenderInputProps = {
  properties: PropertiesInterface;
  onUpdateSettings: ({ key, value }: { key: string; value: any }) => void;
  channel: ChannelIntegrationEditorProps['channel'];
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  buttonCallback?: () => void;
  metadataLoading?: boolean;
  metadataError?: string;
  metadataPopulated?: boolean;
  forCreate?: boolean;
  locationSelected?: boolean;
  acsProviderValue?: string;
  disableInput?: boolean;
};

enum IntegrationInputs {
  LoadSettingsButton = 'loadSettingsButton',
  CredentialTemplateSelector = 'credentialTemplateSelector',
  AccessGroupMappings = 'visitorAccessGroupMappings',
  TenantMappings = 'tenantMappings',
  CredentialRange = 'visitorCredentialRange',
  Heading = 'heading',
  ConfigurableInputField = 'configurableInputField',
}

export function RenderInputs({
  onUpdateSettings,
  buttonCallback,
  metadataLoading,
  metadataError,
  metadataPopulated,
  properties,
  channel,
  settings,
  forCreate,
  locationSelected,
  acsProviderValue,
  disableInput,
}: RenderInputProps) {
  const genetecCardholderGroupSetting = useFlag<boolean>(
    FeatureFlag.GenetecCardholderGroupsFilter,
    false
  );

  useEffect(() => {
    validateMetadataRequestFields(properties, settings);
    setTemplateListValidation(properties, settings);
    Object.entries(properties).forEach(([key, property]) => {
      if (settings?.[key] === undefined && property.default !== undefined) {
        onUpdateSettings({ key, value: property.default });
      }
    });
  }, [onUpdateSettings, properties, settings]);

  function validateMetadataRequestFields(
    properties: RenderInputProps['properties'],
    settings: RenderInputProps['settings']
  ): boolean {
    const metadataRequestProperties = [];

    for (const key in properties) {
      if (
        properties[key]?.tags &&
        properties[key]?.tags?.includes('metadataFieldRequest')
      ) {
        metadataRequestProperties.push(key);
      }
    }

    let isAnyEmpty = false;

    metadataRequestProperties.forEach(key => {
      if (settings?.[key] === undefined || settings?.[key] === '') {
        isAnyEmpty = true;
      }
    });

    return isAnyEmpty;
  }

  function isFacilityCodeEnabled(
    properties: RenderInputProps['properties'],
    settings: RenderInputProps['settings']
  ): boolean {
    if ('facilityCodeEnabled' in settings) {
      return settings.facilityCodeEnabled && 'facilityCode' in properties;
    }

    return 'facilityCode' in properties;
  }

  function isVisitorFacilityCodeEnabled(
    properties: RenderInputProps['properties'],
    settings: RenderInputProps['settings']
  ): boolean {
    if ('visitorFacilityCodeEnabled' in settings) {
      return (
        settings.visitorFacilityCodeEnabled &&
        'visitorFacilityCode' in properties
      );
    }

    return 'visitorFacilityCode' in properties;
  }

  function setTemplateListValidation(
    properties: RenderInputProps['properties'],
    settings: RenderInputProps['settings']
  ) {
    if (properties?.templates?.validators?.length) {
      if (settings?.templates?.length === 0) {
        properties.templates.validators.push({
          name: 'Required',
          value: true,
        });
      } else {
        properties.templates.validators = properties?.templates?.validators.filter(
          (validator: any) => validator.name !== 'Required'
        );
      }
    }
  }

  const hideCardholderGroup =
    !settings ||
    !settings?.cardholderGroupsFilterEnabled ||
    !genetecCardholderGroupSetting;

  const hideKastleFields = (
    key: string,
    acsProviderValue: string,
    settings: any
  ) => {
    return (
      (!settings?.visitorManagementEnabled &&
        key !== 'visitorManagementEnabled' &&
        Object.keys(KastleServiceProperties).find(
          property => property === key && key !== 'heading'
        )) ||
      (key === 'configurableInputField' && !settings.buildingId)
    );
  };

  const hideCCureFields = (key: string, settings: any) => {
    return (
      !settings?.visitorManagementEnabled &&
      key !== 'visitorManagementEnabled' &&
      Object.keys(CCureVisitorConfigProperties).find(
        property => property === key
      )
    );
  };

  return (
    <>
      {Object.entries(properties).map(([key, property]) => {
        if (
          (key === 'facilityCode' &&
            !isFacilityCodeEnabled(properties, settings)) ||
          (key === 'visitorFacilityCode' &&
            !isVisitorFacilityCodeEnabled(properties, settings)) ||
          (key === 'partitionId' && !settings?.partitionIdEnabled) ||
          (key === 'locationUdf' && !settings?.locationUdfEnabled) ||
          (acsProviderValue === AccessControlServiceEntity.Genea &&
            Object.keys(GeneaVisitorConfigProperties).find(
              property => property === key
            ) &&
            !settings?.visitorManagementEnabled) ||
          (acsProviderValue === AccessControlServiceEntity.Genetec &&
            Object.keys(GenetecVisitorConfigProperties).find(
              property => property === key
            ) &&
            !settings?.visitorManagementEnabled) ||
          (acsProviderValue === AccessControlServiceEntity.Sipass &&
            Object.keys(SipassVisitorConfigProperties).find(
              property => property === key
            ) &&
            !settings?.visitorManagementEnabled) ||
          (acsProviderValue === AccessControlServiceEntity.Kastle &&
            hideKastleFields(key, acsProviderValue, settings)) ||
          (acsProviderValue === AccessControlServiceEntity.CCure &&
            hideCCureFields(key, settings)) ||
          (key === 'cardholderGroups' && hideCardholderGroup) ||
          ((key === 'cardholderGroupsFilterEnabled' ||
            key === 'mobAccessUdf') &&
            !genetecCardholderGroupSetting) ||
          property.forDisplay === false ||
          (!settings?.isAppleWalletEnabled &&
            Object.keys(HIDAppleWalletAdditionalProperties).find(
              property => property === key
            ))
        ) {
          return null;
        }

        return (
          <div key={key}>
            {property.description && property.type === 'Media' ? (
              <Text mb={7} size="large">
                {property.description}
              </Text>
            ) : null}
            <div className={styles.property}>
              <IntegrationsComponent
                type={key as IntegrationInputs}
                props={{
                  disableInput,
                  property,
                  metadataLoading,
                  metadataError,
                  metadataPopulated,
                  properties,
                  settings,
                  onUpdateSettings,
                  channel,
                  buttonCallback,
                  validateMetadataRequestFields,
                  forCreate,
                  locationSelected,
                  acsProviderValue,
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

type AdditionalProps = {
  validateMetadataRequestFields: (
    properties: RenderInputProps['properties'],
    settings: RenderInputProps['settings']
  ) => boolean;
  property: PropertyType<PropertiesInterface>;
};

function IntegrationsComponent({
  type,
  props,
}: {
  type: IntegrationInputs;
  props: RenderInputProps & AdditionalProps;
}) {
  const tenantKey = (acsProviderValue: string | undefined) => {
    if (acsProviderValue === AccessControlServiceEntity.Genea) {
      return 'geneaTenantIDs';
    }

    // only for genetec, sipass and ccure
    return 'vtsTenantIDs';
  };

  const tenantMappingKey = (acsProviderValue: string | undefined) => {
    if (acsProviderValue === AccessControlServiceEntity.Genea) {
      return 'geneaTenantId';
    }

    // only for kastle
    return 'acsTenantId';
  };

  switch (type) {
    case IntegrationInputs.LoadSettingsButton:
      return (
        <LoadSettingsButton
          loading={props.metadataLoading}
          error={props.metadataError}
          callback={props.buttonCallback || (() => {})}
          disabled={
            props.disableInput ||
            props.validateMetadataRequestFields(
              props.properties,
              props.settings
            )
          }
        />
      );
    case IntegrationInputs.CredentialTemplateSelector:
      return (
        <>
          <LoadSettingsButton
            loading={props.metadataLoading}
            error={props.metadataError}
            callback={props.buttonCallback || (() => {})}
            disabled={
              props.disableInput ||
              props.validateMetadataRequestFields(
                props.properties,
                props.settings
              )
            }
            textContent="web.admin.channel.integrations.access.access-control-settings.load-credential-settings"
          />

          <CredentialTemplateSelector
            properties={props.properties}
            channel={props.channel}
            settings={props.settings}
            metadataPopulated={props.metadataPopulated}
            metadataLoading={props.metadataLoading}
          />
        </>
      );
    case IntegrationInputs.AccessGroupMappings:
      return (
        <AccessControlMapping
          label={props.property.friendlyName || props.property.name || type}
          propertyKey={type}
          property={props.property}
          onChange={(value: any) => {
            props.onUpdateSettings({ key: type, value });
          }}
          metadataLoading={props.metadataLoading}
          settings={props.settings}
          forCreate={props.forCreate}
          tenantKey={tenantKey(props?.acsProviderValue)}
          acsProviderValue={props?.acsProviderValue}
          channel={props.channel}
        />
      );
    case IntegrationInputs.TenantMappings:
      return (
        <TenantMapping
          channel={props.channel}
          label={props.property.friendlyName || props.property.name || type}
          property={props.property}
          value={props.settings?.[type]}
          onChange={(value: any) =>
            props.onUpdateSettings({ key: type, value })
          }
          metadataLoading={props.metadataLoading}
          settings={props.settings}
          forCreate={props.forCreate}
          tenantKey={tenantMappingKey(props?.acsProviderValue)}
          acsProviderValue={props?.acsProviderValue || ''}
        />
      );
    case IntegrationInputs.CredentialRange:
      return (
        <CredentialRange
          property={props.property}
          propertyKey={type}
          value={props.settings}
          error={props.metadataError}
          onChange={(key: string, value: any) =>
            props.onUpdateSettings({ key, value })
          }
        />
      );
    case IntegrationInputs.ConfigurableInputField:
      return (
        <ConfigurableInputField
          disabled={!props.property?.editable}
          propertyKey={type}
          settings={props.settings}
          label={props.property.friendlyName as string}
        />
      );
    case IntegrationInputs.Heading:
      return <H4 mb={4}>{props?.property?.friendlyName}</H4>;

    default:
      return (
        <PropertyComponent
          object={props.settings}
          channel={props.channel}
          label={props.property.friendlyName || props.property.name || type}
          property={props.property}
          propertyKey={type}
          value={props.settings?.[type]}
          onChange={(value: any) =>
            props.onUpdateSettings({ key: type, value })
          }
          disabled={
            props.disableInput ||
            (!props.metadataPopulated &&
              props.property.tags?.includes('metadataFieldResponse')) ||
            (props.property.tags?.includes('locationSelected') &&
              !props.locationSelected)
          }
          onPropertyChange={() => null}
        />
      );
  }
}
