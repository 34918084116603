import { useQuery } from '@apollo/client';
import { Loading } from 'design-system-web/components/Loading/Loading';
import { getChannel } from 'lane-shared/graphql/query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NotFoundError } from 'activate-errors';
import { ErrorPage } from 'components';
import { getDisplayName } from 'lane-shared/helpers';

export const ChannelProfile = () => {
  const { channelSlug }: { channelSlug: string } = useParams();
  const { data, error, loading } = useQuery(getChannel, {
    variables: { slug: channelSlug },
    fetchPolicy: 'cache-and-network',
  });

  const { channel } = data ?? {};

  if (loading) {
    return <Loading />;
  }

  if (error || !channel) {
    return (
      <ErrorPage
        error={error ?? new NotFoundError('Channel is not available')}
      />
    );
  }

  return <div>Profile page for {getDisplayName(channel)}</div>;
};
