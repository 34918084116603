import React from 'react';

import { Button } from 'design-system-web';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { HookFormInput } from 'components/reactHookForm';

import { MarketingEmailOptIn } from '../MarketingEmailOptIn';
import { TermsAndConditionsOptIn } from '../TermsAndConditionsOptIn';

import styles from './index.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

const TRANSLATION_KEYS = {
  fullNameLabel: 'web.onboarding.userProfile.fullNameLabel',
  firstNameLabel: 'web.onboarding.userProfile.firstNameLabel',
  lastNameLabel: 'web.onboarding.userProfile.lastNameLabel',
  button: 'web.onboarding.userProfile.continueButton',
  requiredFieldError: 'web.onboarding.userProfile.requiredField.error',
  textLengthError: 'web.onboarding.userProfile.textLength.error',
  invalidCharactersError: 'web.onboarding.userProfile.invalidCharacters.error',
};

type FormData = {
  fullName: string;
  firstName: string;
  lastName: string;
  marketingOptIn: boolean;
  termsAndConditionsOptIn: boolean;
};

export type ProfileData = Pick<FormData, 'fullName' | 'marketingOptIn'>;

type Props = {
  defaultUserName?: string;
  onSubmit: (data: ProfileData) => void;
  whitelabel: Pick<
    WhiteLabelType,
    | 'termsAndConditionsUrls'
    | 'privacyPolicyUrls'
    | 'complianceContact'
    | 'name'
  >;
};

const allowCharactersPattern = /^(?!\s*$)[-' a-zA-Z]+$/;

export const ProfileForm = ({
  defaultUserName,
  onSubmit,
  whitelabel,
}: Props) => {
  const { t } = useTranslation();

  const isNewOnboardingFeatureEnabled = useFlag(
    FeatureFlag.OnboardingProfileFirstAndLastName,
    false
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      fullName: defaultUserName || '',
      firstName: '',
      lastName: '',
      marketingOptIn: false,
      termsAndConditionsOptIn: false,
    },
  });

  const getErrorMessage = (field: keyof FormData) => {
    const fieldError = errors[field];
    if (fieldError?.type === 'required') {
      return [t(TRANSLATION_KEYS.requiredFieldError)];
    }
    if (fieldError?.type === 'minLength') {
      return [t(TRANSLATION_KEYS.textLengthError)];
    }
    if (fieldError?.type === 'pattern') {
      return [t(TRANSLATION_KEYS.invalidCharactersError)];
    }
    return null;
  };

  const handleFormSubmission = (data: FormData) => {
    const fullName = isNewOnboardingFeatureEnabled
      ? `${data.firstName.trim()} ${data.lastName.trim()}`
      : data.fullName.trim();

    onSubmit({
      fullName,
      marketingOptIn: data.marketingOptIn,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmission)}>
      {isNewOnboardingFeatureEnabled ? (
        <>
          {/* Separate first and last name fields */}
          <HookFormInput
            control={control}
            isRequired
            name="firstName"
            label={TRANSLATION_KEYS.firstNameLabel}
            minLength={2}
            errorMessage={getErrorMessage('firstName')}
            pattern={{ value: allowCharactersPattern }}
          />
          <br />
          <HookFormInput
            control={control}
            isRequired
            name="lastName"
            label={TRANSLATION_KEYS.lastNameLabel}
            minLength={2}
            errorMessage={getErrorMessage('lastName')}
            pattern={{ value: allowCharactersPattern }}
          />
        </>
      ) : (
        // Single full name field
        <HookFormInput
          control={control}
          isRequired
          name="fullName"
          label={TRANSLATION_KEYS.fullNameLabel}
          minLength={2}
          errorMessage={getErrorMessage('fullName')}
        />
      )}

      <div className={styles.termsAndConditions}>
        <TermsAndConditionsOptIn
          control={control}
          whitelabel={{
            name: whitelabel.name,
            termsAndConditionsUrls: whitelabel.termsAndConditionsUrls,
            privacyPolicyUrls: whitelabel.privacyPolicyUrls,
          }}
        />
      </div>
      <div className={styles.marketingEmail}>
        <MarketingEmailOptIn
          control={control}
          complianceContact={whitelabel?.complianceContact}
        />
      </div>
      <Button
        className={styles.button}
        fullWidth
        size="large"
        type="submit"
        disabled={!isValid}
      >
        {t(TRANSLATION_KEYS.button)}
      </Button>
    </form>
  );
};
