import { ModuleDefinition } from 'lane-shared/types/modules/moduleDefinition';

export default function hasModuleEnabled(
  channelModules: ModuleDefinition[],
  moduleName: string
) {
  if (!channelModules || !channelModules.length) {
    return false;
  }

  return channelModules.some(
    channelModule =>
      channelModule.name === moduleName && !channelModule.disabled
  );
}
