/* eslint-disable react/forbid-component-props */
import React, { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Input } from 'design-system-web';
import {
  ContentFilterStatus,
  Flex,
  IconButton,
  ButtonStrip,
  ContentCard,
} from 'components';
import { ButtonLink } from 'lane-web/src/components/general/ButtonLink';
import { PresetContentSort } from 'lane-shared/types/filters/PresetContentSort';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { SectionTypeEnum } from 'lane-shared/types/sections/SectionTypeEnum';
import { SectionFilters } from './SectionFilters';
import { AddPageModal } from '../../AddPageModal';
import { useUpdateSection } from 'lane-shared/hooks/useUpdateSection';
import useFlag from 'lane-shared/hooks/useFlag';
import useSectionContentForAdmin from 'lane-shared/hooks/useSectionContentForAdmin';
import { CustomizationPanel } from '../CustomizationPanel';
import SectionContentTable from './SectionContentTable';
import { isEqual, isUndefined, pick } from 'lodash';
import {
  headers,
  views,
  VIEW_TYPE,
  VIEW_LIST,
  VIEW_GRID,
  COMPARABLE_SECTION_PROPERTIES,
} from '../../../constants';
import { Link } from 'react-router-dom';
import { routes } from 'lane-shared/config';
import Section from 'lane-web/src/pages/portal/section/index';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import cx from 'classnames';

import type { SectionType } from 'lane-shared/types/sections/SectionType';
import type { ChannelType } from 'lane-shared/types/ChannelType';
import type { DocumentType } from 'lane-shared/types/DocumentType';

import styles from './styles.scss';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useMutation } from '@apollo/client';
import {
  pinSectionContent,
  reorderSection,
  unpinSectionContent,
} from 'graphql-queries';
import { v4 as uuid } from 'uuid';

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { SectionContentType } from './types';
import { DynamicSectionContentCard } from './DynamicSectionContentCard';
import { AnalyticsContext, UserDataContext } from 'lane-shared/contexts';
import { emitPinContent } from 'lane-shared/helpers/analytics/sections/emitPinContent';
import { emitUnpinContent } from 'lane-shared/helpers/analytics/sections/emitUnpinContent';
import { emitReorderContent } from 'lane-shared/helpers/analytics/sections/emitReorderContent';
import { emitViewButtonClicked } from 'lane-shared/helpers/analytics/sections/emitViewButtonClicked';

type PreviewProps = {
  section: SectionType;
  channel: ChannelType;
  selectedView: VIEW_TYPE;
  selectedOrder: string;
  selectedSort: string;
  reverseOrder: string;
  makeUrl: (value: any) => string;
};

const platformButtons = [
  {
    value: PlatformEnum.Web,
    name: 'web.admin.channel.content.layout.editor.blockBuilder.webButton',
    icon: 'desktop',
  },
  {
    value: PlatformEnum.Mobile,
    name: 'web.admin.channel.content.layout.editor.blockBuilder.mobileButton',
    icon: 'mobile',
  },
];

export const Preview = ({
  section,
  channel,
  selectedSort,
  selectedOrder,
  reverseOrder,
  selectedView,
  makeUrl,
}: PreviewProps) => {
  const { t } = useTranslation();
  const { location } = useLocation();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [sectionGridKey, setSectionGridKey] = useState<null | string>(null);
  const [platform, setPlatform] = useState(PlatformEnum.Web);
  const [search, setSearch] = useState('');
  const isPinningEnabled = useFlag(FeatureFlag.PinningSectionContent, false);
  const [sectionRefreshTrigger, setSectionRefreshTrigger] = useState<
    string | null
  >(null);
  const [sectionContentFE, setSectionContentFE] = useState<
    Array<SectionContentType>
  >([]);

  const {
    addContent,
    removeContent,
    sectionContent,
    refetch,
  } = useSectionContentForAdmin({
    sectionId: section?._id,
    searchOptions: {
      search,
    },
  });

  const [pinSectionContentMutation] = useMutation(pinSectionContent);
  const [unpinSectionContentMutation] = useMutation(unpinSectionContent);
  const [reorderSectionContentMutation] = useMutation(reorderSection);

  const {
    onSectionUpdated,
    section: updatedSection,
    saveSection,
  } = useUpdateSection(section);
  const analytics = useContext(AnalyticsContext);
  const { user } = useContext(UserDataContext);

  const onAddContent = async (content: DocumentType | null | undefined) => {
    setIsAddOpen(false);

    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.view.addContent.title', {
          contentName: content?.name,
        }),
        message: t('web.admin.content.sections.view.addContent.message', {
          contentName: content?.name,
        }),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    window.Alert.loading({
      title: t('web.admin.content.sections.view.adding'),
    });

    try {
      await addContent(content!);
      window.Toast.show(
        t('web.admin.content.sections.view.addContent.toast', {
          contentName: content?.name,
        })
      );
      window.Alert.hide();
    } catch (error) {
      window.Alert.alert({
        title: t('web.admin.content.sections.view.addContentError.title', {
          contentName: content?.name,
        }),
        message: t('web.admin.content.sections.view.addContentError.message'),
        error,
      });
    }

    refetch();
  };

  const onRemoveContent = async (sectionContent: SectionContentType) => {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.view.removeContent.title', {
          contentName: sectionContent.content.name,
        }),
        message: t('web.admin.content.sections.view.removeContent.title', {
          contentName: sectionContent.content.name,
          sectionName: section.name,
        }),
      });
    } catch (err) {
      return;
    }

    window.Alert.loading({
      title: t('web.admin.content.sections.view.removing'),
    });

    try {
      await removeContent(sectionContent);
      window.Toast.show(
        t('web.admin.content.sections.view.removeContent.toast', {
          contentName: sectionContent.content.name,
        })
      );
      window.Alert.hide();
    } catch (error) {
      await window.Alert.alert({
        title: t('web.admin.content.sections.view.removeContentError.title', {
          contentName: sectionContent.content.name,
        }),
        error,
      });
    }
  };

  const isSortedByDistance = Boolean(
    section?.sorts?.includes(PresetContentSort.Location)
  );

  const onPinContent = async (contentId: string) => {
    try {
      await pinSectionContentMutation({
        variables: {
          contentId,
          sectionId: section?._id,
        },
      });

      window.Toast.show(
        t(
          'web.admin.content.sections.view.availableTabs.preview.pin.action.success.message'
        )
      );

      if (selectedView === VIEW_LIST) {
        refetch();
      } else {
        setSectionRefreshTrigger(uuid());
      }

      const contentName = sectionContent.find(
        item => item.content._id === contentId
      )?.content.name;
      const channelName = channel.name;

      if (user && !isUndefined(channelName) && !isUndefined(contentName)) {
        emitPinContent({
          userId: user?._id,
          channelId: channel._id,
          channelName,
          contentId,
          contentName,
          sectionId: section._id,
          sectionName: section.name,
          analytics,
        });
      } else {
        console.error(
          'Could not send analytics for pin content. One of the required parameters is not defined',
          { user, channelName, contentName }
        );
      }
    } catch (err) {
      window.Toast.show(err.message);
      console.error('pinSectionContentMutation error: ', err);
    }
  };

  const onUnpinContent = async (contentId: string) => {
    try {
      await unpinSectionContentMutation({
        variables: {
          contentId,
          sectionId: section?._id,
        },
      });

      window.Toast.show(
        t(
          'web.admin.content.sections.view.availableTabs.preview.unpin.action.success.message'
        )
      );

      if (selectedView === VIEW_LIST) {
        refetch();
      } else {
        setSectionRefreshTrigger(uuid());
      }

      const contentName = sectionContent.find(
        item => item.content._id === contentId
      )?.content.name;
      const channelName = channel.name;

      if (user && !isUndefined(channelName) && !isUndefined(contentName)) {
        emitUnpinContent({
          userId: user?._id,
          channelId: channel._id,
          channelName,
          contentId,
          contentName,
          sectionId: section._id,
          sectionName: section.name,
          analytics,
        });
      } else {
        console.error(
          'Could not send analytics for unpin content. One of the required parameters is not defined',
          { user, channelName, contentName }
        );
      }
    } catch (err) {
      window.Toast.show(err.message);
      console.error('unpinSectionContentMutation error: ', err);
    }
  };

  const updateFeVariable = (oldIndex: number, newIndex: number) => {
    const results = arrayMove(sectionContentFE, oldIndex, newIndex);
    // Note: we did not use `array.sort` here as we want to retain sort order
    const pinned = results.filter(item => item.isPinned);
    const unpinned = results.filter(item => !item.isPinned);

    setSectionContentFE(pinned.concat(unpinned));
  };

  const onDragContentGridView = async (event: DragEndEvent) => {
    const { active, over } = event;
    const contentId = active.id as string;

    if (active.id === over?.id) {
      return;
    }

    const oldIndex = sectionContentFE.findIndex(
      item => item.content._id === active.id
    );
    const newIndex = sectionContentFE.findIndex(
      item => item.content._id === over?.id
    );

    updateFeVariable(oldIndex, newIndex);

    try {
      await reorderSectionContentMutation({
        variables: {
          contentId,
          sectionId: section?._id,
          order: newIndex,
        },
      });

      window.Toast.show(t('Changes saved'));

      const contentName = sectionContent.find(
        item => item.content._id === active.id
      )?.content.name;
      const channelName = channel.name;

      if (user && !isUndefined(channelName) && !isUndefined(contentName)) {
        emitReorderContent({
          userId: user?._id,
          channelId: channel._id,
          channelName,
          contentId,
          contentName,
          sectionId: section._id,
          sectionName: section.name,
          oldPosition: oldIndex + 1,
          newPosition: newIndex + 1,
          analytics,
        });
      } else {
        console.error(
          'Could not send analytics for unpin content. One of the required parameters is not defined',
          { user, channelName, contentName }
        );
      }
    } catch (err) {
      window.Toast.show(err.message);
      console.error('reorderSectionContentMutation error: ', err);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const pinnedItems = useMemo(
    () => sectionContentFE.filter(item => item.isPinned),
    [sectionContentFE]
  );
  const sectionContentIds = useMemo(
    () => sectionContentFE?.map(item => item._id),
    [sectionContentFE]
  );

  const onViewButtonClick = (contentId: string) => {
    const contentName = sectionContent.find(
      item => item.content._id === contentId
    )?.content.name;
    const channelName = channel.name;

    if (user && !isUndefined(channelName) && !isUndefined(contentName)) {
      emitViewButtonClicked({
        userId: user?._id,
        channelId: channel._id,
        channelName,
        contentId,
        contentName,
        sectionId: section._id,
        sectionName: section.name,
        analytics,
      });
    } else {
      console.error(
        'Could not send analytics for view button click. One of the required parameters is not defined',
        { user, channelName, contentName }
      );
    }
  };

  const sectionContentGridView = (
    sectionContents: SectionContentType[],
    areFiltersApplied: boolean = false
  ) => {
    return isPinningEnabled && section?.type === SectionTypeEnum.Dynamic ? (
      <>
        {areFiltersApplied && pinnedItems.length > 0 && (
          <p className={styles.messageForManualSortingDisablement}>
            <Trans
              i18nKey="web.admin.content.sections.view.availableTabs.preview.userInfoMessage.sortDisabled"
              components={[<strong key="note" />]}
            />
          </p>
        )}
        <ul className={cx([styles.sectionContentList, styles[platform]])}>
          <DndContext onDragEnd={onDragContentGridView} sensors={sensors}>
            <SortableContext items={sectionContentIds}>
              {sectionContents?.map((sectionContent: any) => (
                <DynamicSectionContentCard
                  key={sectionContent._id}
                  channelSlug={channel?.slug}
                  sectionContent={sectionContent}
                  isSortedByDistance={isSortedByDistance}
                  location={location}
                  disabled={
                    !sectionContent.isPinned ||
                    areFiltersApplied ||
                    pinnedItems.length === 1
                  }
                  onUnpinContent={onUnpinContent}
                  onPinContent={onPinContent}
                  filterStatus={
                    <ContentFilterStatus
                      content={sectionContent.content}
                      className={styles.contentFilterStatus}
                      metatags={updatedSection?.sectionMetatags?.map(
                        ({ metatag }) => metatag
                      )}
                      filters={section?.filters}
                      sorts={section?.sorts}
                    />
                  }
                  onViewButtonClick={onViewButtonClick}
                />
              ))}
            </SortableContext>
          </DndContext>
        </ul>
      </>
    ) : (
      <ul className={cx([styles.sectionContentList, styles[platform]])}>
        {sectionContents?.map(sectionContent => (
          <li key={sectionContent._id}>
            <div className={styles.contentContainer}>
              <Flex direction="row" align="center">
                {section?.type === SectionTypeEnum.Static && (
                  <IconButton
                    data-test="buttonRemove"
                    onClick={() => onRemoveContent(sectionContent)}
                    icon="trash-alt"
                  />
                )}
                <Link
                  to={routes.channelAdminContent
                    .replace(':id', channel?.slug)
                    .replace(':contentId', sectionContent.content._id)}
                >
                  <ContentCard
                    className={styles.content}
                    content={sectionContent.content}
                    showDistance={isSortedByDistance}
                    location={location}
                  />
                </Link>
              </Flex>
              <ContentFilterStatus
                content={sectionContent.content}
                className={styles.contentFilterStatus}
                metatags={updatedSection?.sectionMetatags?.map(
                  ({ metatag }) => metatag
                )}
                filters={section?.filters}
                sorts={section?.sorts}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Flex gap={5}>
        <div className={styles.contentPreviewContainer}>
          <div className={styles.previewHeader}>
            {selectedView === VIEW_GRID ? (
              <ButtonStrip
                buttons={platformButtons}
                onClick={value => setPlatform(value)}
                selected={platform}
              />
            ) : (
              <Input
                value={search}
                onChange={search => setSearch(search)}
                icon="search"
                showClear
              />
            )}

            <Flex>
              <Flex>
                {views.map(view => (
                  <Link key={view} to={makeUrl({ view })}>
                    <IconButton
                      icon={view}
                      selected={selectedView === view}
                      inverted
                    />
                  </Link>
                ))}
              </Flex>
              {section?.type === SectionTypeEnum.Static && (
                <Flex gap={5}>
                  <ButtonLink
                    size="large"
                    to={routes.channelAdminPageCenter.replace(
                      ':id',
                      channel?._id
                    )}
                  >{t`web.admin.content.sections.view.newPage`}</ButtonLink>
                  <Button size="large" onClick={() => setIsAddOpen(true)}>
                    {t`web.admin.content.sections.view.addContent`}
                  </Button>
                </Flex>
              )}
            </Flex>
          </div>

          <Flex className={styles.previewWrapper}>
            {selectedView === VIEW_LIST && (
              <SectionContentTable
                headers={headers}
                section={section}
                sectionContent={sectionContent as Array<SectionContentType>}
                channel={channel}
                selectedOrder={selectedOrder}
                selectedSort={selectedSort}
                reverseOrder={reverseOrder}
                onRemoveContent={onRemoveContent}
                makeUrl={makeUrl}
                onPinContent={onPinContent}
                onUnpinContent={onUnpinContent}
              />
            )}
            {selectedView === VIEW_GRID && (
              <div
                className={cx([
                  styles.previewContentBody,
                  { [styles.mobileWidth]: platform === PlatformEnum.Mobile },
                  { [styles.webWidth]: platform === PlatformEnum.Web },
                ])}
              >
                <Section
                  sectionId={section._id}
                  showName={false}
                  contentComponent={sectionContentGridView}
                  refresh={sectionRefreshTrigger}
                  sectionContentFE={sectionContentFE}
                  setSectionContentFE={setSectionContentFE}
                  setSectionRefreshTrigger={setSectionRefreshTrigger}
                  key={sectionGridKey}
                />
              </div>
            )}
          </Flex>
        </div>

        <CustomizationPanel
          disabled={isEqual(
            pick(section, ...COMPARABLE_SECTION_PROPERTIES),
            pick(updatedSection, ...COMPARABLE_SECTION_PROPERTIES)
          )}
          buttonAction={() => {
            // This saves the section and then creates a new key for the grid
            // This is done to re-render the entire grid component inorder to re-run all its hooks
            saveSection().then(() => setSectionGridKey(uuid()));
          }}
        >
          <SectionFilters
            section={updatedSection}
            channel={channel}
            onSectionUpdated={onSectionUpdated}
          />
        </CustomizationPanel>
      </Flex>

      <AddPageModal
        channel={channel}
        isAddOpen={isAddOpen}
        setIsAddOpen={setIsAddOpen}
        onAddContent={onAddContent}
      />
    </>
  );
};
