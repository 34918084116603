import React, { useState } from 'react';

import {
  TabStrip,
  ErrorMessage,
  CreatedBy,
  PublishedBy,
  Flex,
} from 'components';
import { Button } from 'design-system-web';
import { ButtonLink } from 'lane-web/src/components/general/ButtonLink';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { unPublishContent } from 'lane-shared/graphql/mutation';
import { pause, getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { SORT_CREATED } from 'lane-shared/helpers/interactions/types';
import { useContentForAdmin } from 'lane-shared/hooks';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { ContentUCIs } from 'components/interactions';
import ContentTargetingDetails from 'components/lane/ContentTargetingDetails';
import { AdminPage } from 'components/layout';
import { BreadCrumbs } from 'components/lds';
import { LookerIframeWidget } from 'components/reports/LookerIframeWidget';
import { H3, H4 } from 'components/typography';
import {
  breadCrumbsLabel,
  breadCrumbsUrlForContentTypeMap,
} from './helpers/getBreadCrumbs';

import { LookerReportType } from 'graphql-query-contracts';
import styles from './AdminViewContent.scss';
import { InfoTab } from './InfoTab';
import { useReservableWaitlist } from 'lane-shared/hooks/useReservableWaitlist';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useQuantityWaitlistEnabled } from 'lane-shared/hooks/useQuantityWaitlistEnabled';
import { ContentWaitlistTableView } from './components/ContentWaitlistTableView';

export default function AdminViewContent({ channel }: any) {
  const { contentId } = useParams<{ contentId: string }>();

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { content, ...data } = useContentForAdmin({
    contentId,
    disableLocalization: true,
  });
  const { reservableFeature, quantityFeature } = explodeFeatures(
    content?.features || []
  );

  const contentName = content?.name;

  const timeZone = content?.state?.timezone
    ? content.state.timezone
    : getTimeZoneByGeoLocation({
        latitude: content?.geo[1],
        longitude: content?.geo[0],
      });

  type ArrayElement<
    ArrayType extends readonly unknown[]
  > = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
  type TabItem = ArrayElement<React.ComponentProps<typeof TabStrip>['tabs']>;

  const TabsMap = Object.freeze({
    info: {
      label: 'web.admin.channel.contentCenter.tab.info',
      value: 'info' as const,
      Component: <InfoTab content={content} channel={channel} />,
    },
    table: {
      label: 'web.admin.channel.contentCenter.tab.table',
      value: 'table' as const,
      Component: (
        <ContentUCIs
          content={content}
          interactionSortKey={SORT_CREATED}
          timeZone={timeZone}
        />
      ),
    },
    waitlist: {
      label: 'web.admin.channel.contentCenter.tab.waitlist',
      value: 'waitlist' as const,
      Component: (
        <ContentWaitlistTableView
          content={content}
          channel={channel}
          timeZone={timeZone}
          contentId={contentId}
        />
      ),
    },
    targeting: {
      label: 'web.admin.channel.contentCenter.tab.targeting',
      value: 'targeting' as const,
      Component: (
        <ContentTargetingDetails
          content={content}
          channel={channel}
          timeZone={timeZone}
        />
      ),
    },
    analytics: {
      label: 'web.admin.channel.contentCenter.tab.analytics',
      value: 'analytics' as const,
      Component: contentName && (
        <LookerIframeWidget
          title="Looker Post Analytics"
          reportType={LookerReportType.PostAnalytics}
          channelName={channel?.name}
          channelId={channel?._id}
          contentName={contentName}
        />
      ),
    },
  });

  const [query, goToUrl] = useQueryString({
    tab: TabsMap.info.value,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isReservableWaitlistEnabled = useReservableWaitlist();
  const isQuantityWaitlistEnabled = useQuantityWaitlistEnabled();

  type Tab = typeof TabsMap[keyof typeof TabsMap]['value'];

  const selectedTab: Tab =
    typeof query.tab === 'string'
      ? (query.tab.toLowerCase() as Tab)
      : TabsMap.info.value;

  const availableTabs: TabItem[] = [
    TabsMap.info,
    TabsMap.targeting,
    TabsMap.analytics,
  ];

  if (content?.isInteractive) {
    if (
      (isReservableWaitlistEnabled && reservableFeature?.useWaitlist) ||
      (isQuantityWaitlistEnabled && quantityFeature?.useWaitlist)
    ) {
      availableTabs.splice(1, 0, TabsMap.table, TabsMap.waitlist);
    } else {
      availableTabs.splice(1, 0, TabsMap.table);
    }
  }

  async function onUnPublishContent() {
    try {
      await window.Alert.confirm({
        title: `Un-publish ${content?.name}?`,
        message: `Are you sure you want to un-publish ${content?.name}?`,
        children: content.sectionContent?.length > 0 && (
          <div>
            <H4 mb={2}>This will also remove this from these sections</H4>
            <ul>
              {content.sectionContent.map((sectionContent: any) => (
                <li key={sectionContent._id}>{sectionContent.section.name}</li>
              ))}
            </ul>
          </div>
        ),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    setLoading(true);
    window.Alert.loading({
      title: 'Un-publishing…',
    });

    try {
      await pause(2000);
      await getClient().mutate({
        mutation: unPublishContent,
        variables: {
          id: content?._id,
        },
      });

      window.Toast.show(`${content?.name} has been un-published.`);
      history.push(`/l/channel/${channel?.slug}/admin/draft/${content?._id}`);
    } catch (err: any) {
      setError(err);
    }

    window.Alert.hide();
    setLoading(false);
  }

  return (
    <AdminPage className={styles.AdminViewContent}>
      <BreadCrumbs
        links={[
          {
            label: t(breadCrumbsLabel(content?.type)),
            url: content?.type
              ? breadCrumbsUrlForContentTypeMap[
                  content.type as ContentTypeEnum
                ](channel?.slug)
              : '',
          },
          { label: content?.name },
        ]}
      />
      <Flex direction="row" justify="space-between" m={[5, 0, 3, 0]}>
        <H3>{content?.name}</H3>
        <Flex gap={3} className={styles.buttonGroupContainer}>
          <Button
            className="btnUnPublish"
            size="large"
            loading={loading || !channel}
            onClick={onUnPublishContent}
            testId="buttonUnPublish"
            variant="secondary"
          >
            {t('web.admin.content.draftContent.header.button.label.unpublish')}
          </Button>
          <ButtonLink
            size="large"
            to={{
              pathname: routes.channelAdminDraft
                .replace(':id', channel?.slug)
                .replace(':draftId', content?._id),
              state: { from: location.pathname },
            }}
            className="btnEdit"
            testId="buttonEdit"
          >
            {t('Edit')}
          </ButtonLink>
        </Flex>
      </Flex>
      <TabStrip
        disabled={loading}
        tabs={availableTabs}
        selected={TabsMap[selectedTab]}
        onSelectTab={tab => goToUrl({ tab: tab.value })}
        fullWidth
      />
      <ErrorMessage error={error || data.error} />
      <div className={styles.main}>{TabsMap[selectedTab].Component}</div>
      <CreatedBy className={styles.createdBy} object={content} forAdmin />
      <PublishedBy object={content} forAdmin />
    </AdminPage>
  );
}
