import type { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import {
  HIDTemplatesMap,
  SelectorProperties,
  Template,
} from '../CredentialTemplateSelector/types';

export enum HIDCredTemplateStrategy {
  Both = 'both',
  HID22 = 'hid22',
  HID30 = 'hid30',
}

// if the flag for HID 30 is on then, we will use HID 30
// if the flag for HID 22 is on then, we will use HID 22
// if both flags are on then, we will use both
// this function requires at least one flag to be on
// so we will never run into a situation where both flags are off
// and strategy is not evaluated
export function getCredentialTemplateStrategy({
  hid22,
  hid30,
}: {
  hid22: boolean;
  hid30: boolean;
}) {
  if (hid22 && hid30) {
    return HIDCredTemplateStrategy.Both;
  }

  if (hid22) {
    return HIDCredTemplateStrategy.HID22;
  }

  return HIDCredTemplateStrategy.HID30;
}

export function saveHID22CredentialTemplates({
  settings,
  newCredentialTemplates,
  newConnectedCredentialTemplates,
  hidTemplatesMap,
  channelId,
}: {
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  newCredentialTemplates: Template[];
  newConnectedCredentialTemplates: SelectorProperties[];
  hidTemplatesMap: HIDTemplatesMap;
  channelId: string;
}) {
  const currentChannelRequest = newCredentialTemplates
    .map(template => {
      const templateId = hidTemplatesMap[template.label]?.hid22 || '';

      return {
        vtsOrganizationId: channelId,
        partNumberId: templateId,
        partNumber: template.label,
      };
    })
    .filter(template => template.partNumberId !== '');

  settings.partNumbers = currentChannelRequest;

  settings.connectedPartNumbers = newConnectedCredentialTemplates.flatMap(
    selector => {
      return selector.templates
        .map(template => {
          const templateId = hidTemplatesMap[template.label]?.hid22 || '';

          return {
            vtsOrganizationId: selector.channel,
            partNumberId: templateId,
            partNumber: template.label,
          };
        })
        .filter(template => template.partNumberId !== '');
    }
  );
}

export function saveHID30CredentialTemplates({
  settings,
  newCredentialTemplates,
  newConnectedCredentialTemplates,
  hidTemplatesMap,
  channelId,
}: {
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  newCredentialTemplates: Template[];
  newConnectedCredentialTemplates: SelectorProperties[];
  hidTemplatesMap: HIDTemplatesMap;
  channelId: string;
}) {
  const currentChannelRequest = newCredentialTemplates
    .map(template => {
      const templateId = hidTemplatesMap[template.label]?.hid30 || '';

      return {
        vtsOrganizationId: channelId,
        credentialTemplateId: templateId,
      };
    })
    .filter(template => template.credentialTemplateId !== '');

  settings.credentialTemplates = currentChannelRequest;

  settings.connectedCredentialTemplates = newConnectedCredentialTemplates.flatMap(
    selector => {
      return selector.templates
        .map(template => {
          const templateId = hidTemplatesMap[template.label]?.hid30 || '';

          return {
            vtsOrganizationId: selector.channel,
            credentialTemplateId: templateId,
          };
        })
        .filter(template => template.credentialTemplateId !== '');
    }
  );
}

export function saveBothCredentialTemplates({
  settings,
  newCredentialTemplates,
  newConnectedCredentialTemplates,
  hidTemplatesMap,
  channelId,
}: {
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  newCredentialTemplates: Template[];
  newConnectedCredentialTemplates: SelectorProperties[];
  hidTemplatesMap: HIDTemplatesMap;
  channelId: string;
}) {
  saveHID22CredentialTemplates({
    settings,
    newCredentialTemplates,
    newConnectedCredentialTemplates,
    hidTemplatesMap,
    channelId,
  });
  saveHID30CredentialTemplates({
    settings,
    newCredentialTemplates,
    newConnectedCredentialTemplates,
    hidTemplatesMap,
    channelId,
  });
}

export function makeSaveTemplateFunc(strategy: string) {
  if (strategy === HIDCredTemplateStrategy.HID22) {
    return saveHID22CredentialTemplates;
  }

  if (strategy === HIDCredTemplateStrategy.HID30) {
    return saveHID30CredentialTemplates;
  }

  return saveBothCredentialTemplates;
}
