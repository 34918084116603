import { routes } from 'lane-shared/config';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { UserType } from 'lane-shared/types/User';
import React from 'react';
import { Redirect } from 'react-router-dom';
import GetInviteDataAndRedirectUser from './GetInviteDataAndRedirectUser';

type Props = {
  user?: UserType;
  match: object;
};

const AcceptInvite = ({ user, match }: Props) => {
  const { inviteId } = (match as any).params;
  const isAuth0Enabled = useAuth0Enabled();

  if (isAuth0Enabled && inviteId) {
    const path = `${routes.identifier}?inviteId=${inviteId}`;

    return <Redirect to={path} />;
  }

  return <GetInviteDataAndRedirectUser user={user} inviteId={inviteId} />;
};

export default AcceptInvite;
