import React, { useState, useEffect } from 'react';

import { Icon, Button } from 'design-system-web';
import { Input, ErrorMessage, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { createAudience, updateAudience } from 'lane-shared/graphql/audiences';
import { queryChannels } from 'lane-shared/graphql/channel';
import { getChannel } from 'lane-shared/graphql/query';
import getAudience from 'lane-shared/graphql/query/getAudience';
import getGroupRole from 'lane-shared/graphql/query/getGroupRole';
import { getDisplayName } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useContentTargetingAudienceSize } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { GroupRole } from 'lane-shared/types/GroupRole';
import { Audience } from 'lane-shared/types/audiences/Audience';
import { TargetingRule } from 'lane-shared/types/audiences/TargetingRule';
import { validateAudience } from 'lane-shared/validation';
import { useAudienceAnalytics } from 'lane-shared/hooks/analytics';
import { channelCollectables } from 'lane-shared/hooks/analytics/collectables/channel.collectable';
import { groupRoleCollectables } from 'lane-shared/hooks/analytics/collectables/groupRole.collectable';
import { AudienceTargets } from 'lane-shared/hooks/analytics/useAudienceAnalytics';

import { DangerousTranslate } from 'components/DangerousTranslate';
import Checkbox from 'components/form/Checkbox';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import ChannelRelationshipsSearch from 'components/lane/ChannelRelationshipsSearch';
import ChannelSelector from 'components/lane/ChannelSelector';
import ContentPlacementListItem from 'components/lane/ContentPlacementListItem';
import GroupRoleSelector from 'components/lane/GroupRoleSelector';
import { Flex } from 'components/layout';
import { BreadCrumbs, Modal } from 'components/lds';
import { H2 } from 'components/typography';

import style from './AudienceForm.scss';

type ChannelTargetingRule = {
  name: string;
  channelId: string;
  wasAddedAsSubChannel?: boolean;
  // A targeting rule having an id itself means it was pre-existing and we fetched it
  id?: string;
  _pull?: boolean;
};

type GroupRoleTargetingRule = {
  name: string;
  groupRoleId: string;
  id?: string;
  _pull?: boolean;
};

type Props = {
  channel: ChannelType;
  forUpdate?: boolean;
};

export function AudienceForm({ channel, forUpdate = false }: Props) {
  const [audience, setAudience] = useState<Audience | undefined>();
  const [channels, setChannels] = useState<ChannelType[]>();
  const [audienceName, setAudienceName] = useState('');
  const [channelTargetingRules, setChannelTargetingRules] = useState<
    ChannelTargetingRule[]
  >([]);
  const [groupRoleTargetingRules, setGroupRoleTargetingRules] = useState<
    GroupRoleTargetingRule[]
  >([]);

  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [isRelationshipOpen, setisRelationshipOpen] = useState(false);
  const [isSubChannelsOpen, setIsSubChannelsOpen] = useState(false);
  const [isChannelsOpen, setIsChannelsOpen] = useState(false);
  const [groupRoleTarget, setGroupRoleTarget] = useState<GroupRole | null>(
    null
  );
  const [isSendingToChannel, setIsSendingToChannel] = useState(false);
  const [error, setError] = useState('');

  const { audienceId } = useParams<{ audienceId: string }>();
  const { audienceTracker } = useAudienceAnalytics({
    audience,
    channels,
  });

  useEffect(() => {
    if (forUpdate) {
      if (audience && channels) {
        audienceTracker.Update.Start(audience, channels);
      }
    } else {
      audienceTracker.Create.Start(audience);
    }
  }, [audience?.id, JSON.stringify(channels?.map(i => i._id))]);

  const { t } = useTranslation();
  const history = useHistory();
  const [audienceSize] = useContentTargetingAudienceSize({
    placements: [
      ...channelTargetingRules
        .filter(
          (channelTargetingRule: ChannelTargetingRule) =>
            !channelTargetingRule._pull
        )
        .map((channelTargetingRule: ChannelTargetingRule) => {
          return {
            channel: { _id: channelTargetingRule.channelId },
          };
        }),
      ...groupRoleTargetingRules
        .filter(
          (groupRoleTargetingRule: GroupRoleTargetingRule) =>
            !groupRoleTargetingRule._pull
        )
        .map((groupRoleTargetingRule: any) => {
          return {
            groupRole: { _id: groupRoleTargetingRule.groupRoleId },
          };
        }),
    ],
  });

  useEffect(() => {
    if (!audience && channel && audienceId) {
      fetchAudience();
    }
  }, [channel]);

  const doFetch = async () => {
    await fetchChannels();
    fetchTeams();
  };

  const fetchAudience = async () => {
    if (audience) {
      return;
    }

    const audienceRes = (
      await getClient().query({
        fetchPolicy: 'network-only',
        query: getAudience,
        variables: {
          audienceId,
          channelId: channel?._id,
        },
      })
    ).data.audience;

    if (audienceRes.isDefault) {
      history.push(
        `${routes.channelAdminAudiencesDetails
          .replace(':id', channel?.slug!)
          .replace(':audienceId', audienceId)}`
      );
    }

    setAudienceName(audienceRes.name);
    setAudience(audienceRes);
  };

  const fetchChannels = async () => {
    if (!audience) {
      return;
    }

    const fetchedChannels = (
      await Promise.all(
        audience.channelTargetingRules.map(
          async (channelTargetingRule: any) => {
            let channelResponse;

            try {
              channelResponse = await getClient().query<{
                channel: ChannelType;
              }>({
                fetchPolicy: 'network-only',
                query: getChannel,
                variables: {
                  id: channelTargetingRule.channelId,
                },
              });

              return channelResponse.data.channel;
            } catch {
              return undefined;
            }
          }
        )
      )
    ).filter(channel => channel);

    setChannels(fetchedChannels as any);

    // Find channel that matches the current targeting rule and create a new object with the channel's name and the target's id
    const channelTargetsWithNames = audience.channelTargetingRules.map(
      channelTarget => {
        if (channelTarget.channelId === channel._id) {
          setIsSendingToChannel(true);
        }

        const match = fetchedChannels.find(
          channel =>
            (channel || ({} as ChannelType))._id === channelTarget.channelId
        );

        return {
          id: channelTarget.id,
          name: match?.name!,
          channelId: match?._id!,
        };
      }
    );

    setChannelTargetingRules(channelTargetsWithNames);
  };

  const fetchTeams = async () => {
    if (!audience) {
      return;
    }

    const groupRoles = (
      await Promise.all(
        audience.groupRoleTargetingRules.map(
          async (groupRoleTargetingRule: TargetingRule) => {
            try {
              return (
                await getClient().query({
                  fetchPolicy: 'network-only',
                  query: getGroupRole,
                  variables: {
                    id: groupRoleTargetingRule.groupRoleId,
                  },
                })
              ).data.groupRole;
            } catch {
              return undefined;
            }
          }
        )
      )
    ).filter(teams => teams);

    const groupRoleTargetsWithNames = audience.groupRoleTargetingRules.map(
      groupRoleTarget => {
        const match = groupRoles.find(
          groupRole => groupRole._id === groupRoleTarget.groupRoleId
        );

        return {
          id: groupRoleTarget.id,
          name: match.name,
          groupRoleId: match._id,
        };
      }
    );

    setGroupRoleTargetingRules(groupRoleTargetsWithNames);
  };

  const {
    audienceNameValidator,
    audienceTargetingRulesValidator,
  } = validateAudience(t);

  const validate = async () => {
    await audienceNameValidator.validate(audienceName);

    await audienceTargetingRulesValidator.validate(
      [
        ...audienceRequestPayload.channelTargetingRules,
        ...audienceRequestPayload.groupRoleTargetingRules,
      ].filter(targettingRule => !targettingRule._pull)
    );
  };

  const subChannelsResult = useQuery(queryChannels, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        isSub: true,
        parent: {
          _id: channel?._id,
        },
      },
    },
    skip: !channel?._id,
  });

  const subChannels = subChannelsResult?.data?.channels?.items || [];

  const audienceRequestPayload = {
    name: audienceName,
    channelTargetingRules,
    groupRoleTargetingRules,
    managementChannels: [channel?._id],
    shareChannels: [],
    contentToTarget: [],
  };

  const header = !forUpdate
    ? 'web.pages.portal.admin.audiences.create.header'
    : 'web.pages.portal.admin.audiences.update.header';

  const breadCrumbs = !forUpdate
    ? [
        {
          label: t('web.pages.portal.admin.audiences.create.breadCrumbBack'),
          url: `${routes.channelAdminAudiences.replace(':id', channel?.slug!)}`,
        },
        {
          label: t('web.pages.portal.admin.audiences.create.breadCrumbCurrent'),
        },
      ]
    : [
        {
          label: t('web.pages.portal.admin.audiences.create.breadCrumbBack'),
          url: `${routes.channelAdminAudiences.replace(':id', channel?.slug!)}`,
        },
        {
          label: audience?.name || '',
          url: `${routes.channelAdminAudiencesDetails
            .replace(':id', channel?.slug!)
            .replace(':audienceId', audienceId)}`,
        },
        {
          label: t('web.pages.portal.admin.audiences.update.breadCrumbCurrent'),
        },
      ];

  const submitButtonText = !forUpdate
    ? 'web.pages.portal.admin.audiences.create.createButton'
    : 'web.pages.portal.admin.audiences.update.updateButton';

  const createAudienceMutation = async () => {
    try {
      await validate();
      await getClient().mutate({
        mutation: createAudience,
        variables: {
          audience: {
            ...audienceRequestPayload,
            createdByChannel: channel ? channel?._id : null,
          },
        },
      });

      audienceTracker.Create.End();

      history.push(
        `${routes.channelAdminAudiences.replace(
          ':id',
          channel!._id
        )}?search=&sortKey=createdAt&dir=desc`
      );

      window.Toast.show(
        t('web.pages.portal.admin.audiences.create.successToast', {
          audienceName,
        })
      );
    } catch (e) {
      setError(e);
    }
  };

  const updateMutation = async () => {
    try {
      await validate();
      await getClient().mutate({
        mutation: updateAudience,
        variables: {
          audience: {
            ...audienceRequestPayload,
            id: audience?.id,
            contentToTarget: audience?.contentToTarget,
            managementChannels: audience?.managementChannels,
            shareChannels: audience?.shareChannels,
          },
        },
      });

      audienceTracker.Update.End();

      history.push(
        `${routes.channelAdminAudiencesDetails
          .replace(':id', channel?.slug!)
          .replace(':audienceId', audienceId)}`
      );

      window.Toast.show(
        t('web.pages.portal.admin.audiences.update.successToast', {
          audienceName,
        })
      );
    } catch (e) {
      setError(e);
    }
  };

  const submitAction = !forUpdate ? createAudienceMutation : updateMutation;

  useEffect(() => {
    if (audienceId) {
      doFetch();
    }
  }, [audienceId, audience?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const addChannelTargetingRule = (
    channelToTarget: ChannelType,
    target: AudienceTargets
  ) => {
    if (forUpdate) {
      audienceTracker.Update.Select(
        channelCollectables(channelToTarget),
        target
      );
    } else {
      audienceTracker.Create.Select(
        channelCollectables(channelToTarget),
        target
      );
    }

    if (!channels?.find(channel => channel._id === channelToTarget._id)) {
      setChannels((channels || []).concat(channelToTarget));
    }

    // Case for when an existing targeting rule that was fetched is removed and is being readded
    const readdRemovedTargetingRule =
      forUpdate &&
      audienceRequestPayload.channelTargetingRules.some(
        (existingTarget: { channelId: string; id?: string; _pull?: boolean }) =>
          existingTarget.id &&
          existingTarget.channelId === channelToTarget._id &&
          existingTarget._pull
      );

    if (readdRemovedTargetingRule) {
      const updatedTargetingRules = channelTargetingRules.map(
        existingTarget => {
          if (
            existingTarget.channelId === channelToTarget._id &&
            existingTarget._pull
          ) {
            delete existingTarget._pull;
          }

          return existingTarget;
        }
      );

      setChannelTargetingRules(updatedTargetingRules);

      if (channelToTarget._id === channel._id) {
        setIsSendingToChannel(true);
      }

      setIsChannelsOpen(false);

      return;
    }

    // If a targeting rule has already been created, just return
    if (
      audienceRequestPayload.channelTargetingRules.some(
        (existingTarget: { channelId: string }) =>
          existingTarget.channelId === channelToTarget._id
      )
    ) {
      return;
    }

    const displayName = getDisplayName(channelToTarget);

    setChannelTargetingRules([
      ...channelTargetingRules,
      {
        channelId: channelToTarget._id,
        name: displayName,
      },
    ]);

    // Check checkmark if the channel we're creating a targeting rule for is the channel the user is currently on
    if (channelToTarget._id === channel._id) {
      setIsSendingToChannel(true);
    }

    setIsChannelsOpen(false);
  };

  const removeChannelTargetingRule = (
    channelTargetToRemove: ChannelTargetingRule
  ) => {
    audienceTracker.Update.Remove(
      channelCollectables(
        channels?.find(
          channel => channel._id === channelTargetToRemove.channelId
        )
      ),
      AudienceTargets.CHANNEL
    );
    const isFetchedTargetingRule = audienceRequestPayload.channelTargetingRules.some(
      (existingTarget: { channelId: string; id?: string }) =>
        existingTarget.channelId === channelTargetToRemove.channelId &&
        existingTarget.id
    );

    if (isFetchedTargetingRule) {
      const updatedTargetingRules = channelTargetingRules.map(
        existingTarget => {
          if (existingTarget.channelId === channelTargetToRemove.channelId) {
            existingTarget._pull = true;
          }

          return existingTarget;
        }
      );

      setChannelTargetingRules(updatedTargetingRules);

      if (channelTargetToRemove.channelId === channel._id) {
        setIsSendingToChannel(false);
      }

      return;
    }

    setChannelTargetingRules(
      channelTargetingRules.filter(
        existingTarget =>
          existingTarget.channelId !== channelTargetToRemove.channelId
      )
    );

    // If the channel we're removing is the channel we're on, we'll want to uncheck the checkmark box
    if (channelTargetToRemove.channelId === channel._id) {
      setIsSendingToChannel(false);
    }
  };

  const addGroupRoleTargetingRule = (groupRoleToTarget: GroupRole) => {
    if (forUpdate) {
      audienceTracker.Update.Select(
        groupRoleCollectables(groupRoleToTarget),
        AudienceTargets.TEAM
      );
    } else {
      audienceTracker.Create.Select(groupRoleToTarget, AudienceTargets.TEAM);
    }

    const readdRemovedTargetingRule =
      forUpdate &&
      audienceRequestPayload.groupRoleTargetingRules.some(
        (existingTarget: {
          groupRoleId: string;
          id?: string;
          _pull?: boolean;
        }) =>
          existingTarget.id &&
          existingTarget.groupRoleId === groupRoleToTarget._id &&
          existingTarget._pull
      );

    if (readdRemovedTargetingRule) {
      const updatedTargetingRules = groupRoleTargetingRules.map(
        existingTarget => {
          if (
            existingTarget.groupRoleId === groupRoleToTarget._id &&
            existingTarget._pull
          ) {
            delete existingTarget._pull;
          }

          return existingTarget;
        }
      );

      setGroupRoleTargetingRules(updatedTargetingRules);

      return;
    }

    if (
      audienceRequestPayload.groupRoleTargetingRules.some(
        (existingTarget: { groupRoleId: string }) =>
          existingTarget.groupRoleId === groupRoleToTarget._id
      )
    ) {
      return;
    }

    setGroupRoleTargetingRules([
      ...groupRoleTargetingRules,
      {
        groupRoleId: groupRoleToTarget._id,
        name: groupRoleToTarget.name,
      },
    ]);
  };

  const removeGroupRoleTargetingRule = (groupRoleToRemove: {
    groupRoleId: string;
  }) => {
    if (forUpdate) {
      audienceTracker.Update.Remove(groupRoleToRemove, AudienceTargets.TEAM);
    } else {
      audienceTracker.Create.Remove(groupRoleToRemove, AudienceTargets.TEAM);
    }

    const targetingRuleWasFetched =
      forUpdate &&
      audienceRequestPayload.groupRoleTargetingRules.some(
        (existingTarget: { groupRoleId: string; id?: string }) =>
          existingTarget.groupRoleId === groupRoleToRemove.groupRoleId &&
          existingTarget.id
      );

    if (targetingRuleWasFetched) {
      const updatedTargetingRules = groupRoleTargetingRules.map(
        existingTarget => {
          if (existingTarget.groupRoleId === groupRoleToRemove.groupRoleId) {
            existingTarget._pull = true;
          }

          return existingTarget;
        }
      );

      setGroupRoleTargetingRules(updatedTargetingRules);

      return;
    }

    setGroupRoleTargetingRules(
      groupRoleTargetingRules.filter(
        existingTarget =>
          existingTarget.groupRoleId !== groupRoleToRemove.groupRoleId
      )
    );
  };

  const handleUncheck = (channelToRemove: { _id: string }) => {
    const currentChannelWasFetched = audienceRequestPayload.channelTargetingRules.some(
      (existingTarget: { channelId: string; id?: string }) =>
        existingTarget.channelId === channelToRemove._id && existingTarget.id
    );

    if (currentChannelWasFetched) {
      const updatedTargetingRules = channelTargetingRules.map(
        existingTarget => {
          if (existingTarget.channelId === channelToRemove._id) {
            existingTarget._pull = true;
          }

          return existingTarget;
        }
      );

      setChannelTargetingRules(updatedTargetingRules);
    } else {
      setChannelTargetingRules(
        channelTargetingRules.filter(
          existingTarget => existingTarget.channelId !== channelToRemove._id
        )
      );
    }

    setIsSendingToChannel(false);
  };

  if (!channel || (forUpdate && !audience)) {
    return (
      <div className={style.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={style.createAudienceContainer}>
      {error && <ErrorMessage error={error} />}
      <div className={style.topContainer}>
        <BreadCrumbs links={breadCrumbs} />
        <Button
          onClick={submitAction}
          size="large"
          variant="primary"
          testId="audiencesFormSubmitButton"
        >
          {t(submitButtonText)}
        </Button>
      </div>
      <div>
        <H2 className={style.header}>{t(header)}</H2>
        <label className={style.label}>
          {t('web.pages.portal.admin.audiences.create.label')}
        </label>
      </div>
      <div className={style.targetingContainer}>
        <div>
          <div className={style.audienceNameContainer}>
            <label className={style.label}>
              {t('web.pages.portal.admin.audiences.create.inputLabel')}{' '}
              <span>*</span>
            </label>{' '}
            <Input
              testId="audienceNameInput"
              className={style.input}
              placeholder="Audience Name"
              value={audienceName}
              onChange={audienceName => setAudienceName(audienceName)}
            />
          </div>
          <section className={style.DraftContentTargetingPlacements}>
            <div data-test="contentReachNumber" className={style.panel}>
              <h1>
                <DangerousTranslate
                  translationKey="web.admin.content.draftContent.audienceSize"
                  values={{
                    count: audienceSize,
                  }}
                />
              </h1>
            </div>
            <div className={style.panel}>
              <div className={style.group}>
                {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ selected: b... Remove this comment to see the full error message */}
                <Checkbox
                  testId="sendToEveryoneCheckbox"
                  selected={isSendingToChannel}
                  text={`Add ${getDisplayName(channel)}`}
                  onChange={() =>
                    isSendingToChannel
                      ? handleUncheck(channel)
                      : addChannelTargetingRule(
                          channel,
                          AudienceTargets.CHANNEL
                        )
                  }
                />
                {audienceRequestPayload.channelTargetingRules.filter(
                  channelTargetingRule => !channelTargetingRule._pull
                ).length > 0 && (
                  <>
                    <h1>
                      {t(
                        'web.pages.portal.admin.audiences.create.channelTargets'
                      )}
                    </h1>
                    <ul data-test="contentChannelReach">
                      {audienceRequestPayload.channelTargetingRules
                        .filter(
                          channelTargetingRule => !channelTargetingRule._pull
                        )
                        .map((target: ChannelTargetingRule) => (
                          <ContentPlacementListItem
                            key={target.channelId}
                            className={style.placement}
                            channelId={target.channelId}
                            onRemovePlacement={() =>
                              removeChannelTargetingRule(target)
                            }
                          />
                        ))}
                    </ul>
                  </>
                )}

                {audienceRequestPayload.groupRoleTargetingRules.filter(
                  groupRoleTargetingRule => !groupRoleTargetingRule._pull
                ).length > 0 && (
                  <>
                    <h1>
                      {' '}
                      {t(
                        'web.pages.portal.admin.audiences.create.groupRoleTargets'
                      )}
                    </h1>
                    <ul data-test="contentTeamReach">
                      {audienceRequestPayload.groupRoleTargetingRules
                        .filter(
                          groupRoleTargetingRule =>
                            !groupRoleTargetingRule._pull
                        )
                        .map((target: GroupRoleTargetingRule) => (
                          <ContentPlacementListItem
                            key={target.groupRoleId}
                            className={style.placement}
                            groupRoleId={target.groupRoleId}
                            onRemovePlacement={() =>
                              removeGroupRoleTargetingRule(target)
                            }
                          />
                        ))}
                    </ul>
                  </>
                )}
              </div>
            </div>

            <div className={style.panel}>
              <button
                onClick={() => {
                  if (forUpdate) {
                    audienceTracker.Update.Add(AudienceTargets.CHANNEL);
                  } else {
                    audienceTracker.Create.Add(AudienceTargets.CHANNEL);
                  }

                  setIsSubChannelsOpen(true);
                }}
              >
                {t('web.pages.portal.admin.audiences.create.targetSubChannel', {
                  channelName: getDisplayName(channel),
                })}{' '}
                <Icon
                  testId="targetSubChannel"
                  name="plus-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={style.addIcon}
                />
              </button>

              <button
                onClick={() => {
                  if (forUpdate) {
                    audienceTracker.Update.Add(AudienceTargets.TENANT);
                  } else {
                    audienceTracker.Create.Add(AudienceTargets.TENANT);
                  }

                  setisRelationshipOpen(true);
                }}
              >
                {t('web.pages.portal.admin.audiences.create.targetTenant', {
                  channelName: getDisplayName(channel),
                })}
                <Icon
                  testId="targetTenantChannel"
                  name="plus-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={style.addIcon}
                />
              </button>

              <button
                onClick={() => {
                  if (forUpdate) {
                    audienceTracker.Update.Add(AudienceTargets.TEAM);
                  } else {
                    audienceTracker.Create.Add(AudienceTargets.TEAM);
                  }

                  setIsTeamOpen(true);
                }}
              >
                {t('web.pages.portal.admin.audiences.create.targetGroupRole')}
                <Icon
                  testId="targetTeam"
                  name="plus-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={style.addIcon}
                />
              </button>

              <button
                onClick={() => {
                  if (forUpdate) {
                    audienceTracker.Update.Add(AudienceTargets.CHANNEL);
                  } else {
                    audienceTracker.Create.Add(AudienceTargets.CHANNEL);
                  }

                  setIsChannelsOpen(true);
                }}
              >
                {t('web.pages.portal.admin.audiences.create.targetChannel')}
                <Icon
                  testId="targetChannel"
                  name="plus-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={style.addIcon}
                />
              </button>
            </div>

            <ModalBackground
              className={style.background}
              isOpen={isSubChannelsOpen}
              onClose={() => setIsSubChannelsOpen(false)}
            >
              <ResizableWindow
                className={style.window}
                name="DraftContentTargetingSubChannels"
                onClose={() => setIsSubChannelsOpen(false)}
                defaultPosition={ResizableWindow.centerPosition()}
                showHeader
              >
                <ul>
                  {subChannels.map((channel: ChannelType) => (
                    <button
                      key={channel._id}
                      className={style.placementOption}
                      onClick={() => {
                        addChannelTargetingRule(
                          channel,
                          AudienceTargets.CHANNEL
                        );
                        setIsSubChannelsOpen(false);
                      }}
                    >
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message */}
                      <ChannelCircleListView channel={channel} />
                    </button>
                  ))}
                </ul>
              </ResizableWindow>
            </ModalBackground>

            <Modal
              title={t(
                'web.pages.portal.admin.audiences.create.groupRoleModalTitle'
              )}
              isOpen={isTeamOpen}
              onClose={() => {
                setGroupRoleTarget(null);
                setIsTeamOpen(false);
              }}
              size="small"
              style={{
                height: '60vh',
              }}
              actions={
                <Flex gap={2}>
                  <Button
                    variant="secondary"
                    size="large"
                    onClick={() => {
                      setIsTeamOpen(false);
                      setGroupRoleTarget(null);
                    }}
                  >
                    {t('web.pages.portal.admin.audiences.create.modalCancel')}
                  </Button>
                  <Button
                    variant="primary"
                    size="large"
                    disabled={!groupRoleTarget}
                    onClick={() => {
                      addGroupRoleTargetingRule(groupRoleTarget!);
                      setIsTeamOpen(false);
                      setGroupRoleTarget(null);
                    }}
                  >
                    {t('web.pages.portal.admin.audiences.create.modalSelect')}
                  </Button>
                </Flex>
              }
            >
              <Flex direction="column" gap={5}>
                <GroupRoleSelector
                  groupRoleId={groupRoleTarget?._id}
                  channelId={channel?._id}
                  style={{
                    flexDirection: 'column',
                  }}
                  channelSelectorButtonStyle={{
                    maxWidth: '100%',
                    marginBottom: '1em',
                    marginRight: '0em',
                  }}
                  onGroupRoleSelected={groupRole => {
                    setGroupRoleTarget(groupRole);
                  }}
                />
              </Flex>
            </Modal>

            <ModalBackground
              className={style.background}
              isOpen={isRelationshipOpen}
              onClose={() => setisRelationshipOpen(false)}
            >
              <ResizableWindow
                className={style.window}
                name="DraftContentTargetingRelationships"
                onClose={() => setisRelationshipOpen(false)}
                defaultPosition={ResizableWindow.centerPosition()}
                showHeader
              >
                <ChannelRelationshipsSearch
                  className={style.relationships}
                  channelId={channel?._id}
                  renderRelationship={relationship => (
                    <button
                      data-test="tenantTarget"
                      key={relationship._id}
                      className={style.placementOption}
                      onClick={() => {
                        addChannelTargetingRule(
                          relationship.channel,
                          AudienceTargets.TENANT
                        );
                        setisRelationshipOpen(false);
                      }}
                    >
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message */}
                      <ChannelCircleListView channel={relationship.channel} />
                    </button>
                  )}
                />
              </ResizableWindow>
            </ModalBackground>

            <ModalBackground
              className={style.background}
              isOpen={isChannelsOpen}
              onClose={() => setIsChannelsOpen(false)}
            >
              <ResizableWindow
                className={style.window}
                name="DraftContentTargeting"
                onClose={() => setIsChannelsOpen(false)}
                defaultPosition={ResizableWindow.centerPosition()}
                showHeader
              >
                <ChannelSelector
                  storageKey="DraftContentTargetingChannelSelector"
                  className={style.channelSelector}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '(channel: any) => void' is not assignable to... Remove this comment to see the full error message
                  onChannelSelected={(channel: ChannelType) => {
                    addChannelTargetingRule(channel, AudienceTargets.CHANNEL);
                  }}
                />
              </ResizableWindow>
            </ModalBackground>
          </section>
        </div>
      </div>
    </div>
  );
}
