import React, { useEffect, useMemo, useState } from 'react';

import { Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery, useLazyQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { queryChannels } from 'lane-shared/graphql/channel';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { Table, PageSizeType } from 'design-system-web';

import { searchTasksQuery } from 'graphql-queries';
import { ClientTask } from 'graphql-query-contracts';

import { PMTaskStatusDropdown } from '../TaskStatusDropDown/PMTaskStatusDropdown';
import { H4 } from 'components/typography';

import styles from './styles.scss';

const PER_PAGE = 50;

export function TinyTaskList({
  channel,
  parentType,
  searchID = '',
  showTitle = false,
}: any) {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<PageSizeType>(PER_PAGE);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const isCrossProperty = channel?.settings?.hasWorkOrderCrossPropertyEnabled;

  const subChannelsResult = useQuery(queryChannels, {
    variables: {
      pagination: {
        start: 0,
        perPage: 100,
      },
      search: {
        sortBy: {
          key: 'name',
          dir: 'asc',
        },
        // isSub: true,
        parent: {
          _id: channel?._id,
        },
      },
    },
  });

  let subChannelIds: string[] = [];

  if (isCrossProperty) {
    subChannelIds =
      subChannelsResult?.data?.channels?.items?.map(
        (subChannel: ChannelType) => subChannel?._id
      ) || [];
  }

  const [fetchTasks, { data, loading }] = useLazyQuery(searchTasksQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchTasks({
      variables: {
        groupIds: channel?._id ? [channel?._id, ...subChannelIds] : [],
        search: {
          sortBy: {
            key: sortBy,
            dir: sortDirection,
          },
        },
        pagination: {
          start: ((page || 0) as number) * perPage,
          perPage,
        },
        filter: {
          equipments:
            parentType === 'EQUIPMENT' && searchID
              ? [convertToUUID(searchID)]
              : [],
          schedules:
            parentType === 'SCHEDULE' && searchID
              ? [convertToUUID(searchID)]
              : [],
        },
      },
      fetchPolicy: 'network-only',
    });
  }, [page, perPage, sortBy, sortDirection, fetchTasks]);

  const totalTasks = data?.searchTasks?.pageInfo?.total;

  const tableRows = useMemo(() => {
    if (!data?.searchTasks?.tasks) return [];

    const rows = data?.searchTasks?.tasks.map((task: ClientTask) => ({
      ...task,
      created_at: task.createdAt,
      completed_at: task.completedAt,
      friendly_id: task.friendlyID,
      user_friendly_id: task.userFriendlyID,
      channel: task.groupBy?.name,
    }));

    return rows;
  }, [data?.searchTasks]);

  const columns = [
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.id`,
      key: 'user_friendly_id',
      renderCell: (user_friendly_id: string, row: any) => (
        <Link
          to={routes.channelAdminWorkOrdersPMTaskDetails
            .replace(':id', channel?.slug)
            .replace(':taskId', row.id)}
        >
          {user_friendly_id}
        </Link>
      ),
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.schedule`,
      key: 'schedule',
      renderCell: (schedule: any) => {
        if (!schedule) return '';

        return schedule.title;
      },
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.status`,
      key: 'status',
      renderCell: (_: any, row: any) => (
        <PMTaskStatusDropdown taskData={row} isTaskList />
      ),
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.createdAt`,
      key: 'created_at',
      type: 'date',
    },
    {
      header: t`web.admin.workOrder.preventiveMaintenance.task.tableColumn.completedAt`,
      key: 'completed_at',
      type: 'date',
      renderCell: (completed_at: Date, row: any) => {
        if (!row.completed_by) return '';

        return new Date(completed_at).toDateString();
      },
    },
  ];

  if (isCrossProperty) {
    columns.splice(1, 0, {
      key: 'channel',
      header: t('web.admin.serviceRequest.property'),
      type: 'text',
    });
  }

  return (
    <Flex direction="column" className={styles.taskListTable} gap={5}>
      {showTitle && <H4>{t('web.admin.serviceRequest.tasks.title')}</H4>}
      <Table
        maxHeight="40vh"
        isLoading={loading}
        columns={columns}
        data={tableRows}
        totalRows={Number(totalTasks)}
        pagination="server"
        onPageChange={(page: number) => {
          setPage(page);
        }}
        onPageSizeChange={(pageSize: PageSizeType) => {
          setPerPage(pageSize);
        }}
        onSortChange={(sort: any) => {
          if (sort?.id) {
            setSortBy(sort?.id || 'created_at');
          }

          if (sort?.direction) {
            setSortDirection(sort?.direction || 'desc');
          }
        }}
      />
    </Flex>
  );
}
