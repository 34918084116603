import ExcelJS from 'exceljs';

import { HYPERCARE_MAPPINGS, RECURRENCE_TYPES } from './constants';

export const addDropdowns = (workbook: ExcelJS.Workbook) => {
  const equipmentSheet = workbook.getWorksheet('EquipmentImports');
  const scheduleSheet = workbook.getWorksheet('ScheduleImports');
  const userSheet = workbook.getWorksheet('UserImports');

  if (equipmentSheet) {
    addCategoryDropdown(equipmentSheet);
    addStatusDropdown(equipmentSheet);
  }

  if (scheduleSheet) {
    addRecurrenceDropdown(scheduleSheet);
  }

  if (userSheet) {
    addMarketingDropdown(userSheet);
    addUserStatusDropdown(userSheet);
  }
};

const addCategoryDropdown = (equipmentSheet: ExcelJS.Worksheet) => {
  const categoryColumn = equipmentSheet?.getColumn(3);
  const categoryOptions =
    HYPERCARE_MAPPINGS.PMSetupEquipSched.category.dropdownOptions;

  if (categoryOptions) {
    const formulaRange = `${categoryOptions.sheetName}!${categoryOptions.start}:${categoryOptions.end}`;

    categoryColumn?.eachCell((cell, index) => {
      if (index > 1) {
        cell.dataValidation = {
          type: 'list',
          formulae: [formulaRange],
        };
      }
    });
  }
};

const addStatusDropdown = (equipmentSheet: ExcelJS.Worksheet) => {
  const statusColumn = equipmentSheet?.getColumn(4);
  const statusOptions = ['Active', 'Inactive'];

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: [`"${statusOptions.join(',')}"`],
      };
    }
  });
};

const addRecurrenceDropdown = (scheduleSheet: ExcelJS.Worksheet) => {
  const recurrenceColumn = scheduleSheet?.getColumn(6);
  const recurrenceOptions = RECURRENCE_TYPES;

  recurrenceColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: [`"${recurrenceOptions.join(',')}"`],
      };
    }
  });
};

const addMarketingDropdown = (userSheet: ExcelJS.Worksheet) => {
  const marketingColumn = userSheet?.getColumn(5);
  const marketingOptions = ['TRUE', 'FALSE'];

  marketingColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: [`"${marketingOptions.join(',')}"`],
      };
    }
  });
};

const addUserStatusDropdown = (userSheet: ExcelJS.Worksheet) => {
  const statusColumn = userSheet?.getColumn(6);
  const statusOptions = ['Active', 'Disabled'];

  statusColumn?.eachCell((cell, index) => {
    if (index > 1) {
      cell.dataValidation = {
        type: 'list',
        formulae: [`"${statusOptions.join(',')}"`],
      };
    }
  });
};

export const populateBillingSheets = (workbook: ExcelJS.Workbook) => {
  addChargeCodes(workbook);
  addCategories(workbook);
  AddProducts(workbook);
};

function addChargeCodes(workbook: ExcelJS.Workbook) {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingChargeCodesSheet = workbook.getWorksheet('Billing Charge Codes');

  if (!serviceSchedulesSheet || !billingChargeCodesSheet) {
    return;
  }

  const chargeCodesSet = new Set<string>();

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const chargeCode1 = row.getCell('G').value;

    if (chargeCode1 && chargeCode1 !== '-') {
      chargeCodesSet.add(chargeCode1.toString());
    }

    const chargeCode2 = row.getCell('J').value;

    if (chargeCode2 && chargeCode2 !== '-') {
      chargeCodesSet.add(chargeCode2.toString());
    }
  });

  let rowIndex = 2;

  chargeCodesSet.forEach(chargeCode => {
    billingChargeCodesSheet.getCell(`A${rowIndex}`).value = chargeCode;
    rowIndex++;
  });
}

function addCategories(workbook: ExcelJS.Workbook) {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingCategoriesSheet = workbook.getWorksheet('Billing Categories');

  if (!serviceSchedulesSheet || !billingCategoriesSheet) {
    return;
  }

  let rowIndex = 2;
  const uniqueProducts = new Set<string>();
  const uniqueServices = new Set<string>();

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const labor = row.getCell('F').value;
    const categoryName = row.getCell('C').value;
    const value = categoryName?.toString();

    if (typeof labor === 'number' && labor > 0 && value) {
      if (!uniqueServices.has(value)) {
        uniqueServices.add(value);
        billingCategoriesSheet.getCell(`A${rowIndex}`).value = categoryName;
        billingCategoriesSheet.getCell(`B${rowIndex}`).value = 'Service';
        rowIndex++;
      }
    }

    const material = row.getCell('I').value;

    if (typeof material === 'number' && material > 0 && value) {
      if (!uniqueProducts.has(value)) {
        uniqueProducts.add(value);
        billingCategoriesSheet.getCell(`A${rowIndex}`).value = value;
        billingCategoriesSheet.getCell(`B${rowIndex}`).value = 'Product';
        rowIndex++;
      }
    }
  });
}

function AddProducts(workbook: ExcelJS.Workbook) {
  const serviceSchedulesSheet = workbook.getWorksheet('(opt)ServiceSchedules');
  const billingProductsSheet = workbook.getWorksheet(
    'Billing Products & Services'
  );

  if (!serviceSchedulesSheet || !billingProductsSheet) {
    return;
  }

  let rowIndex = 2;

  const addProductOrService = (
    productName: any,
    categoryName: any,
    amount: any,
    code: any,
    type: 'Service' | 'Product',
    markup: any,
    tax: any
  ) => {
    billingProductsSheet.getCell(`A${rowIndex}`).value = productName;
    billingProductsSheet.getCell(`D${rowIndex}`).value = categoryName;
    billingProductsSheet.getCell(`C${rowIndex}`).value = type;
    billingProductsSheet.getCell(`F${rowIndex}`).value = amount;

    if (code !== '-') {
      billingProductsSheet.getCell(`E${rowIndex}`).value = code;
    }

    billingProductsSheet.getCell(`G${rowIndex}`).value = 'Fixed';
    billingProductsSheet.getCell(`H${rowIndex}`).value = markup || 0;
    billingProductsSheet.getCell(`I${rowIndex}`).value = 'Fixed';
    billingProductsSheet.getCell(`J${rowIndex}`).value = tax || 0;

    rowIndex++;
  };

  serviceSchedulesSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;

    const productName = row.getCell('E').value;
    const categoryName = row.getCell('C').value;
    const labor = row.getCell('F').value;
    const material = row.getCell('I').value;
    const laborCode = row.getCell('G').value;
    const materialCode = row.getCell('J').value;
    const markup = row.getCell('L').value;
    const tax = row.getCell('N').value;

    if (productName) {
      if (typeof labor === 'number' && labor > 0) {
        addProductOrService(
          productName,
          categoryName,
          labor,
          laborCode,
          'Service',
          markup,
          tax
        );
      }

      if (typeof material === 'number' && material > 0) {
        addProductOrService(
          productName,
          categoryName,
          material,
          materialCode,
          'Product',
          markup,
          tax
        );
      }
    }
  });
}
