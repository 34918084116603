import { useState, useEffect, useCallback, useMemo } from 'react';

import { config } from '../config';
import { PlatformEnum } from '../types/PlatformEnum';

// New custom attributes should be added to this "LDUserCustom" type
export type LDUserCustom = {
  platform: PlatformEnum;
  appVersion: string;
  whiteLabelName?: string;
  whiteLabelId?: string;
  groupRoleIds?: string[];
  focusedChannelId?: string;
  hardwareId?: string;
};

export type LDUser = {
  key?: string;
  anonymous?: boolean;
  custom: LDUserCustom;
};

export default function useLaunchDarklyUser({
  platform,
  onChange,
  appVersion,
  whiteLabelName,
  whiteLabelId,
}: {
  platform: PlatformEnum;
  onChange: (lDUser: LDUser) => void;
  appVersion?: string;
  whiteLabelName?: string;
  whiteLabelId?: string;
}) {
  const anonymousLDUser: LDUser = useMemo(
    () => ({
      anonymous: true,
      custom: {
        platform,
        appVersion: appVersion ?? config.laneVersion,
        whiteLabelName,
        whiteLabelId,
      },
    }),
    [appVersion, platform, whiteLabelName, whiteLabelId]
  );

  const [lDUser, setLDUser] = useState<LDUser>(anonymousLDUser);
  const setUser = useCallback(
    (key: string, custom: Partial<LDUserCustom> = {}) => {
      setLDUser(prevState => {
        const updatedUser = { ...prevState };

        updatedUser.anonymous = false;
        updatedUser.key = key;
        updatedUser.custom = { ...updatedUser.custom, ...custom };

        return updatedUser;
      });
    },
    []
  );

  const unsetUser = useCallback(() => {
    setLDUser(anonymousLDUser);
  }, [setLDUser, anonymousLDUser]);

  const setAttribute = useCallback((field: Partial<LDUserCustom>) => {
    setLDUser(prevState => {
      const updatedUser = { ...prevState };

      updatedUser.custom = { ...updatedUser.custom, ...field };

      return updatedUser;
    });
  }, []);

  useEffect(() => {
    onChange(lDUser);
  }, [onChange, lDUser]);

  return {
    lDUser,
    setUser,
    unsetUser,
    setAttribute,
  };
}
