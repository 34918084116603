import { ContentType } from 'lane-shared/types/content/Content';

export type RoomSize = {
  min: number;
  max?: number;
  label?: string;
};

type Room = { _id: string; content: ContentType };

/*
Tricky config for tricky requirements.

1. On UI show "1 - 10", but filter in range [1, 16].
1.1 If no results, then filter in range [17, Infinity).
1.2 If no results, then return empty result.

2. On UI show "11 - 20", but filter in range [11, 36].
2.1 If no results, then return empty result.
*/
export const roomSizes: RoomSize[] = [
  { min: 1, max: 16, label: '1 - 10' },
  { min: 17 },
  { min: 11, max: 36, label: '11 - 20' },
];

export function attendeesRowText(roomSize: RoomSize) {
  return `${roomSize.min}${roomSize.max ? ' - ' : '+'}${roomSize.max ?? ''}`;
}

function getAmounOfSeatsMetatag(metatags: any[]) {
  return metatags.find(
    (metaTag: { metatag: { properties: { key: string } } }) => {
      return metaTag.metatag?.properties?.key === 'amount-of-seats';
    }
  );
}

export function filterRoomBySize(rooms: Room[], roomSize: RoomSize) {
  // this map is just for sorting
  const sizeToContentMap = new Map<number, Room[]>();

  rooms.forEach(room => {
    if (!room.content?.contentMetatags) {
      return;
    }

    const amountOfSeatTag = getAmounOfSeatsMetatag(
      room.content.contentMetatags
    );

    if (amountOfSeatTag) {
      const size: number = amountOfSeatTag.value.Number;

      const toInclude = roomSize.max
        ? roomSize.max >= size && roomSize.min <= size
        : roomSize.min <= size;

      if (!toInclude) {
        return;
      }

      let contents = sizeToContentMap.get(size);

      if (!contents) {
        contents = [];
        sizeToContentMap.set(size, contents);
      }

      contents.push(room);
    }
  });

  const items = Array.from(sizeToContentMap).sort(([a], [b]) => a - b);

  return items.reduce(
    (aggregated, [, contents]) => aggregated.concat(contents),
    [] as Room[]
  );
}

export function filterRoomBySize2(rooms: Room[], roomSize?: RoomSize) {
  if (!roomSize) {
    return {
      items: rooms,
    };
  }

  let isNextLevelTried = false;
  let items = filterRoomBySize(rooms, roomSize);

  if (items.length === 0) {
    const { min } = roomSize;
    const index = roomSizes.findIndex(item => item.min === min);
    const nextRange = roomSizes[index + 1];

    if (nextRange) {
      items = filterRoomBySize(rooms, nextRange);
      isNextLevelTried = true;
    }
  }

  return {
    items,
    warning:
      isNextLevelTried && items.length > 0
        ? 'Important! No Burst rooms are available for your selected # of Attendees. The results show Burst rooms with a higher capacity.'
        : undefined,
  };
}
