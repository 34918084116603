import { LaneType, LaneIntegrationType } from 'common-types';

type ShopifyConfigError = {
  result: 'error';
  errorMessage: string;
};
type ShopifyConfigSuccess = {
  result: 'ok';
};
type ShopifyConfigResult = ShopifyConfigSuccess | ShopifyConfigError;

/**
 * simple check to make sure that all Burst configurations are in place
 */
export default function validateShopifyIntegrationConfig(
  burstConfig: LaneIntegrationType.ShopifyBurst | null
): ShopifyConfigResult {
  if (!burstConfig) {
    return {
      result: 'error',
      errorMessage: 'Shopify Burst Integration is not configured',
    };
  }

  const {
    parentChannel,
    roomBookingSection,
    dailyScheduleOptions,
    activitiesBookingSection,
    startMetatag,
    lunchMetatag,
    endMetatag,
  } = burstConfig;

  const portValidation = validateParentChannel(parentChannel);

  if (portValidation.result === 'error') {
    return portValidation;
  }

  const roomSectionValidation = validateRoomBookingSection(roomBookingSection);

  if (roomSectionValidation.result === 'error') {
    return roomSectionValidation;
  }

  const dailyScheduleValidation = validateDailyScheduleSection(
    dailyScheduleOptions
  );

  if (dailyScheduleValidation.result === 'error') {
    return dailyScheduleValidation;
  }

  const activitiesValidation = validateActivitiesSection(
    activitiesBookingSection
  );

  if (activitiesValidation.result === 'error') {
    return activitiesValidation;
  }

  const tagValidation = validateLunchTag(
    lunchMetatag,
    startMetatag,
    endMetatag
  );

  if (tagValidation.result === 'error') {
    return tagValidation;
  }

  return {
    result: 'ok',
  };
}

function validateParentChannel(
  parentChannel: LaneType.Channel
): ShopifyConfigResult {
  if (!parentChannel) {
    return {
      result: 'error',
      errorMessage: 'Shopify Burst Parent Channel aka Port is not configured. ',
    };
  }

  return {
    result: 'ok',
  };
}

function validateRoomBookingSection(
  roomsSection: LaneType.Section
): ShopifyConfigResult {
  if (!roomsSection) {
    return {
      result: 'error',
      errorMessage: 'Shopify Room Booking Section is not configured.',
    };
  }

  return {
    result: 'ok',
  };
}

function validateDailyScheduleSection(
  dailyScheduleSection: LaneType.Section
): ShopifyConfigResult {
  if (!dailyScheduleSection) {
    return {
      result: 'error',
      errorMessage: 'Shopify Daily Schedule Section is not configured.',
    };
  }

  return {
    result: 'ok',
  };
}

function validateActivitiesSection(
  activitiesSection: LaneType.Section
): ShopifyConfigResult {
  if (!activitiesSection) {
    return {
      result: 'error',
      errorMessage: 'Shopify Activities Section is not configured.',
    };
  }

  return {
    result: 'ok',
  };
}

function validateLunchTag(
  lunchTag: LaneType.Metatag,
  startTag: LaneType.Metatag,
  endTag: LaneType.Metatag
): ShopifyConfigResult {
  if (!lunchTag) {
    return {
      result: 'error',
      errorMessage: 'Shopify Lunch Tag is not configured.',
    };
  }

  if (!startTag) {
    return {
      result: 'error',
      errorMessage: 'Shopify Lunch Tag is not configured.',
    };
  }

  if (!endTag) {
    return {
      result: 'error',
      errorMessage: 'Shopify Lunch Tag is not configured.',
    };
  }

  return {
    result: 'ok',
  };
}
