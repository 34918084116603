import { Mapping } from './types';

export const HYPERCARE_IMPORTS: { [key: string]: string } = {
  'PMSetupEquipSched': 'EquipmentImports',
  'PMSetupTasksSteps': 'TasksImports',
  'PMScheduleList': 'ScheduleImports',
  'WOListTR': 'ServiceRequestImports',
  '(OPT)PoolsList': 'UserImports',
};

export const HYPERCARE_EQUIPMENT_MAPPING: Mapping = {
  equipment_id: { keys: ['Equipment ID'], header: 'Equipment ID' },
  name: { keys: ['Equipment Name'], header: 'Equipment name' },
  category: {
    keys: ['Equipment Class'],
    header: 'Category',
    dropdownOptions: {
      sheetName: 'Reference',
      start: 'B2',
      end: 'B105',
    },
  },
  status: { keys: ['Active'], transforms: ['status'], default: 'Active', header: 'Status' },
  location: { keys: ['Building'], header: 'Location' },
  floor: { keys: ['Floor'], header: 'Floor' },
  suite: { keys: ['Suite'], header: 'Suite (tenant space)' },
  make: { keys: ['Make'], header: 'Make' },
  model: { keys: ['Model'], header: 'Model' },
  asset: { keys: ['Asset Code'], header: 'Asset #' },
  serial: { keys: ['Serial Number'], header: 'Serial #' },
  warranty_expiration_date: { keys: ['Warranty Expiry'], transforms: ['date'], header: 'Warranty expiration date' },
  install_date: { keys: ['Date Installed'], transforms: ['date'], header: 'Install date' },
  notes: { keys: ['Notes'], header: 'Additional notes' },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com', header: 'Created By' },
  created_at: { keys: [], header: 'Created At' },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com', header: 'Updated By' },
  updated_at: { keys: [], header: 'Updated At' },
};

export const HYPERCARE_SERVICE_REQUESTS_MAPPING: Mapping = {
  service_request_id: { keys: ['ID'] },
  company: { keys: ['Tenant'] },
  description: { keys: ['WO Description'] },
  category: { keys: ['Trade'] },
  issue: { keys: ['Request Type'] },
  location: { keys: ['Building'] },
  floor: { keys: ['Floor'] },
  suite: { keys: ['Suite'] },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  assignee: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  status: { keys: ['Status'] },
  submit_on_behalf_of: { keys: [] },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  notes: { keys: ['WO Detail'] },
  created_at: { keys: ['Date Received'], transforms: ['date'] },
  updated_at: { keys: ['Date Closed'], transforms: ['date'] },
  is_billable: { keys: [] },
};

export const HYPERCARE_SCHEDULE_MAPPING: Mapping = {
  schedule_id: { keys: ['Schedule #'], header: 'Schedule ID' },
  title: { keys: ['Task'], header: 'Schedule title' },
  notes: { keys: ['Schedule Description'], header: 'Notes' },
  assignee: { keys: [], default: 'zach.targos+tempeng@vts.com', header: 'Assignee' },
  time_to_complete: {
    keys: ['Est. Time (h:mm)'],
    transforms: ['hours_to_number'],
    header: 'Estimated time to complete (hrs)',
  },
  repeats: { keys: ['Rec. Period'], transforms: ['repeats'], header: 'Repeats' },
  days_ahead: { keys: [], header: 'Number of days in advance the task is created' },
  next_due_date: { keys: [], header: 'First due date' },
  until_date: { keys: ['Start / End'], transforms: ['date_range_end'], header: 'End date' },
  location: { keys: ['Floor / Suite'], header: 'Location' },
  steps: { keys: [], header: 'Steps' },
  meter_reading: { keys: [], header: 'Meter reading' },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com', header: 'Created By' },
  created_at: { keys: [], header: 'Created At' },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com', header: 'Updated By' },
  updated_at: { keys: [], header: 'Updated At' },
};

export const HYPERCARE_TASK_MAPPING: Mapping = {
  task_id: { keys: ['Task ID'] },
  due_date: { keys: [] },
  repeats: { keys: ['Rec. Period'] },
  estimate_time_to_complete: { keys: ['Est. Time (h:mm)'] },
  assignee: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  notes: { keys: ['WO Description'] },
  hours: { keys: ['Est. Time (h:mm)'] },
  steps: { keys: ['Task Line Details'] },
  created_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  created_at: { keys: [] },
  updated_at: { keys: [] },
  updated_by: { keys: [], default: 'zach.targos+tempeng@vts.com' },
  meter_reading: { keys: [] },
  location: { keys: ['Floor'] },
  status: { keys: [] },
  schedule_id: { keys: ['Schedule ID'] },
  schedule_notes: { keys: [] },
};

export const HYPERCARE_USER_MAPPING: Mapping = {
  'Full name': { keys: ['Employee Name'], transforms: ['name'] },
  'Email': { keys: ['User Name'] },
  'Phone Number': { keys: [] },
  'Teams': { keys: [] },
  'Marketing Opt-In': { keys: [], default: 'FALSE' },
  'User Status': { keys: [], default: 'Active' },
  'Tenant': { keys: [] },
};

export const HYPERCARE_MAPPINGS: { [key: string]: Mapping } = {
  'PMSetupEquipSched': HYPERCARE_EQUIPMENT_MAPPING,
  'PMSetupTasksSteps': HYPERCARE_TASK_MAPPING,
  'PMScheduleList': HYPERCARE_SCHEDULE_MAPPING,
  'WOListTR': HYPERCARE_SERVICE_REQUESTS_MAPPING,
  '(OPT)PoolsList': HYPERCARE_USER_MAPPING,
};

export const HYPERCARE_SOURCES: { [key: string]: Mapping } = {
  'EquipmentImports': HYPERCARE_EQUIPMENT_MAPPING,
  'TasksImports': HYPERCARE_TASK_MAPPING,
  'ScheduleImports': HYPERCARE_SCHEDULE_MAPPING,
  'ServiceRequestImports': HYPERCARE_SERVICE_REQUESTS_MAPPING,
  'UserImports': HYPERCARE_USER_MAPPING,
};

export const RECURRENCE_TYPES = [
  'Daily',
  'Weekly',
  'Bi-weekly',
  'Monthly',
  'Bi-monthly',
  'Quarterly',
  'Semi-annually',
  'Annually',
];
