import React, { useContext, useState } from 'react';

import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { useMagicLinksFeatureEnabled } from 'lane-shared/hooks';
import type { UserLoginMethod } from 'lane-shared/hooks/auth/useExistingOAuthUserLogins';
import usePersonalizedSSOEnabled from 'lane-shared/hooks/usePersonalizedSSOEnabled';

import { Text } from 'components/typography';

import WelcomeTemplate from '../WelcomeTemplate';
import LoginEmail from './LoginEmail';
import LoginMagicLink from './LoginMagicLink';
import LoginManual from './LoginManual';
import LoginWithSSO from './LoginWithSSO';

const MESSAGES = {
  error: 'Server error - try again later',
};

export default function LoginPage() {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { whitelabel } = useContext(AppContext);
  const { email: existingUserEmail } = useSignUpContext();
  const magicLinksFeatureEnabled = useMagicLinksFeatureEnabled();
  const isPersonalizedSSOLoginEnabled = usePersonalizedSSOEnabled();
  const enabledSSOs = whitelabel.oAuthConfig?.filter(
    oAuthConfig => oAuthConfig?.enabled
  );
  const isSSOEnabled = (enabledSSOs?.length ?? 0) > 0;

  const { t } = useTranslation();

  const searchParams = qs.parse(history?.location?.search || '');

  const contentPathSearchParam = Array.isArray(searchParams?.contentPath)
    ? searchParams?.contentPath[0]
    : searchParams?.contentPath;

  function handleUserExistsSuccess({
    email,
    isLoginEmailExist,
    oAuthLoginMethods,
  }: {
    email: string;
    isLoginEmailExist: boolean;
    oAuthLoginMethods: UserLoginMethod[];
  }) {
    setUserEmail(email);

    const isOAuthUsedInSignup = oAuthLoginMethods.length > 0;
    const isOAuthLoginExist =
      isSSOEnabled && (isOAuthUsedInSignup || !isPersonalizedSSOLoginEnabled);

    if (!isLoginEmailExist) {
      history.push({
        pathname: routes.signUp,
        search: qs.stringify(searchParams),
        state: { email },
      });
    } else {
      const magicLinkOrDefaultRoute =
        whitelabel.isMagicLinkEnabled && magicLinksFeatureEnabled
          ? routes.magicLinkRequest
          : routes.manualLogin;

      history.push({
        pathname: isOAuthLoginExist ? routes.ssoLogin : magicLinkOrDefaultRoute,
        search: qs.stringify(searchParams),
        state: { email, oAuthUserLoginMethods: oAuthLoginMethods },
      });
    }
  }

  function handleError() {
    window.Toast.show(<Text>{t(MESSAGES.error)}</Text>);
  }

  function handleSSOError(err: Error | null) {
    if (err) {
      window.Toast.show(<Text>{t(MESSAGES.error)}</Text>);
    }
  }

  function handleLoginSuccess() {
    const route = contentPathSearchParam || routes.home;

    history.push(route);
  }

  function redirectToHomeAfterLogin(route: string) {
    const path = contentPathSearchParam || route;

    history.push(path);
  }

  const redirectToLoginWithoutSSO = () => {
    const magicLinkOrDefaultRoute =
      whitelabel.isMagicLinkEnabled && magicLinksFeatureEnabled
        ? routes.magicLinkRequest
        : routes.manualLogin;

    history.push({
      pathname: magicLinkOrDefaultRoute,
      search: qs.stringify(searchParams),
    });
  };

  return (
    <WelcomeTemplate portalSize="small">
      <Switch>
        <Route exact path={path}>
          <LoginEmail
            onError={handleError}
            onSuccess={handleUserExistsSuccess}
            whitelabel={whitelabel}
          />
        </Route>
        <Route exact path={routes.manualLogin}>
          {userEmail || existingUserEmail ? (
            <LoginManual
              userEmail={userEmail || existingUserEmail}
              onError={handleError}
              onSuccess={handleLoginSuccess}
            />
          ) : (
            <Redirect to={routes.login} />
          )}
        </Route>
        <Route exact path={routes.ssoLogin}>
          {userEmail ? (
            <LoginWithSSO
              userEmail={userEmail}
              onError={handleSSOError}
              onManualLogin={redirectToLoginWithoutSSO}
              redirectToHomeAfterLogin={redirectToHomeAfterLogin}
              whitelabel={whitelabel}
            />
          ) : (
            <Redirect to={routes.login} />
          )}
        </Route>
        <Route exact path={routes.magicLinkRequest}>
          {userEmail ? (
            <LoginMagicLink userEmail={userEmail} />
          ) : (
            <Redirect to={routes.login} />
          )}
        </Route>
      </Switch>
    </WelcomeTemplate>
  );
}
