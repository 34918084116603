import React from 'react';

import { Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';

import { searchPMSchedulesQuery } from 'graphql-queries';

import { H4, M } from 'components/typography';

import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

import styles from './index.scss';

export interface EquipmentSchedulesListProps {
  channel?: UseChannelForAdminQueryResponse['channel'];
  equipmentId?: string;
  enableLinkToSchedule?: boolean;
}

const EquipmentSchedulesList = ({
  channel,
  equipmentId,
  enableLinkToSchedule,
}: EquipmentSchedulesListProps) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(searchPMSchedulesQuery, {
    variables: {
      refIds: [channel?._id ?? ''],
      pagination: {
        start: 0,
        perPage: 50,
      },
      filter: {
        equipments: [equipmentId ?? ''],
      },
      includeArchived: true,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || !data?.findSchedules?.schedules?.length) {
    return null;
  }

  return (
    <Flex direction="column" className={styles.EquipmentSchedulesList} gap={4}>
      <H4 mt={2}>
        {t`web.admin.serviceRequest.equipment.schedulesTableHeading`}
      </H4>
      <Flex
        direction="row"
        gap={4}
        className={styles.EquipmentSchedulesItemsList}
      >
        {data?.findSchedules?.schedules.map(({ scheduleId, title }) =>
          enableLinkToSchedule ? (
            <Link
              key={scheduleId}
              className={styles.EquipmentSchedulesLinks}
              to={routes.channelAdminWorkOrdersPMScheduleDetails
                .replace(':id', channel?.slug || '')
                .replace(':scheduleId', scheduleId ?? '')}
            >
              <M className={styles.EquipmentSchedulesLinkLabel}>{title}</M>
            </Link>
          ) : (
            <M key={scheduleId} className={styles.EquipmentSchedulesItems}>
              {title}
            </M>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default EquipmentSchedulesList;
