import { Button } from 'design-system-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';
import { RecurringCharge } from 'graphql-query-contracts';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { ExternalActorType } from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useUserData } from 'lane-shared/hooks';
import { cancelRecurringChargeMutation } from '../recurringChargeQueries';

export function CancelButton({
  recurringCharge,
  refetch,
}: {
  recurringCharge: Partial<RecurringCharge>;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const { t } = useTranslation();
  const { user } = useUserData();
  const [cancelRecurringCharge] = useMutation(cancelRecurringChargeMutation);

  const recurrence = recurringCharge.recurrence!;
  const chargeTemplate = recurringCharge.charge!;

  const isActive = recurrence.isActive;
  const anyoneCanCancel = recurrence.anyoneCanCancel;

  const onCancel = async () => {
    try {
      await window.Alert.confirm({
        title: t('abp.subscription.cancelSubscription.user.title', {
          chargeName: chargeTemplate.name,
        }),
        message: t('abp.subscription.cancelSubscription.user.message', {
          chargeName: chargeTemplate.name,
          nextOccurrence: dateFormatter(
            recurrence.nextOccurrence,
            dates.SIMPLE_DATE
          ),
        }),
        confirmText: t('abp.actions.cancelSubscription'),
        cancelText: t('abp.subscription.cancelSubscription.goBack'),
      });

      // User confirmed
      await cancelRecurringCharge({
        variables: {
          cancelRecurringChargeRequest: {
            recurrenceId: recurringCharge.recurrence!.recurrenceId,
            externalActorId: convertToUUID(user?._id) || '',
            externalActorType:
              ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
          },
        },
      });

      window.Toast.show(
        t(
          'abp.subscription.cancelSubscription.subscriptionCancelledSuccessfully'
        )
      );

      refetch!();
    } catch (err) {
      if (err === false) {
        // user cancelled
      } else {
        window.Toast.show(err.message, 5000);
      }
    } finally {
      window.Alert.hide();
    }
  };

  return (
    <>
      {isActive && anyoneCanCancel && (
        <Button interfaceStyle="light" variant="secondary" onClick={onCancel}>
          {t('abp.actions.cancelSubscription')}
        </Button>
      )}
      {!isActive && (
        <span className={styles.expiredHeading}>
          {t('abp.subscription.card.expired')}
        </span>
      )}
    </>
  );
}
