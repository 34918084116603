import React, { useState, useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import SignUpContext from 'lane-shared/contexts/SignUpContext';
import sendMagicLinkMutation from 'lane-shared/graphql/user/sendMagicLink';
import validateMagicLinkLogin from 'lane-shared/validation/validateMagicLinkLogin';

import magicLinkImage from 'static/img/magic-link-image.png';
import magicLinkSentImage from 'static/img/magic-link-sent.png';

import InfoViewAfterAction from './InfoViewAfterAction';
import InfoViewBeforeAction from './InfoViewBeforeAction';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  navigateBack: () => void;
  navigateToManualLogin: () => void;
  isModal?: boolean;
  magicLinkSent?: boolean;
};

export default function MagicLink({
  className,
  navigateBack,
  navigateToManualLogin,
  magicLinkSent,
}: Props) {
  const { email } = useContext(SignUpContext);
  const [submitted, setSubmitted] = useState(magicLinkSent);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function validate() {
      try {
        await validateMagicLinkLogin.validate({ email });
      } catch (err) {
        await window.Alert.alert({
          title: t(`Your email isn't valid`),
          message: t(`Please try filling in your email again.`),
          confirmText: t('OK'),
        });
        navigateBack();
      }
    }

    validate();
  }, []);

  async function sendMagicLink() {
    try {
      setLoading(true);
      setError(null);
      await getClient().mutate({
        mutation: sendMagicLinkMutation,
        variables: {
          email,
        },
      });
      setSubmitted(true);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  if (submitted) {
    return (
      <InfoViewAfterAction
        className={className}
        error={error}
        header="Check your email"
        loading={loading}
        sendMagicLink={sendMagicLink}
        email={email}
        image={magicLinkSentImage}
        navigateBack={() => {
          setSubmitted(false);
        }}
      />
    );
  }

  return (
    <InfoViewBeforeAction
      className={className}
      error={error}
      header={t('Tired of typing passwords to sign in?')}
      body={t(
        'We can email you a magic link that will sign you in to your account instantly.'
      )}
      loading={loading}
      sendMagicLink={sendMagicLink}
      image={magicLinkImage}
      navigateBack={navigateBack}
      navigateToManualLogin={navigateToManualLogin}
    />
  );
}
