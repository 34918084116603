import React from 'react';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

import { OnboardingCompany } from 'lane-web/src/components/onboarding/Onboarding/OnboardingSteps/OnboardingCompany';

export function OnboardingTestingCompany() {
  const whitelabel = useWhitelabelByIndexHTML();

  return (
    <OnboardingCompany
      errorMessage="onboarding completion error"
      userName="onboarding tester"
      onboardingState="loading"
      whitelabel={whitelabel}
      onComplete={() => {
        return;
      }}
      onClear={() => {
        return;
      }}
    />
  );
}

export default OnboardingTestingCompany;
