import React from 'react';

import { useTranslation } from 'react-i18next';

import { filterPastMeetings } from 'lane-shared/helpers/integrations/ProxyClick';
import { ContentQueryInteraction } from 'lane-shared/hooks/useContentQuery';

import Line from 'components/general/Line';

import MeetingList from './MeetingList';

import styles from './PastMeetings.scss';

type Props = {
  interactions: ContentQueryInteraction[];
};

export default function PastMeetings({ interactions }: Props) {
  const meetings = filterPastMeetings(interactions);
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <p className={styles.header}>{t('Previous Meetings')}</p>
        {meetings.length > 0 ? (
          <>
            <Line />
            <MeetingList interactions={meetings} />
          </>
        ) : (
          <div className={styles.alignCenter}>
            <div className={styles.narrow}>
              <p>{t('You don’t have any previous meetings.')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
