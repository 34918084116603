/* eslint-disable react/jsx-no-literals */
import { FileImportRecord } from './fileImportRecord';
import styles from './styles.scss';
import { useLazyQuery } from '@apollo/client';

import { getIntegrationProcesses } from 'lane-shared/graphql/fileImport';
import Label from 'components/general/Label';
import { AdminPage, Flex, PageHeader } from 'components/layout';
import { Alert } from 'components/lds';
import { M, S } from 'components/typography';
import { routes } from 'lane-shared/config';
import { ImportJobDefinitions } from 'lane-shared/domains/importer/jobDefinition';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { simpleDate } from 'lane-shared/helpers/formatters';
import {
  FileImportStatus,
  Providers,
} from 'lane-shared/types/integrations/FileImport';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  JOB_STATUS,
  ProcessAggregation,
  getChipStyleFromStatus,
  getProcessAggregation,
  getStatusFromProcesses,
} from '../util/getProcessGroups';

type Props = {
  channel: any;
};
const generateDatadogLink = ({
  jobId,
  date,
}: {
  jobId: string;
  date: string;
}): string => {
  const currentDate = new Date(date);
  // Calculate from and to dates
  const from = new Date(currentDate);

  from.setDate(currentDate.getDate() - 1); // Set to the day before
  from.setHours(0, 0, 0, 0); // Set to the start of the day (00:00:00.000)
  const to = new Date(currentDate);

  to.setDate(currentDate.getDate() + 1); // Set to the day after
  to.setHours(23, 59, 59, 999); // Set to the end of the day (23:59:59.999)

  const fromDate = from.getTime();
  const toDate = to.getTime();

  // Construct the query parameters object
  const queryParams = {
    query: encodeURIComponent(
      `service:integrations-gateway @logger.name:IGS_Reporting @JobId:${jobId}`
    ),
    from: encodeURIComponent(fromDate),
    to: encodeURIComponent(toDate),
  };

  // Construct the final URL
  const url = `https://app.datadoghq.com/logs?query=${queryParams.query}&from=${queryParams.from}&to=${queryParams.to}&from_ts=${queryParams.from}&to_ts=${queryParams.to}&live=false`;

  return url;
};

export const ImporterDetail = ({ channel }: Props) => {
  const moduleDef = ImportJobDefinitions[0];
  const [importJob, setImportJob] = useState<any>([]);
  const { jobId } = useParams<{ jobId: string }>();
  const { t } = useTranslation();
  const [dataDogLink, setDataDogLink] = useState('');
  const [jobStatus, setJobStatus] = useState<string>(
    JOB_STATUS.CREATED.friendlyName
  );
  const [
    processAggregation,
    setProcessAggregation,
  ] = useState<ProcessAggregation>();
  const [getImportJob, { data }] = useLazyQuery(getIntegrationProcesses, {
    onCompleted: async () => {
      const processes = data?.getIntegrationProcesses?.processes;

      if (processes != null && processes.length > 0) {
        setImportJob(
          processes.filter(
            (job: any) =>
              job?.metadata?.integrationMetadata?.entityType !== 'USER'
          )
        );
        setJobStatus(getStatusFromProcesses(importJob));
        setProcessAggregation(getProcessAggregation(importJob));
        const dataDogLink = generateDatadogLink({
          jobId: processes[0].metadata?.jobId,
          date: processes[0].createdAt,
        });

        setDataDogLink(dataDogLink);
      }
    },
  });

  useEffect(() => {
    if (jobId && channel) {
      getImportJob({
        variables: {
          input: {
            integrationIdentifier: {
              provider: Providers.IMPORTER,
              vts_organization_id: safeConvertToUUID(channel?._id),
            },
            search: {
              processGroupId: safeConvertToUUID(jobId),
            },
          },
        },
      });
    }
  }, [jobId, getImportJob, channel]);

  const pageHeaderProps = {
    header: t('web.admin.channel.detail.data.import.title', {
      importId: jobId,
    }),
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t('web.admin.importer.workorders.breadcrumb.data.import'),
        url: routes.channelAdminDataImportListView.replace(
          ':id',
          channel?.slug
        ),
      },
      {
        label: t('web.admin.channel.detail.data.import.header', {
          importId: jobId,
        }),
      },
    ],
  };

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader
          {...pageHeaderProps}
          status={jobStatus}
          statusType={getChipStyleFromStatus(jobStatus)}
        />
      </div>

      <Flex className={styles.bodyWrapper} direction="column" gap={5}>
        {jobStatus === FileImportStatus.IN_PROGRESS && (
          <Alert type="info" fullWidth>
            {t('web.admin.importer.workorders.detail.inProgress.message')}
          </Alert>
        )}
        {/* TODO: Currently we dont support a import report on integration, add once its added */}
        {(processAggregation?.totalFailedRecords || 0) > 0 && (
          <Alert type="warning" fullWidth>
            <Trans i18nKey="web.admin.importer.workorders.detail.error.message">
              {{ errorCount: processAggregation?.totalFailedRecords }}
              {'items failed to be imported. '}
            </Trans>
          </Alert>
        )}
        {importJob && importJob.length > 0 && (
          <M variant="secondary">
            {t('web.admin.importer.workorders.detail.imported.on', {
              importedDate: simpleDate(importJob[0]?.createdAt),
            })}
          </M>
        )}
        <Flex direction="column">
          <Label>
            {t('web.admin.importer.workorders.detail.module.title')}
          </Label>
          <S variant="secondary" className={styles.description}>
            {t(moduleDef.friendlyName)}
          </S>
        </Flex>

        {dataDogLink && (
          <Flex direction="column">
            <a href={dataDogLink} target="_blank" rel="noreferrer">
              {t('web.admin.importer.workorders.detail.datadog')}
            </a>
          </Flex>
        )}

        {importJob &&
          importJob.map((job: any) => (
            <FileImportRecord job={job || {}} key={job?.metaData?.entityType} />
          ))}
        {/* service request */}
      </Flex>
    </AdminPage>
  );
};
