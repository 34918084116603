import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getWeekOfMonth } from 'date-fns';
import { z } from 'zod';

import {
  Equipment,
  type ClientPmSchedule, 
  type StepTemplate,
  type CreatePmScheduleDto
} from 'graphql-query-contracts';
import { getEquipmentQuery } from 'graphql-queries';

import { MultiSectionForm } from 'components/form';

import { recurrenceTypes } from '../../constants/recurentTypes';
import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { AttachmentData, SelectFileReturnType } from '../PMAttachments';
import _ from 'lodash';

import { scheduleSchema, ScheduleEndDateType } from './constants';
import { ScheduleDetails } from './ScheduleDetails';
import { ScheduleAttachments } from './ScheduleAttachments';
import { ScheduleEquipments } from './ScheduleEquipments';
import { ScheduleSteps } from './ScheduleSteps';
import type { Assignable,
  EquipmentData,
  MeterReadingOption,
  MREquipment,
  ScheduleFormValidationErrors,
} from './types';

interface ScheduleEditorProps {
  attachmentFiles: AttachmentData[];
  channel: any;
  channelEquipments: Equipment[];
  onAddAttachments: SelectFileReturnType;
  onCancel: () => void;
  onRemoveAttachment: (index: string) => void;
  onSaveSchedule: (schedule: CreatePmScheduleDto) => void;
  scheduleData?: ClientPmSchedule;
  breadcrumbLinks?: { label: string; url?: string }[];
  title: string;
}

export const ScheduleEditor = ({
  attachmentFiles,
  channel,
  channelEquipments,
  onAddAttachments,
  onCancel,
  onRemoveAttachment,
  onSaveSchedule,
  scheduleData,
  breadcrumbLinks = [],
  title,
}: ScheduleEditorProps) => {
  const { t } = useTranslation();

  // State Variables
  const [schedule, setSchedule] = useState<ClientPmSchedule | undefined>({
    ...scheduleData!,
  });

  const [
    validationErrors,
    setValidationErrors,
  ] = useState<ScheduleFormValidationErrors>();

  const [assignedMember, setAssignedMember] = useState<Assignable>();
  const [assignedTeams, setAssignedTeams] = useState<Assignable[]>([]);

  const [endDateType, setEndDateType] = useState(
    scheduleData?.untilDate
      ? ScheduleEndDateType.Specific
      : ScheduleEndDateType.Never
  );

  const [selectedEquipments, setSelectedEquipments] = useState<EquipmentData[]>(
    []
  );
  const [isEquipmentLinked, setIsEquipmentLinked] = useState(false);
  const [availableEquipment, setAvailableEquipment] = useState<
    Map<string, Equipment[]>
  >(new Map<string, Equipment[]>());
  const [meterReadings, setMeterReadings] = useState<MeterReadingOption[]>([]);
  const [equipmentWithMR, setEquipmentWithMR] = useState<MREquipment[]>([]);

  const [currentSteps, setCurrentSteps] = useState<(StepTemplate | null)[] | null>(
    scheduleData?.stepTemplate ?? []
  );

  const [activeSection, setActiveSection] = useState('details');

  const isEquipmentEnabled =
    channel?.settings?.hasWorkOrderEquipmentEnabled;

  // Handlers
  const handleScheduleFieldChange = (
    prop: keyof ClientPmSchedule,
    value: string
  ) => {
    setSchedule({
      ...(schedule ?? ({} as ClientPmSchedule)),
      [prop]: value,
    });
  };

  const handleScheduleSave = async () => {
    setValidationErrors(undefined);

    if (!schedule) {
      return;
    }

    let weekN = schedule.nextDueDate
      ? getWeekOfMonth(schedule.nextDueDate)
      : schedule.weekNo;

    if (weekN && weekN > 4) {
      weekN = 4;
    }

    const dataToValidate = {
      ...schedule,
      endDateType,
      extRefId: channel._id,
      assignee: assignedMember?.value ?? '',
      weekday: schedule.nextDueDate
        ? new Date(schedule.nextDueDate).getDay()
        : schedule.weekday,
      weekNo: weekN,
      monthNo: schedule.nextDueDate
        ? new Date(schedule.nextDueDate).getMonth() + 1
        : schedule.monthNo,
      equipmentIds: selectedEquipments
        .filter(eq => eq.equipment)
        .map(eq => eq.equipment!.id),
      assigneeGroups: assignedTeams.map(team => convertToUUID(team.value)),
    };

    let dataToSave: CreatePmScheduleDto;

    try {
      dataToSave = scheduleSchema.parse(dataToValidate) as CreatePmScheduleDto;
    } catch (err) {
      window.Toast.show(
        t('web.admin.workOrder.preventiveMaintenance.schedule.Form.toast.error')
      );

      if (
        err instanceof z.ZodError ||
        (err as any).constructor.name === z.ZodError.name
      ) {
        const vErrors = (err as z.ZodError).formErrors.fieldErrors;

        setValidationErrors(vErrors as ScheduleFormValidationErrors);
      }

      return;
    }

    switch (schedule?.repeats) {
      case recurrenceTypes.BIWEEKLY:
      case recurrenceTypes.BIMONTHLY:
        dataToSave.interval = 2;
        break;
      case recurrenceTypes.QUARTERLY:
        dataToSave.interval = 3;
        break;
      case recurrenceTypes.SEMIANNUALLY:
        dataToSave.interval = 6;
        break;
    }

    dataToSave.stepTemplate = currentSteps;

    await onSaveSchedule(dataToSave);
  };

  const handleAddStep = () => {
    document.getElementById('link-bottom')?.click();
  };

  // Queries
  const getEquipmentData = async () => {
    const equipmentQueries = selectedEquipments.map(eq => {
      const searchId = eq?.equipment?.id;

      if (!searchId) return;

      return getClient().query({
        query: getEquipmentQuery,
        variables: {
          equipmentId: eq?.equipment?.id,
        },
        fetchPolicy: 'network-only',
      });
    });

    await Promise.all(equipmentQueries).then(data => {
      const allMeterReadingOptions: MeterReadingOption[] = [];
      const allEquipmentWithMR: MREquipment[] = [];

      data.forEach(d => {
        const settings = d?.data?.getEquipment?.meterReadingSettings;
        const meterReadingOption = settings?.map((setting: any) => ({
          id: setting.id,
          name: setting.name,
          unit: setting.unit,
          equipmentId: setting.equipmentId,
        }));

        if (!meterReadingOption) return;

        allMeterReadingOptions.push(...meterReadingOption);
        allEquipmentWithMR.push({
          id: d?.data?.getEquipment?.id || '',
          name: d?.data?.getEquipment?.name || '',
        });

        setEquipmentWithMR(allEquipmentWithMR);
        setMeterReadings(allMeterReadingOptions);
      });
    });
  };

  const submitEnabled =
    schedule?.title &&
    schedule.timeToComplete &&
    schedule.repeats &&
    schedule.nextDueDate &&
    (schedule.daysAhead || schedule.daysAhead === 0);

  const scheduleFormSections = [
    {
      id: 'details',
      title: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.details'
      ),
      content: (
        <ScheduleDetails
          channel={channel}
          schedule={schedule}
          onScheduleFieldChange={handleScheduleFieldChange}
          validationErrors={validationErrors}
          assignedMember={assignedMember}
          setAssignedMember={setAssignedMember}
          assignedTeams={assignedTeams}
          setAssignedTeams={setAssignedTeams}
          endDateType={endDateType}
          setEndDateType={setEndDateType}
        />
      ),
    },
    {
      id: 'attachments',
      title: t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.attachments'
      ),
      content: (
        <ScheduleAttachments
          attachmentFiles={attachmentFiles}
          onAddAttachments={onAddAttachments}
          onRemoveAttachment={onRemoveAttachment}
        />
      ),
    },
    ...(isEquipmentEnabled
      ? [
          {
            id: 'equipment',
            title: t(
              'web.admin.workOrder.preventiveMaintenance.schedule.Form.equipment'
            ),
            content: (
              <ScheduleEquipments
                onScheduleFieldChange={handleScheduleFieldChange}
                schedule={schedule}
                selectedEquipments={selectedEquipments}
                setSelectedEquipments={setSelectedEquipments}
                validationErrors={validationErrors}
                isEquipmentLinked={isEquipmentLinked}
                setIsEquipmentLinked={setIsEquipmentLinked}
                availableEquipment={availableEquipment}
                currentSteps={_.compact(currentSteps)}
                meterReadings={meterReadings}
              />
            ),
          },
        ]
      : []),
    {
      id: 'steps',
      title: t('web.admin.workOrder.preventiveMaintenance.schedule.Form.steps'),
      content: (
        <ScheduleSteps
                currentSteps={_.compact(currentSteps)}
          setCurrentSteps={setCurrentSteps}
          meterReadings={meterReadings}
          mrEquipments={equipmentWithMR}
          onAddStep={handleAddStep}
        />
      ),
    },
  ];

  useEffect(() => {
    getEquipmentData();
  }, [selectedEquipments]);

  useEffect(() => {
    const eqMap = new Map<string, Equipment[]>();

    channelEquipments.forEach(eq => {
      if (eqMap.has(eq.category)) {
        const arr = eqMap.get(eq.category);

        arr!.push(eq);
      } else {
        eqMap.set(eq.category, [eq]);
      }
    });

    setAvailableEquipment(eqMap);

    // set selected equipments
    if (schedule?.equipmentIds && schedule.equipmentIds.length > 0) {
      const pmEqs = schedule.equipmentIds
        .map(eqId => {
          const eq = channelEquipments.find(eq => eq.id === eqId);

          return eq ? { category: eq.category, equipment: eq } : undefined;
        })
        .filter(eq => eq !== undefined) as {
        category: string;
        equipment: Equipment;
      }[];

      setIsEquipmentLinked(true);
      setSelectedEquipments(pmEqs);
    }
  }, [channelEquipments, schedule?.equipmentIds]);

  useEffect(() => {
    const obOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const obCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target?.id);
        }
      });
    };

    const observer = new IntersectionObserver(obCallback, obOptions);

    scheduleFormSections.forEach(section => {
      const target = document.getElementById(section.id);

      if (target) {
        observer.observe(target);
      }
    });
  });

  return (
    <MultiSectionForm
      title={title}
      submitText="Submit"
      cancelText="Cancel"
      sections={scheduleFormSections}
      handleSubmit={handleScheduleSave}
      handleCancel={onCancel}
      enableSubmit={!!submitEnabled}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      links={breadcrumbLinks}
    />
  );
};
