import React, { useContext, useEffect } from 'react';

import AppContext from 'lane-shared/contexts/AppContext';
import {
  OnboardingContextProvider,
  OnboardingData,
} from 'lane-shared/contexts/OnboardingContext';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useAuth0Enabled } from 'lane-shared/hooks/auth0/useAuth0Enabled';
import { useInviteContext } from 'lane-shared/contexts/InviteContext';

import { UserIdentificationAndOnboardingTemplate as Template } from 'components/pageTemplate/UserIdentificationAndOnboarding';
import { Loading } from 'design-system-web/components/Loading/Loading';

import { OnboardingSteps } from './OnboardingSteps';
import { useAuthClearSession } from './useAuthClearSession';

const MESSAGES = {
  notAvailable: 'Not available',
};

export type UserOnboardingData = OnboardingData;

type Props = {
  onOnboarding: (data: OnboardingData) => Promise<void>;
  onOnboardingError: (error: Error) => Promise<string>;
};

export const Onboarding = ({ onOnboarding, onOnboardingError }: Props) => {
  const { whitelabel } = useContext(AppContext);

  const isAuth0Enabled = useAuth0Enabled();
  const { building, company, userName, loading } = useInviteContext();
  const authClearSession = useAuthClearSession();

  useEffect(() => {
    function handleBeforeUnload(_e: BeforeUnloadEvent) {
      authClearSession();
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [authClearSession]);

  const handleOnboardingComplete = async (data: UserOnboardingData) => {
    await onOnboarding(data);
  };

  if (!isAuth0Enabled) {
    return <div>{MESSAGES.notAvailable}</div>;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <OnboardingContextProvider
      defaultData={{ userName, building, company }}
      asyncOnComplete={handleOnboardingComplete}
      asyncOnError={onOnboardingError}
    >
      <Template
        logo={imageUrl(whitelabel.profile.logo)}
        name={whitelabel.profile.name}
      >
        <OnboardingSteps />
      </Template>
    </OnboardingContextProvider>
  );
};
