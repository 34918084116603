import React, { useContext } from 'react';

import { UserDataContext } from 'lane-shared/contexts';

import Property from 'components/builder/properties/input/Property';
import { H4 } from 'components/typography';

import { ChannelIntegrationEditorProps } from './ChannelIntegrationEditorProps';

import styles from './ChannelIntegrationEditorAutoRenderer.scss';

export default function ChannelIntegrationEditorAutoRenderer({
  definition,
  channel,
  channelIntegration,
  onUpdateChannelIntegration,
}: ChannelIntegrationEditorProps) {
  const { user } = useContext(UserDataContext);

  return (
    <div className={styles.ChannelIntegrationEditorAutoRenderer}>
      <H4 mb={4}>{definition.friendlyName} Settings</H4>
      {Object.entries(definition.properties).map(([key, property]) => {
        if (property.hidden) {
          return null;
        }

        if (
          channelIntegration.settings[key] === undefined &&
          property.default !== undefined
        ) {
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              [key]: property.default,
            },
          });
        }

        return (
          <div key={key} className={styles.property}>
            <Property
              object={channelIntegration.settings}
              channel={channel}
              label={property.friendlyName || property.name || key}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ object: any; channel: ChannelType; label: ... Remove this comment to see the full error message
              user={user}
              property={property}
              propertyKey={key}
              value={channelIntegration.settings[key]}
              onChange={value =>
                onUpdateChannelIntegration({
                  settings: {
                    ...channelIntegration.settings,
                    [key]: value,
                  },
                })
              }
              onPropertyChange={({ key, value }) =>
                onUpdateChannelIntegration({
                  settings: {
                    ...channelIntegration.settings,
                    [key]: value,
                  },
                })
              }
            />
          </div>
        );
      })}
    </div>
  );
}
