import React, { useState } from 'react';

import { PropertyType } from 'lane-shared/types/properties/Property';

import { CustomData } from './components';
import { CustomPropertyType } from './types';

type Props = {
  customQuestions: PropertyType[];
  onSettingsUpdated: (customQuestions: PropertyType[]) => void;
};
export function CustomQuestionBuilder({
  customQuestions,
  onSettingsUpdated,
}: Props) {
  const [customFields, setCustomFields] = useState<CustomPropertyType[]>(
    customQuestions
  );
  const saveCustomFields = (customFields: PropertyType[]) => {
    setCustomFields(customFields);
    onSettingsUpdated(customFields);
  };

  function onCustomDataDelete(input: CustomPropertyType) {
    const inputs = [...customFields.filter(field => field._id !== input._id)];

    saveCustomFields(inputs);
  }

  return (
    <div>
      <CustomData
        customInputs={customFields || []}
        onCustomInputsChange={saveCustomFields}
        onCustomDataDelete={onCustomDataDelete}
        disableSave
      />
    </div>
  );
}
