import React, { useState, useEffect, useContext } from 'react';

import {
  ControlMenu,
  Loading,
  Button as LegacyButton,
  ErrorMessage,
  Flex,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { addSectionToChannel } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { constructSection } from 'lane-shared/helpers/channel';
import { validateCreateSection } from 'lane-shared/validation';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import ChannelAdminContext from '../../ChannelAdminContext';

import styles from './styles.scss';

import { SectionForm } from '../components/SectionForm';
import { SectionFormLegacy } from '../components/SectionFormLegacy';
import { SectionEditLegacy } from '../components/SectionEditLegacy';
import { BreadCrumbs } from 'components/lds';
import { routes } from 'lane-shared/config';
import { H3 } from 'components/typography';
import { Button, TextButton } from 'design-system-web';

function ChannelSectionNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel, refetchChannel } = useContext(ChannelAdminContext);
  const [section, setSection] = useState(null);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const newSectionUIIsEnabled = useFlag(FeatureFlag.SectionUI, false);
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);

  useEffect(() => {
    if (channel?._id) {
      setSection(constructSection({ channelId: channel._id }));
    }
  }, [channel?._id]);

  if (!section) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  async function validate() {
    setValidation(null);

    try {
      await validateCreateSection.validate(section, { abortEarly: false });

      return true;
    } catch (err) {
      setValidation(err);

      return false;
    }
  }

  function onSectionUpdated(update: any) {
    setSection({
      ...(section || {}),
      ...update,
    });

    if (validation) {
      validate();
    }
  }

  async function createSection() {
    setError(null);

    if (!(await validate())) {
      return;
    }

    try {
      setLoading(true);
      await pause();
      const { data } = await getClient().mutate({
        mutation: addSectionToChannel,
        variables: { section },
      });

      refetchChannel();

      const sectionShowRoute = routes.channelAdminSectionsView
        .replace(':id', channel?.slug!)
        .replace(':sectionId', data.addSectionToChannel._id);

      history.push(sectionShowRoute);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  const sectionIndexRoute = routes.channelAdminSections.replace(
    ':id',
    channel?.slug!
  );

  const breadCrumbs = [
    {
      label: t('web.admin.content.sections.breadcrumbs.index'),
      url: sectionIndexRoute,
    },
    {
      label: t('web.admin.content.sections.breadcrumbs.new'),
    },
  ];

  const cancelAction = async () => {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.new.cancelTitle'),
        message: `${t('web.admin.content.sections.new.cancelMesage')}`,
      });
    } catch (err) {
      // user cancelled
      return;
    }

    history.push(sectionIndexRoute);
  };

  if (newSectionUIIsEnabled) {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <BreadCrumbs links={breadCrumbs} />
        </div>
        <H3>{t('web.admin.content.sections.new.title')}</H3>
        <SectionForm
          validation={validation}
          channel={channel}
          section={section}
          onSectionUpdated={onSectionUpdated}
          forCreate
        />
        <Flex direction="row" align="center" m={[5, 0, 0, 0]}>
          <Button
            onClick={createSection}
            size="large"
            variant="primary"
            testId="sectionCreateButton"
          >
            {t('Create')}
          </Button>
          <TextButton onClick={cancelAction}>{t('Cancel')}</TextButton>
        </Flex>
      </div>
    );
  }

  if (!newSectionUIIsEnabled && areContentTagsEnabled) {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <BreadCrumbs links={breadCrumbs} />
        </div>
        <H3>{t('web.admin.content.sections.new.title')}</H3>
        <SectionFormLegacy
          validation={validation}
          channel={channel}
          section={section}
          onSectionUpdated={onSectionUpdated}
          forCreate
        />
        <Flex direction="row" align="center" m={[5, 0, 0, 0]}>
          <Button
            onClick={createSection}
            size="large"
            variant="primary"
            testId="sectionCreateButton"
          >
            {t('Create')}
          </Button>
          <TextButton onClick={cancelAction}>{t('Cancel')}</TextButton>
        </Flex>
      </div>
    );
  }

  return (
    <div className={styles.ChannelSectionNew}>
      <ControlMenu>
        <h1>{t`web.admin.content.sections.new.createSectionHeader`}</h1>
        <LegacyButton
          loading={loading}
          onClick={() => createSection()}
          variant="contained"
          testId="createSection"
        >
          {t`web.admin.content.sections.new.create`}
        </LegacyButton>
      </ControlMenu>

      <ErrorMessage error={error} />

      <SectionEditLegacy
        validation={validation}
        channel={channel}
        section={section}
        onSectionUpdated={onSectionUpdated}
        forCreate
      />
    </div>
  );
}

export default ChannelSectionNew;
