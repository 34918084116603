import React from 'react';
import {
  NativeFilterTypes,
  FilterType,
  Column,
  getFiltersFromQueryString,
  QueryString,
} from 'design-system-web';

import { uniq, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ChipStyle, ChipSelect } from 'components/ads';

import { JobResponse } from './RecentJobs';

export function useRecentJobsTableColumns(): Column<JobResponse>[] {
  const { t } = useTranslation();

  return [
    {
      header: t`web.admin.portalManagement.recentJobs.id`,
      key: 'id',
      disableSorting: true,
    },
    {
      header: t`web.admin.portalManagement.recentJobs.created`,
      key: 'created',
    },
    {
      header: t`web.admin.portalManagement.recentJobs.updated`,
      key: 'updated',
    },
    {
      header: t`web.admin.portalManagement.recentJobs.status`,
      key: 'status',
      renderCell: (cell: string, _: JobResponse) => (
        <ChipSelect.NonInteractive
          value={t(`web.admin.portalManagement.recentJobs.status.${cell}`)}
          type={getColorFromJobStatus(cell)}
          size="md"
        />
      ),
    },
    {
      header: t`web.admin.portalManagement.recentJobs.type`,
      key: 'type',
    },
  ];
}

function getColorFromJobStatus(status: string) {
  switch (status) {
    case 'JOB_COMPLETED':
      return ChipStyle.Green;
    case 'JOB_FAILED':
    case 'JOB_UNRECOGNIZED':
      return ChipStyle.Red;
    default:
      return ChipStyle.Blue;
  }
}

export function getSortedFilteredRecentJobsData({
  t,
  jobs,
  queryStrings,
}: {
  t: (key: string) => string;
  jobs: JobResponse[];
  queryStrings: QueryString;
}) {
  const statuses = uniq(jobs.map(({ status }: { status: string }) => status));
  const types = uniq(jobs.map(({ type }: { type: string }) => type));

  const filtersFromJobs: FilterType[] = [
    {
      label: t('web.admin.portalManagement.recentJobs.status'),
      key: 'status',
      type: NativeFilterTypes.Multiselect,
      options: statuses.map(status => ({
        label: t(
          `web.admin.portalManagement.recentJobs.status.${status as string}`
        ),
        value: status as string,
      })),
      isPromoted: true,
    },
    {
      label: t('web.admin.portalManagement.recentJobs.type'),
      key: 'type',
      type: NativeFilterTypes.Multiselect,
      options: types.map(type => ({
        label: t(
          `web.admin.portalManagement.recentJobs.type.${type as string}`
        ),
        value: type as string,
      })),
      isPromoted: true,
    },
  ];

  const activeFilters = getFiltersFromQueryString(
    queryStrings as Record<string, string>,
    filtersFromJobs
  );

  const searchData = queryStrings.keyword
    ? jobs.filter((job: JobResponse) =>
        Object.values(job).some(value => {
          return value && queryStrings.keyword
            ? (value as string)
                .toLocaleLowerCase()
                .includes(queryStrings.keyword.toLocaleLowerCase())
            : false;
        })
      )
    : jobs;

  const filteredJobs = searchData.filter((job: JobResponse) => {
    return activeFilters.length > 0
      ? activeFilters.every(filter => {
          type JobKeys = keyof JobResponse;
          const filterId: JobKeys = filter.id as JobKeys;

          if (filter.value) {
            if (typeof filter.value === 'string') {
              return job[filterId] === filter.value;
            }

            if (Array.isArray(filter.value) && filter.value.length > 0) {
              return filter.value.includes(job[filterId]);
            }
          }

          return true;
        })
      : true;
  });

  if (queryStrings.sortBy !== '' && queryStrings.sortDirection !== '') {
    const filteredSortedJobs = orderBy(filteredJobs, queryStrings.sortBy, [
      queryStrings.sortDirection as 'asc' | 'desc',
    ]);

    return { filteredJobs: filteredSortedJobs, filtersFromJobs };
  }

  return { filteredJobs, filtersFromJobs };
}
