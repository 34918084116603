import React from 'react';

import { Flex } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';

import { PageSizeType, Table } from 'design-system-web';

import { archiveMeterReadingValueMutation } from 'graphql-queries';
import { SortDirection } from 'graphql-query-contracts';

import styles from './MeterReadingTable.scss';
import {
  VISUALIZATION_TYPES,
  useMeterReadingList,
} from './meterReadingListContext';

export function MeterReadingTable() {
  const { t } = useTranslation();

  const {
    channel,
    isLoading,
    refetch,
    meterReadingValues,
    totalCount,
    perPage,
    exportOptions,
    setVisualizationType,
    setKeyword,
    setPage,
    setPerPage,
    setSortBy,
    setSortDirection,
    setEditingReading,
    columns,
  } = useMeterReadingList();

  const rowActions = [
    {
      label: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.actions.editValue'
      ),
      onClick: (row: any) => {
        setEditingReading(row);
      },
    },
    {
      label: t(
        'web.admin.serviceRequest.equipment.meterReadingValues.tableColumn.actions.archieveValue'
      ),
      onClick: async (row: any) => {
        await getClient().mutate({
          mutation: archiveMeterReadingValueMutation,
          variables: {
            meterReadingValueId: row?.id,
            channelId: channel?._id,
          },
        });
        window.Toast.show(
          t(
            'web.admin.serviceRequest.equipment.MeterReadingInput.archive.successToast'
          )
        );

        refetch();
      },
    },
  ];

  return (
    <Flex direction="column" className={styles.meterReadingTable} gap={5}>
      <Table
        maxHeight="65vh"
        isLoading={isLoading}
        columns={columns}
        data={meterReadingValues}
        totalRows={Number(totalCount)}
        pagination="server"
        rowActions={rowActions}
        exportOptions={exportOptions}
        hasKeywordFilter
        visualizationTypes={VISUALIZATION_TYPES}
        visualizationType="table"
        pageSize={perPage}
        onVisualizationTypeChange={visualizationType => {
          setVisualizationType(visualizationType);
        }}
        onKeywordFilterChange={(keyword: string) => {
          setKeyword(keyword);
        }}
        onPageChange={(page: number) => {
          setPage(page);
        }}
        onPageSizeChange={(pageSize: PageSizeType) => {
          setPerPage(pageSize);
        }}
        onSortChange={(sort: any) => {
          if (sort?.id) {
            setSortBy(sort?.id || 'createdAt');
          }

          if (sort?.direction) {
            setSortDirection((sort?.direction || 'desc') as SortDirection);
          }
        }}
      />
    </Flex>
  );
}
