import React, { useContext } from 'react';
import { UserDataContext } from 'lane-shared/contexts';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingStates from './components/general/LoadingStates';
import { Redirect, useLocation } from 'react-router-dom';
import { routes } from 'lane-shared/config';

export const RequireUserOnboarded = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { user, isLoggingIn: isLoggingInUser, hasAttemptedLogin } = useContext(
    UserDataContext
  );

  const {
    isAuthenticated: isAuthenticatedViaAuth0,
    isLoading: isLoadingAuth0Authentication,
  } = useAuth0();

  const location = useLocation();

  const needsToSignup = isAuthenticatedViaAuth0 && !user;
  const needsToOnboard = isAuthenticatedViaAuth0 && !user?.roles?.length;

  if (isLoadingAuth0Authentication || isLoggingInUser) {
    return <LoadingStates />;
  }

  if (!hasAttemptedLogin) {
    return <LoadingStates />;
  }

  const queryParams = location.search.substring(1);

  if (needsToSignup) {
    return (
      <Redirect
        to={{
          pathname: `${routes.signup}/profile`,
          search: queryParams,
        }}
      />
    );
  }

  if (needsToOnboard) {
    return (
      <Redirect
        to={{
          pathname: `${routes.onboarding}/profile`,
          search: queryParams,
        }}
      />
    );
  }

  return children;
};
