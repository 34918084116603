import React from 'react';

import { dateFormatter } from 'lane-shared/helpers/formatters';
import { LONG_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import type { MeterReadingValueResponseType } from 'graphql-query-contracts';

type Payload = {
  payload: MeterReadingValueResponseType & { unit: string };
};

export const MeterReadingDetails = ({
  active,
  payload,
}: {
  active: boolean;
  payload: Payload[];
}) => {
  if (active && payload?.length > 0) {
    const meterReading = payload[0].payload;

    return (
      <div className="bg-background-primary rounded shadow-lg px-2 py-1 max-w-48">
        <div>
          {meterReading.value}
          {meterReading.unit}
        </div>
        {meterReading.notes && (
          <div className="mt-1 font-body-small italic">
            {meterReading.notes}
          </div>
        )}
        <div className="text-text-subdued font-body-small mt-1">
          {dateFormatter(meterReading.createdAt, LONG_DATE_TIME)}
        </div>
      </div>
    );
  }

  return null;
};
