import React, { useMemo } from 'react';

import upgradeOutdatedBurstInteractionData from 'lane-shared/helpers/integrations/ShopifyBurst/upgradeOutdatedBurstInteractionData';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import {
  IWizardState,
  useWizard,
  WizardStepComponent,
} from 'lane-shared/hooks/useWizard';
import { ContentType } from 'lane-shared/types/content/Content';
import {
  BurstDraftInteractionData,
  CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
} from 'lane-shared/types/integrations/ShopifyBurst';

import Loading from 'components/general/Loading';
import BurstDiyContent from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstDiyContent';
import BurstGuests from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstGuests';
import BurstL9 from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstL9';
import BurstQuestionnaire from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstQuestionnaire';
import BurstRoomBook from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstRoomBook';
import BurstSpecialRequest from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstSpecialRequest';
import { Flex } from 'components/layout';

type Props = {
  burstContent: ContentType;
  burstData: BurstDraftInteractionData;
  onDataUpdated?(data: BurstDraftInteractionData): void;
  onComplete?(
    wizardState: IWizardState<BurstDraftInteractionData>
  ): Promise<void>;
};

const BurstAdminEditFlowPages: WizardStepComponent<BurstDraftInteractionData>[] = [
  BurstRoomBook,
  BurstQuestionnaire,
  BurstGuests,
  BurstSpecialRequest,
  BurstL9,
];

const BurstAdminEditDiyBurstPages: WizardStepComponent<BurstDraftInteractionData>[] = [
  BurstQuestionnaire,
  BurstDiyContent,
  BurstGuests,
  BurstSpecialRequest,
  BurstL9,
];

export default function BurstAdminEditFlow({
  burstContent,
  burstData: possiblyOutdatedBurstData,
  onDataUpdated = () => undefined,
  onComplete,
}: Props) {
  const {
    loading,
    diyQuestionsContent,
    specialRequestContent,
    questionnaireContent,
    burstNameContent,
    acknowledgementContent,
  } = useShopifyBurstContext();

  const burstData = useMemo(() => {
    if (
      questionnaireContent &&
      possiblyOutdatedBurstData &&
      possiblyOutdatedBurstData.schemaVersion !==
        CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION
    ) {
      return upgradeOutdatedBurstInteractionData({
        interactionData: possiblyOutdatedBurstData,
        questionnaireContent,
      });
    }

    return possiblyOutdatedBurstData;
  }, [possiblyOutdatedBurstData, questionnaireContent]);

  const initialWizardState = {
    currentStepIndex: 0,
    data: burstData,
    updatedAt: new Date(),
  };

  const scenes = burstData.isDiyBurst
    ? BurstAdminEditDiyBurstPages
    : BurstAdminEditFlowPages;

  const {
    goToPrevStep,
    goToNextStep,
    goToStep,
    goToStepByName,
    setData,
    wizardState,
    resetWizard,
  } = useWizard({
    scenes,
    initialWizardState,
    onComplete,
    async onDataUpdated() {},
    async onCompleteStep(wizardState) {
      onDataUpdated(wizardState.data);
    },
    keepInData: {
      specialRequestContent:
        burstData.specialRequestContent || specialRequestContent,
      questionnaireContent:
        burstData.questionnaireContent || questionnaireContent,
      diyQuestionsContent: burstData.diyQuestionsContent || diyQuestionsContent,
      burstNameContent: burstData.burstNameContent || burstNameContent,
      acknowledgementContent:
        burstData.acknowledgementContent || acknowledgementContent,
    },
  });

  const Scene = scenes[wizardState.currentStepIndex]!;

  if (loading) {
    return <Loading />;
  }

  return (
    <Flex direction="column">
      <Scene
        key={wizardState.currentStepIndex}
        index={wizardState.currentStepIndex}
        content={burstContent}
        wizardState={wizardState}
        goToPrevStep={goToPrevStep}
        goToNextStep={goToNextStep}
        onDataUpdated={setData}
        goToStep={goToStep}
        goToStepByName={goToStepByName}
        resetWizard={resetWizard}
        totalSteps={scenes.length}
      />
    </Flex>
  );
}
