import React from 'react';

import { IconButton } from 'components';
import { useTranslation } from 'react-i18next';

import {
  MarkupAmountType,
  SupportedPaymentCurrency,
  TaxAmountType,
  AddProductToChargeDetails,
} from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { M, Column, Icon, Tooltip } from 'design-system-web';

import styles from '../addProductToChargeStyles.scss';

export function useTableColumnsAddedProducts({
  currency,
  removeProduct,
  editProduct,
}: {
  currency: SupportedPaymentCurrency;
  removeProduct: Function;
  editProduct: Function;
}): Column<AddProductToChargeDetails>[] {
  const { t } = useTranslation();

  const renderFixedAmountCell = (cell: string) => {
    return currencyFormatter({
      currency,
    })(Number(cell));
  };

  const renderProductTypeCell = (cell: string) => {
    return t(`abp.productsServices.productType.${cell}`);
  };

  const renderRateModifierCell = (cell: {
    value: string;
    type: MarkupAmountType | TaxAmountType;
  }) => {
    const isPercentageCell =
      cell.type === MarkupAmountType.MarkupAmountTypePercentage ||
      cell.type === TaxAmountType.TaxAmountTypePercentage;

    return isPercentageCell
      ? `${cell.value} %`
      : renderFixedAmountCell(cell.value);
  };

  const renderIconCell = (
    data: AddProductToChargeDetails,
    type: 'delete' | 'edit'
  ) => {
    const iconToUse = type === 'delete' ? 'trash-alt' : 'pen';
    const iconFunction = type === 'delete' ? removeProduct : editProduct;
    const icontestId =
      type === 'delete' ? 'deleteIconButton' : 'editIconButton';

    return (
      <IconButton
        icon={iconToUse}
        onClick={() => iconFunction(data)}
        testId={icontestId}
      />
    );
  };

  const renderTotalCell = (cell: string, row: AddProductToChargeDetails) => {
    return (
      <div
        className={styles.totalCell}
        data-test={`totalCell-${row.productId}`}
      >
        <M>{renderFixedAmountCell(cell)}</M>
        {row.originalPrice && (
          <Tooltip
            TooltipComponent={t('abp.charges.addProduct.modal.exceptionInfo')}
            zIndex={100}
            placement="right"
          >
            <Icon
              name="exclamation-circle"
              set="FontAwesome"
              type="far"
              size="medium"
            />
          </Tooltip>
        )}
      </div>
    );
  };

  return [
    {
      key: 'productCategoryType',
      header: t('abp.charges.addProduct.table.headers.type'),
      renderCell: renderProductTypeCell,
    },
    {
      key: 'productCategoryName',
      header: t('abp.charges.addProduct.table.headers.category'),
      renderCell: renderStringCell,
    },
    {
      key: 'chargeCode',
      header: t('abp.charges.addProduct.table.headers.chargeCode'),
      renderCell: renderStringCell,
    },
    {
      key: 'name',
      header: t('abp.charges.addProduct.table.headers.item'),
      renderCell: renderStringCell,
    },
    {
      key: 'rate',
      header: t('abp.charges.addProduct.table.headers.rate'),
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'quantity',
      header: t('abp.charges.addProduct.table.headers.qty'),
      renderCell: renderStringCell,
    },
    {
      key: 'markup',
      header: t('abp.charges.addProduct.table.headers.markup'),
      renderCell: renderRateModifierCell,
    },
    {
      key: 'tax',
      header: t('abp.charges.addProduct.table.headers.tax'),
      renderCell: renderRateModifierCell,
    },
    {
      key: 'total',
      header: t('abp.charges.addProduct.table.headers.total'),
      renderCell: (cell: string, row: AddProductToChargeDetails) =>
        renderTotalCell(cell, row),
    },
    {
      key: 'delete',
      header: '',
      renderCell: (_: unknown, row: AddProductToChargeDetails) =>
        renderIconCell(row, 'delete'),
    },
    {
      key: 'edit',
      header: '',
      renderCell: (_: unknown, row: AddProductToChargeDetails) =>
        renderIconCell(row, 'edit'),
    },
  ];
}

function renderStringCell(cell: string) {
  return cell;
}
