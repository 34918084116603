// @ts-nocheck FIXME: Ignored due failing CI after React update
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { ValidationError } from 'yup';

import { useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { getMetatag } from 'lane-shared/graphql/query';
import { GetMetatagResponse } from 'lane-shared/graphql/query/getMetatag';
import { castForUpdate, objectToArray, pause } from 'lane-shared/helpers';
import { MetatagType } from 'lane-shared/helpers/constants/metatags';
import { slugify } from 'lane-shared/helpers/formatters';
import { constructFriendlyType } from 'lane-shared/helpers/properties';
import { byOrder } from 'lane-shared/helpers/sort';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  PropertiesInterface,
  PropertyType,
} from 'lane-shared/types/properties/Property';
import {
  validateCreateMetatag,
  validateUpdateMetatag,
} from 'lane-shared/validation';

import createMetatagMutation from './createMetatagMutation';
import deleteMetatagMutation from './deleteMetatagMutation';
import updateMetatagMutation from './updateMetatagMutation';

export type MetatagEditType = {
  _id: LaneType.UUID;
  type: MetatagType;
  properties: PropertiesInterface | PropertyType;
};

export default function useMetatagEdit({
  metatagId,
  channel,
}: {
  metatagId: LaneType.UUID;
  channel: ChannelType;
}) {
  const { t } = useTranslation();
  const [hasSchemaChanges, setHasSchemaChanges] = useState(false);
  // has the user tried to do a create or save yet
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  // is a save or create currently running
  const [updating, setUpdating] = useState(false);
  // for complex types, the current field being edited
  const [editingField, setEditingField] = useState<PropertyType | null>(null);
  // an array of the fields in the metatag.properties for complex types
  const [fields, setFields] = useState<PropertyType[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [metatag, setMetatag] = useState<MetatagEditType | null>(
    metatagId ? null : constructMetatag(MetatagType.Simple)
  );

  const [fetchMetatag, metatagResult] = useLazyQuery<GetMetatagResponse>(
    getMetatag,
    {
      client: getClient(),
      fetchPolicy: 'network-only',
    }
  );

  function constructMetatag(type: MetatagType): MetatagEditType {
    return {
      _id: uuid(),
      type,
      properties:
        type === MetatagType.Simple
          ? constructFriendlyType(
              'String',
              t('web.admin.content.metatag.useMetatagEdit.newData')
            )
          : {
              Name: {
                ...constructFriendlyType(
                  'String',
                  t('web.admin.content.metatag.useMetatagEdit.name')
                ),
                _order: 1,
              },
              Icon: {
                ...constructFriendlyType(
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"Icon"' is not assignable to par... Remove this comment to see the full error message
                  'Icon',
                  t('web.admin.content.metatag.useMetatagEdit.icon')
                ),
                _order: 0,
              },
            },
    };
  }

  useEffect(() => {
    // refetch when the id changes.
    if (metatagId && !metatagResult.loading) {
      fetchMetatag({
        variables: {
          id: metatagId,
        },
      });
    }
  }, [metatagId]);

  useEffect(() => {
    if (metatagResult?.data?.metatag) {
      // _ids may not be set in old data, enforce setting them here.
      const metatag = metatagResult.data.metatag;

      if (metatag.type === 'Complex' && metatag.properties) {
        Object.values(metatag.properties).forEach(property => {
          if (!(property as any)._id) {
            (property as any)._id = uuid();
          }
        });
      }

      setHasSchemaChanges(false);
      setMetatag(metatag);
    }
  }, [metatagResult?.data?.metatag]);

  async function resetMetatag() {
    setMetatag(null);
    setHasSchemaChanges(false);

    // get the metatag from the server again.
    fetchMetatag({
      variables: {
        id: metatagId,
      },
    });
  }

  useEffect(() => {
    if (metatag?.type === MetatagType.Complex) {
      // when properties updates, recreate the field array for easy access
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PropertiesInterface<PropertyOpti... Remove this comment to see the full error message
      const fields = objectToArray(metatag.properties);

      fields.sort(byOrder);

      fields.forEach(field => {
        if (!(field as any)._id) {
          (field as any)._id = uuid();
        }
      });

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name?: string | undefined; }[]... Remove this comment to see the full error message
      setFields(fields);
    }
  }, [metatag?.type, metatag?.properties]);

  function updateMetatag(props: Partial<MetatagEditType>) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id?: string | undefined; type... Remove this comment to see the full error message
    setMetatag({
      ...metatag,
      ...props,
    });

    validate();
  }

  function toggleType(complex: boolean) {
    // simple and complex types are different, so re-construct when this
    // changes.
    updateMetatag(
      constructMetatag(complex ? MetatagType.Complex : MetatagType.Simple)
    );
  }

  function addField() {
    const field: PropertyType = {
      _id: uuid(),
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string; new: boolean; placeholder: st... Remove this comment to see the full error message
      new: true,
      placeholder: '',
      name: t('web.admin.content.metatag.create.newField', {
        fieldNumber: `${Object.keys(fields).length + 2}`,
      }),
      type: 'String',
      width: 150,
    };

    setEditingField(field);
  }

  function updateSchemaChanged(value: boolean) {
    setHasSchemaChanges(value);
  }

  /**
   * adds a new field into a complex metatag.
   *
   * @param field
   */
  function saveField(field: any) {
    const properties = {
      ...metatag!.properties,
    };

    // check to see if this field name already exists.
    const existing = Object.entries(metatag!.properties).find(
      ([, value]) => (value as any)._id === field._id
    );

    // if the name of this has changed, remove the old entry.
    if (existing && field.name !== existing[0]) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      delete properties[existing[0]];
    }

    // otherwise just overwrite it.
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    properties[field.name] = field;

    setEditingField(null);

    setHasSchemaChanges(true);

    updateMetatag({
      properties,
    });
  }

  async function removeField(field: PropertyType) {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.content.metatag.tabItem.edit.datePicker.removeFieldName',
          { fieldName: field.name }
        ),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const properties = {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      ...metatag.properties,
    };

    const found = Object.entries(
      metatag!.properties! as PropertiesInterface
    ).find(([, value]) => value._id === field._id);

    if (!found) {
      return;
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete properties[found[0]];

    setEditingField(null);

    setHasSchemaChanges(true);
    updateMetatag({
      properties,
    });
  }

  function validate(forUpdate = false) {
    try {
      if (forUpdate) {
        validateUpdateMetatag.validateSync(metatag);
      } else {
        validateCreateMetatag.validateSync(metatag);
      }

      setValidation(null);

      return true;
    } catch (err) {
      setValidation(t(err.message));

      return false;
    }
  }

  async function saveMetatag() {
    if (!validate(true)) {
      return false;
    }

    setError(null);
    setUpdating(true);
    setHasAttemptedSubmit(true);

    try {
      await pause();

      const { data } = await getClient().mutate({
        refetchQueries: ['getMetatag'],
        mutation: updateMetatagMutation,
        variables: {
          metatag: castForUpdate(metatag),
        },
      });

      setUpdating(false);

      return data.metatag;
    } catch (err) {
      setUpdating(false);
      setError(err);

      throw err;
    }
  }

  async function deleteMetatag() {
    setError(null);
    setUpdating(true);
    setHasAttemptedSubmit(true);

    try {
      await pause();

      await getClient().mutate({
        mutation: deleteMetatagMutation,
        variables: {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          id: metatag._id,
        },
      });
    } catch (err) {
      setError(err);

      throw err;
    } finally {
      setUpdating(false);
    }
  }

  async function createMetatag() {
    if (!validate()) {
      return false;
    }

    setError(null);
    setUpdating(true);
    setHasAttemptedSubmit(true);

    try {
      await pause();

      const { data } = await getClient().mutate({
        mutation: createMetatagMutation,
        variables: {
          libraryItem: {
            channel: {
              _id: channel._id,
            },
            metatag,
          },
        },
      });

      setUpdating(false);

      return data.createMetatag.metatag;
    } catch (err) {
      setUpdating(false);
      setError(err);

      throw err;
    }
  }

  function updateName(name: any) {
    if (metatag?.type === MetatagType.Simple) {
      // also set the name and the key of the inner property for simple types
      updateMetatag({
        name,
        properties: {
          ...metatag.properties,
          key: slugify(name),
          name,
        },
      } as any);
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ name: any; }' is not assignabl... Remove this comment to see the full error message
      updateMetatag({ name });
    }
  }

  return {
    metatag,
    fields,
    error,
    updating,
    validation,
    editingField,
    setEditingField,
    hasAttemptedSubmit,
    hasSchemaChanges,
    updateSchemaChanged,
    validate,
    addField,
    saveField,
    removeField,
    updateName,
    toggleType,
    updateMetatag,
    resetMetatag,
    saveMetatag,
    createMetatag,
    deleteMetatag,
  };
}
