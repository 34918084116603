import React, { useState } from 'react';

import cx from 'classnames';
import { Dropdown, Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  MarkupAmountType,
  TaxAmountType,
  ProductType,
  ChargeCode,
  AddItemType,
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
  Product,
  ProductCategory,
  convertToGqlProductType,
} from 'lane-shared/domains/billingPayments/types';
import { EMPTY_CATEGORY_DETAILS } from 'lane-shared/domains/billingPayments/constants';

import { useChargeCodeListQuery } from 'lane-web/src/pages/portal/admin/channel/products-services/helpers/index';

import { Item } from 'components/form/Dropdown/Dropdown';

import styles from '../addItemsToChargeStyles.scss';
import useUserLocale from 'hooks/useUserLocale';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import type { Sort } from 'design-system-web';
import { ProductCategoryDropdown } from 'lane-web/src/domains/billingAndPayments/components/ProductCategoryDropdown';
import { ProductDropdown } from 'lane-web/src/domains/billingAndPayments/components/ProductDropdown';

export function AddProductCategoryAndProductSelection({
  formType,
  productDetails,
  productType,
  handleProductDetailsUpdate,
  currency,
  inclusivePricing = false,
}: {
  formType: AddItemType | undefined;
  productDetails: AddProductToChargeDetails;
  productType: ProductType;
  handleProductDetailsUpdate: Function;
  currency: SupportedPaymentCurrency;
  inclusivePricing?: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const [selectedCategory, setSelectedCategory] = useState(
    EMPTY_CATEGORY_DETAILS
  );

  const handleCategorySelection = (
    selectedCategoryFromDropdown: ProductCategory
  ) => {
    setSelectedCategory(selectedCategoryFromDropdown);
    handleProductDetailsUpdate({
      ...productDetails,
      productCategoryId: selectedCategoryFromDropdown.productCategoryId,
      productCategoryName: selectedCategoryFromDropdown.name,
      productCategoryType: selectedCategoryFromDropdown.productType,
    });
  };

  const onProductSelection = (selectedProduct: Product) => {
    if (selectedProduct) {
      // TODO: TM-18527 - Update to use the generated types correctly
      const priceComponents = productToPriceComponents(
        (selectedProduct as unknown) as Product
      );
      const { netPrice, rate } = getPricing({
        currency,
        locale,
        priceComponents,
        inclusivePricing,
      });

      handleProductDetailsUpdate({
        ...productDetails,
        productId: selectedProduct.id,
        productGroupId: selectedProduct.groupId,
        name: selectedProduct.name,
        description: selectedProduct.description,
        rate: rate.toString(),
        markup: {
          type:
            selectedProduct?.markup?.markupAmountType ||
            MarkupAmountType.MarkupAmountTypePercentage,
          value: selectedProduct?.markup?.value.toString() || '',
        },
        tax: {
          type:
            selectedProduct.tax?.taxAmountType ||
            TaxAmountType.TaxAmountTypePercentage,
          value: selectedProduct?.tax?.value.toString() || '',
        },
        originalPrice: undefined,
        chargeCode: selectedProduct.chargeCode?.chargeCode || '',
        chargeCodeId: selectedProduct.chargeCode?.id || '',
        netPrice: netPrice.toString(),
      });
    }
  };

  const [inputChargeCode, setInputchargeCode] = useState<string>('');
  const chargeCodeSort: Sort = { id: 'charge_code', direction: 'asc' };
  const chargeCodeList = useChargeCodeListQuery(
    0,
    100,
    chargeCodeSort,
    inputChargeCode
  );

  function getChargeCodeItems(): Item<string>[] {
    const listOfChargeCodes = chargeCodeList.data?.accounts?.listChargeCode
      ?.chargeCodes as ChargeCode[] | undefined;

    if (listOfChargeCodes) {
      const dropdownChargeCodeItems = listOfChargeCodes.map(chargeCode => {
        return {
          label: chargeCode.chargeCode,
          value: chargeCode.id,
        };
      });

      return dropdownChargeCodeItems;
    }

    return [];
  }

  const fetchChargeCodesForInput = async (inputValue: string) => {
    setInputchargeCode(inputValue);

    return getChargeCodeItems();
  };

  const onChargeCodeSelection = (item: Item<string>) => {
    const listOfChargeCodes = chargeCodeList.data?.accounts?.listChargeCode
      ?.chargeCodes as ChargeCode[] | undefined;

    const selectedChargeCode = listOfChargeCodes?.find(
      chargeCode => chargeCode.id === item.value
    );

    if (selectedChargeCode) {
      handleProductDetailsUpdate({
        ...productDetails,
        chargeCodeId: selectedChargeCode.id,
        chargeCode: selectedChargeCode.chargeCode,
      });
    }
  };

  return (
    <>
      <ProductCategoryDropdown
        value={productDetails.productCategoryId}
        handleCategorySelection={handleCategorySelection}
        productType={convertToGqlProductType(productType)}
        className={cx(styles.categoryAndProductDropdown)}
        placeholder={t(
          'abp.productsServices.createProduct.category.placeholder'
        )}
        label={t('abp.charges.addProduct.modal.categoryInputLabel')}
        isRequired
        fixedLabel
      />
      {formType === AddItemType.EXISTING ? (
        <>
          <ProductDropdown
            selectedProduct={productDetails}
            productCategory={selectedCategory}
            handleProductSelection={onProductSelection}
            className={cx(styles.categoryAndProductDropdown)}
            label={t('abp.charges.addProduct.modal.productInputLabel')}
            placeholder={t(
              'abp.charges.addProduct.modal.productInputPlaceholder'
            )}
            isRequired
            fixedLabel
            disabled={Boolean(!productDetails.productCategoryId)}
          />

          <Input
            className={styles.inputFields}
            label={t('abp.charges.addProduct.modal.chargeCodeInputPlaceholder')}
            fixedLabel
            value={productDetails.chargeCode}
            testId="chargeCodeInput"
            disabled
            onChange={() => {}}
          />
        </>
      ) : (
        <>
          <Input
            className={styles.categoryAndProductDropdown}
            label={t('abp.charges.addProduct.modal.productInputLabel')}
            fixedLabel
            isRequired
            value={productDetails.name}
            onChange={(value: string) =>
              handleProductDetailsUpdate(value, 'name')
            }
            testId="productInput"
          />

          <Dropdown
            id="chargeCodeDropdown"
            doTranslation={false}
            className={cx(styles.categoryAndProductDropdown)}
            value={{
              label: productDetails.chargeCode,
              value: productDetails.chargeCodeId,
            }}
            placeholder={t(
              'abp.charges.addProduct.modal.chargeCodeInputPlaceholder'
            )}
            label={t('abp.charges.addProduct.modal.chargeCodeInputLabel')}
            onChange={onChargeCodeSelection}
            defaultOptions={getChargeCodeItems()}
            fixedLabel
            loadOptions={fetchChargeCodesForInput}
          />
        </>
      )}
    </>
  );
}
